<template>
	<header id="nav" class="u-container-fluid relative" :class="{'is-chat-route': currentRouteName === 'messages'}">
		<div class="u-row">
			<div class="u-col u-col-12">
				<div class="left-header-data burger">
					<div class="burger-icon" :class="{'open': sidemenuOpen}" @click="openSidemenu()" v-if="isAuthenticated.status.loggedIn">
						<span></span>
						<span></span>
						<span></span>
					</div>
					<router-link to="/about">
						<div class="about-link">
							Здесь вы можете
							<i class="fas fa-chevron-right"></i>
						</div>
					</router-link>
				</div>
				<div class="left-header-data logo">
					<router-link to="/">
						<img src="../../../assets/img/logo_new.svg" alt="logo">
					</router-link>
					
					<router-link to="/about">
						<div class="about-link">
							Здесь вы можете
							<i class="fas fa-chevron-right"></i>
						</div>
					</router-link>
				</div>
				<div class="centered-header-data logo">
					<router-link to="/">
						<img src="../../../assets/img/logo_new.svg" alt="logo">
					</router-link>
				</div>
				<div v-if="isAuthenticated.status.loggedIn" class="centered-header-data questions-details-min is-auth" >
					<div class="details-title" v-if="+grid.id !== 9999999 && grid.answered > 0">
						Вы ответили на {{this.grid.answered}} из {{this.grid.all}} основных вопросов по теме "{{this.grid.name}}"
					</div>
					<div class="details-title" v-else-if="+grid.id !== 9999999 && grid.answered === 0">
						Вы еще не отвечали на вопросы по теме "{{this.grid.name}}"
					</div>
					<div class="details-title" v-else>
						Вы ответили на {{this.grid.answered}} из {{this.grid.all}} основных вопросов<span v-if="currentRouteName !== 'main-questions'">, <router-link to="/questions/main-questions">
						продолжить <img class="arrow-link" src="../../../assets/img/Arrow-Right-Link.svg" alt=""></router-link></span>
					</div>
					<div class="details-grid" v-if="grid.answered > 0">
						<span class="grid-item" v-bind:class="i === 1 ? 'is-finished' : 'not-finished'" v-for="i of grid.items"></span>
					</div>
				</div>
				<div v-if="!isAuthenticated.status.loggedIn" class="centered-header-data questions-details-min is-unauth">
					<div class="page-title">
						<span v-if="route.name && route.name.includes('login')">Вход</span>
						<span v-if="route.name && route.name.includes('registration')">Регистрация</span>
					</div>
				</div>
				<div v-if="isAuthenticated.status.loggedIn" class="right-header-data is-auth">
					<div class="messages-data">
						<router-link to="/" class="home"></router-link>
						<a class="notifications notifications-dropdown" @click="toggleDropdown">
							<span class="counter notifications-counter" v-show="notificationsCount > 0">{{notificationsCount}}</span>
							<notifications v-if="showNotifDropdown" @handleDropdown="handleDropdown" :is-dropdown="true"></notifications>
						</a>
						<router-link to="/notifications" class="notifications notifications-link">
							<span class="counter notifications-counter" v-show="notificationsCount > 0">{{notificationsCount}}</span>
						</router-link>
						<router-link to="/messages" class="messages">
							<span class="counter notifications-counter" v-show="unreadCount > 0">{{unreadCount}}</span>
						</router-link>
					</div>
					<router-link class="my-profile-link" to="/me" v-if="isAuthenticated.status.loggedIn && isAuthenticated.user">
						<div class="my-profile-data">
							<span class="user-name">{{isAuthenticated.user.name}}</span>
							<avatar :width="36" :height="36" :img="myAvatar"></avatar>
						</div>
					</router-link>
				</div>
				<div v-if="!isAuthenticated.status.loggedIn" class="right-header-data is-unauth">
					<router-link v-if="route.name && route.name.includes('login')" to="/registration" exact>Регистрация</router-link>
					<router-link v-if="route.name && route.name.includes('registration')" to="/login" exact>Войти</router-link>
				</div>


				<div v-if="isAuthenticatedStatus && deviceWidth" class="sidebar-menu" :class="{'open': sidemenuOpen}" @click="openSidemenu()">
					<LeftSidebar :sidebarmenu="true"></LeftSidebar>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import avatar from "../Avatar";
	import LeftSidebar from "./TheLeftSidebar";
	import Notifications from "../../../views/notifications/Notifications";

	export default {
		name: "Header",
		components: {Notifications, LeftSidebar, avatar},
		data() {
			return {
				sidemenuOpen: false,
				route: '',
				showNotifDropdown: false,
				openedDropdown: false,
				userAvatar: null,
				dataForGrid: {},
				grid: [],
				topic_list: [],
				data: {}
			}
		},
		created() {
			this.route = this.$route;
		},
		mounted() {
			if (this.isAuthenticated.user) {
				this.$store.dispatch('question/getTopics', 7);
			}
		},
		computed: {
			isAuthenticatedStatus () {
				return this.$store.state.authentication.status.loggedIn
			},
			myAvatar() {
				return this.$store.state.authentication.user_avatar
			},
			isAuthenticated() {
				return this.$store.state.authentication
			},
			unreadCount() {
				return this.$store.state.firebase.unreadCount
			},
			notificationsCount() {
				return this.$store.state.firebase.notificationsCount
			},
			topicList() {
				return this.$store.state.question.topic_list_main;
			},
			currentRouteName() {
				return this.$route.name;
			},
			deviceWidth() {
				return this.$store.state.misc.device_width <= 768
			},
		},
		methods: {
			logout() {
				this.$store.dispatch('authentication/logout');
			},
			handleDropdown() {
				if (this.openedDropdown && this.showNotifDropdown) {
					this.showNotifDropdown = false;
				}
			},
			toggleDropdown() {
				this.showNotifDropdown = !this.showNotifDropdown;
			},
			gridSetup(v) {
				let arr = [];
				for (let i = 0; i < v.count_all; i++) {
					if (i < v.count_answered) {
						arr.push(1);
					} else {
						arr.push(0);
					}
				}
				this.grid = {
					answered: v.count_answered,
					all: v.count_all,
					items: arr,
					name: v.name,
					id: v.id
				};
			},
			openSidemenu(close) {
				let el = document.getElementsByTagName('body');
				this.sidemenuOpen = (close) ? false : !this.sidemenuOpen;
				(this.sidemenuOpen) ? el[0].classList.add('sidebar-menu-opened') : el[0].classList.remove('sidebar-menu-opened');
			}
		},
		watch: {
			isAuthenticatedStatus(res) {
				if (res) {
					this.$store.dispatch('question/getTopics', 7);
				}
			},
			myAvatar(val) {
				// console.log(val);
			},
			$route: function (newVal, oldVal) {
				this.route = newVal;
				this.showNotifDropdown = false;
				// console.log(this.topic_list);
				this.openSidemenu(true);
				if (this.topic_list[0]) {
					this.gridSetup(this.topic_list[0]);
				}
			},
			showNotifDropdown: function (newVal, oldVal) {
				if (this.showNotifDropdown) {
					setTimeout(() => {
						this.openedDropdown = true;
					}, 350);
				} else {
					this.openedDropdown = false;
				}
			},
			topicList: {
				handler: function (val) {
					// console.log(val);
					this.topic_list = val;
					val.forEach(v => {
						if (v['active'] === 1) {
							//this.dataForGrid = v;
							this.gridSetup(v);
						}
					})
				},
				deep: true
			}
		},
	}
</script>

<style lang="less" scoped>
	@import "../../../assets/styles/_vars.less";
	#nav {
		position: -moz-sticky;
		position: -o-sticky;
		position: -ms-sticky;
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		width: 100%;
		background-color: #fff;
		margin-bottom: 30px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
		z-index: 999;
		.u-row {
			.u-col {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				height: 64px;
				a {
					&.router-link-exact-active {
						// color: #42b983;
					}
				}
				.left-header-data {
					width: 30%;
					text-align: left;
					a {
						&:last-child {
							width: 100%;
						}
					}
					&.burger {
						display: none;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: nowrap;
						.burger-icon {
							display: block;
							cursor: pointer;
							&.open {
								span {
									&:first-child {
										opacity: 1;
										transform: rotate(45deg) translate(0, -4px);
									}
									&:nth-child(2) {
										opacity: 0;
									}
									&:last-child {
										transform: rotate(-45deg) translate(0, 0);
									}
								}
							}
							span {
								display: block;
								width: 25px;
								height: 3px;
								margin-bottom: 3px;
								position: relative;
								background: #7b7b7b;
								border-radius: 3px;
								z-index: 1;
								transform-origin: 4px 0;
								transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
								background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
								opacity 0.55s ease;
								&:first-child {
									transform-origin: 0 0;
								}
								&:nth-child(2) {
									transform-origin: 0 100%;
								}
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
						a {
							width: 100%;
							.about-link {
								text-align: center;
								font-size: 12px;
								padding: 5px;
								color: @theme_color;
								line-height: 14px;
								.fa-chevron-right {
									color: rgba(222,94,86,1);
									font-size: 12px;
								}
							}
						}
					}
					&.logo {
						display: flex;
						justify-content: space-evenly;
						align-items: center;
						img {
							width: 119px;
							height: 40px;
							/*width: 100%;
                            height: 100%;*/
						}
						.about-link {
							text-align: center;
							font-size: 12px;
							padding: 5px;
							color: @theme_color;
							line-height: 14px;
							.fa-chevron-right {
								color: rgba(222,94,86,1);
								font-size: 12px;
							}
						}
					}
				}
				.centered-header-data {
					width: 40%;
					&.logo {
						display: none;
						a {
							margin: 0 auto;
							img {
								width: 80%;
							}
						}
					}
					&.is-auth {
						font-size: 12px;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: flex-start;
						.details-title {
							font-size: 12px;
							font-weight: 400;
							color: #333333e6;
							// margin-bottom: 10px;
							text-align: center;
							margin: 0 auto 10px auto;
							a {
								display: inline-flex;
								color: @theme_color;
							}
							.arrow-link {
								width: 12px;
								height: 18px;
							}
						}
						.details-grid {
							display: flex;
							flex-wrap: wrap;
							max-width: 400px;
							margin: 0 auto 0 auto;
							width: 100%;
							.grid-item {
								width: 3px;
								height: 3px;
								margin: 0.5px;
								border-radius: 30px;
								&.is-finished {
									background-color: #999999;
								}
								&.not-finished {
									background-color: #D8D8D8;
								}
							}
						}
					}
					&.is-unauth {
						.page-title {
							color: #666666;
							font-size: 18px;
							text-transform: uppercase;
							font-weight: 500;
						}
					}
				}
				.right-header-data {
					width: 30%;
					&.is-auth {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						flex-wrap: wrap;
						.messages-data {
							margin-right: 40px;
							display: flex;
							justify-content: center;
							align-items: center;
							flex-wrap: nowrap;
							.notifications-link {
								display: none;
							}
							a {
								position: relative;
								height: 24px;
								width: 24px;
								&:not(:last-child) {
									margin-right: 15px;
								}
								span {
									position: absolute;
									width: 18px;
									height: 18px;
									font-size: 10px;
									background-color: #d6585bde;
									color: white;
									top: -9px;
									right: -9px;
									border-radius: 50%;
									box-shadow: 0 0 0 3px #ffffffe0;
									transition: 250ms all;
									display: flex;
									justify-content: center;
									align-items: center;
								}
								&.notifications {
									cursor: pointer;
									background: url('../../../assets/img/notifications.svg') 0 0 no-repeat;
								}
								&.messages {
									background: url('../../../assets/img/messages.svg') 0 0 no-repeat;
								}
								&.home {
									background: url('../../../assets/img/home.svg') 0 0 no-repeat;
								}
							}
							img {
								width: 17px;
							}
						}
						.my-profile-data {
							display: flex;
							justify-content: center;
							align-items: center;
							.user-name {
								margin-right: 10px;
								font-size: 12px;
								font-weight: 500;
								width: 160px;
								display: inline-block;
								white-space: nowrap;
								overflow: hidden !important;
								text-overflow: ellipsis;
								text-align: right;
							}
						}
					}
					&.is-unauth {
						font-size: 12px;
						font-weight: 500;
						text-align: right;
					}
				}

				.sidebar-menu {
					display: none;
					position: absolute;
					left: 0;
					top: 54px;
					transform: translateX(-100%);
					width: 100%;
					transition: all 0.5s;
					&.open {
						transform: translateX(0);
					}
					#left-sidebar {
						height: calc(100vh - 54px);
						background-color: #e0e3ea;
						padding: 30px 40px 30px 20px;
						width: fit-content;
						overflow-y: auto;
					}
				}
			}
		}
	}

	// Large devices (desktops, less than 1200px)
	@media (max-width: 1200px) {
		#nav {
			.u-row {
				.u-col {
					.right-header-data {
						&.is-auth {
							.my-profile-data {
								.user-name {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
	// Medium devices (tablets, less than 992px)
	@media (max-width: 991px) {  }
	// Small devices (landscape phones, less than 768px)
	@media (max-width: 768px) {
		#nav {
			&.is-chat-route {
				margin-bottom: 10px;
			}
			.u-row {
				.u-col {
					height: 54px;
					.left-header-data {
						&.burger {
							display: flex;
						}
						&.logo {
							display: none;
						}
					}
					.centered-header-data {
						&.logo {
							display: flex;
						}
						&.is-auth, &.is-unauth {
							display: none;
						}
					}
					.right-header-data {
						&.is-auth {
							.messages-data {
								margin-right: 0;
								.home {
									display: none;
								}
								.notifications-dropdown {
									display: none;
								}
								.notifications-link {
									display: flex;
								}
							}
							.my-profile-link {
								display: none;
							}
						}
					}
					.sidebar-menu {
						display: flex;
					}
				}
			}
		}
	}
	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 576px) {
		#nav {
			.u-row {
				.u-col {
					.left-header-data {
						&.burger {
							a {
								// display: none;
								.about-link {
									font-size: 11px;
									line-height: 10px;
									.fa-chevron-right {
										font-size: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
</style>
