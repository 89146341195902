<template>
	<transition name="c-modal-fade">
		<div class="c-modal-backdrop-wr" v-if="isVisible">
			<div class="c-modal">
				<div class="window">
					<p>{{ message }}</p>
					<div class="btns">
						<button v-if="hasCancelBtn" class="c-cancel-btn" @click="_cancel">{{ cancelButton }}</button>
						<button class="с-ok-btn" @click="_confirm">{{ okButton }}</button>
					</div>
				</div>
			</div>
			<div class="c-modal-backdrop"></div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'CustomModal',
	props: {
		hasCancelBtn: {
			default: true,
			type: Boolean
		}
	},
	data () {
		return {
			isVisible: false,
			title: undefined,
			message: undefined, // Main text content
			okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
			cancelButton: 'Нет', // text for cancel button
			
			// Private variables
			resolvePromise: undefined,
			rejectPromise: undefined,
			
			isSecondModal: false
		}
	},
	watch: {
		isVisible: function(res) {
			console.log(this.isSecondModal);
			if (res) {
				this.isSecondModal = document.body.classList.contains('modal-open');
				document.body.classList.add("modal-open");
			} else {
				if (!this.isSecondModal) {
					setTimeout(() => {
						document.body.classList.remove("modal-open");
					}, 300)
				}
			}
		},
	},
	destroyed: function() {
		this.$el.parentNode.removeChild(this.$el);
	},
	
	methods: {
		open() {
			this.isVisible = true;
			this.$root.$el.append(this.$el);
		},
		close() {
			this.isVisible = false
		},
		show(opts = {}) {
			this.title = opts.title;
			this.message = opts.message;
			this.okButton = opts.okButton;
			if (opts.cancelButton) {
				this.cancelButton = opts.cancelButton
			}
			// Once we set our config, we tell the popup modal to open
			this.open();
			// Return promise so the caller can get results
			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			})
		},
		
		_confirm() {
			this.close();
			this.resolvePromise(true);
		},
		
		_cancel() {
			this.close();
			this.resolvePromise(false);
			// Or you can throw an error
			// this.rejectPromise(new Error('User cancelled the dialogue'))
		},
	},
}
</script>

<style lang="less" scoped>
@import "../../../assets/styles/_vars.less";

/* css class for the transition */
.c-modal-fade-enter-active,
.c-modal-fade-leave-active {
	transition: opacity 0.3s;
}
.c-modal-fade-enter,
.c-modal-fade-leave-to {
	opacity: 0;
}

.c-modal-backdrop-wr {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1050;
	.c-modal {
		overflow-x: auto;
		display: flex;
		flex-direction: column;
		z-index: 1041;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		.window {
			background: #fff;
			border-radius: 5px;
			box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
			max-width: 480px;
			margin-left: auto;
			margin-right: auto;
			padding: 1rem;
			.btns {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				justify-content: flex-end;
				.с-ok-btn {
					border: 1px solid @theme_color;
					box-shadow: 0 0 2px 0 @theme_color;
					background: transparent;
					border-radius: 5px;
					padding: 2px 10px;
					cursor: pointer;
					color: @theme_color;
					transition: all 250ms;
					width: auto;
					text-align: center;
					&:hover {
						color: #fff;
						background-color: @theme_color;
					}
				}
				.c-cancel-btn {
					border: 1px solid white;
					box-shadow: 0 0 2px 0 red;
					margin-right: 20px;
					background: transparent;
					border-radius: 5px;
					padding: 2px 10px;
					cursor: pointer;
					color: red;
					transition: all 250ms;
					width: auto;
					min-width: 50px;
					text-align: center;
					&:hover {
						border: 1px solid red;
					}
				}
			}
		}
	}
	.c-modal-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1040;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 0;
	}
}
</style>
