import { render, staticRenderFns } from "./_PostDetailedModal.vue?vue&type=template&id=2752b6af&"
import script from "./_PostDetailedModal.vue?vue&type=script&lang=js&"
export * from "./_PostDetailedModal.vue?vue&type=script&lang=js&"
import style0 from "./_PostDetailedModal.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports