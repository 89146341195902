export const misc = {
	namespaced: true,
	state: {
		device_width: 1920
	},
	actions: {
		setDeviceWidth({commit, dispatch}, data) {
			commit('saveDeviceWidth', data)
		}
	},
	mutations: {
		saveDeviceWidth(state, data) {
			state.device_width = data;
		},
	}
};
