import {QuestionModel} from "@/_models/question";

// types: 101(image post), 3(personal question), 2(main question), 1(question of the day)

export function parsePostBody(q, show_item = false) {
    return new Promise((resolve, reject) => {
        q['question_id'] = q.id;
        q['object_id'] = q.id;
        q['id'] = q.unique_id;
        q['show_item'] = show_item;
        q['is_important'] = (q.is_important) ? q.is_important : false;
        q['bg_color'] = q.color || '#c1debe';
        q['placeholder'] = 'Начните вводить текст...';
        q['visibility'] = (q.visibility !== undefined && q.visibility !== null) ? q.visibility : 1;
        q['is_radio'] === 1 ? q['selected'] = null : q['selected'] = [];
        q['friends_count'] = q.friend_answers_count;
        q['all_count'] = q.answer_count;
        q['dates_of_questions_the_day'] = q.dates_of_questions_the_day;
        //console.log(q);

        resolve(q);

    })
}
