<template>
    <div id="visibility-item-select">
        <div class="selected-item" @click="toggleDropdown">
            <span v-if="!noIcon" class="icon" v-bind:class="selectedItem.class"></span>
            <span class="description">{{selectedItem.description}}</span>
        </div>
        <div ref="dropdown_wrapper" class="dropdown-wrapper" v-if="showDropdown" :class="{'top': ddPosition === 'top'}">
            <div class="item" v-for="i in listOfItems" @click="selectItem(i)">
                <span v-if="!noIcon" class="icon" v-bind:class="i.class"></span>
                <span class="description">{{i.description}}</span>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'visibility-item-select',
        props: {
            visibilityType: Number,
            isDisabled: Boolean,
            noIcon: Boolean,
            customVisibilityList: Array,
            ddPosition: String
        },
        data() {
            return {
                listOfItems: [
                    {
                        description: 'Все',
                        class: 'eye',
                        type: 1
                    },
                    {
                        description: 'Друзья Up&Up',
                        class: 'circle',
                        type: 2
                    },
                    {
                        description: 'Никто',
                        class: 'secret',
                        type: 0
                    }
                ],
                selectedItem: {},
                showDropdown: false
            }
        },
        watch: {
            isDisabled: function (val) {
                if (val) {
                    this.showDropdown = false;
                }
            },
            visibilityType: function (val) {
                this.findPreselected();
            }
        },
        computed: {},
        created() {
            if (this.customVisibilityList) {
                // // console.log(this.customVisibilityList);
                this.listOfItems = [...this.customVisibilityList];
            }
        },
        mounted() {
            this.findPreselected();
        },
        beforeDestroy () {},
        methods: {
            findPreselected() {
                for (let i = 0; i < this.listOfItems.length; i++) {
                    if (this.visibilityType === this.listOfItems[i].type) {
                        this.selectedItem = this.listOfItems[i];
                    }
                }
            },
            toggleDropdown (e) {
                e.stopPropagation(); // this will stop propagation of this event to upper level
                if (!this.isDisabled) {
                    this.showDropdown = !this.showDropdown;
                    if (this.showDropdown) {
                        window.addEventListener('click', () => {
                        this.showDropdown = false
                        })
                    } else {
                        window.removeEventListener('click', () => {
                        this.showDropdown = false
                        })
                    }
                }
            },
            selectItem(i) {
                // console.log(i);
                this.selectedItem = i;
                this.showDropdown = false;
                this.$emit('updateType', this.selectedItem.type);
            }
        }
    }

</script>

<style lang="less" scoped>
    @import "../../assets/styles/_vars";
    #visibility-item-select {
        width: 80px;
        position: relative;
        &.def-icon {
            .selected-item {
                flex-direction: row;
                .icon {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-right: 3px;
                    &.visible-icon {
                        display: inline-flex;
                    }
                    &.circle {
                        width: 24px;
                        height: 24px;
                        background-image: url(../../assets/img/circle.svg);
                        background-size: 12px;
                    }
                    &.eye {
                        width: 24px;
                        height: 24px;
                        background-image: url(../../assets/img/eye-icon.svg);
                        background-size: 20px;
                    }
                    &.secret {
                        width: 24px;
                        height: 24px;
                        background-image: url(../../assets/img/secret.svg);
                        background-size: 14px;
                    }
                }
                .description {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
            .dropdown-wrapper {
                .item {
                    .icon {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin-right: 3px;
                        &.visible-icon {
                            display: inline-flex;
                        }
                        &.circle {
                            width: 24px;
                            height: 24px;
                            background-image: url(../../assets/img/circle.svg);
                            background-size: 12px;
                        }
                        &.eye {
                            width: 24px;
                            height: 24px;
                            background-image: url(../../assets/img/eye-icon.svg);
                            background-size: 20px;
                        }
                        &.secret {
                            width: 24px;
                            height: 24px;
                            background-image: url(../../assets/img/secret.svg);
                            background-size: 14px;
                        }
                    }
                    .description {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
        }
        &.only-small-icon {
            width: auto;
            .selected-item {
                flex-direction: column;
                justify-content: center;
                .icon {
                    width: 15px;
                    height: 15px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    &.visible-icon {
                        display: inline-flex;
                    }
                    &.circle {
                        background-image: url(../../assets/img/circle.svg);
                        background-size: 8px;
                    }
                    &.eye {
                        background-image: url(../../assets/img/eye-icon.svg);
                    }
                    &.secret {
                        background-image: url(../../assets/img/secret.svg);
                    }
                }
                .description {
                    display: none;
                }
            }
            .dropdown-wrapper {
                min-width: 34px;
                .item {
                    justify-content: center;
                    flex-direction: column;
                    .icon {
                        width: 12px;
                        height: 12px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        &.visible-icon {
                            display: inline-flex;
                        }
                        &.circle {
                            background-image: url(../../assets/img/circle.svg);
                            background-size: 8px;
                        }
                        &.eye {
                            background-image: url(../../assets/img/eye-icon.svg);
                        }
                        &.secret {
                            background-image: url(../../assets/img/secret.svg);
                        }
                    }
                    .description {
                        font-size: 8px;
                        line-height: 18px;
                        letter-spacing: 0.0615385px;
                        color: #666666;
                    }
                }
            }
        }
        &.small-icon {
            width: auto;
            .selected-item {
                flex-direction: column;
                justify-content: center;
                .icon {
                    width: 12px;
                    height: 12px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    &.visible-icon {
                        display: inline-flex;
                    }
                    &.circle {
                        background-image: url(../../assets/img/circle.svg);
                        background-size: 8px;
                    }
                    &.eye {
                        background-image: url(../../assets/img/eye-icon.svg);
                    }
                    &.secret {
                        background-image: url(../../assets/img/secret.svg);
                    }
                }
                .description {
                    font-size: 8px;
                    line-height: 18px;
                    letter-spacing: 0.0615385px;
                    color: #666666;
                }
            }
            .dropdown-wrapper {
                min-width: 34px;
                .item {
                    justify-content: center;
                    flex-direction: column;
                    .icon {
                        width: 12px;
                        height: 12px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        &.visible-icon {
                            display: inline-flex;
                        }
                        &.circle {
                            background-image: url(../../assets/img/circle.svg);
                            background-size: 8px;
                        }
                        &.eye {
                            background-image: url(../../assets/img/eye-icon.svg);
                        }
                        &.secret {
                            background-image: url(../../assets/img/secret.svg);
                        }
                    }
                    .description {
                        font-size: 8px;
                        line-height: 18px;
                        letter-spacing: 0.0615385px;
                        color: #666666;
                    }
                }
            }
        }
        .selected-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 2px 5px;
            cursor: pointer;
            .icon {
                display: none;
            }
            .description {
                font-size: 12px;
                line-height: 14px;
                color: #333333;
            }
        }
        .dropdown-wrapper {
            z-index: 99;
            position: absolute;
            width: auto;
            // width: 100%;
            top: 100%;
            left: 0;
            // left: 50%;
            /*transform: translateX(-50%);*/
            transform: translateX(0);
            padding: 3px 0;
            background: #fff;
            border-radius: 0 0 1px 1px;
            box-shadow: 0 0 6px -4px black;
            &.top {
                top: unset;
                bottom: 100%;
            }
            .item {
                &:hover {
                    background-color: #E8F1F9;
                }
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 2px 5px;
                cursor: pointer;
                .description {
                    font-size: 12px;
                    line-height: 14px;
                    color: #333333;
                }
            }
        }
    }
</style>
