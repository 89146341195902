import axios from 'axios';

export const questionsService = {
    getFirstQuestions,
    sendAnswer,
    changeAnswer,
    acceptAnswer,
    sendAnswerPQ,
    changeAnswerPQ,
    getClosedQuestions,
    getOpenQuestions,
    getAllQuestions,
    getClosedQOTDQuestions,
    getOpenQOTDQuestions,
    getAllQOTDQuestions,
    getQOTD,
    getQuestionsFeed,
    getPersonalQuestions,
    getPersonalQuestionsInnerCircle,
    createPersonalQuestion,
    editPersonalQuestion,
    deletePersonalQuestion,
    checkIfPQEditingAvailable,
    getTopicList,
    getUsersList,
    getUsersForAskPersonalQuestion,
    getUsersForAskQuestionAPI,
    getUserProfileData,
    getSingleQuestionsCountersAPI,
    getSingleQuestionsUsersWhoAnsweredAPI,
    getPersonalQuestionsAskedByUser,
    askQuestion,
    searchQuestionsAPI,
    searchPersonalQuestionsAPI,
    getUserProgressBarAPI,
    getSimilarQuestion,
    getSingleQuestionDataAPI,
    deleteAnswerAPI,
    getCombinedQuestions
};


function getSingleQuestionDataAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/id`, {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}
function getSingleQuestionsCountersAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/question/counters`, {
            params: data
        }).then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })
}
function getSingleQuestionsUsersWhoAnsweredAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/users/answer`, {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}

function getQuestionsFeed(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/feed/questions`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}
function getFirstQuestions() {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/first`,   {})
        .then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })
}
function getOpenQuestions(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/opened`,     {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}
function getAllQuestions(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions`,     {
            params: data
        }).then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })
}
function getClosedQuestions(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/closed`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}

// QOTD = question of the day
function getOpenQOTDQuestions(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/day/opened`,     {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}
function getAllQOTDQuestions(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/day/all`,     {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}
function getClosedQOTDQuestions(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/day/closed`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}
function getQOTD(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/day`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}

function getCombinedQuestions(data) {
    return axios.get(
            `${process.env.VUE_APP_BASE_URL}v1/questions/all`,   {
                params: data
            }).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}

function sendAnswer(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/answers`, data)
        .then((res) => {
            return res;
        }).catch(err => {
            // console.log(err);
        })
}
function changeAnswer(data) {
    console.log('PUT');
    return axios.put(
        `${process.env.VUE_APP_BASE_URL}v1/answers`, data)
        .then((res) => {
            return res;
        }).catch(err => {
            // console.log(err);
        })
}
function acceptAnswer(data) {
    console.log('PUT');
    return axios.put(
        `${process.env.VUE_APP_BASE_URL}v1/answers/accept`, data)
        .then((res) => {
            return res;
        }).catch(err => {
            // console.log(err);
        })
}
function sendAnswerPQ(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/personal_answers`, data)
        .then((res) => {
            return res;
        }).catch(err => {
            // console.log(err);
        })
}
function changeAnswerPQ(data) {
    console.log('PUT');
    return axios.put(
        `${process.env.VUE_APP_BASE_URL}v1/personal_answers`, data)
        .then((res) => {
            return res;
        }).catch(err => {
            // console.log(err);
        })
}
function getPersonalQuestions(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/personal_questions`, {
            params: data
        }).then((res) => {
        // console.log(res);
        return res.data;
    }).catch(err => {
        // console.log(err);
        return Promise.reject(err);
    })
}
function getPersonalQuestionsInnerCircle(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/personal_questions/asked_by_friends`, {
            params: data
        }).then((res) => {
        // console.log(res);
        return res.data;
    }).catch(err => {
        // console.log(err);
        return Promise.reject(err);
    })
}
function createPersonalQuestion(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/personal_questions`, data).then((res) => {
        return res;
    }).catch(err => {
        console.log(err);
    })
}
function deletePersonalQuestion(data) {
    console.log(data);
    return axios.delete(
        `${process.env.VUE_APP_BASE_URL}v1/personal_questions`, {
            params: data
        }).then((res) => {
        return res;
    }).catch(err => {
        console.log(err);
    })
}
function checkIfPQEditingAvailable(data) {
    console.log(data);
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/personal_questions/check`, data).then((res) => {
        return res;
    }).catch(err => {
        console.log(err);
    })
}
function editPersonalQuestion(data) {
    console.log(data);
    return axios.put(
        `${process.env.VUE_APP_BASE_URL}v1/personal_questions`, data).then((res) => {
        return res;
    }).catch(err => {
        console.log(err);
    })
}
function getTopicList(type) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/topics`,   {
            params: {
                type_id: type
            }
        })
        .then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })
}

function getUserProfileData(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/user/data`, {
            params: {
                user_id: null
            }
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}
function getUsersList(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/search/users`,   {
            params: {
                perPage: data.perPage,
                page: data.page,
                query: data.query
            }
        })
        .then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })
}
function getUsersForAskPersonalQuestion(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/personal_questions/ask/users`,   {
            params: data
        }).then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })

}
function getUsersForAskQuestionAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/ask/users`,   {
            params: data
        }).then((res) => {
            console.log(res);
            return res.data;
        }).catch(err => {
            // console.log(err);
        })

}

function getPersonalQuestionsAskedByUser(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/personal_questions/asked_by_user/${data.user_id}`,   {
            params: {
                user_id: data.user_id,
                page: data.page
            }
        }).then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}

function askQuestion(data) {
    console.log(data);
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/questions/ask`, data).then((res) => {
        return res;
    }).catch(err => {
        console.log(err);
    })
}

function searchQuestionsAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/search/questions`, {
            params: {
                page: data.page,
                perPage: data.perPage,
                topic_id: data.topic_id,
                query: data.query,
                type: data.type, // opened/closed/blank if all,
                is_day: data.is_day,
                day_main: data.day_main,
            }
        }).then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })
}
function searchPersonalQuestionsAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/search/personal_questions`, {
            params: {
                page: data.page,
                perPage: data.perPage,
                query: data.query,
                type: data.type, // opened/closed/blank if all,
                is_my: data.is_my
            }
        }).then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })
}

function getUserProgressBarAPI(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/counters`, data).then((res) => {
        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}


function getSimilarQuestion(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/questions/similar`, {
            params: {
                page: data.page,
                perPage: data.perPage,
                user_id: data.user_id,
                topic_id: data.topic_id
            }
        }).then((res) => {
            return res.data;
        }).catch(err => {
            // console.log(err);
        })
}

function deleteAnswerAPI(data) {
    console.log(data);
    return axios.delete(
        `${process.env.VUE_APP_BASE_URL}v1/answers`, {
            params: data
        }).then((res) => {
        return res;
    }).catch(err => {
        console.log(err);
    })
}

