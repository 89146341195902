import {questionsService} from "@/_api";

const initialState = {
    topic_list_main: [],
    topic_list_personal: [],
    topic_list_qotd: [],
    topic_list_home: [],
    topic_list_combined: [],
    active_main_topic_id: null,
    active_personal_topic_id: null,
    active_qotd_topic_id: null,
    active_home_topic_id: null,
    active_combined_topic_id: null,
    search: {active: false, str: ''}
};

export const question = {
    namespaced: true,
    state: initialState,
    actions: {
        getTopics({ commit, dispatch, state }, type) {
            questionsService.getTopicList(type).then((res) => {
                function fn(topic_type, id) {
                    if (topic_type === 7) {
                        if (state['active_main_topic_id'] === id) {
                            return 1
                        } else {
                            return 0
                        }
                    } else if (topic_type === 8) {
                        if (state['active_personal_topic_id'] === id) {
                            return 1
                        } else {
                            return 0
                        }
                    } else if (topic_type === 9) {
                        if (state['active_qotd_topic_id'] === id) {
                            return 1
                        } else {
                            return 0
                        }
                    } else if (topic_type === 11) {
                        if (state['active_home_topic_id'] === id) {
                            return 1
                        } else {
                            return 0
                        }
                    } else if (topic_type === 12) {
                        if (state['active_combined_topic_id'] === id) {
                            return 1
                        } else {
                            return 0
                        }
                    }
                }
                function invertColor(hex, bw) {
                    if (hex.indexOf('#') === 0) {
                        hex = hex.slice(1);
                    }
                    // convert 3-digit hex to 6-digits.
                    if (hex.length === 3) {
                        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                    }
                    if (hex.length !== 6) {
                        throw new Error('Invalid HEX color.');
                    }
                    var r = parseInt(hex.slice(0, 2), 16),
                            g = parseInt(hex.slice(2, 4), 16),
                            b = parseInt(hex.slice(4, 6), 16);
                    if (bw) {
                        // https://stackoverflow.com/a/3943023/112731
                        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                                ? '#000000'
                                : '#FFFFFF';
                    }
                    // invert color components
                    r = (255 - r).toString(16);
                    g = (255 - g).toString(16);
                    b = (255 - b).toString(16);
                    // pad each with zeros and return
                    return "#" + padZero(r) + padZero(g) + padZero(b);
                }
                for (let i = 0; i < res.length; i++) {
                    if (!res[i]['id']) {
                        res[i]['id'] = 9999999;
                        // res[i]['active'] = 1;
                    }
                    res[i]['active'] = fn(type, res[i]['id']);
                    if (res[i]['layout_color']) {
                        res[i]['oposite_layout_color'] = invertColor(res[i]['layout_color'], true);
                    }
                }
                if (!res.filter(obj => {return obj.active}).length) {
                    res.map(o => {
                        if (o.id === 9999999) {
                            o['active'] = 1;
                        }
                        return o;
                    })
                }
                let data = {res, type};
                console.log(data);
                commit('topicsSuccess', data);
            })

        },
        setActiveTopic({ commit, dispatch, state }, obj) {
            function fn(arr) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i]['id'] === obj.item['id']) {
                        arr[i]['active'] = 1;
                        commit('setActiveTopic', {
                            id: arr[i]['id'],
                            type: obj.question_type
                        });
                    } else {
                        arr[i]['active'] = 0;
                    }
                }

                setTimeout(() => {
                    let data = {
                        res: arr,
                        type: obj.question_type
                    };
                    commit('topicsSuccess', data);
                }, 500);

                return arr
            }
            if (obj.question_type === 7) {
                fn(state.topic_list_main)
            } else if (obj.question_type === 8) {
                fn(state.topic_list_personal);
            } else if (obj.question_type === 9) {
                fn(state.topic_list_qotd);
            } else if (obj.question_type === 11) {
                fn(state.topic_list_home);
            } else if (obj.question_type === 12) {
                fn(state.topic_list_combined);
            }
        },

        setSearch({ commit, dispatch, state }, data) {
            state.search = {active: data.active, str: data.str};
        }
    },
    mutations: {
        topicsSuccess(state, data) {
            if (data.type === 7) {
                state.topic_list_main = data.res;
            } else if (data.type === 8) {
                state.topic_list_personal = data.res;
            } else if (data.type === 9) {
                state.topic_list_qotd = data.res;
            } else if (data.type === 11) {
                state.topic_list_home = data.res;
            } else if (data.type === 12) {
                state.topic_list_combined = data.res;
            }
        },
        setActiveTopic(state, data) {
            if (data.type === 7) {
                state.active_main_topic_id = data.id;
            } else if (data.type === 8) {
                state.active_personal_topic_id = data.id;
            } else if (data.type === 9) {
                state.active_qotd_topic_id = data.id;
            } else if (data.type === 11) {
                state.active_home_topic_id = data.id;
            } else if (data.type === 12) {
                state.active_combined_topic_id = data.id;
            }
        }
    }
};
