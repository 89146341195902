<template>
	<div id="start-chat-btn">
		<!--<button class="def-btn has-loading-grid" @click="startChat()" :disabled="loadingChat">Написать сообщение <loading :reverse="true" v-if="loadingChat"></loading></button>-->
		<c_button :c_class="'def-btn'" :c_loading="loadingChat" :c_text="'Написать сообщение'" @clickEvent="startChat()"></c_button>
		<ChatModal v-if="data" ref="chat-modal"></ChatModal>
	</div>
</template>

<script>
	import ChatModal from "@/components/_shared/modals/_ChatModal";
	import {usersService} from "@/_api";
	import Loading from "@/components/_shared/Loading";
	import C_button from "@/components/_shared/Button";
	
	export default {
		name: 'StartChatBtn',
		props: {
			data: {
				required: true
			}
		},
		data() {
			return {
				loadingChat: false
			}
		},
		components: {C_button, Loading, ChatModal},
		computed: {
			deviceWidth() {
				return this.$store.state.misc.device_width <= 768
			},
		},
		methods: {
			startChat() {
				this.loadingChat = true;
				console.log(this.data);
				usersService.getChatIdAPI(this.data).then((res) => {
					setTimeout(() => {
						this.loadingChat = false;
					}, 500);
					console.log(res);
					console.log(this.deviceWidth);
					if (res) {
						// this.$refs['chat-modal'].showModal(res, true);
						(this.deviceWidth) ? this.$router.push({ path: '/messages', query: { t: res, ff: true } }) : this.$refs['chat-modal'].showModal(res, true);
						// console.log(res);
						// this.$router.push({ path: '/messages', query: { t: res, ff: true } });
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped></style>
