<template>
    <div id="search-filter">
        <div class="search-wrapper">
            <input type="text" class="search-field" v-model="search" @keyup="handleEnter" :placeholder="placeholderText" @change="onChange">
            <span class="search-error" v-if="search_error && showError">Для того чтобы осуществить поиск введите как минимум 3 символа.</span>
            <button v-if="activeSearch" @click="clearSearch" class="clear-search-btn"></button>
            <button @click="filteredList(false)" class="search-btn" :disabled="searchDisabled">Найти</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'search-filter',
        props: {
            items: Array,
            placeholderText: String,
            isServerSide: Boolean,
            showError: Boolean,
            searchDisabled: Boolean,
            preloadData: Object
        },
        data () {
            return {
                search: '',
                activeSearch: false,
                search_error: false
            }
        },
        computed: {},
        methods: {
            onChange() {
                this.$emit('onChange', this.search);
            },
            filteredList(isClear) {
                if (this.search.length >= 3 || isClear) {
                    let arr = this.items;
                    arr = arr.filter(item => {return item.name.toLowerCase().includes(this.search.toLowerCase())});
                    this.activeSearch = true;
                    this.search_error = false;
                    this.$emit('filtered', {
                        arr: arr,
                        searchStr: this.search
                    });
                } else {
                    this.search_error = true;
                }
            },
            handleEnter(e) {
                if (e.keyCode === 13) {
                    if (this.searchDisabled === undefined || !this.searchDisabled) {
                        this.filteredList(false);
                    }

                }
            },
            clearSearch() {
                this.search = '';
                this.filteredList(true);
                this.activeSearch = false;
            },
            remoteClearSearch() {
                this.search = '';
                this.activeSearch = false;
            }
        },
        created() {
            if (this.preloadData && this.preloadData.name) {
                this.search = this.preloadData.name;
                if (this.preloadData.name.length) {
                    this.activeSearch = true;
                }
            }
            if (this.preloadData && this.preloadData.search_str) {
                this.search = this.preloadData.search_str;
                if (this.preloadData.search_str.length && this.preloadData.active) {
                    this.activeSearch = true;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    #search-filter {
        .search-wrapper {
            width: 100%;
            display: flex;
            padding: 12px 16px;
            position: relative;
            .search-error {
                position: absolute;
                font-size: 10px;
                z-index: 20;
                bottom: 0;
                left: 0;
                color: #e91e63;
            }
            .search-field {
                font-size: 13px;
                line-height: 20px;
                color: #999999;
                width: 100%;
                padding-left: 35px;
                border: none;
                outline: none;
                background: transparent url(../../assets/img/search-right.svg) left center no-repeat;
            }
            .clear-search-btn {
                background: transparent;
                height: 22px;
                width: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                position: relative;
                margin-right: 5px;
                outline: none;
                &:before {
                    content: '+';
                    position: absolute;
                    transform: rotate(45deg);
                    font-size: 24px;
                    color: red;
                }
            }
            .search-btn {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                text-align: right;
                text-transform: uppercase;
                color: #666666;
                background: transparent;
                border: none;
                outline: none;
                text-align: right;
                padding: 0px;
            }
            
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #search-filter {
            .search-wrapper {
                padding: 8px 12px;
            }
        }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
</style>
