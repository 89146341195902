import Vue from "vue";
import Vuex from "vuex";

import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { firebase } from './firebase.module';
import { question } from './question.module';
import { misc } from "@/_store/misc.module";
// import createPersistedState from "vuex-persistedstate";
/*import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
	storage: window.localStorage
});*/

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	actions: {},
	mutations: {},
	modules: {
		alert,
		authentication,
		firebase,
		question,
		misc
	},
	plugins: []
})
