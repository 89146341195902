import axios from 'axios';

export const commentsService = {
    sendCommentAPI,
    updateCommentAPI,
    getCommentsCounterAPI,
    deleteCommentAPI,
    getInnerCircleCommentsAPI
};

function getCommentsCounterAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/comments/counters`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}
function sendCommentAPI(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/comments`, data, {
            params: {
                is_personal: data.is_personal,
                is_post: data.is_post
            }
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}
function updateCommentAPI(data, is_personal, is_post) {
    return axios.put(
        `${process.env.VUE_APP_BASE_URL}v1/comments`, data, {
            params: {
                is_personal: is_personal,
                is_post: is_post
            }
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}
function deleteCommentAPI(data) {
    return axios.delete(`${process.env.VUE_APP_BASE_URL}v1/comments`, {
        params: data
    }).then((res) => {
        console.log(res);
        return res.data;
    }).catch(err => {
        console.log(err);
    })

}

function getInnerCircleCommentsAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/comments/friend`, {
            params: data
        }).then((res) => {

        return res.data;
    }).catch(err => {
        // console.log(err);
    })
}
