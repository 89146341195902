<template>
	<button :class="[c_class]" @click="triggerClick()">
		<span v-if="!c_loading">{{c_text}}</span>
		<loading :reverse="c_loading_reverse" v-if="c_loading"></loading>
	</button>
</template>

<script>
import Loading from "@/components/_shared/Loading";
export default {
	name: 'c_button',
	props: {
		c_class: {
			default: false
		},
		c_text: {},
		c_loading: {},
		c_loading_reverse: {
			default: true
		}
	},
	data () {
		return {
		
		}
	},
	components: {Loading},
	computed: {},
	created() {},
	mounted() {},
	methods: {
		triggerClick() {
			this.$emit('clickEvent')
		}
	}
}
</script>

<style lang="less" scoped>

// Large devices (desktops, less than 1200px)
@media (max-width: 1200px) {}
// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {}
// Small devices (landscape phones, less than 768px)
@media (max-width: 768px) {}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {}
</style>

