<template>
    <b-modal ref="ask-question" size="md" id="ask-question" hide-footer :no-close-on-backdrop="true" title="Кому задать этот вопрос?" @hidden="resetUsersModal">
        <search-filter :show-error="true" :placeholderText="'Найти пользователей'" :items="usersList" @filtered="filteredList" class="search-filter" :is-server-side="true"></search-filter>
        <div class="users-list-wrapper" :class="{'loading': loading}">
            <div class="list" v-if="usersList.length">
                <div class="users-list" v-if="usersList.length">
                    <div class="user" v-for="(u, index) in usersList" :key="index" @click="selectUser(u)" :class="{'selected': u.selected}" v-if="u.filtered">
                        <div class="avatar-wrapper image-as-image" :style="{'background-image': (u.avatar_parsed) ? 'url(' + u.avatar_parsed + ')' : 'url(' + require('../../../assets/img/no-avatar.jpg') + ')' }"></div>
                        <div class="user-data">
	                        <div class="name">{{u.name}}</div>
	                        <div class="current-answer" v-if="u.answer && u.selected">
		                        <span class="current-answer-indicator">Уже ответил:</span>
		                        <span class="current-answer-name">{{u.answer.name}}</span>
	                        </div>
                        </div>
                        <img v-if="u.selected" class="control-icons" src="../../../assets/img/check_new.svg" alt="delete">
                    </div>
	                <c_button v-if="usersList.length && (page !== last_page)" :c_loading="loading" c_class="def-btn" c_text="Загрузить еще" @clickEvent="getUsersForAskQuestion()"></c_button>
                </div>
            </div>
            <div class="list no-friends-to-ask" v-if="!loading && !usersList.length">
                Некому задать вопрос
            </div>
        </div>

        <div class="modal-controls" v-if="usersList.length">
            <button class="add-users" @click="sendQuestionToUsers(true)">
                <span v-if="!isSelected().length || isSelected().length && isSelected().length !== usersList.length">Добавить всех</span>
                <span v-else-if="isSelected().length > 0 && isSelected().length === usersList.length">Убрать всех</span>
            </button>
            <button v-if="isSelected().length" class="add-users" @click="sendQuestionToUsers()" :disabled="everySelectedWithAnswer()">
                <span v-if="isSelected().length">Отправить {{isSelected().length}}</span>
            </button>
        </div>
    </b-modal>
</template>

<script>
    import searchFilter from '../SearchFilter';
    import {questionsService} from "../../../_api";
    import {handleAWSImage} from "../../../_helpers/aws-handler";
    import C_button from "@/components/_shared/Button";

    export default {
        name: 'askQuestionModal',
        data() {
            return {
	            loading: false,
	            usersList: [],
                question: null,
	            page: 0,
	            last_page: 0,
	            search_str: ''
            }
        },
        props: {},
        components: {
	        C_button,
            searchFilter
        },
        beforeDestroy() {},
        methods: {
            showModal(item) {
                this.question = item;
                this.getUsersForAskQuestion();
                this.$refs['ask-question'].show();
            },
            resetUsersModal() {
                this.usersList = [];
				this.loading = false;
				this.search_str = '';
				this.page = 0;
				this.last_page = 0;
				this.question = null;
				this.$forceUpdate();
            },
	        loadMore() {
		        this.loading = true;
				setTimeout(() => {
					this.loading = false;
				}, 15000)
	        },
	        filteredList(data) {
		        console.log(data);
		        this.search_str = data.searchStr;
		        this.usersList = [];
		        this.page = 0;
		        this.getUsersForAskQuestion();
	        },
            getUsersForAskQuestion () {
                this.loading = true;
                questionsService.getUsersForAskQuestionAPI({
                    question_id: this.question.question_id,
                    is_personal: this.question.type === 3 ? 1 : 0,
	                page: (!this.page) ? 1 : this.page + 1,
	                user_name: this.search_str
                }).then(res => {
	                if (res) {
						res.data.forEach((obj) => {
							this.usersList.push(obj);
						})
		                // this.usersList = res.data;
		                console.log(res);
		                // this.question.users = [];
		                this.usersList.forEach(obj => {
			                /*if (this.$route.query.user_id && this.$route.query.user_id === obj.id && !this.question.users.length) {
								obj['selected'] = true;
								this.question.users.push(obj);
								this.$router.replace({'user_id': null});
							}*/
			                !obj['selected'] ? obj['selected'] = false : obj['selected'] = true;
			                // obj['selected'] = false;
			                obj['filtered'] = true;
			                obj['avatar_parsed'] = null;
			                if (obj.avatar) {
				                handleAWSImage(obj.avatar_64).then((res) => {
					                obj['avatar_parsed'] = res;
					                this.$forceUpdate();
				                })
			                }
			                this.loading = false;
		                });
						
						this.page = res.current_page
						this.last_page = res.last_page
	                }
                });
            },
            selectUser(data) {
                data.selected = !data.selected;
                this.$forceUpdate();
            },
            sendQuestionToUsers(select_all) {
                if (select_all) {
                    if (this.isSelected().length === this.usersList.length) {
	                    this.usersList.forEach(obj => {
		                    obj['selected'] = false;
		                    this.$forceUpdate();
	                    });
                    } else {
	                    this.usersList.forEach(obj => {
		                    obj['selected'] = true;
		                    this.$forceUpdate();
	                    });
                    }
                } else {
                    let ids = [];
	                this.usersList.forEach(obj => {
		                if (obj.selected && obj.answer === null) {
			                ids.push(obj.id);
		                }
		                this.$forceUpdate();
	                });
					console.log(ids);
                    questionsService.askQuestion({
                        to_user_ids: ids,
                        question_id: this.question.question_id,
                        is_personal: this.question.type === 3 ? 1 : 0,
                        redirect: 1
                    }).then((res) => {
                        this.$store.dispatch('alert/success', 'Вопрос отправлен', { root: true });
                        this.$refs['ask-question'].hide();
                    })
                }
            },
            filteredUsersList(data) {
				console.log(data);
                this.searchStr = data.searchStr;
                if (data.searchStr.length) {
                    data.arr.forEach(obj => {
                        for (let key in this.usersList) {
	                        this.usersList.forEach(c_obj => {
		                        c_obj['filtered'] = c_obj.id === obj.id;
		                        this.$forceUpdate()
	                        });
                        }
                    });
                } else {
	                this.usersList.forEach(c_obj => {
		                c_obj['filtered'] = true;
		                this.$forceUpdate()
	                });
                }

            },
            isSelected() {
                return this.usersList.filter((obj) => {return obj.selected})
            },
	        everySelectedWithAnswer() {
				let sel = this.usersList.filter((e) => {return e.selected});
		        return sel.length &&  sel.every((e)=> {return e.answer !== null})
	        }
        },
        computed: {
			
        },
        beforeCreate() {},
        created() {},
        mounted() {},
        watch: {}

    }
</script>

<style lang="less">
    @import "../../../assets/styles/_vars.less";

    #ask-question {
        .modal-dialog {
            .modal-body {
                padding: 0;
                position: relative;
                .question-wrapper {
                    padding: 20px;
                    text-align: center;
                    display: flex;
                    min-height: 100px;
                    background: #5EBD9C;
                    justify-content: center;
                    align-items: center;
                    p {
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 21px;
                        color: #000000;
                        margin-bottom: 0;
                    }
                }
                .users-list-wrapper {
                    padding: 20px;
                    max-height: 350px;
                    min-height: 350px;
                    overflow-y: auto;
                    &.loading {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &::-webkit-scrollbar{
                        height: 4px;
                        width: 4px;
                        background: gray;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb{
                        background: lightgrey;
                        border-radius: 10px;
                    }
                    .users-list {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        .divider {
                            color: #666;
                            font-size: 11px;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                        .user {
                            text-align: center;
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 5px 5px;
                            margin-bottom: 4px;
                            border-radius: 10px;
                            position: relative;
                            &.selected {
                                background-color: #e0e0e0;
                            }
                            .avatar-wrapper {
                                width: 36px;
                                height: 36px;
                                background-size: cover;
                                display: block;
                                border-radius: 100px;
                                -webkit-border-radius: 100px;
                                -moz-border-radius: 100px;
                                margin-right: 15px;
                            }
                            .user-data {
	                            display: flex;
	                            flex-wrap: wrap;
	                            justify-content: flex-start;
	                            align-items: center;
	                            .name {
		                            font-weight: 500;
		                            font-size: 12px;
		                            color: #333333; // rgba(51, 51, 51, 0.5);
		                            overflow-wrap: break-word;
		                            text-align: left;
		                            width: 100%;
	                            }
	                            .current-answer {
		                            font-size: 12px;
		                            font-style: italic;
		                            display: flex;
		                            flex-wrap: wrap;
		                            justify-content: flex-start;
		                            align-items: center;
		                            .current-answer-indicator {
			                            border-bottom: 1px solid grey;
			                            font-weight: bold;
		                            }
		                            .current-answer-name {
		                                width: 100%;
			                            text-align: left;
		                            }
	                            }
                            }
                            img {
                                width: 20px;
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                margin-top: -10px;
                            }
                        }
	                    .def-btn {
		                    margin: 0 auto;
		                    font-size: 10px;
		                    padding: 2px;
		                    min-height: unset;
		                    text-transform: lowercase;
	                    }
                    }
                }
                .modal-controls {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    background: #fff;
                    bottom: 0;
                    right: 0;
                    padding: 10px 20px;
                    justify-content: flex-end;
                    button {
                        &:last-child {
                            margin-left: 5px;
                        }
                        background-color: @theme_color;
                        color: #ffffff;
                        min-height: 32px;
                        min-width: 60px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -ms-flex-line-pack: center;
                        align-content: center;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        border: none;
                        border-radius: 35px;
                        padding: 5px 15px;
                        -webkit-transition: all 250ms;
                        transition: all 250ms;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        cursor: pointer;
                        outline: none;
                        max-width: 130px;
                        width: 100%;
                        &:focus {
                            box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
                        }
	                    &:disabled {
		                    background-color: #6e9ab9;
	                    }
                    }
                }
            }
        }
    }
</style>
