<template>
    <div id="chat-window-page">
	    <div @click="goBack()" class="mobile-back-btn" v-if="activeChat && isMobile">
		    <i class="fas fa-chevron-left"></i> Назад
	    </div>
	    <div class="chat-is-not-selected" v-show="showTip">
		    Для того, чтобы начать общение, добавьте собеседников в Ближний Круг.
		    <br/>
		    Новых друзей Up&Up Вы можете найти через “Поиск”, а также среди предложенных Пользователей в разделе "Похожие"
		    <button class="def-btn" @click="redirectTo()">Перейти</button>
	    </div>
        <div class="chat-selected" v-if="activeChat && !isLoading">
            <div class="chat-window-header">
                <div class="author-avatar" @click="goToProfile()">
                    <avatar :id="activeChat.id" :width="64" :height="64" :img="activeChat.friendData.avatar_parsed"></avatar>
                </div>
                <div class="author-info-wrapper">
                    <div class="column left" :class="{'full-width': friendData && (!friendData.interests || !friendData.interests.length) && !friendData.education && !friendData.family_status && !friendData.children}">
                        <div class="user-fullname" @click="goToProfile()">{{activeChat.friendData.name}}</div>
                        <div class="user-description" v-if="friendData">
                            <span v-if="friendData.birthday">{{friendData.birthday}}</span>
                            <span v-if="friendData.age_range">{{(friendData.age_range.from + ' - ' + friendData.age_range.to)}}</span>
                            <span class="location" v-if="friendData.location && friendData.location.city">{{friendData.location.city.name}}</span>
                            <span id="occupations" v-if="friendData.occupations && friendData.occupations.length" >
                                {{friendData.occupations[0].name}}
                                <b-tooltip :target="'occupations'" custom-class="chat-interests-tooltip">
                                    <ul><li v-for="ii in friendData.occupations">{{ii.name}}</li></ul>
                                </b-tooltip>
                            </span>
                        </div>
                        <person-progress v-if="friendData" width="165" height="5" :green="friendData.similar_answers_percent" :red="friendData.diff_answers_percent" :gray="friendData.not_answered_percent"></person-progress>
                    </div>
                    <div class="column right" v-if="friendData">
                        <div class="user-additional-info">
                            <div class="education" :class="{'full-width': !friendData.family_status && !friendData.children}" v-if="friendData.education" :id="friendData.education.name">
                                {{friendData.education.name}}
                                <b-tooltip :target="friendData.education.name" custom-class="chat-interests-tooltip">{{friendData.education.name}}</b-tooltip>
                            </div>
                            <div v-if="friendData.family_status || friendData.children" class="marital-status" :class="{'full-width': !friendData.education}" :id="(friendData.family_status) ? friendData.family_status.name : null">
                                <span v-if="friendData.family_status">
                                    {{friendData.family_status.name + (friendData.children ? ', ' : '') }}
                                </span>
                                <span v-if="friendData.children">{{'\xa0' + friendData.children.name}}</span>

                                <b-tooltip v-if="friendData.family_status" :target="friendData.family_status.name" custom-class="chat-interests-tooltip">
                                    <span >
                                        {{friendData.family_status.name + (friendData.children ? ', ' : '') }}
                                    </span>
                                    <span v-if="friendData.children">{{'\xa0' + friendData.children.name}}</span>
                                </b-tooltip>

                            </div>
                        </div>
                        <div class="interests" v-if="friendData.interests && friendData.interests.length">
                            <span :id="i.name" v-for="i in interestsComp" :key="i.id">
                                {{i.name}}
                                <b-tooltip :target="i.name" custom-class="chat-interests-tooltip">{{i.name}}</b-tooltip>
                            </span>
                            <span id="more-interests" v-if="friendData.interests.length > 3" class="more">
                                +{{friendData.interests.length - 3}}
                                <b-tooltip :target="'more-interests'" custom-class="chat-interests-tooltip">
                                    <ul><li v-for="ii in interestsCompMore">{{ii.name}}</li></ul>
                                </b-tooltip>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat" @click="readMessages()">
                <messages-window :chatMessagesSorted="chatMessagesSorted" :loadPreviousMessages="loadPreviousMessages" @messageAction="messageAction" :getUnreadMessages="activeChat.unreadCount" :activeChat="activeChat" ref="messageWindow" :scrollDown="Bus"></messages-window>
            </div>
            <div id="chat-window-footer" class="chat-window-footer" :class="{'edit-message': editMessageObj, 'answer-message': answerMessageObj, 'image-upload-message': fileUploadList.length}">
                <div class="message-preview" :class="{'files': fileUploadList.length}" v-if="editMessageObj || answerMessageObj || fileUploadList.length">
                    <span v-if="editMessageObj">
                        {{editMessageObj.message}}
                    </span>
                    <span v-if="answerMessageObj">
                        {{answerMessageObj.message}}
                    </span>
                    <div v-if="fileUploadList.length" class="files-list">
                        <!-- v-b-tooltip.hover v-b-tooltip.hover.html="fileArraySplit"-->
	                    <div class="file-wr" v-for="(val, index) of fileUploadList" v-show="index < 3">
		                    <img draggable="false" :src="val.url" :alt="val.name">
		                    <div class="drop-single-file-btn" @click="deleteFile(index)">
			                    <i class="fas fa-times"></i>
		                    </div>
	                    </div>
                        <div class="more" v-if="fileUploadList.length > 3">+{{fileUploadList.length - 3}}</div>
	                    <!--<div class="more add-icon" @click="fileInputTrigger()"><i class="fas fa-plus"></i></div>-->
                    </div>
                    <span v-if="editMessageObj || answerMessageObj" class="close-edit-mode" @click="clearMessageInput()"></span>
                </div>
                <div class="user-image-upload">
                    <!--@click.prevent.stop-->
                    <img src="../../../assets/img/upload-img-icon1.svg" alt="media" @click="fileInputTrigger()">
                    <input ref="image-upload-input" type="file" name="file-upload" id="file-upload" style="display:none;" @change="fileInputListener" accept="image/*">
                </div>
                <div class="user-icon-wrapper">
<!--                    <img src="../../../assets/img/no-avatar.jpg" alt="avatar">-->
                    <avatar :width="25" :height="25" :img="getMyUserData.avatar_parsed"></avatar>
                </div>
	            <!--:disabled="fileUploadList.length > 0"-->
                <textarea ref="message-input" v-model="chatMessage" @keypress.enter="handleEnter($event)" rows="1" placeholder="Напишите сообщение" class="write-message-input" id="write-message-input"></textarea>
                <button class="submit-message-btn" @click="submitMessage()" :disabled="messageSubmitLoading" :class="{'disabled': messageSubmitLoading}"></button>
            </div>
        </div>
    </div>

</template>

<script>

    import MessagesWindow from "./MessagesWindow";
    import Vue from 'vue';
    import avatar from "../../_shared/Avatar";
    import moment from "moment";
    import {usersService} from "@/_api";
    import PersonProgress from "../../_shared/UserProgress";
	import sanitizeHtml from "sanitize-html";

    export default {
        name: 'ChatWindow',
        props: ['activeAvatar', 'activeChat', 'isLoading', 'friendData', 'chatsList', 'showTip', 'isMobile', 'showContent'],
        components: {PersonProgress, MessagesWindow, avatar},
        data(){
            return {
                initLoad: true,
                chatMessage: '',
                chatMessagesSorted: [],
                lastVisible: null,
                editMessageObj: null,
                answerMessageObj: null,
                fileUploadList: [],
                chatMessageHeight: 0,
                chatWindowFooterHeight: 0,
                chatWindowWrapperHeight: 0,

                messagesListener: null,
                prevMessagesListener: null,

                Bus: new Vue(),

                messageSubmitLoading: false
            }
        },
        computed: {
            getMyUserData() {
                return this.$store.state.authentication.user
            },
            getFireUserData() {
                return this.$store.state.firebase.firebaseUserData
            },
            interestsComp() {
                return this.friendData.interests.filter((o, index) => {
                    return index <= 2
                })
            },
            interestsCompMore() {
                return this.friendData.interests.filter((o, index) => {
                    return index > 2
                })
            },
        },
        mounted() {
            if (this.activeChat) {
                this.getMessages('MOUNTED');
            }
        },
        beforeDestroy() {
            if (this.messagesListener) {
                this.messagesListener();
            }
            if (this.prevMessagesListener) {
                this.prevMessagesListener();
            }
        },
        watch: {
            activeChat: function(val) {
                console.log('WATCHER');
                this.initLoad = true;
                if (val) {
                    this.getMessages('WATCHER');
                }
            },
            chatMessage: function(val) {
                if (!val.length && this.editMessageObj) {
                    this.editMessageObj = null;
                }
                /*const f = document.getElementById('chat-window-footer').offsetHeight;
                const i = document.getElementById('write-message-input').offsetHeight;
                const w = document.getElementById('chat-window-wrapper').offsetHeight;
                if (this.chatMessage.match(/\n/g) && this.chatMessage.match(/\n/g).length < 3) {
                    if (f <= 47) {
                        document.getElementById('chat-window-footer').style.height = f * 2 + 'px';
                        document.getElementById('write-message-input').style.height = i * 2 + 'px';
                        document.getElementById('chat-window-wrapper').style.height = w - f + 'px';
                    }
                    document.getElementById('write-message-input').style.overflowY = 'auto';
                } else if (!this.chatMessage.match(/\n/g)) {
                    document.getElementById('chat-window-footer').style.height = '47px';
                    document.getElementById('write-message-input').style.height = '25px';
                    document.getElementById('write-message-input').style.overflowY = 'hidden';
                    document.getElementById('chat-window-wrapper').style.height = this.isMobile ? "'calc(100vh - 208px)'" : "'calc(100vh - 341px)'";
                }*/

            },
            /*friendData: function (val) {
                // console.log(val);
                if (val) {
                    if(val['birthday'] && val['birthday'] !== null) {
                        val['birthday'] = moment().diff(val['birthday'], 'years',false)
                    }
                    this.friendData = val;
                }
            }*/
        },
        methods: {
            goBack() {
                this.$emit('clearActiveChat');
            },
            goToProfile() {
                //console.log(this.friendData);
                this.$router.push({path: `/profile/${this.friendData.id}`});
            },
            getMessages(data) {
                // // console.log(data);
                if (this.messagesListener) {
                    this.messagesListener();
                }
                if (this.prevMessagesListener) {
                    this.prevMessagesListener();
                }
                this.chatMessagesSorted = [];
                this.messagesListener = this.$firebaseChats.doc(this.activeChat.id).collection('messages').orderBy('timestamp', 'desc').limit(15).onSnapshot(snapshot => {
                    // // console.log('ACTIVE CHAT 1');
                    if (this.initLoad) {
                        // this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                        this.initLoad = false;
						console.log('AAAAAAAAAAAAAAAAAAAAAAAAA');
                    } else {
	                    this.readMessages();
                    }
	                let count = snapshot.docs.length;
	                console.log(snapshot.docs);
	                console.log(count);
	                this.lastVisible = snapshot.docs[snapshot.docs.length - 1];

                    snapshot.docChanges().forEach((change) => {
                        if (change.type === 'added') {
                            console.log('ADDED');
                            this.handleMessageObj(change.doc.data()).then((data) => {

                                /*if (this.$refs.messageWindow.el.isBottom) {
                                    // // console.log('IS BOTTOM');
                                    this.readMessages();
                                    this.scrollDown(true);
                                } else {
                                    this.scrollDown(false);
                                }*/
	                            // console.log(this.$refs);

                            });
                        } else if (change.type === 'modified') {
                            console.log('MODIFIED');
                            this.handleMessageObj(change.doc.data()).then((data) => {});

                        } else if (change.type === 'removed') {
                            console.log('REMOVED');
                            let messageTimestamp = this.moment(change.doc.data().timestamp.toDate()).format("YYYY.MM.DD");
                            for (let i = 0; i < this.chatMessagesSorted.length; i++) {
                                if (this.moment(this.moment(this.chatMessagesSorted[i].timestamp).format('YYYY-MM-DD')).isSame(this.moment(messageTimestamp, 'YYYY.MM.DD').format('YYYY-MM-DD'))) {
                                    this.chatMessagesSorted[i].messages = this.chatMessagesSorted[i].messages.filter((q => {
                                        return q.id !== change.doc.id
                                    }))
                                }
                            }
                        }
                    });
                    /*this.chatMessagesSorted = this.chatMessagesSorted.sort((a,b) => {
                        return this.moment(a.timestamp, 'YYYY-MM-DD') - this.moment(b.timestamp, 'YYYY-MM-DD');
                    });
                    this.chatMessagesSorted.forEach(obj => {
                        obj.messages = obj.messages.sort((a,b) => {
                            return this.moment(a.timestamp.toDate()).diff(this.moment(b.timestamp.toDate()));
                        })
                    });*/
                });
            },

            scrollDown(data) {
                this.Bus.$emit('scroll', data);
            },
            handleEnter(e) {
                if (e.keyCode === 13 && e.shiftKey) {
					console.log(e);
                    e.preventDefault();
                    // this.chatMessage = this.chatMessage + "\n";
	                const cursorPos = e.target.selectionStart;
	                let strToArr = Array.from(this.chatMessage);
					strToArr.splice(e.target.selectionStart, 0, "\n")
	                this.chatMessage = strToArr.join('');
	                this.$nextTick(() => {
		                this.$refs['message-input'].selectionStart = cursorPos+1;
		                this.$refs['message-input'].selectionEnd = cursorPos+1;
		                this.$forceUpdate();
	                })
                    /*setTimeout(function() {
                        document.getElementById('write-message-input').scrollTop = document.getElementById('write-message-input').scrollHeight - document.getElementById('write-message-input').clientHeight;
                    }, 0);*/
                } else if (e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault();
                    this.submitMessage();
                }
            },
            submitMessage() {
                if (!this.messageSubmitLoading) {
                    if (this.fileUploadList.length) {
                        this.messageSubmitLoading = true;
                        for (var i = 0; i < this.fileUploadList.length; i++) {
                            var ref = this.$storage.ref();
                            var pathRef = ref.child(`images/${this.moment().format('YYYY_MM_DD_hh_mm_ss')}_${this.fileUploadList[i].name}`);
                            pathRef.put(this.fileUploadList[i]).then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((URL) => {
                                    let ref = this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc();
                                    let refId = ref.id;
                                    let temp = {
                                        attachment: {
                                            type: 2,
                                            type_name: 'image',
                                            data: {
                                                authorId: this.getFireUserData.id,
                                                authorName: this.getFireUserData.name,
                                                // authorName: this.activeChat.chatMembersDetailed.filter(o => {return o.id === this.getFireUserData.id})[0].name,
                                                text: null,
                                                path: URL
                                            }
                                        },
                                        authorId: this.getFireUserData.id,
                                        authorFull: this.getFireUserData,
                                        chatId: this.activeChat.id,
                                        id: refId,
                                        message: this.chatMessage,
                                        timestamp: this.$firebase.serverTimestamp,
                                        type: 0,
                                        editedAt: null,
                                        status: 0
                                    };
                                    this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc(refId).set(temp).then(() => {
                                        this.$firebaseChats.doc(this.activeChat.id).get().then(obj => {
                                            this.$firebaseChats.doc(this.activeChat.id).update({
                                                updatedAt: this.$firebase.serverTimestamp,
                                                unreadCount: obj.data().unreadCount + 1,
                                                is_active: 1,
                                                lastMessage: {
                                                    messageId: refId,
                                                    text: this.chatMessage,
                                                    authorId: this.getFireUserData.id,
                                                    authorName: this.getFireUserData.name,
                                                    date: this.$firebase.serverTimestamp
                                                }
                                            }).then(() => {
                                                // // console.log('IS FILE UPLAODED');
                                                this.readMessages();
                                                this.scrollDown(true);
                                                this.clearMessageInput();
                                                this.unArchiveUser();
                                                setTimeout(() => {
                                                    this.messageSubmitLoading = false;
                                                }, 50)
                                            });
                                        });
                                    });
                                })
                            }, err => {
                                // // // // console.log(err);
                            });
                        }
                    } else {
                        if (this.chatMessage.length > 0 && !this.editMessageObj && !this.answerMessageObj) {
                            // // console.log('REGULAR MESSAGE');
                            this.messageSubmitLoading = true;
                            let ref = this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc();
                            let refId = ref.id;
                            let temp = {
                                attachment: null,
                                authorId: this.getFireUserData.id,
                                authorFull: this.getFireUserData,
                                chatId: this.activeChat.id,
                                id: refId,
                                message: this.chatMessage,
                                timestamp: this.$firebase.serverTimestamp,
                                type: 0,
                                editedAt: null,
                                status: 0
                            };
                            this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc(refId).set(temp).then(() => {
                                this.$firebaseChats.doc(this.activeChat.id).get().then(obj => {
                                    this.$firebaseChats.doc(this.activeChat.id).update({
                                        updatedAt: this.$firebase.serverTimestamp,
                                        unreadCount: obj.data().unreadCount + 1,
                                        is_active: 1,
                                        lastMessage: {
                                            messageId: refId,
                                            text: this.chatMessage,
                                            authorId: this.getFireUserData.id,
                                            authorName: this.getFireUserData.name,
                                            date: this.$firebase.serverTimestamp
                                        }
                                    }).then(() => {
                                        this.readMessages();
                                        this.scrollDown(true);
                                        this.clearMessageInput();
                                        this.unArchiveUser();
                                        setTimeout(() => {
                                            this.messageSubmitLoading = false;
                                        }, 50)
                                    });
                                });
                            });
                        } else if (this.chatMessage.length > 0 && this.editMessageObj && !this.answerMessageObj) {
                            // console.log('UPDATED MESSAGE');
                            this.messageSubmitLoading = true;
                            let timestamp = this.$firebase.serverTimestamp;

                            let messageRef = this.$firebaseChats.doc(this.editMessageObj.chatId).collection('messages').doc(this.editMessageObj.id);
                            messageRef.update({
                                message: this.chatMessage,
                                editedAt: timestamp
                            }).then(() => {
                                this.$firebaseChats.doc(this.activeChat.id).get().then(obj => {
                                    if (obj.data().lastMessage.messageId === this.editMessageObj.id) {
                                        this.$firebaseChats.doc(this.activeChat.id).update({
                                            lastMessage: {
                                                messageId: obj.data().lastMessage.messageId,
                                                text: this.chatMessage,
                                                authorId: obj.data().lastMessage.authorId,
                                                authorName: obj.data().lastMessage.authorName,
                                                date: timestamp
                                            }
                                        }).then(() => {
                                            this.clearMessageInput();
                                            setTimeout(() => {
                                                this.messageSubmitLoading = false;
                                            }, 50)
                                        });
                                    } else {
                                        this.clearMessageInput();
                                        setTimeout(() => {
                                            this.messageSubmitLoading = false;
                                        }, 50)
                                    }
                                });
                            })
                        } else if (this.chatMessage.length > 0 && !this.editMessageObj && this.answerMessageObj) {
                            // // console.log('ANSWER MESSAGE');
                            this.messageSubmitLoading = true;
                            let ref = this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc();
                            let refId = ref.id;
                            let temp = {
                                attachment: {
                                    type: 1,
                                    type_name: 'answer',
                                    data: {
                                        authorId: this.answerMessageObj.authorFull.id,
                                        authorName: this.answerMessageObj.authorFull.name,
                                        text: this.answerMessageObj.message,
                                        path: null
                                    }
                                },
                                authorId: this.getFireUserData.id,
                                authorFull: this.getFireUserData,
                                chatId: this.activeChat.id,
                                id: refId,
                                message: this.chatMessage,
                                timestamp: this.$firebase.serverTimestamp,
                                type: 0,
                                editedAt: null,
                                status: 0
                            };
                            this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc(refId).set(temp).then(() => {
                                this.$firebaseChats.doc(this.activeChat.id).get().then(obj => {
                                    this.$firebaseChats.doc(this.activeChat.id).update({
                                        updatedAt: this.$firebase.serverTimestamp,
                                        unreadCount: obj.data().unreadCount + 1,
                                        is_active: 1,
                                        lastMessage: {
                                            messageId: refId,
                                            text: this.chatMessage,
                                            authorId: this.getFireUserData.id,
                                            authorName: this.getFireUserData,
                                            date: this.$firebase.serverTimestamp
                                        }
                                    }).then(() => {
                                        this.readMessages();
                                        this.scrollDown(true);
                                        this.clearMessageInput();
                                        setTimeout(() => {
                                            this.messageSubmitLoading = false;
                                        }, 50)
                                    });
                                });
                            });
                        }
                    }
                }
            },
            readMessages() {
                this.$firebaseChats.doc(this.activeChat.id).collection('messages').where('status', '==', 0).get().then(obj => {
                    if (!obj.empty) {
                        obj.docs.forEach(c_obj => {
                            if (this.getFireUserData.id !== c_obj.data().authorId) {
                                this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc(c_obj.id).update({
                                    status: 1
                                })
                            }
                        });
                        this.$firebaseChats.doc(this.activeChat.id).collection('messages').where('status', '==', 0).get().then(obj => {
                            if (!obj.empty) {
                                this.$firebaseChats.doc(this.activeChat.id).update({
                                    unreadCount: obj.docs.length
                                })
                            } else {
                                this.$firebaseChats.doc(this.activeChat.id).update({
                                    unreadCount: 0
                                })
                            }
                        })
                    }
                })
            },
            loadPreviousMessages() {
                console.log('LOAD PREVIOUSE MESSAGES');
                return new Promise(((resolve, reject) => {
                    this.prevMessagesListener = this.$firebaseChats.doc(this.activeChat.id).collection('messages').orderBy('timestamp', 'desc').limit(10).startAfter(this.lastVisible).onSnapshot(snapshot => {
                        if (snapshot.docs.length) {
                            this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
                            snapshot.docChanges().forEach((change) => {
                                if (change.type === 'added') {
	                                console.log('ADDED PREVIOUS');
                                    this.handleMessageObj(change.doc.data(), true).then((data) => {});
                                } else if (change.type === 'modified') {
	                                console.log('MODIFIED PREVIOUS');
                                    this.handleMessageObj(change.doc.data()).then((data) => {});
                                } else if (change.type === 'removed') {
                                    console.log('REMOVED PREVIOUS');
                                    let messageTimestamp = this.moment(change.doc.data().timestamp.toDate()).format("YYYY.MM.DD");
                                    for (let i = 0; i < this.chatMessagesSorted.length; i++) {
                                        if (this.moment(this.moment(this.chatMessagesSorted[i].timestamp).format('YYYY-MM-DD')).isSame(this.moment(messageTimestamp, 'YYYY.MM.DD').format('YYYY-MM-DD'))) {
                                            this.chatMessagesSorted[i].messages = this.chatMessagesSorted[i].messages.filter((q => {
                                                return q.id !== change.doc.id
                                            }))
                                        }
                                    }
                                }
                            });
                        }
                        resolve()
                    });
                }))
            },

            clearMessageInput() {
                this.chatMessage = '';
                this.answerMessageObj = null;
                this.fileUploadList = [];
            },
            deleteItem(data) {
                this.$firebaseChats.doc(data.chatId).collection('messages').doc(data.id).delete().then(()=>{
                    if (data.id === this.activeChat.lastMessage.messageId) {
                        this.$firebaseChats.doc(this.activeChat.id).collection('messages').orderBy('timestamp', 'desc').get().then(obj => {
                            // console.log(obj);
                            if (obj.docs.length <= 1) {
                                this.$firebaseChats.doc(this.activeChat.id).update({
                                    updatedAt: null,
                                    lastMessage: null
                                })
                            } else {
                                let lastEl = obj.docs[0].data();
                                this.$firebaseChats.doc(this.activeChat.id).update({
                                    updatedAt: lastEl.timestamp,
                                    lastMessage: {
                                        messageId: lastEl.id,
                                        text: lastEl.message,
                                        authorId: lastEl.authorId,
                                        authorName: lastEl.authorFull.name,
                                        date: lastEl.timestamp
                                    }
                                })
                            }
                        });
                    }
                })
            },
            editItem(data) {
                this.chatMessage = data.message;
                this.editMessageObj = data;
            },
            answerItem(data) {
                this.answerMessageObj = data;
				this.$refs['message-input'].focus();
            },
            messageAction(data, action) {
                switch (action.id) {
                    case 1: {
                        this.editItem(data);
                        break;
                    }
                    case 2: {
                        this.deleteItem(data);
                        break;
                    }
                    case 3: {
                        this.answerItem(data);
                        break;
                    }
                }
            },

            handleMessageObj(obj, paginated) {
                return new Promise((resolve, reject) => {
                    let messageData = obj;
                    (messageData.authorId === this.getFireUserData.id) ? messageData['isMyMessage'] = true : messageData['isMyMessage'] = false;
                    if (messageData.timestamp) {
	                    messageData['timestamp_formatted'] = this.moment(messageData.timestamp.toDate()).format("YYYY.MM.DD hh:mm");
	                    messageData['timestamp_formatted1'] = this.moment(messageData.timestamp.toDate()).format("DD.MM.YYYY HH:mm");

                      /*messageData.message = messageData.message.replace(/(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/ig, '<a href="$&" style="color: blue"  target="_blank">$&</a>');*/
	                    messageData.message = sanitizeHtml(messageData.message).replace(/(https?:\/\/[^\s]+)/g,'<a href="$&" style="color: blue"  target="_blank">$&</a>')
                        let tempObj = messageData;
                        let title = '';
                        let messageTimestamp = this.moment(tempObj.timestamp_formatted, 'YYYY.MM.DD hh:mm').format('YYYY-MM-DD');
                        let todayDate = this.moment().format('YYYY-MM-DD');
                        let yesterdayDate = this.moment().subtract(1, 'days').format('YYYY-MM-DD');

                        if (this.moment(messageTimestamp).isSame(todayDate)) {
                            title = 'Сегодня';
                        } else if (this.moment(messageTimestamp).isSame(yesterdayDate)) {
                            title = 'Вчера';
                        } else {
                            title = null;
                        }

                        let temp = () => {
                            for (let i = 0; i < this.chatMessagesSorted.length; i++) {
                                if (this.moment(this.moment(this.chatMessagesSorted[i].timestamp).format('YYYY-MM-DD')).isSame(this.moment(tempObj.timestamp_formatted, 'YYYY.MM.DD hh:mm').format('YYYY-MM-DD'))) {
                                    return i
                                }
                            }
                            return null;
                        };

                        let objToPush = {
                            timestamp: this.moment(tempObj.timestamp_formatted, 'YYYY.MM.DD hh:mm').format('YYYY-MM-DD'),
                            timestamp_title: title,
                            messages: [tempObj]
                        };
                      console.log('objToPush',objToPush)

                        if (!this.chatMessagesSorted.length) {
                            if (paginated) {
                                this.chatMessagesSorted.unshift(objToPush);
                            } else {
                                this.chatMessagesSorted.push(objToPush);
                            }
                        } else if (temp() !== null) {
                            if (paginated) {
                                this.chatMessagesSorted[temp()].messages.unshift(tempObj);
                            } else {
                                let tt = this.chatMessagesSorted[temp()].messages.findIndex((i) => {return i.id === tempObj.id});
                                if (tt !== -1) {
                                    this.chatMessagesSorted[temp()].messages[tt] = tempObj;
                                } else {
                                    this.chatMessagesSorted[temp()].messages.push(tempObj);
                                }
                            }
                        } else {
                            this.chatMessagesSorted.unshift(objToPush);
                        }


                        if (temp() !== null) {
                            var found = false;
                            var q = null;
                            for(var i = 0; i < this.chatMessagesSorted[temp()].messages.length; i++) {
                                if (this.chatMessagesSorted[temp()].messages[i].id === obj.id) {
                                    found = true;
                                    q = i;
                                    break;
                                }
                            }
                            if (found) {
                                this.chatMessagesSorted[temp()].messages[q] = tempObj;
                                this.chatMessagesSorted = [...this.chatMessagesSorted];
                            }
                        }
                    }

                    this.chatMessagesSorted.sort((a,b) => {
                        return this.moment(a.timestamp, 'YYYY-MM-DD') - this.moment(b.timestamp, 'YYYY-MM-DD');
                    });
                    this.chatMessagesSorted.forEach(obj => {
                        obj.messages = obj.messages.sort((a,b) => {
                            return this.moment(a.timestamp.toDate()).diff(this.moment(b.timestamp.toDate()));
                        })
                    });

                    resolve(obj);
                })
            },


            fileInputTrigger(e) {
                let fileInputEl = document.getElementById('file-upload');
                //fileInputEl.files = new DataTransfer().files;
                fileInputEl.click();
            },
            fileInputListener(e) {
	            Array.from(e.target.files).forEach((o) => {
		            o['url'] = URL.createObjectURL(o)
		            this.fileUploadList.push(o)
	            })
            },
	        deleteFile(index) {
				this.fileUploadList.splice(index, 1);
	        },

            redirectTo() {
                this.$router.push({ path: '/users/search'});
            },

            unArchiveUser() {
                usersService.archiveUserAPI({
                    chat_id: this.activeChat.id,
                    is_active: 1
                }).then(res => {
                    console.log(res);
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../../assets/styles/_unified";
    #chat-window-page {
	    height: 100%;
        // max-width: 697px;
        // width: 100%;
        //background-color: #ffffff;
        .mobile-back-btn {
            width: 100%;
            // display: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            color: @theme_color;
            margin-bottom: 5px;
            font-size: 14px;
            .fa-chevron-left {
                margin-right: 5px;
            }
        }
        .chat-is-not-selected {
            padding: 40px;
            color: #666;
            background-color: #ffffff;
            .def-btn {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                width: 100%;
                max-width: 150px;
                margin: 20px auto 0 auto;
            }
        }
        .chat-window-header {
            height: 96px;
            box-shadow: 0 0 7px -6px black;
            margin-bottom: 2px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 15px;
            background-color: #ffffff;
            .author-avatar {
                margin-right: 15px;
                cursor: pointer;
            }
            .author-info-wrapper {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 74px);
                height: 100%;
                .column {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;
                    // flex-direction: column;
                    // justify-content: space-around;
                    &.left {
                        // flex-direction: column;
                        // justify-content: space-between;
                        // align-items: flex-start;
                        margin-right: 2%;
                        flex-wrap: wrap;
                        width: 50%;
                        &.full-width {
                            width: 100%;
                            margin-right: 0;
                        }
                    }
                    &.right {
                        // align-items: flex-end;
                        flex-wrap: wrap;
                        width: 48%;
                    }
                    .user-fullname {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #666666;
                        cursor: pointer;
                        width: 100%;
                    }
                    .user-description {
                        font-size: 14px;
                        line-height: 16px;
                        color: #999999;
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        span {
                            &:not(:last-child) {
                                &:after {
                                    content: ', ';

                                }
                            }
                        }
                    }
                    .common-preferences {
                        span {
                            border: 0.5px solid #E0E0E0;
                            font-size: 13px;
                            line-height: 15px;
                            color: #828282;
                            padding: 1px;
                            margin: 0 1.5px;
                        }
                    }
                    .user-additional-info {
                        /*display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;*/
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: nowrap;
                        .education {
                            background: transparent url(../../../assets/img/education-icon.svg) left center no-repeat;
                            margin-right: 5%;
                        }
                        .marital-status {
                            background: transparent url(../../../assets/img/children-icon.svg) left center no-repeat;
                            // margin-left: 20px;
                            span {
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 16px;
                                color: #71809C;
                                mix-blend-mode: normal;
                            }
                        }
                        .marital-status, .education {
                            padding: 3px 0 3px 30px;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;
                            color: #71809C;
                            mix-blend-mode: normal;
                            background-size: 18px;
                            width: 45%;
                            white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                            &.full-width {
                                width: 100%;
                            }
                        }
                    }
                    .interests {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: nowrap;
                        span {
                            font-size: 13px;
                            color: #9B9B9B;
                            border: 1px solid #CBCBCB;
                            padding: 1px 3px;
                            margin-right: 2px;
                            max-width: 84px;
                            white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                            &.more {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

        }
        .chat {
	        height: 100%;
            position: relative;
            background-color: #ffffff;
            .scroll-bottom-wrapper {
                position: absolute;
                bottom: 20px;
                right: 20px;
                animation: new-message 1s linear infinite alternate;
                transition: all 1.5s;
                cursor: pointer;
                opacity: 0;
                width: 40px;
                height: 40px;
                img {
                    width: 100%;
                }
                span {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    font-size: 10px;
                    background-color: #d6585bde;
                    color: white;
                    top: 0;
                    right: 0;
                    border-radius: 50%;
                    border: 1px solid #ffffff;
                }
            }
        }
        .chat-window-footer {
            background-color: #ffffff;
            // height: 47px;
            //max-height: 122px;
            border-top: 1px solid rgba(112, 124, 151, 0.15);
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 15px;
            position: relative;
            &.edit-message, &.answer-message, &.image-upload-message {
                animation: edit-message 500ms linear 1;
            }
            &.edit-message {
                .message-preview {
                    background-image: url("../../../assets/img/pencil-create-new.svg");
                }
            }
            &.answer-message {
                .message-preview {
                    background-image: url("../../../assets/img/arrow-bold-reply.svg");
                }
            }
            &.image-upload-message {
                .message-preview {
                    // background-image: url("../../../assets/img/cloud-upload.svg");
	                height: 110px;
	                top: -110px;
	                padding: 5px;
                    .files-list {
                        display: flex;
	                    .file-wr {
		                    overflow: hidden;
		                    display: flex;
		                    justify-content: center;
		                    align-items: center;
		                    flex-wrap: wrap;
		                    border-radius: 10px;
		                    margin-right: 5px;
		                    position: relative;
		                    img {
			                    width: 100px;
			                    height: 100px;
			                    object-fit: cover;
			                    user-select: none;
		                    }
		                    .drop-single-file-btn {
			                    background-color: #2f4f4fa3;
			                    border-radius: 100%;
			                    padding: 5px;
			                    overflow: hidden;
			                    position: absolute;
			                    top: 5px;
			                    right: 5px;
			                    width: 18px;
			                    height: 18px;
			                    display: flex;
			                    justify-content: center;
			                    align-items: center;
			                    cursor: pointer;
			                    .fa-times {
				                    font-size: 12px;
				                    color: #fff
			                    }
		                    }
	                    }
                        .more {
	                        display: flex;
	                        justify-content: center;
	                        align-items: center;
	                        border-radius: 10px;
	                        border: 1px solid #71809c;
	                        padding: 0 5px;
	                        margin-right: 5px;
	                        color: #71809c;
	                        &.add-icon {
		                        padding: 0 10px;
		                        cursor: pointer;
		                        .fa-plus {
			                        color: #71809c;
		                        }
	                        }
                        }
                    }
                }
            }
            .message-preview {
                width: 100%;
                height: 100%;
                position: absolute;
                top: -100%;
                left: 0;
                text-align: left;
                background: #e2e2e2cc;
                /*display: flex;
                align-items: center;
                justify-content: flex-start;*/
                // background-image: url("../../../assets/img/pencil-create-new.svg");
                background-repeat: no-repeat;
                background-position: 2% 50%;
                color: #71809c;
                font-size: 14px;
	            overflow-x: hidden;
	            overflow-y: auto;
	            white-space: pre-line;
	            padding: 10px 10px 10px 7%;
	            word-break: break-word;
	            &.files {
		            display: flex;
		            align-items: center;
		            justify-content: flex-start;
	            }
                .close-edit-mode {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    margin-top: -12.5px;
                    background: url("../../../assets/img/circle-x.svg") no-repeat;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }
            }
            .user-image-upload {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 20px;
                width: 20px;
                height: 20px;
                overflow: hidden;
                margin-right: 15px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .write-message-input {
                width: 100%;
                padding: 5px 15px;
                border: none;
                height: 60px;
                //max-height: 75px;
                line-height: 18px;
                font-size: 13px;
                resize: none;
                // overflow-x: hidden;
                // overflow-y: hidden;
                &::-webkit-scrollbar{
                    height: 4px;
                    width: 4px;
                    background: gray;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb{
                    background: lightgrey;
                    border-radius: 10px;
                }
                &::placeholder {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 15px;
                    color: #9B9B9B;
                }
            }
            .submit-message-btn {
	            width: 50px;
	            height: 50px;
                background: transparent url(../../../assets/img/send-icon1.svg) center no-repeat;
                background-size: 20px;
                border: none;
                outline: none;
                &:disabled {
                    background-size: 20px!important;
                    background: transparent url(../../../assets/img/Infinity-1s-200px.svg) center no-repeat;
                }
            }
        }
    }

    @keyframes new-message {
        0%   {bottom: 20px;right: 20px;}
        50%  {bottom: 30px;right: 20px;}
        100% {bottom: 20px;right: 20px;}
    }

    @keyframes edit-message {
        0% {box-shadow: 0 0 0 0 red;}
        25% {box-shadow: 0 0 3px 0 red;}
        50% {box-shadow: 0 0 6px 0 red;}
        75% {box-shadow: 0 0 3px 0 red;}
        100% {box-shadow: 0 0 0 0 red;}
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {
        /*#chat-window-page {
            width: calc(100% - 253px);
        }*/
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #chat-window-page {
            .chat-selected {
                .chat-window-header {
                    height: 55px;
                    padding: 7px 15px;
                    .author-avatar {
                        .image-as-bg {
                            width: 40px!important;
                            height: 40px!important;
                            max-width: 40px!important;
                            min-width: 40px!important;
                        }
                    }
                    .author-info-wrapper {
                        height: 100%;
                        align-items: flex-start;
                        width: 100%;
                        .column {
                            &.left {
                                margin: 0;
                                width: 100%;
                                .user-fullname {
                                    font-size: 14px;
                                    margin-bottom: 5px;
                                }
                                .user-description {
                                    display: none;
                                }
                            }
                            &.right {
                                display: none;
                            }
                        }
                    }
                }
	            .chat-window-footer {
		            .write-message-input {
			            height: 40px;
		            }
	            }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {

    }

</style>
