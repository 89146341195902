<template>
    <div class="user">
        <div class="avatar-wrapper" @click="goToProfile(user)">
            <avatar :width="135" :height="135" :img="avatar"></avatar>
        </div>
        <div class="user-info-wrapper">
            <div class="main-info">
                <div class="user-fullname" @click="goToProfile(user)">
                    {{user.name}}
                </div>
                <div class="questionnaire-progress-data">
                    <person-progress width="165" height="4" :green="user.similar_answers_percent" :red="user.diff_answers_percent" :gray="user.not_answered_percent"></person-progress>
                    <div class="answers-wrapper">
                        <span class="green-text-quantity">{{user.similar_count}}</span>
                        <span> схожих ответа</span>
                    </div>
                    <div class="answers-wrapper">
                        <span class="red-text-quantity">{{user.difference_count}}</span>
                        <span> несхожих ответа</span>
                    </div>
                    <div class="answers-wrapper">
                        <span class="gray-text-quantity">{{user.not_answered_count}}</span>
                        <span> еще неотвеченных</span>
                    </div>
                </div>
                <div class="interests" v-if="user.interests && user.interests.length">
                    <span v-for="(i, index) in interestsComp" :key=index>{{i.name}}</span>
                    <span v-if="user.interests.length > 3" class="more">+{{user.interests.length - 3}}</span>
                </div>
            </div>
            <!--<div class="common-preferences"></div>-->
            <div class="actions-wrapper">
                <button :class="{'def-btn': user.is_friend, 'def-btn disabled': !user.is_friend}" :disabled="!user.is_friend" @click="askQuestion(user)">Задать вопрос</button>
<!--                <button class="def-btn" @click="startChat(user)">Написать сообщение</button>-->
              <StartChatBtn :data="user"></StartChatBtn>
              <button v-if="acceptRequest" class="def-btn" @click="acceptFriendRequest(user)">Принять запрос</button>
              <button v-if="deleteFriend" class="def-btn cancel-action" @click="removeFromFriends(user)">Удалить из
                друзей Up&Up
              </button>
              <button v-if="deleteRequest" class="def-btn cancel-action" @click="cancelFriendRequest(user)">Отклонить
                запрос
              </button>

              <button :disabled="user.is_friend_request === 1" v-if="!user.is_friend && sendRequest"
                      :class="{'def-btn': !user.is_friend_request, 'def-btn disabled': user.is_friend_request}"
                      @click="sendFriendRequest(user)">
                {{ (user.is_friend_request) ? 'Запрос отправлен' : 'Добавить в Друзья Up&Up' }}
              </button>
            </div>
        </div>
      <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>

    </div>
</template>

<script>
import {usersService} from "../../_api";
import avatar from "../_shared/Avatar";
import EventBus from "../../_helpers/event-bus";
import PersonProgress from "../_shared/UserProgress";
import StartChatBtn from "@/components/_shared/StartChatBtn";
import ConfirmationModal from "@/components/_shared/modals/_ConfirmationDialogue";

export default {
  name: 'user',
  props: {
    user: {
      type: Object,
      required: true
    },
    avatar: {
      type: String,
      required: false
    },
            sendRequest: {
                type: Boolean,
                required: false
            },
            deleteFriend: {
                type: Boolean,
                required: false
            },
            deleteRequest: {
                type: Boolean,
                required: false
            },
            acceptRequest: {
                type: Boolean,
                required: false
            },
            savePrevRoute: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data () {
            return {
                loadingChat: false
            }
        },
        components: {
          StartChatBtn,
          ConfirmationModal,
          PersonProgress,
          avatar
        },
        computed: {
            interestsComp() {
                return this.user.interests.filter((o, index) => {
                    return index <= 2
                })
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            startChat(data) {
                this.loadingChat = true;
                usersService.getChatIdAPI(data).then((res) => {
                    this.loadingChat = false;
                    if (res) {
                        this.$emit('startChat', res);
                        if (this.isModal) {
                            if (this.$route.query.t && this.$route.query.t !== res) {
                                this.$router.push({query: {'t': res}});
                            }
                        } else {
                            this.$router.push({ path: '/messages', query: { t: res, ff: true } });
                        }
                    }
                })
            },
            goToProfile(data) {
                this.$router.push({path: `/profile/${data.id}`, query: { from: this.savePrevRoute ? this.$route.path : null}});
            },
            askQuestion(user) {
                this.$router.push({path: '/questions/personal-questions', query: {user_id: user.id}});
            },
            removeFromFriends(data){
                let q = confirm('Are you sure?');
                if (q) {
                    usersService.removeFromFriendsAPI({
                        id: data.id
                    }).then((res) => {
                        if (res !== undefined) {
                            this.$emit('configureList');
                            EventBus.$emit('friendsChanged');
                        }
                    })
                }
            },

          cancelFriendRequest(data) {
            this.$refs.confirmDialogue.show({
              title: 'Отклонить запрос',
              message: `Вы уверены в том, что желаете отклонить запрос на добавление в Ближний круг пользователя ${data.name} ?`,
              okButton: 'Да',
            }).then((res) => {
              if (res) {
                this.$emit('configureList');
              }
            });
          },
            acceptFriendRequest(data) {
                usersService.acceptFriendRequestAPI({
                    id: data.id
                }).then((res) => {
                    if (res) {
                        this.$emit('configureList');
                        EventBus.$emit('friendsChanged');
                    }
                })
            },
            sendFriendRequest(data) {
                usersService.sendFriendRequestAPI({
                    id: data.id
                }).then((res) => {
                    if (res) {
                        data['is_friend_request'] = 1;
                        this.$forceUpdate();
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .user {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 25px;
        border-bottom: 1px solid #e3e6eb;
        .avatar-wrapper {
            margin-right: 25px;
            cursor: pointer;
        }
        .user-info-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            .main-info {
                //width: 68%;
	            width: 100%;
                text-align: left;
                // border-right: 1px solid #e3e6eb;
                margin-bottom: 9px;
                display: flex;
                flex-wrap: wrap;
                // justify-content: space-between;
	            justify-content: flex-start;
                //align-items: flex-end;
	            align-items: flex-start;
                .user-fullname {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #666666!important;
                    margin-bottom: 8px;
                    cursor: pointer;
                    width: 100%;
                }
                .user-description {
                    font-size: 14px;
                    line-height: 16px;
                    color: #999999;
                    margin-bottom: 8px;
                }
                .questionnaire-progress-data {
                    width: 50%;
                    .person-progress {
                        margin-bottom: 8px;
                    }
                    .answers-wrapper {
                        span {
                            font-size: 14px;
                            line-height: 16px;
                            color: #71809C;
                            &.green-text-quantity {
                                color: #AFD4AB;
                                font-weight: bold;
                            }
                            &.red-text-quantity {
                                color: #EDA2A4;
                                font-weight: bold;
                            }
                            &.gray-text-quantity {
                                color: #9B9B9B;
                                font-weight: bold;
                            }
                        }
                    }
                }
                .interests {
                    width: 46%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                    span {
                        font-size: 13px;
                        color: #9B9B9B;
                        border: 1px solid #CBCBCB;
                        padding: 1px 3px;
                        margin-right: 2px;
                        margin-bottom: 2px;
                    }
                }
            }
            .common-preferences {
                width: 25%;
                display: flex;
                flex-direction: column;
                margin-bottom: 9px;
                text-align: left;
                justify-content: center;
                span {
                    font-size: 13px;
                    line-height: 18px;
                    color: #333333;
                    margin-bottom: 14px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
            .actions-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                /*justify-content: space-between;*/
                width: 100%;
	            flex-wrap: wrap;
	            ::v-deep #start-chat-btn > .def-btn {
		            max-width: 150px;
		            min-width: 150px;
	            }
                .def-btn, #start-chat-btn  {
                    /*&:not(:last-child) {
                        margin-right: 10px;
                    }*/
	                &:last-child {
		                margin-right: 0;
	                }
	                margin-right: 10px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    margin-bottom: 8px;
                    width: 100%;
                    max-width: 150px;
                    min-width: 150px;
                    &.disabled {
                        background: rgba(0, 0, 0, 0.1)!important;
                        color: #A9BAD4!important;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }



    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        .user {
            flex-wrap: wrap;
            .avatar-wrapper {
                margin: 0 auto 10px auto;
            }
            .user-info-wrapper {
                .main-info {
                    width: 100%;
                    margin-bottom: 10px;
                    border-right: none;
                    text-align: center;
                    .questionnaire-progress-data {
                        width: 100%;
                    }
                    .person-progress {
                        margin: 0 auto 5px auto;
                    }
                    .interests {
                        width: 100%;
                        justify-content: center;
                    }
                }
                .common-preferences {
                    display: none;
                }
                .actions-wrapper {
                    flex-wrap: wrap;
                    justify-content: space-around;
                    button {
                        margin-right: 0!important;
                    }
                }
            }
        }
    }
</style>

