<template>
    <!--@mouseenter="isEarlierNt ? changeNotificationStatus(nt) : null"-->
    <div class="notification-item-wrapper" @mouseenter="isEarlierNt ? changeNotificationStatus(nt) : null" @click.stop.prevent="showObj(nt); meth(nt)" :class="{'loaded': !loading, 'is_checked': nt.is_checked, 'earlier': isEarlierNt}">
        <a href="/" class="preview-user">
            <avatar v-if="nt.from_user" :width="48" :height="48" :img="nt.from_user.avatar_parsed"></avatar>
        </a>
        <div class="info-wrapper">
            <div class="info">
                <div class="notification" v-if="nt.type === 2">
                    <!--Someone answered on your comment-->
                    <span v-if="nt.is_personal"><span class="bold-highlighted" :class="{'is_deleted': nt.from_user.is_deleted}">{{nt.from_user.name}} </span> <span>{{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}}</span> на Ваш комментарий в персональном вопросе</span>
                    <span v-if="!nt.is_personal"><span class="bold-highlighted" :class="{'is_deleted': nt.from_user.is_deleted}">{{nt.from_user.name}} </span> <span>{{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}}</span> на Ваш комментарий</span>
                </div>
                <div class="notification" v-if="nt.type === 3">
                    <!--Someone accepted your friendship request / send request to you-->
                    <div v-if="!nt.is_friend && nt.is_friend_request"><span class="bold-highlighted" :class="{'is_deleted': nt.from_user.is_deleted}">{{nt.from_user.name}} </span> <span>{{(nt.from_user.sex === 'f') ? 'отправила' : 'отправил'}}</span> запрос в друзья Up&Up</div>
                    <div v-if="nt.is_friend && !nt.is_friend_request"><span class="bold-highlighted" :class="{'is_deleted': nt.from_user.is_deleted}">{{nt.from_user.name}} </span> <span>{{(nt.from_user.sex === 'f') ? 'приняла' : 'принял'}}</span> Ваш запрос в друзья Up&Up</div>
                </div>
                <div class="notification" v-if="nt.type === 4">
                    <!--Your personal question/comment has been deleted-->
                    <span v-if="nt.is_personal_question">Ваш персональный "{{nt.body}}" вопрос удалили по причине: <span class="bold-highlighted italic-highlighted">{{nt.message}}</span></span>
                    <span v-if="nt.is_comment && nt.is_personal_comment">Ваш комментарий "{{nt.body}}" на персональном вопросе удалили по причине: <span class="bold-highlighted italic-highlighted">{{nt.message}}</span></span>
                    <span v-if="nt.is_comment && !nt.is_personal_comment">Ваш комментарий "{{nt.body}}" удалили по причине: <span class="bold-highlighted italic-highlighted">{{nt.message}}</span></span>
                </div>
                <div class="notification" v-if="nt.type === 5">
                    <!--Someone redirected question to you-->
                    <span  v-if="!nt.is_personal">
                        <span style="font-weight: 500" :class="{'is_deleted': nt.from_user.is_deleted}">{{nt.from_user.name}} </span> переслал(-a) вам вопрос: <span class="bold-highlighted italic-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="(nt.is_personal && !nt.is_redirected) || (nt.is_personal && nt.is_redirected && (nt.author_fb_id === nt.from_user_fb_id))">
                        <span style="font-weight: 500" :class="{'is_deleted': nt.from_user.is_deleted}">{{nt.from_user.name}} </span> переслал(-a) вам персональный вопрос: <span class="bold-highlighted italic-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="nt.is_personal && nt.is_redirected && (nt.author_fb_id !== nt.from_user_fb_id)">
                        <span style="font-weight: 500" :class="{'is_deleted': nt.from_user.is_deleted}">{{nt.from_user.name}}</span> переслал(-a) вам {{(getFireUserData.fb_id === nt.author_fb_id) ? 'Ваш' : ''}} персональный вопрос {{(getFireUserData.fb_id !== nt.author_fb_id) ? 'пользователя' : ''}} <span class="bold-highlighted" v-if="nt.author && (getFireUserData.fb_id !== nt.author_fb_id)">{{nt.author.name}}</span>: <span class="bold-highlighted italic-highlighted">{{nt.question.name}}</span>
                    </span>
                </div>
                <div class="notification" v-if="nt.type === 6">
                    <!--Someone answered on your personal/redirected question-->
                    <!--<span v-if="(nt.is_personal && !nt.is_changed && !nt.is_redirected) || ((nt.is_personal && !nt.is_changed && nt.is_redirected) && (nt.author_fb_id === nt.from_user_fb_id))" >
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}} </span>
                        {{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}} на Ваш персональный вопрос:
                        <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>-->
                    <span v-if="(nt.is_personal && !nt.is_changed && !nt.is_redirected) && (nt.author_fb_id !== nt.from_user_fb_id)" >
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}}</span>
                        {{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}} на {{(getFireUserData.fb_id === nt.author_fb_id) ? 'Ваш' : ''}} персональный вопрос {{(getFireUserData.fb_id !== nt.author_fb_id) ? 'пользователя' : ''}}
                        <span class="bold-highlighted" v-if="nt.author && (getFireUserData.fb_id !== nt.author_fb_id)">{{nt.author.name}}</span> который Вы пересылали:
                        <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="(nt.is_personal && !nt.is_changed && !nt.is_redirected) && (getFireUserData.fb_id === nt.author_fb_id)">
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}} </span>
                        {{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}} на Ваш персональный вопрос:
                        <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="(nt.is_personal && !nt.is_changed && !nt.is_redirected) && (nt.author_fb_id === nt.from_user_fb_id)">
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}} </span>
                        {{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}} на свой персональный вопрос который Вы переслали:
                        <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="(nt.is_personal && !nt.is_changed && nt.is_redirected) && (nt.author_fb_id !== nt.from_user_fb_id)" >
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}}</span>
                        {{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}} на {{(getFireUserData.fb_id === nt.author_fb_id) ? 'Ваш' : ''}} персональный вопрос {{(getFireUserData.fb_id !== nt.author_fb_id) ? 'пользователя' : ''}}
                        <span class="bold-highlighted" v-if="nt.author && (getFireUserData.fb_id !== nt.author_fb_id)">{{nt.author.name}}</span> который Вы пересылали:
                        <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="(nt.is_personal && !nt.is_changed && nt.is_redirected) && (nt.author_fb_id === nt.from_user_fb_id)" >
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}}</span>
                        {{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}} на свой <!--{{(getFireUserData.fb_id === nt.author_fb_id) ? 'Ваш' : ''}}--> персональный вопрос <!--{{(getFireUserData.fb_id !== nt.author_fb_id) ? 'пользователя' : ''}}
                        <span class="bold-highlighted" v-if="nt.author && (getFireUserData.fb_id !== nt.author_fb_id)">{{nt.author.name}}</span>--> который Вы пересылали:
                        <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>

                    <span v-if="!nt.is_personal && !nt.is_changed">
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}} </span> {{(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'}} на вопрос который Вы переслали: <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="!nt.is_personal && nt.is_changed">
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}} </span> {{(nt.from_user.sex === 'f') ? 'изменила' : 'изменил'}} ответ на вопрос который Вы переслали: <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="(nt.is_personal && nt.is_changed && !nt.is_redirected) || ((nt.is_personal && nt.is_changed && nt.is_redirected) && (nt.author_fb_id === nt.from_user_fb_id))">
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}} </span> {{(nt.from_user.sex === 'f') ? 'изменила' : 'изменил'}} ответ на Ваш персональный вопрос: <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>
                    <span v-if="nt.is_personal && nt.is_changed && nt.is_redirected && (nt.author_fb_id !== nt.from_user_fb_id)">
                        <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}} </span>
                        {{(nt.from_user.sex === 'f') ? 'изменила' : 'изменил'}} ответ на {{(getFireUserData.fb_id === nt.author_fb_id) ? 'Ваш' : ''}} персональный вопрос {{(getFireUserData.fb_id !== nt.author_fb_id) ? 'пользователя' : ''}}
                        <span class="bold-highlighted" v-if="nt.author && (getFireUserData.fb_id !== nt.author_fb_id)">{{nt.author.name}}</span> который Вы пересылали:
                        <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span>
                    </span>
                </div>
	            <div class="notification" v-if="nt.type === 8">
		            <!--Question was changed-->
		            Вопрос <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span> был изменен админом и переведен в Основные. Просьба проверить и актуализировать ранее предоставленный ответ
	            </div>
	            <div class="notification" v-if="nt.type === 9">
		            <!--Your personal question was transfered to main questions-->
		            Ваш персональный вопрос <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span> был переведен админом в Основные вопросы
	            </div>
	            <div class="notification" v-if="nt.type === 10">
		            <!--Your personal question was transfered to main questions-->
		            <span :class="{'is_deleted': nt.from_user.is_deleted}" class="bold-highlighted">{{nt.from_user.name}} </span> {{(nt.from_user.sex === 'f') ? 'добавила' : 'добавил'}} комментарий к Вашему фото
	            </div>
                <!--<div class="friend-request" v-if="nt.type === 7">
                    &lt;!&ndash;<span v-if="!nt.question.is_comment_enabled"><span style="font-weight: 500">{{nt.from_user.name}} </span> {{(nt.from_user.sex === 'f') ? 'выключила' : 'выключил'}} возможность комментировать вопрос: <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span></span>
                    <span v-if="nt.question.is_comment_enabled"><span style="font-weight: 500">{{nt.from_user.name}} </span> {{(nt.from_user.sex === 'f') ? 'включила' : 'включил'}} возможность комментировать вопрос: <span class="italic-highlighted bold-highlighted">{{nt.question.name}}</span></span>&ndash;&gt;
                    &lt;!&ndash;
                        Коли is_comment_enabled міняється personal
                        question
                    &ndash;&gt;
                </div>-->
            </div>
            <div class="timestamp">{{nt.timestamp_parsed}}</div>
        </div>
    </div>
</template>

<script>
    import avatar from "../_shared/Avatar";

    export default {
        name: 'notificationBody',
        props: ['nt', 'isEarlierNt', 'loading'],
        data(){
            return {}
        },
        components: {avatar},
        computed: {
            getFireUserData() {
                return this.$store.state.firebase.firebaseUserData
            },
        },
        mounted() {},
        created() {},
        beforeDestroy() {},
        watch: {},
        methods: {
            showObj(data) {
                console.log(data);
            },
            meth(data) {
                switch (data.type) {
                    case 2:
                        if (data.is_personal) {
                            this.goToMainQuestions(data, data.parent_comment.question_id, true, 'comments');
                        } else {
                            this.goToPQ(data, data.parent_comment.question_id, true, 'comments')
                        }
                        break;
                    case 3:
                        if (!data.is_friend && data.is_friend_request) {
                            this.goToInnerCycle(1);
                        } else if (data.is_friend && !data.is_friend_request) {
                            this.goToInnerCycle(0);
                        }
                        break;
                    case 4:
                        break;
                    case 5:
                        if (!data.is_personal) {
                            this.goToMainQuestions(data, data.question.id, true, 'main')
                        } else {
                            this.goToPQ(data, data.question.id, true, 'main')
                        }
                        break;
                    case 6:
                        if (data.is_personal) {
                            this.goToPQ(data, data.question.id, true, 'main');
                        } else if (!data.is_personal) {
                            this.goToMainQuestions(data, data.question.id, true, 'main');
                        } else if (data.is_personal && data.is_changed) {
                            this.goToPQ(data, data.question.id, true, 'main');
                        } else if (!data.is_personal && data.is_changed) {
                            this.goToMainQuestions(data, data.question.id, true, 'main');
                        }
                        break;
					case 8:
						data['is_personal'] = 0;
						this.goToMainQuestions(data, data.question.id, true, 'main');
                        break;
					case 9:
						data['is_personal'] = 0;
						this.goToMainQuestions(data, data.question.id, true, 'main');
                        break;
					case 10:
						data['is_personal'] = 0;
						this.goToMyProfile(data, data.object_id, true, 'comments');
                        break;
                }
            },
            changeNotificationStatus(data) {
                data.is_checked = true;
                this.$firebaseNotifications.doc(data.id).update({
                    is_checked: true
                })
            },
            goToInnerCycle(tab) {
                this.$router.push({ path: '/users/my-circle', query: { tab: tab } }).catch(error => {
                    if (error.name != "NavigationDuplicated") {
                        throw error;
                    } else {
                        this.hideDropdown();
                    }
                });
            },
            goToMainQuestions(data, id, openModal, modalPos) {
				console.log(data);
                if (openModal) {
                    this.$router.push({ path: '/questions/main-questions', query: { tab: 1, fn: 1, qi: id, ip: (data.is_personal) ? 1 : 0, pos: modalPos, from_id: data.from_user.id, from_name: data.from_user.name, type: data.type, firebaseId: data.id} })
                } else {
                    this.$router.push({ path: '/questions/main-questions', query: { tab: 1} })
                }
            },
            goToPQ(data, id, openModal, modalPos) {
                if (openModal) {
                    this.$router.push({ path: '/questions/personal-questions', query: { tab: 1, fn: 1, qi: id, ip: (data.is_personal) ? 1 : 0, pos: modalPos, from_id: data.from_user.id, from_name: data.from_user.name, type: data.type, firebaseId: data.id} })
                } else {
                    this.$router.push({ path: '/questions/personal-questions', query: { tab: 1} })
                }
            },
            goToProfile(data) {
                if (this.$route.params.id !== data.id) {
                    this.$router.push({path: `/profile/${data.id}`});
                }
            },
	        goToMyProfile(data, id, openModal, modalPos) {
		        this.$router.push({path: `/`, query: { fn: 1, qi: id, pos: modalPos, from_id: data.from_user.id, from_name: data.from_user.name, type: data.type, firebaseId: data.id}});
            }
        }
    }
</script>

<style lang="less" scoped>
    .notification-item-wrapper {
        padding: 8px;
        display: flex;
        border-bottom: 0.5px solid rgba(94, 111, 143, 0.3);
        opacity: 0;
        transition: opacity 500ms;
        &.earlier {
            background-color: #d3eefd;
            &.is_checked {
                background-color: white;
            }
        }
        &.loaded {
            opacity: 1;
        }
        .preview-user {
            margin-right: 8px;
        }
        .info-wrapper {
            .info {
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.1px;
                color: #666666;
                .notification {
                    word-break: break-word;
                    span {
                        &.is_deleted {
                            font-style: italic;
                        }

                        &.bold-highlighted {
                            font-weight: 500;
                        }

                        &.italic-highlighted {
                            font-style: italic;
                        }
                    }
                }
            }

            .timestamp {
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.1px;
                color: #6B6C7E;
            }
        }
    }
</style>
