<template>
	<div id="user-dropdown-filter">
		<div class="selected-item" @click="toggleDropdown">
			<span class="description">{{ selectedItem.description }}</span>
			<img src="../../assets/img/line-top.svg" alt="right">
		</div>
		
		<div class="dropdown-wrapper" v-if="showDropdown">
			<div v-if="i.id !== selectedItem.id" class="item" v-for="i in listOfItems" @click="onChange(i)">
				<span class="description" :class="{'is-selected': i.id === selectedItem.id}">{{ i.description }}</span>
			</div>
		</div>
	</div>

</template>

<script>

import EventBus from "../../_helpers/event-bus";
import {usersService} from "../../_api";

export default {
	name: 'user-dropdown-filter',
	props: {
		isDisabled: Boolean,
		defaultSelected: Number,
		preloadData: Object,
		hasEventBusEmit: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			listOfItems: [
				{
					description: 'По матчингу',
					id: 1
				},
				{
					description: 'Последние',
					id: 2
				}
			],
			selectedItem: {},
			showDropdown: false
		}
	},
	watch: {
		isDisabled: function (val) {
			if (val) {
				this.showDropdown = false;
			}
		}
	},
	computed: {},
	created() {
		// alert(this.getDetailedSearchAPI())
		if (this.preloadData) {
			this.search = this.preloadData.name;
		}
	},
	mounted() {
		this.selectDefault();
		EventBus.$on('matchingFilterChange', (data) => {
			console.log(data);
			if (data) {
				this.onChange(this.listOfItems[0], false);
			}
		})
	},
	beforeDestroy() {
		EventBus.$off('matchingFilterChange');
	},
	methods: {
		selectDefault() {
			this.selectedItem = this.listOfItems.filter((obj) => {return obj.id === this.defaultSelected})[0];
			console.log('this.selectedItem', this.selectedItem)
			if (this.hasEventBusEmit) {
				EventBus.$emit('matchingDDFilterChange', this.selectedItem, false);
			}
		},
		toggleDropdown(e) {
			e.stopPropagation(); // this will stop propagation of this event to upper level
			if (!this.isDisabled) {
				this.showDropdown = !this.showDropdown;
				if (this.showDropdown) {
					window.addEventListener('click', () => {
						this.showDropdown = false
					})
				} else {
					window.removeEventListener('click', () => {
						this.showDropdown = false
					})
				}
			}
		},
		onChange(i, reload) {
			let r = (reload !== undefined) ? reload : true;
			this.selectedItem = i;
			this.showDropdown = false;
			console.log('this.selectedItem', this.selectedItem);
			this.$emit('onChange', this.selectedItem.id, r);
			if (this.hasEventBusEmit) {
				EventBus.$emit('matchingDDFilterChange', this.selectedItem, true);
			}
		}
	}
}

</script>

<style lang="less" scoped>
@import "../../assets/styles/_vars";

#user-dropdown-filter {
	// min-width: 150px;
	position: relative;
	
	.selected-item {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 2px 5px;
		cursor: pointer;
		
		img {
			transform: rotate(180deg);
		}
		
		.description {
			font-size: 12px;
			line-height: 14px;
			color: #333333;
			margin-right: 5px;
		}
	}
	
	.dropdown-wrapper {
		z-index: 99;
		position: absolute;
		width: 100%;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		padding: 3px 0;
		background: #fff;
		border-radius: 0 0 1px 1px;
		box-shadow: 0 0 6px -4px black;
		
		.item {
			&:hover {
				background-color: #E8F1F9;
			}
			
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 2px 5px;
			cursor: pointer;
			
			.description {
				font-size: 12px;
				line-height: 14px;
				color: #333333;
			}
		}
	}
}
</style>
