<template>
	<b-modal ref="desktop-chat-window" size="lg" hide-footer hide-header id="desktop-chat-window" :no-close-on-backdrop="true">
		<div class="modal-close-btn" @click="closeModal()">
			<i class="fa fa-times" aria-hidden="true"></i>
		</div>
		<chat-window :class="{'chat-not-selected': !activeChat}" :active-avatar="activeAvatar" :activeChat="activeChat" :friendData="friend_data" :isLoading="loading" :chatsList="chatsList"></chat-window>
	</b-modal>
</template>

<script>
import ChatWindow from "../../../components/chat/chat-window/ChatWindow";
import {usersService, questionsService} from "../../../_api";
import {handleAWSImage} from "@/_helpers/aws-handler";
import moment from "moment";

export default {
	name: 'ChatModal',
	components: {ChatWindow},
	props: ['activeChatFromList', 'chatId'],
	data: function () {
		return {
			screenWidth: 1920,
			activeAvatar: null,
			activeChat: null,
			cacheLoading: true,
			chatsLimit: 20,
			chatsList: [],
			chatListener: null,
			prevChatListener: null,
			lastVisible: null,
			initLoad: true,
			friend_data: null,
			searchResults: [],
			searchLoading: false,
			loading: false
		}
	},
	created() {
		this.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
		window.addEventListener("resize", this.screenWidthChange);
	},
	mounted() {},
	beforeDestroy() {},
	watch: {
		activeChat: function(val) {
			if (val) {
				this.friend_data = null;
				this.getUserInfo(val.friendData.id);
			}
		}
	},
	computed: {
		getFireUserData() {
			return this.$store.state.firebase.firebaseUserData
		}
	},
	methods: {
		showModal(data, full_reload) {
			this.getActiveChatData(data, full_reload).then((result) => {
				if (result) {
					setTimeout(() => {
						this.$refs['desktop-chat-window'].show();
						this.readMessages();
					}, 500);
				} else {
					alert('Something unexpected happened :(')
				}
			})
		},
		getActiveChatData(data, full_reload) {
			return new Promise((resolve, reject) => {
				if (!full_reload) {
					this.activeChat = data;
					resolve(true);
				} else {
					this.$firebaseChats.doc(data).get().then(querySnapshot => {
						if (querySnapshot.exists) {
							this.getUser(querySnapshot.data()).then(res => {
								if (res) {
									this.chatObjFormation(querySnapshot.id, querySnapshot.data(), res).then((data) => {
										this.activeChat = {...data};
										resolve(true);
									})
								} else {
									resolve(false);
								}
							})
						} else {
							resolve(false);
						}
					});
				}
			})
		},
		closeModal() {
			this.$refs['desktop-chat-window'].hide();
			this.$emit('clearActiveChat');
		},
		getUserInfo(id) {
			usersService.getUsersData({
				user_id: id
			}).then((res) => {
				if (res) {
					this.friend_data = res;
					console.log(this.friend_data);
				}
			})
		},
		/*setActive(data, automated, modal) {
			function arraymove(arr, fromIndex, toIndex) {
				var element = arr[fromIndex];
				arr.splice(fromIndex, 1);
				arr.splice(toIndex, 0, element);
			}
			if (modal) {
				/// 1 check all the chats list if selected chat is in
				/// if in, simply perform actions from else condition
				/// if not, get it, and put in the list top
				let q;
				if (data.id !== undefined) {
					q = data.id;
				} else {
					q = data;
				}
				
				let index = this.chatsList.findIndex((i) => {return i.id === data});
				if (index !== -1) { // IF USER IS IN THE ACTIVE LIST OF CHATS
					this.chatsList = this.chatsList.filter(obj => {
						if (obj.id === this.chatsList[index].id) {
							obj.active = true;
							this.activeChat = {...this.chatsList[index]};
							this.$forceUpdate();
						} else {
							obj.active = false
						}
						return obj
					});
					
					arraymove(this.chatsList, index, 0);
					
				} else { // IF USER IS NOT IN THE ACTIVE LIST OF CHATS WE GET HIS FIREBASE DATA AS USUAL AND PERFORM REGULAR OBJ TRANSFORMATION
					let isInit = true;
					this.$firebaseChats.doc(data).get().then(querySnapshot => {
						if (!querySnapshot.empty) {
							this.getUser(querySnapshot.data()).then(res => {
								if (res) {
									this.chatObjFormation(querySnapshot.id, querySnapshot.data(), res).then((data) => {
										if (isInit) {
											// this.chatsList.unshift(data);
											this.activeChat = {...data};
											this.chatsList = this.chatsList.filter(obj => {
												obj.active = obj.id === this.activeChat.id;
												return obj
											});
											
											if (this.$route.query.t !== this.activeChat.id) {
												this.$router.push({path: 'messages',query: {'t': this.activeChat.id}});
											}
										}
									})
								}
							})
						}
					});
				}
			} else {
				this.chatsList = this.chatsList.filter(obj => {
					if (obj.id === data.id) {
						obj.active = true;
						this.activeChat = data;
						this.readMessages();
						this.$forceUpdate();
					} else {
						obj.active = false
					}
					this.$forceUpdate();
					return obj
				});
				if (!automated && this.getActiveChat !== data.id) {
					this.$router.push({path: 'messages',query: {'t': data.id}});
				}
			}
		},*/
		getUser(data) {
			console.log(data);
			console.log(this.getFireUserData);
			let idToFind = data.chatMembers.filter((o) => {return o !== this.getFireUserData.fb_id})[0];
			return new Promise(((resolve, reject) => {
				this.$firebaseUsers.where('fb_id', '==', idToFind).get().then(querySnapshot => {
					if (!querySnapshot.empty) {
						console.log(querySnapshot.docs[0].data());
						resolve(querySnapshot.docs[0].data());
					} else {
						resolve(null);
					}
				})
			}))
		},
		
		chatObjFormation(chat_id, chat_data, friend_data) {
			return new Promise((resolve, reject) => {
				let chatItemData = chat_data;
				chatItemData['id'] = chat_id;
				chatItemData['active'] = chat_data.active || false;
				chatItemData['unreadCount'] = 0;
				if (friend_data) {
					chatItemData['friendData'] = friend_data;
				}
				if (chatItemData['friendData']['avatar_parsed'] === undefined) {
					chatItemData['friendData']['avatar_parsed'] = null;
					console.log(chatItemData);
					if (chatItemData['friendData']['avatar'] !== null && chatItemData['friendData']['avatar'].includes('http')) {
						handleAWSImage(chatItemData['friendData']['avatar']).then((response) => {
							chatItemData['friendData']['avatar_parsed'] = response;
							this.$forceUpdate();
						})
					}
				}
				if (chatItemData.lastMessage && chatItemData.lastMessage.date) {
					chatItemData.lastMessage['timestamp_formatted'] = this.moment(chatItemData.lastMessage.date.toDate()).format("YYYY.MM.DD");
				}
				if (chatItemData.updatedAt) {
					chatItemData['updated_at_readable'] = chatItemData.updatedAt.toDate();
				}
				// this.getUserProgressBar(chatItemData);
				resolve(chatItemData);
			})
			
		},
		/*getUserProgressBar(data) {
			questionsService.getUserProgressBarAPI({
				user_ids: [data.friendData.id]
			}).then(res => {
				if (res) {
					data = {...data, ...res[0]};
					let t = this.chatsList.findIndex((i) => {return i.id === data.id});
					if (t !== -1) {
						this.chatsList[t] = data;
						this.chatsList = [...this.chatsList];
					}
					let tt = this.searchResults.findIndex((i) => {return i.id === data.id});
					if (tt !== -1) {
						this.searchResults[tt] = data;
						this.searchResults = [...this.searchResults];
					}
				}
			});
		},*/
		
		readMessages() {
			this.$firebaseChats.doc(this.activeChat.id).collection('messages').where('status', '==', 0).get().then(obj => {
				if (!obj.empty) {
					obj.docs.forEach(c_obj => {
						if (this.getFireUserData.id !== c_obj.data().authorId) {
							this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc(c_obj.id).update({
								status: 1
							})
						}
					});
					this.$firebaseChats.doc(this.activeChat.id).collection('messages').where('status', '==', 0).get().then(obj => {
						if (!obj.empty) {
							this.$firebaseChats.doc(this.activeChat.id).update({
								unreadCount: obj.docs.length
							})
						} else {
							this.$firebaseChats.doc(this.activeChat.id).update({
								unreadCount: 0
							})
						}
					})
				}
			})
		},
	}
}
</script>

<style lang="less" scoped>
@import "../../../assets/styles/_unified";
::v-deep #desktop-chat-window {
	.modal-body {
		padding: 0;
		position: relative;
		.modal-close-btn {
			color: gray;
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1200px) {

}
// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 768px) {
	::v-deep #nav {
		margin-bottom: 10px;
	}
	::v-deep body {
		&.modal-open {
			#chat-window-page {
				position: initial;
			}
		}
	}
	#messages-page {
		overflow: hidden;
		flex-wrap: wrap;
		.mobile-back-btn {
			display: flex;
		}
		#list-of-chats-page {
			max-width: unset;
			position: fixed;
			top: 70px;
			width: 90%;
			left: 0;
			right: 0;
			margin: 0 auto;
			&.chat-selected {
				display: none;
			}
			::v-deep .search-wrapper {
				width: 100%;
			}
			::v-deep .list-of-chats-wrapper {
				height: calc(100vh - 250px);
			}
		}
		#chat-window-page {
			max-width: unset;
			position: fixed;
			top: 65px;
			width: 95%;
			left: 0;
			right: 0;
			margin: 0 auto;
			&.chat-not-selected {
				display: none;
			}
			::v-deep #chat-window-wrapper {
				height: calc(100vh - 320px);
			}
		}
	}
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {

}
</style>
