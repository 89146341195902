<template>
    <div class="wrapper">
        <div class="similar-people-header">
            <div class="similar-people-header__title">
                {{headerTitle}}
            </div>
            <div v-if="onlyHeader" class="invite-friends-wrapper">
                <button class="invite-friends-btn" @click="showInviteFriendsModal()">
                    Пригласить друзей
                </button>
            </div>
            <div class="similar-people-header__controls" @click="show = !show" v-if="!onlyHeader">
                <span>Подобрать по параметрам</span>
                <img src="../../assets/img/line-top.svg" alt="right" :class="{'open': show}">
            </div>
        </div>
        <search-filter v-if="hasNameSearchBar" :show-error="true" :placeholderText="'Введите имя'" :items="[]" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" :search-disabled="searchDisabled" :preloadData="filterData"></search-filter>
        <transition name="fadeFilter">
            <div class="search-filter-wrapper" v-if="!onlyHeader" v-show="show">
	            <div ref="matching-options" class="matching-options" v-if="showMatchedOption" :class="{'no-selected-questions': !filterData.selected_questions.length}">
		            <div ref="matching-options-anchor" id="matching-options-anchor" style="position: absolute; top: -100px"></div>
		            <div class="with-similar-answers-wrapper u-checkbox-wr left-side">
			            <div ref="with-similar-answers-anchor" id="with-similar-answers-anchor" style="position: absolute; top: -10px"></div>
			            <div class="form-group u-radiobutton-wr" v-for="i of matching_options" :class="{'no-border': i.id === 2 && filterData.matching_option && filterData.matching_option.id === 2}">
				            <input type="radio" :id="i.label + i.id" :value="i" v-model="filterData.matching_option" @click="uncheckMatching(i)">
				            <label v-bind:for="i.label + i.id" class="label-as-input border-none"><span class="checkmark"></span>{{i.label}}</label>
			            </div>
		            </div>
		            <button class="def-btn select-questions-modal" @click="showModal(false)" v-if="filterData.matching_option && filterData.matching_option && filterData.matching_option.id === 2" :style="[filterData.selected_questions.length ? {'margin-bottom': '20px'} : {}]">Выбрать вопросы и ответы</button>
		            <div class="selected-questions" v-if="filterData.selected_questions.length" :class="{'no-border': filterData.matching_option.id === 1 && filterData.selected_questions.length}">
			            <div class="selected-questions-title">выбранные вопросы</div>
			            <ul id="selected-questions-list">
				            <li v-for="i in filterData.selected_questions">
					            <div>
						            <span>{{i.selected_filter.question_name}}</span>
						            <span class="delete-icon" @click="deleteQuestion(i)">
	                                <i class="far fa-trash-alt" ></i>
	                            </span>
					            </div>
				            </li>
			            </ul>
			            <div class="question-controls">
				            <div class="show-rest-questions" @click="showModal(true)">
					            показать все вопросы - {{filterData.selected_questions.length}}
				            </div>
				            <div class="add-questions" @click="showModal(false)">
					            Добавить воопросы
				            </div>
			            </div>
			            
		            </div>
	            </div>
                <transition name="fadeFilter">
	                <div ref="filter" class="controls" v-if="showControls" :class="{ 'show-border':showControls}">
		                <div class="filters-title">Фильтры</div>
		                <div ref="filter-anchor" id="filter-anchor" style="position: absolute; top: -100px"></div>
		                <div class="sex-select-wrapper">
			                <div class="sex-item-wrapper">
				                <input id="sex-any" type="radio" value="any" v-model="filterData.sex" name="sex-select" checked/>
				                <label class="label-as-input" for="sex-any">
					                Любой пол
				                </label>
			                </div>
			                <div class="sex-item-wrapper">
				                <input id="sex-man" type="radio" value="m" v-model="filterData.sex" name="sex-select"/>
				                <label class="label-as-input" for="sex-man">
					                Мужчина
				                </label>
			                </div>
			                <div class="sex-item-wrapper">
				                <input id="sex-woman" type="radio" value="f" v-model="filterData.sex" name="sex-select"/>
				                <label class="label-as-input" for="sex-woman">
					                Женщина
				                </label>
			                </div>
		                </div>
		                <div class="age-wrapper">
			                <span class="user-age-text">Возраст:</span>
			                <range-slider ref="range-slider" @updateRange="updateAge($event)" class="user-age-range" :min="0" :max="100" :defaultSelected="preloadData"></range-slider>
		                </div>
		                <div class="location-wrapper">
			                <googleAutocomplete ref="google-autocomplete" id="user-location" classname="form-control" placeholder="Место жительства" customAutocompleteText="Населенный пункт в любой стране, например: Москва, Россия" @placechanged="getAddressData" :savedData="filterData.location" types="(cities)" @inputChange="inputChange" @loading-results="loadingResults"></googleAutocomplete>
		                </div>
		                <div class="checkbox-selectors-wrapper">
			                <div class="family-status-wrapper">
				                <div class="group-title">
					                Семейное положение
				                </div>
				                <div class="form-group u-checkbox-wr left-side" v-for="i of family_status_options">
					                <label :for="i.id" class="checkbox-container">
						                <input type="checkbox" class='custom-checkbox' :value="i" :id="i.id" v-model="filterData.family_status">
						                <span class="checkmark"></span>
						                {{i.label}}
					                </label>
				                </div>
			                </div>
			                <div class="children-wrapper">
				                <div class="group-title">
					                Дети
				                </div>
				                <div class="form-group u-radiobutton-wr" v-for="i of children_options">
					                <input type="radio" :id="i.label + i.id" :value="i" v-model="filterData.children" @click="uncheck(i)">
					                <label v-bind:for="i.label + i.id" class="label-as-input border-none"><span class="checkmark"></span>{{i.label}}</label>
				                </div>
			                </div>
			                <div class="orientation-wrapper">
				                <div class="group-title">
					                Ориентация
				                </div>
				                <div class="form-group u-checkbox-wr left-side" v-for="i of orientation_options">
					                <label :for="i.label+i.id" class="checkbox-container">
						                <input type="checkbox" class='custom-checkbox' :value="i" :id="i.label+i.id" v-model="filterData.orientation_status">
						                <span class="checkmark"></span>
						                {{i.label}}
					                </label>
				                </div>
			                </div>
		                </div>
	                </div>
                </transition>
                <div class="submit-wrapper">
                    <button class="def-btn inverted" @click="toggleFilters()" :disabled="searchDisabled" v-if="peopleSearchSectionUI" :class="{'cancel-action': showControls}">{{showControls ? 'Очистить фильтры' : 'Фильтры'}}</button>
                    <button v-if="!peopleSearchSectionUI" class="clear-filter" @click="clearFilter" :disabled="searchDisabled">Очистить параметры поиска</button>
                    <button class="def-btn" @click="submitFilter" :disabled="searchDisabled">Показать результаты поиска</button>
                </div>
            </div>
        </transition>

        <div v-if="!onlyHeader && hasInviteFriendsOption" class="invite-friends-wrapper">
            <button class="invite-friends-btn" @click="showInviteFriendsModal()">
                Пригласить друзей
            </button>
        </div>
	    <invite-users-modal v-if="showInviteUsersModal" ref="invite-users-modal"></invite-users-modal>
        <search-by-question-modal v-if="showQModal" ref="search-by-question-modal" :selectedQuestions="filterData.selected_questions" :pinnedQuestionId="questionForSeach"></search-by-question-modal>
    </div>
</template>

<script>
    import googleAutocomplete from "./GoogleAutocomplete";
    import rangeSlider from './RangeSlider';
    import {FAMILY_STATUS_OPTIONS, CHILDREN_OPTIONS, ORIENTATIONS_OPTIONS} from '@/_models/static';
    import EventBus from "../../_helpers/event-bus";
    import SearchByQuestionModal from "./modals/_SearchByQuestionModal";
    import SearchFilter from "./SearchFilter";
    import InviteUsersModal from "@/components/_shared/modals/_InviteUsersModal";

    export default {
        name: 'user-complex-filter',
	    props: {
		    showFilter: {},
		    closeAfterSubmit: {},
		    headerTitle: {},
		    showMatchedOption: {},
		    onInitLoad: {},
		    onInitOpen: {},
		    customMargin: {},
		    onlyHeader: {},
		    searchDisabled: {},
		    preloadData: {},
		    customMatchingOption: {},
		    hasNameSearchBar: {},
		    hasInviteFriendsOption: {
				default: true,
			    type: Boolean
		    },
		    peopleSearchSectionUI: {
				default: false,
			    type: Boolean
		    }
	    },
        data () {
            return {
                show: false,
	            showControls: true,
	            showQModal: false,
	            showInviteUsersModal: false,
                filterData: {
                    age_from: 0,
                    age_to: 100,
                    sex: 'any',
                    goals: [],
                    occupations: [],
                    orientation: null,
                    family_status: [],
                    orientation_status: [],
                    children: null,
                    education: null,
                    work_status: null,
                    location: {},
                    purpose_of_stay: [],
                    coincidence_topics: [],
                    matching_option: null,
                    selected_questions: [],
                    name: ''
                },
                family_status_options: FAMILY_STATUS_OPTIONS,
                orientation_options: ORIENTATIONS_OPTIONS,
                children_options: CHILDREN_OPTIONS.filter((obj) => {return obj.id === 1 || obj.id === 2;}),
                matching_options: [
                    {
                        id: 1,
                        label: 'Выберите тех, чьи ответы похожи на ваши'
                    },
                    {
                        id: 2,
                        label: 'Поиск по вопросам и ответам'
                    }
                ],
                show_rest_questions: false,
	            searchDisabledLocally: false,
	            submitted: false
            }
        },
        created() {
            if (this.preloadData) {
                this.filterData = this.preloadData;
                this.$forceUpdate();
            }
            if (this.customMatchingOption) {
                this.filterData['matching_option'] = this.matching_options.filter((o) => {return o.id === this.customMatchingOption})[0];
                this.$forceUpdate();
            }
            this.show = this.showFilter;
            if (this.onInitLoad) {
                this.submitFilter('type');
            }
			
			if (this.questionForSeach) {
				this.showModal();
				this.filterData.matching_option = this.matching_options[1];
			}
			if (this.peopleSearchSectionUI) {
				this.showControls = false
			}
        },
        mounted() {
			EventBus.$on('searchModalHidden', () => {
				if (this.filterData.selected_questions.length) {
					setTimeout(() => {
						this.$refs['with-similar-answers-anchor'].scrollIntoView({behavior: 'smooth'})
					}, 250)
				}
			});
            EventBus.$on('matchingDDFilterChange', (data, reload) => {
				console.log(data);
				console.log(reload);
				console.log(this.filterData.matching_option);
				if (!reload) {
					this.filterData.matching_option = this.matching_options[0];
				} else {
					if (data.id === 2) {
						this.filterData.matching_option = null
					} else {
						this.filterData.matching_option = this.matching_options[0];
					}
					/*if (this.filterData.matching_option === null) {
						this.filterData.matching_option = this.matching_options[0];
					} else if (this.filterData.matching_option.id === 1) {
						this.filterData.matching_option = (data) ? this.matching_options[0] : null
					}*/
				}
                /*if (this.filterData.matching_option === null) {
                    // this.filterData.matching_option = this.matching_options[0];
                } else if (this.filterData.matching_option.id === 1) {
                    this.filterData.matching_option = (data) ? this.matching_options[0] : null
                }*/
            });
        },
        beforeDestroy() {
            EventBus.$off('matchingDDFilterChange');
        },
        computed: {
	        questionForSeach () {
		        return this.$route.query.question_for_search
	        },
        },
        watch: {
            'filterData.matching_option': function (value) {
                console.log(value);
                if (value !== null && value.id === 1) {
                    EventBus.$emit('matchingFilterChange', true);
                } else if (value === null) {
                    // this.filterData.selected_questions = [];
                }
            },
	        searchDisabledLocally: function (value) {
				if (!value && this.submitted) {
					this.submitFilter('scroll')
				}
	        }
        },
        methods: {
	        loadingResults(data) {
		        this.searchDisabledLocally = data;
	        },
			deleteQuestion(data) {
				if (data.object_id === parseInt(this.questionForSeach)) {
					this.$router.replace({});
				}
				this.filterData.selected_questions = this.filterData.selected_questions.filter((res) => {
					return res.id !== data.id
				});
				this.$forceUpdate();
			},
            onSearchChange(data) {
                this.filterData.name = data;
            },
            filteredList (data) {
                this.filterData.name = data.searchStr;
                this.submitFilter('scroll');
            },
	        toggleFilters() {
				if (this.showControls) {
					this.clearFilter();
					this.$refs['matching-options-anchor'].scrollIntoView({behavior: 'smooth'})
				} else {
					this.showControls = !this.showControls;
					this.$nextTick(() => {
						this.$refs["filter-anchor"].scrollIntoView({behavior: 'smooth'})
					})
				}
	        },

            showModal(show_selected) {
				this.showQModal = true;
				this.$nextTick(() => {
					this.$refs['search-by-question-modal'].showModal(show_selected);
				})
            },
            showInviteFriendsModal() {
				this.showInviteUsersModal = true;
				this.$nextTick(() => {
					this.$refs['invite-users-modal'].showModal();
				})
            },
            uncheck() {
                this.filterData.children = null
            },
            uncheckMatching(i) {
                if (this.filterData.matching_option && (this.filterData.matching_option.id === i.id)) {
                    this.filterData.matching_option = null;
                }
            },
            inputChange(data) {
                if (data.newVal !== undefined && !data.newVal.length) {
                    this.filterData.location = {};
                    this.filterData = {...this.filterData};
                }
            },
            getAddressData(data,i,s,fromAutocomplete) {
                this.filterData.location = data;
            },
            updateAge(arr) {
                this.filterData.age_from = arr[0];
                this.filterData.age_to = arr[1];
            },
            clearFilter() {
				if (this.peopleSearchSectionUI) {
					this.showControls = false;
				}
                this.filterData = {...this.filterData, ...{
		                age_from: 0,
		                age_to: 100,
		                sex: 'any',
		                goals: [],
		                occupations: [],
		                orientation: null,
		                family_status: [],
		                orientation_status: [],
		                children: null,
		                education: null,
		                work_status: null,
		                location: {},
		                purpose_of_stay: [],
		                coincidence_topics: []
	                }};
                this.$refs['google-autocomplete'].clear();
                this.$refs['range-slider'].clear();
                this.$emit('filterCleared');
				if (this.$refs['search-by-question-modal']) {
					this.$refs['search-by-question-modal'].searchClear();
				}
                this.$forceUpdate();
            },
            submitFilter(type) {
                console.log(this.filterData);
                console.log(type);
	            this.submitted = false;
                if (!this.searchDisabledLocally) {
	                let t = null;
	                if (type === 'scroll') {
		                t = 'scroll';
	                }
	                this.filterData['type'] = t;
	                this.$emit('filterSubmit', this.filterData);
	                if (this.closeAfterSubmit) {
		                this.show = false;
	                }
                } else {
	                this.submitted = true;
                }
            },
        },
        components: {
	        InviteUsersModal,
            SearchFilter,
            SearchByQuestionModal,
            googleAutocomplete,
            rangeSlider
        }
    }
</script>

<style lang="less" scoped>
    @import "../../assets/styles/_vars.less";
    .fadeFilter-enter-active, .fadeFilter-leave-active {
        transition: all .3s;
    }
    .fadeFilter-enter, .fadeFilter-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .wrapper {
        .similar-people-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-bottom: 10px;
            .invite-friends-wrapper {
                margin: 0;
            }
            .similar-people-header__title {
                font-size: 14px;
                line-height: 24px;
                text-align: right;
                letter-spacing: -0.14px;
                text-transform: uppercase;
                color: #666666;
            }
            .similar-people-header__controls {
                display: flex;
                align-self: center;
                font-size: 12px;
                line-height: 14px;
                text-align: right;
                color: #333333;
                cursor: pointer;
                img {
                    transition: all 0.5s;
                    transform: rotate(180deg);
                    &.open {
                        transform: rotate(360deg);
                    }
                }
                span {
                    margin-right: 10px;
                }
            }
        }
        .search-filter {
            border: 1px solid #e3e6eb;
            margin-bottom: 16px;
            background-color: #fff;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        }
        .search-filter-wrapper {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 35px;
            margin-bottom: 10px;
            border-bottom: 1px solid #e3e6eb;
	        .controls {
		        width: 100%;
		        flex-wrap: wrap;
		        display: flex;
		        position: relative;
		        .filters-title {
			        display: flex;
			        width: 100%;
			        font-size: 18px;
			        color: gray!important;
			        margin-bottom: 15px;
			        border-bottom: 1px solid lightgray;
		        }
		        &.show-border {
			        border-bottom: 1px solid #e3e6eb;
			        margin-bottom: 20px;
			        padding-bottom: 10px;
		        }
	        }
            label {
                opacity: 0.8;
            }
            .group-title {
                width: 100%;
                font-weight: 500;
                font-size: 11px;
                line-height: 24px;
                letter-spacing: -0.11px;
                text-transform: uppercase;
                color: #666666;
                text-align: left;
                margin-bottom: 15px;
            }
            .checkbox-selectors-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;
            }
            .children-wrapper, .family-status-wrapper, .orientation-wrapper {
                width: 32%;
                // margin-bottom: 20px;
                .u-checkbox-wr {
                    .checkbox-container {
                        font-size: 15px;
                    }
                }
            }

            .sex-select-wrapper {
                display: flex;
                width: 50%;
                margin-right: 3%;
                margin-bottom: 20px;
                .sex-item-wrapper {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        visibility: hidden;
                        &:checked + label {
                            background: #71809C;
                            color: #fff;
                        }
                    }
                    .label-as-input {
                        margin-bottom: 0;
                        font-size: 15px;
                        line-height: 15px;
                        letter-spacing: 0.1px;
                        color: #5E6F8F;
                        padding: 15px;
                        border: 1px solid rgba(94, 111, 143, 0.3);
                        cursor: pointer;
                    }
                }
            }
            .age-wrapper {
                width: 47%;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                .user-age-text {
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 15px;
                    letter-spacing: 0.1px;
                    color: #8894AC;
                    margin-right: 15px;
                }
                .user-age-range {
                    width: 100%;
                }
            }
            .location-wrapper {
                width: 100%;
                margin-bottom: 20px;
                #autocomplete-directive {
                    .input-wrapper {
                        background: red;
                        span {
                            input {

                            }
                        }
                    }
                }
            }
            .purpose-of-stay-wrapper {
                width: 40%;
                margin-bottom: 20px;
                .checkboxes-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .purpose-option-wrapper {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        label {
                            margin-bottom: 0;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 15px;
                            letter-spacing: 0.1px;
                            color: #333333;
                            mix-blend-mode: normal;
                            opacity: 0.8;
                        }
                    }
                }
            }
            .coincidence-on-topics-wrapper {
                display: flex;
                flex-wrap: wrap;
                width: 60%;
                align-items: flex-start;
                justify-content: flex-start;
                align-content: flex-start;
                .checkboxes-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 48%;
                    margin-right: 2%;
                    margin-bottom: 20px;
                    .coincidence-option-wrapper {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        label {
                            margin-bottom: 0;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 15px;
                            letter-spacing: 0.1px;
                            color: #333333;
                            mix-blend-mode: normal;
                            opacity: 0.8;
                        }
                    }
                }
            }
            .show-more-options-wrapper {
                width: 67%;
                text-align: left;
                .show-more-title {
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 15px;
                    text-transform: uppercase;
                    color: #5E6F8F;
                    mix-blend-mode: normal;
                    opacity: 0.64;
                    margin-bottom: 6px;
                }
                .show-more-subtitle {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 15px;
                    letter-spacing: 0.1px;
                    color: #5E6F8F;
                    mix-blend-mode: normal;
                    opacity: 0.8;
                    margin-bottom: 6px;
                }
            }
            .with-similar-answers-wrapper {
	            position: relative;
            }
            .matching-options {
                padding: 20px 0;
	            width: 100%;
	            border-bottom: 1px solid lightgrey;
	            margin-bottom: 50px;
	            position: relative;
	            &.no-selected-questions {
		            border-bottom: none;
		            margin-bottom: 20px;
	            }
                .with-similar-answers-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
	                position: relative;
                    .form-group {
                        margin: 0;
	                    width: 100%;
	                    border-bottom: 1px solid lightgrey;
	                    padding: 0 0 20px 0;
	                    &.no-border {
		                    border-bottom: none;
		                    padding-bottom: 0;
	                    }
	                    &:last-child {
		                    padding-top: 20px;
	                    }
                        &:not(:last-child) {
                            // margin-bottom: 10px;
                        }
                        .label {
                            line-height: 15px;
                            font-weight: 500;
                            font-size: 14px;
                        }
                    }
                }
                .selected-questions {
                    padding: 10px 0 0 0;
                    border-top: 1px solid lightgrey;
                    position: relative;
	                //margin-bottom: 50px;
	                &.no-border {
		                border-top: none;
	                }
                    .selected-questions-title {
                        text-transform: uppercase;
                        font-size: 14px;
                        color: #666666!important;
                        text-align: left;
                        margin-bottom: 10px;
                    }
                    ul {
                        margin: 0;
                        padding: 0 0 0 16px;
                        max-height: 200px;
                        overflow-y: auto;
                        li {
                            text-align: left;
                            font-size: 15px;
                            color: #666666!important;
                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
	                        &>div {
		                        display: flex;
		                        justify-content: space-between;
		                        align-items: flex-start;
		                        flex-wrap: nowrap;
		                        span {
			                        &:first-child {
				                        margin-right: 10px;
			                        }
			                        &:last-child {
				                        cursor: pointer;
			                        }
		                        }
	                        }
                        }
                    }
	                .question-controls {
		                position: absolute;
		                bottom: -34px;
		                left: 0;
		                display: flex;
		                justify-content: center;
		                align-items: center;
		                flex-wrap: wrap;
		                width: 100%;
		                .show-rest-questions, .add-questions {
			                background: white;
			                box-shadow: 0 0 3px 0 grey;
			                padding: 5px 20px;
			                border-radius: 35px;
			                cursor: pointer;
			                font-size: 10px;
			                color: @theme_color!important;
			                text-align: center;
			                font-weight: 500;
			                // transform: translate(-50%, 50%);
			                &:first-child {
				                margin-right: 10px;
			                }
		                }
	                }
                 
                }

            }
            .submit-wrapper {
                // width: 33%;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
	            button {
		            &:not(:last-child) {
			            margin-right: 10px;
		            }
	            }
                .def-btn {
                    padding: 10px 30px;
                    height: 42px;
                    font-size: 14px;
                    &:disabled {
                        cursor: wait;
                    }
                }
                .clear-filter {
                    background: none;
                    border: none;
                    color: gray;
                    font-size: 14px;
                    cursor: pointer;
                    //margin-right: 10px;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .invite-friends-wrapper {
            text-align: right;
            margin-bottom: 15px;
            .invite-friends-btn {
                border: 1px solid #d3d3d3;
                border-radius: 35px;
                padding: 10px 20px 10px 40px;
                color: #71809C;
                background-color: transparent;
                position: relative;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                &:focus {
                    outline: none;
                }
                &:before {
                    content: '';
                    background-image: url("../../assets/img/mail.svg");
                    background-size: 16px 16px;
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 14px;
                    top: 50%;
                    margin-top: -8px;
                }
            }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        .wrapper {
            .invite-friends-wrapper {
                .invite-friends-btn {
                    padding: 5px 15px 5px 35px;
                }
            }
            .search-filter-wrapper {
                padding: 15px;
                .sex-select-wrapper {
                    width: 100%;
                    margin: 0 0 25px 0;
                    flex-wrap: wrap;
	                justify-content: center;
	                .sex-item-wrapper {
		                .label-as-input {
			                padding: 6px;
		                }
	                }
                }
                .age-wrapper {
                    width: 100%;
                }
                .checkbox-selectors-wrapper {
                    &>div {
                        width: 100%;
                    }
                }
	            .matching-options {
		            .selected-questions {
			            .question-controls {
				            .show-rest-questions, .add-questions {
					            padding: 5px 10px;
					            &:first-child {
						            margin-right: 3px;
					            }
				            }
			            }
		            }
	            }
                .submit-wrapper {
	                flex-wrap: wrap;
	                .def-btn {
		                width: 100%;
		                margin: 0 0 10px 0!important;
	                }
                    .clear-filter {
                        padding: 0;
	                    width: 100%;
	                    margin-bottom: 20px;
                    }
                }
            }
        }
    }
    
    
    
</style>

