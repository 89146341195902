<template>
    <div id="autocomplete-directive">
        <div class="input-wrapper">
            <ValidationProvider :rules="validation" v-slot="{classes, errors}">
                <input type="text" ref="autocomplete" :class="[classname, classes]" :id="id" :placeholder="placeholder" v-bind:class="classes" v-model="autocompleteText" @focus="onFocus()" @blur="onBlur()" @change="onChange" @keypress="onKeyPress" @keyup="onKeyUp">
            </ValidationProvider>
            <span class="search-icon"></span>
        </div>
        <p class="autogeoresult-block" v-if="!customAutocompleteText && autocompleteAutoGeolocationText && !autocompleteText">Мы правильно определили, что вы в <span>{{autocompleteAutoGeolocationText.locality + ', ' + autocompleteAutoGeolocationText.country}}</span>? <span class="confirm-block" v-if="!autocompleteText && autocompleteAutoGeolocationText" @click="setAutoGeoAsDefault">Да, верно</span></p>

        <p class="autogeoresult-block" v-if="customAutocompleteText && autocompleteAutoGeolocationText && !autocompleteText">Искать людей по Вашему местонахождению <span>{{autocompleteAutoGeolocationText.locality + ', ' + autocompleteAutoGeolocationText.country}}</span>? <span class="confirm-block" v-if="!autocompleteText && autocompleteAutoGeolocationText" @click="setAutoGeoAsDefault">Да, добавить в параметры поиска</span></p>


    </div>

</template>

<script>
    const ADDRESS_COMPONENTS = {
        subpremise : 'short_name',
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'long_name',
        country: 'long_name',
        postal_code: 'short_name'
    };
    const CITIES_TYPE = ['locality', 'administrative_area_level_3'];
    const REGIONS_TYPE = ['locality', 'sublocality', 'postal_code', 'country',
        'administrative_area_level_1', 'administrative_area_level_2'];

    export default {
        name: 'VueGoogleAutocomplete',
        props: {
            id: {
                type: String,
                required: true
            },
            classname: String,
            placeholder: {
                type: String,
                default: 'Start typing'
            },
            types: {
                type: String,
                default: null
            },
            country: {
                type: [String, Array],
                default: null
            },
            enableGeolocation: {
                type: Boolean,
                default: false
            },
            geolocationOptions: {
                type: Object,
                default: null
            },
            validation: {
                type: String
            },
            savedData: {
                type: Object,
                default: null
            },
            customAutocompleteText: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                autocomplete: null,
	            service: null,
	            geocoder: null,
                autocompleteText: '',
                autocompleteAutoGeolocationText: '',
                geolocation: {
                    geocoder: null,
                    loc: null,
                    position: null
                }
            }
        },
        watch: {
            autocompleteText: function (newVal, oldVal) {
	            if (!oldVal) {
					this.autocompleteResult = null;
	            }
                this.$emit('inputChange', { newVal, oldVal }, this.id);
            },
            country: function(newVal, oldVal) {
                this.autocomplete.setComponentRestrictions({
                    country: this.country === null ? [] : this.country
                });
            }
        },
        mounted() {
            const options = {};
            if (this.types) {
                options.types = [this.types];
            }
            if (this.country) {
                options.componentRestrictions = {
                    country: this.country
                };
            }
            this.autocomplete = new google.maps.places.Autocomplete(
                document.getElementById(this.id),
                options
            );
	        this.service = new google.maps.places.AutocompleteService();
			this.geocoder = new google.maps.Geocoder();
            this.autocomplete.addListener('place_changed', this.onPlaceChanged);
	        google.maps.event.addDomListener(this.$refs['autocomplete'], 'blur', function() {
		        var pacContainer = document.getElementsByClassName('pac-container');
		        if (pacContainer[0].matches(':hover') === false) {
			        google.maps.event.trigger(this, 'focus', {});
			        google.maps.event.trigger(this, 'keydown', {
				        keyCode: 13
			        });
		        }
	        });
            this.getUserLocation();
            if (this.savedData) {
                this.autocompleteText = this.savedData.formatted_address;
                if (this.savedData.formatted_address === undefined) {
                    this.getUserLocation(this.savedData.place_id)
                }
            }
        },
        methods: {
	        enterKeyPress() {
		        // google.maps.event.trigger(this.autocomplete, 'place_changed');
	        },
            getUserLocation(place_id) {
                if (place_id) {
                    if (!this.geolocation.geocoder) {
                        this.geolocation.geocoder = new google.maps.Geocoder();
                    }
                    this.geolocation.geocoder.geocode({ 'placeId': place_id }, (results, status) => {
                        if (status === "OK") {
                            if (results[0]) {
                                this.autocompleteText = results[0].formatted_address;
                            } else {}
                        } else {}
                    });
                } else {
                    if (navigator.geolocation) {
                        let options = {};
                        navigator.geolocation.getCurrentPosition(position => {
                            this.geolocation.loc = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            };
                            this.geolocation.position = position;

                            if (!this.geolocation && !(this.geolocation.loc.lat || this.geolocation.loc.lng)) return;
                            if (!this.geolocation.geocoder) this.geolocation.geocoder = new google.maps.Geocoder();
                            this.geolocation.geocoder.geocode({'location': this.geolocation.loc}, (results, status) => {
                                if (status === 'OK') {
                                    results = this.filterGeocodeResultTypes(results);
                                    if (results[0]) {
                                        this.updateAutoGeolocation(this.formatResult(results[0]));
                                    } else {
                                        this.$emit('error', 'no result for provided coordinates');
                                    }
                                } else {
                                    this.$emit('error', 'error getting address from coords');
                                }
                            });
                        }, err => {
                            this.$emit('error', 'Cannot get Coordinates from navigator', err);
                        }, options);

                    }
                }
            },
            setAutoGeoAsDefault() {
                this.$emit('placechanged', this.autocompleteAutoGeolocationText, this.autocompleteAutoGeolocationText, this.id);
                this.update(this.autocompleteAutoGeolocationText.formatted_address);
            },

            /**
             * When a place changed
             */
            onPlaceChanged() {
                let place = this.autocomplete.getPlace();
                if (place.place_id !== undefined) {
	                if (!place.geometry) {
		                // User entered the name of a Place that was not suggested and
		                // pressed the Enter key, or the Place Details request failed.
		                this.$emit('no-results-found', place, this.id);
		                return;
	                }
	                if (place.address_components !== undefined) {
		                // return returnData object and PlaceResult object
		                this.$emit('placechanged', this.formatResult(place), place, this.id, false);
		                // update autocompleteText then emit change event
		                this.autocompleteText = document.getElementById(this.id).value;
		                this.onChange()
	                }
                } else {
                     this.$emit('loading-results', true);
	                 let request = {
						input: this.autocompleteText,
						types: [this.types]
					}
					this.service.getPlacePredictions(request, (predictions) => {
						if (predictions !== null && predictions.length > 0) {
							this.formatServiceResults(predictions[0]);
						}
					})
                }
            },
            /**
             * When the input gets focus
             */
            onFocus() {
                this.biasAutocompleteLocation();
                this.$emit('focus');
                this.$refs.autocomplete.focus();
            },
            /**
             * When the input loses focus
             */
            onBlur() {
                this.$emit('blur');
                this.$refs.autocomplete.blur();
            },
            /**
             * When the input got changed
             */
            onChange() {
                this.$emit('change', this.autocompleteText);
            },
            /**
             * When a key gets pressed
             * @param  {Event} event A keypress event
             */
            onKeyPress(event) {
                this.$emit('keypress', event);
            },
            /**
             * When a keyup occurs
             * @param  {Event} event A keyup event
             */
            onKeyUp(event) {
                this.$emit('keyup', event);
            },
            /**
             * Clear the input
             */
            clear() {
                this.autocompleteText = '';
            },
            clearAutoGeolocation() {
                this.autocompleteAutoGeolocationText = '';
            },
            /**
             * Update the value of the input
             * @param  {String} value
             */
            update (value) {
                this.autocompleteText = value
            },
            updateAutoGeolocation (value) {
                this.autocompleteAutoGeolocationText = value;
            },
            /**
             * Update the coordinates of the input
             * @param  {Coordinates} value
             */
            updateCoordinates (value) {
                if (!value && !(value.lat || value.lng)) return;
                if (!this.geolocation.geocoder) this.geolocation.geocoder = new google.maps.Geocoder();
                this.geolocation.geocoder.geocode({'location': value}, (results, status) => {
                    if (status === 'OK') {
                        results = this.filterGeocodeResultTypes(results);

                        if (results[0]) {
                            this.$emit('placechanged', this.formatResult(results[0]), results[0], this.id);
                            this.update(results[0].formatted_address);
                        } else {
                            this.$emit('error', 'no result for provided coordinates');
                        }
                    } else {
                        this.$emit('error', 'error getting address from coords');
                    }
                })
            },
            /**
             * Update location based on navigator geolocation
             */
            geolocate () {
                this.updateGeolocation ((geolocation, position) => {
                    this.updateCoordinates(geolocation)
                })
            },
            /**
             * Update internal location from navigator geolocation
             * @param  {Function} (geolocation, position)
             */
            updateGeolocation (callback = null) {
                if (navigator.geolocation) {
                    let options = {};
                    if (this.geolocationOptions) Object.assign(options, this.geolocationOptions);
                    navigator.geolocation.getCurrentPosition(position => {
                        let geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        this.geolocation.loc = geolocation;
                        this.geolocation.position = position;
                        if (callback) callback(geolocation, position);
                    }, err => {
                        this.$emit('error', 'Cannot get Coordinates from navigator', err);
                    }, options);
                }
            },
            // Bias the autocomplete object to the user's geographical location,
            // as supplied by the browser's 'navigator.geolocation' object.
            biasAutocompleteLocation () {
                if (this.enableGeolocation) {
                    this.updateGeolocation((geolocation, position) => {
                        let circle = new google.maps.Circle({
                            center: geolocation,
                            radius: position.coords.accuracy
                        });
                        this.autocomplete.setBounds(circle.getBounds());
                    })
                }
            },
            /**
             * Format result from Geo google APIs
             * @param place
             * @returns {{formatted output}}
             */
            formatResult(place) {
                let returnData = {};
                for (let i = 0; i < place.address_components.length; i++) {
                    let addressType = place.address_components[i].types[0];
                    if (ADDRESS_COMPONENTS[addressType]) {
                        returnData[addressType] = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
                    }
                }
                returnData['id'] = place.id;
                returnData['place_id'] = place.place_id;
                returnData['formatted_address'] = place.formatted_address;
                returnData['latitude'] = place.geometry.location.lat();
                returnData['longitude'] = place.geometry.location.lng();
                return returnData
            },
	        formatServiceResults(place) {
		        this.geocoder.geocode({ 'placeId': place.place_id }, (results, status) => {
			        if (status === "OK") {
				        if (results[0]) {
					        this.$emit('placechanged', this.formatResult(results[0]), results[0], this.id, true);
					        this.$emit('loading-results', false);
					        this.autocompleteText = this.formatResult(results[0]).formatted_address;
					        this.onChange();
							this.$forceUpdate();
				        } else {}
			        } else {}
		        });
	        },
            /**
             * Extract configured types out of raw result as
             * Geocode API does not allow to do it
             * @param results
             * @returns {GeocoderResult}
             * @link https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
             */
            filterGeocodeResultTypes (results) {
                if (!results || !this.types) return results;
                let output = [];
                let types = [this.types];
                if (types.includes('(cities)')) types = types.concat(CITIES_TYPE);
                if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE);
                for (let r of results) {
                    for (let t of r.types) {
                        if (types.includes(t)) {
                            output.push(r);
                            break;
                        }
                    }
                }
                return output;
            }
        }
    }

</script>

<style lang="less" scoped>
    #autocomplete-directive {
        .input-wrapper {
            position: relative;
            input {
                padding-right: 36px!important;
            }
            .search-icon {
                background-image: url("../../assets/img/search.svg");
                background-repeat: no-repeat;
                position: absolute;
                right: 10px;
                margin-top: -12px;
                top: 50%;
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
        p {
            font-size: 13px;
            color: #5E6F8F!important;
            text-align: left;
            margin: 0;
            &.autogeoresult-block {
                span {
                    text-decoration: underline;
                    &.confirm-block {
                        cursor: pointer;
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

</style>
