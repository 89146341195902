import axios from 'axios';
import store from "../_store/store";

export default function() {
    axios.interceptors.request.use(function(config) {
        const token = JSON.parse(localStorage.getItem('user'));
        if(token && token.api_key) {
            config.headers.Authorization = token.api_key;
            config.headers.id = token.id;
        }
        return config;
    }, function(err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response.status === 401) {
            if (store.state.authentication.status.loggedIn) {
                store.dispatch('authentication/logout');
            }
        }
        return Promise.reject(error);
    });
}
