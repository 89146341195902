<template>
    <div
      @click="clickEvent()"
      class="image-as-bg"
      :style="{'background-image': 'url(' + (image || require('../../assets/img/no-avatar.jpg')) + ')', 'width': width + 'px', 'height': height + 'px', 'max-width': width + 'px', 'min-width': width + 'px'}">
    </div>
</template>

<script>
    export default {
        name: 'avatarWrapper',
        props: ['img', 'width', 'height', 'isImage'],
        data() {
            return {
                image: null,
                avatarPlaceholder: require('../../assets/img/no-avatar.jpg'),
                imagePlaceholder: require('../../assets/img/300x300_no_image_placeholder.svg')
            }
        },
        created() {
            if (this.img && !this.img.includes('base64')) {
                this.image = 'data:image/jpeg;base64,' + this.img;
            } else {
                this.image = this.img;
            }
        },
	    methods: {
		    clickEvent() {
				this.$emit('clickEvent')
		    }
	    },
        watch: {
            img: function (val) {
                if (this.img && !this.img.includes('base64')) {
                    this.image = 'data:image/jpeg;base64,' + this.img;
                } else {
                    this.image = this.img;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .image-as-bg {
        background-size: cover;
        display: block;
        border-radius: 100%;
        background-repeat: no-repeat;
	    cursor: pointer;
        // box-shadow: 1px 1px 3px 0 #9a9a9a;
    }
</style>
