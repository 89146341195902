import axios from 'axios';
import store from "../_store/store";

export const authService = {
    login,
    logout,
    loginFb,
    loginVk,
    getRegData,
    registerUser,
    setPassword,
    resendEmail,
    updateUser,
    resetPasswordSendEmail
};


// LOGIN RELATED METHODS
function login(username, password) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/user/login/email`,
        {},
        {
            params: {
                email: username,
                password: password
            }
        }).then(res => {
        if (res && res.data.api_key) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            store.dispatch('authentication/login', res.data);
            store.dispatch('alert/success', res.data, { root: true });
        }
        return res;
    }).catch((error) => {
        // console.log(error.response);
        // this.$store.dispatch('alert/error', err, { root: true });
        // store.dispatch('alert/error', err.response.data, { root: true });
        return Promise.reject(error);
    })
}
function logout() {
    store.dispatch('authentication/logout');
}
function loginFb(invitedBy) {
    FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
            apiAuthenticate(authResponse.accessToken, invitedBy)
        } else {
            FB.login(function (res) {
                if (res.authResponse) {
                    apiAuthenticate(res.authResponse.accessToken, invitedBy)
                }
            }, {scope: 'email'});
        }
    });
}
function loginVk(vk_params) {
    let token = {
        token: vk_params.access_token,
        invited_by: vk_params.invitedBy,
        email: vk_params.email
    };
    return axios.post(`${process.env.VUE_APP_BASE_URL}v1/user/login/vk`, token)
        .then((res) => {
            if (vk_params.email) {
                res.data.email = vk_params.email;
            }
            store.dispatch('authentication/setFbData', res.data);
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
}
function apiAuthenticate(accessToken, invitedBy) {
    // authenticate with the api using a facebook access token
    let token = {
        token: accessToken,
        invited_by: invitedBy
    };
    return axios.post(`${process.env.VUE_APP_BASE_URL}v1/user/login/fb`, token)
        .then((res) => {
            store.dispatch('authentication/setFbData', res.data);
            return res.data;
        }).catch((err) => {
            console.log(err);
        });
}

// REGISTRATION RELATED METHODS
function getRegData() {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/registration/data`,{})
        .then((res) => {
            return res.data;
        }).catch(err => {
            return Promise.reject(err);
        })

}
function registerUser(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/user`, data).then((res) => {
        return res;
    }).catch(err => {
        if (err.response.data) {
            if (err.response.data.email && err.response.data.email.length) {
                store.dispatch('alert/error', "Пользователь с указанным e-mail уже зарегистрирован в системе Up-and-Up", { root: true });
            } else {
                store.dispatch('alert/error', err.response.data, { root: true });
            }
        } else {
            store.dispatch('alert/error', err.response.data, { root: true });
        }
        // return err;
    })

}
function updateUser(data, api_key, is_reg) {
    let config;
    if (is_reg) {
        config = {
            headers: {
                Authorization: api_key,
                id: data.id
            }
        }
    } else {
        config = {}
    }
    return axios.put(
        `${process.env.VUE_APP_BASE_URL}v1/user`, data, config).then((res) => {
        return res;
    }).catch(err => {
        console.log(err);
    })
}
function setPassword(data) {
    return axios.post(`${process.env.VUE_APP_BASE_URL}v1/user/set/password`,
        {
            password : data.password
        },
        {
            headers: {
                'Authorization': data.key,
                'id': data.id
            }
        }).then((res) => {
            return res;
        }).catch(err => {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data) {
                        store.dispatch('alert/error', err.response.data, { root: true });
                    }
                } else {
                    store.dispatch('alert/error', err.response.statusText, { root: true });
                }
            } else {
                store.dispatch('alert/error', err, { root: true });
            }
        })
}
function resendEmail(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/user/resend/email`, data).then((res) => {
        return res;
    }).catch(err => {
        if (err.response) {
            if (err.response.data) {
                if (err.response.data.email && err.response.data.email.length) {
                    store.dispatch('alert/error', 'Пользователь с указанным e-mail уже зарегистрирован в системе Up-and-Up', { root: true });
                } else {
                    store.dispatch('alert/error', err.response.data, { root: true });
                }
            } else {
                store.dispatch('alert/error', err.response.statusText, { root: true });
            }
        } else {
            store.dispatch('alert/error', err.response.statusText, { root: true });
        }
    })

}
function resetPasswordSendEmail(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/user/reset/password`, data).then((res) => {
        return res;
    }).catch(err => {
        return Promise.reject(err);
    })

}
