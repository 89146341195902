<template>
    <div id="question" :class="{'has-margin': hasMargin, 'blurred-option': hideContent && hasSexTopic}">
	    <div v-if="hideContent && hasSexTopic" class="blurred-foreground" @click="startVerification()">
		    <i class="far fa-eye-slash"></i>
		    <span>При нажатии на этот вопрос появляется соответствующая проверка и только после неё разворачивается весь ответ.</span>
	    </div>
        <div class="post-item-wrapper">
            <div class="post-item" :class="{ visible: item.show_item }">
                <div class="post-header" v-show="item.show_item">
                    <div class="post-header-title">
	                    <avatar v-if="showTitleAvatar && item.author" class="avatar-wrapper" :width="35" :height="35" :img="item.author.avatar_parsed" @clickEvent="goToProfile(item)"></avatar>
                        <span>{{item.is_day ? 'Вопрос дня' : blockTitle}}</span>
                    </div>
                    <div class="post-header-time" v-if="topicGlobalId === 9 || item.is_question_of_the_day || hasDelete || hasEdit">
                        <!--21 из 200-->
                        <div class="date" :id="`${item.id}_date`" v-if="showDate && item.dates_of_questions_the_day">
	                        {{item.dates_if_the_day_preview}}
	                        <b-tooltip v-if="item.dates_of_questions_the_day.length > 3" :target="`${item.id}_date`" custom-class="chat-interests-tooltip">{{item.dates_if_the_day_full}}</b-tooltip>
                        </div>
                        <div class="header-controls" >
                            <span class="control-icons" v-if="hasEdit && !isModal" @click="editQuestion(item)">
                                <i class="far fa-edit" ></i>
                            </span>
                            <span class="control-icons" v-if="hasDelete && !isModal" @click="deleteQuestion(item)">
                                <i class="far fa-trash-alt" ></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="post-body-wrapper">
                    <div class="post-body" :style="{'background-color': item.color}" v-on="(item.show_item) ? {click: () => {showItemBlock(item); showModal('main', item)}} : {click: () => showItemBlock(item)}">
                        <div class="post-body-tags">
                            <div class="tag" v-for="tag of item.topics">
                                {{tag.name}}
                            </div>
                        </div>
                        <div class="post-body-main-title">
                            {{item.name}}
                        </div>
                    </div>
                    <div class="post-body-questions" v-show="item.show_item">
                        <div class="radio-buttons-wrapper" v-if="item.is_radio === 1">
                            <div class="u-radiobutton-wr" :class="{'radiobutton-with-avatar': showAvatars, 'read-only': readOnly}" v-for="(i, index) of item.answers" :key="i.id">
                                <avatar class="avatar-wrapper my-checked" v-if="i.checked && showAvatars" :width="30" :height="30" :img="user.avatar_parsed"></avatar>
                                <avatar v-if="(item.changed === 1 && i.another_checked) ||(item.changed === 1 && i.previous_checked) || (item.changed === 0 && i.another_checked) || (item.is_personal === 1 && i.another_checked)" class="avatar-wrapper" :class="{'single-avatar': !i['checked'], 'previous-answer': item.changed === 1 && i.previous_checked && !i.another_checked}" :width="30" :height="30" :img="item.avatar_parsed"></avatar>
                                <input :id="i.name + i.id + rm" :disabled="item.has_answer_mode" type="radio" :value="i" v-model="item.selected" :name="item.name + item.id + rm" v-if="!readOnly" @click="(item.has_answer_mode) ? null : setCheckedManually(i, index)"/>
                                <label class="label-as-input" :class="{'previous_answer': item.changed === 1 && i.previous_checked, 'current_answer': (item.changed === 1 && i.another_checked) || (item.changed === 0 && i.another_checked) || (item.is_personal === 1 && i.another_checked)}" :for="i.name + i.id + rm">
                                    <span v-if="!readOnly" class="checkmark"></span>
                                    {{i.name}}
                                </label>
                            </div>
                        </div>
                        <!--<div class="question-multiselect-wrapper" v-if="item.is_radio === 0">
                            <multiselect :id="item.id.toString()" :placeholder="item.placeholder" :list="item.answers" :listSelected="item.selected"></multiselect>
                        </div>-->
                        <!--<div class="question" v-for="question of item.post_questions_arr">
                            <input type="checkbox" class='custom-checkbox' v-bind:id="question.name + item.id" v-bind:value="question.name" v-model="question.is_selected">
                            <label v-bind:for="question.name + item.id">{{question.name}}</label>
                        </div>-->
                    </div>
                </div>
                <div class="post-pre-footer" v-if="!readOnly" v-show="item.show_item">
                    <div class="pre-post-actions">
                        <button :disabled="loading" v-if="item.has_answer_mode && item.selected" class="submit-answer" :class="{'loading': loading}" @click="changeAnswer(item)">Изменить ответ</button>
                        <button :disabled="loading" v-if="(!item.has_answer && !item.has_answer_mode && item.selected) || (item.has_answer && !item.has_answer_mode && item.selected && !isFeed)" class="submit-answer" :class="{'loading': loading}" @click="submitAnswer(item)">Подтвердить ответ</button>

                        <button :disabled="loading" v-if="item.has_answer && item.selected === null && !item.has_answer_mode" class="submit-answer save-answer" :class="{'loading': loading}" @click="setQuestionUnanswered(item)">Сохранить</button>
                        <button :disabled="loading" v-if="item.has_answer && item.selected === null && !item.has_answer_mode" class="submit-answer cancel-answer" :class="{'loading': loading}" @click="resetAnswer(item)">Отменить</button>
                        <button :disabled="loading" v-if="isFeed && ((item.has_answer && item.selected && !item.accepted) || (item.has_answer && item.selected && item.accepted && !item.has_answer_mode))" class="submit-answer accept-answer" :class="{'loading': loading}" @click="acceptAnswer(item)">Подтвердить ответ</button>
                    </div>
                    <div class="pre-post-visibility" v-if="item.has_answer_mode || item.selected">
                        <div class="visibility-wrapper">
                            <span class="text">Ответ виден:</span>
                            <visibility-item-select :disabled="item.has_answer_mode" :is-disabled="item.has_answer_mode" :visibilityType="item.visibility" @updateType="refreshVisibility($event)" :dd-position="(topicGlobalId === 8) ? 'top' : null" class="def-icon" :custom-visibility-list="item.redirected ? customVisibilityListFull : customVisibilityList"></visibility-item-select>
                        </div>
                        <div class="is-important u-checkbox-wr right-side" v-if="topicGlobalId !== 8">
                            <label :for="item.name + item.id" class="checkbox-container" :class="{'disabled': item.has_answer_mode}">
                                <input type="checkbox" class='custom-checkbox' :disabled="item.has_answer_mode" :id="item.name + item.id" v-model="item.is_important">
                                <span class="checkmark"></span>
                                Мне это важно:
                            </label>
                        </div>
                    </div>
                </div>
                <div class="post-footer" v-show="item.show_item">
                    <div class="post-counters">
                        <div class="post-footer-likes" @click="showModal('main', item)">
                            <span class="icon"></span>
                            <span class="all-count">{{item.answer_count}}</span>
                            <span class="inner-circle-count">{{item.friend_answers_count}}</span>
                        </div>
                        <div class="post-footer-comments" @click="showModal('comments', item)">
                            <span class="icon"></span>
                            <span class="all-count">{{item.comments_count}}</span>
                            <span class="inner-circle-count">{{item.friend_comments_count}}</span>
                        </div>
                    </div>
                    <div class="post-actions">
                        <button class="ask-friends" @click="showAskQuestionModal(item)">Cпросить других</button>
                    </div>
                </div>
            </div>
      </div>
        <question-details-modal v-if="loadQuestionModal" ref="question-details-modal" :topic-global-id="topicGlobalId" :modal-title="blockTitle" :is-question-feed="showAvatars" :has-confirmation-modal="hasConfirmationModal" :showDate="showDate"></question-details-modal>
        <ask-question-modal v-if="loadAskQuestionModal" ref="askQuestionRef"></ask-question-modal>
	    <custom-modal v-if="loadCustomModal" ref="customModal"></custom-modal>
	    <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
    </div>
</template>

<script>
    import {questionsService} from "@/_api";
    import visibilityItemSelect from "../_shared/VisibilityItemSelect";
    import multiselect from "../_shared/Multiselect";
    import questionDetailsModal from "../_shared/modals/_PostDetailedModal";
    import _askQuestionModal from "../_shared/modals/_AskQuestionModal";
    import avatar from "../_shared/Avatar";
    import EventBus from "../../_helpers/event-bus";
    import {handleAWSImage} from "@/_helpers/aws-handler";
    import CustomModal from "@/components/_shared/modals/_CustomModal";
    import moment from "moment";
    import ConfirmationModal from "@/components/_shared/modals/_ConfirmationDialogue";

    export default {
        name: 'QuestionPost',
        props: {
            item: Object, // main question object
            list: Array, // array of question objects (to enable slider)
            blockTitle: String, // UI title
            hasDelete: Boolean, // used for my PQ questions
            hasEdit: Boolean, // used for my PQ questions
            topicGlobalId: Number, // either 7/8/9 (main/personal/qotd)
            hasMargin: Number, // distance between elements vertically
            deleteAfterSubmit: Boolean, // delete item from list after submitting/accepting answer
            isFeed: Boolean, // if component is rendered in home component
            readOnly: Boolean, // no way to answer the questiom,
            showAvatars: Boolean, // show avatars near answers,
            showTitleAvatar: Boolean, // show avatars in the title,
            isModal: Boolean, // is used in user profiles personal list modal
	        hasConfirmationModal: Boolean,
	        showDate: Boolean,
	        blurredOption: Boolean
        },
        data () {
            return {
                rm: Math.random(),
                loading: false,
	            customVisibilityListFull: [
		            {
			            description: 'Все',
			            class: 'eye',
			            type: 1
		            },
		            {
			            description: 'Друзья Up&Up',
			            class: 'circle',
			            type: 2
		            },
		            {
			            description: 'По запросу',
			            class: 'secret',
			            type: 0
		            }
	            ],
	            customVisibilityList: [
		            {
			            description: 'Все',
			            class: 'eye',
			            type: 1
		            },
		            {
			            description: 'Друзья Up&Up',
			            class: 'circle',
			            type: 2
		            }
	            ],
	            hideContent: this.blurredOption,
	            loadQuestionModal: false,
                loadAskQuestionModal: false,
                loadCustomModal: false,
            }
        },
        components: {
	        ConfirmationModal,
	        CustomModal,
            questionDetailsModal,
            visibilityItemSelect,
            multiselect,
            avatar,
            'ask-question-modal': _askQuestionModal
        },
        created() {},
        mounted(){
          console.log(this.blockTitle)
            if (this.item.author) {
                this.item.author['avatar_parsed'] = null;
                handleAWSImage(this.item.author.avatar_36).then(res => {
                    this.item.author['avatar_parsed'] = res;
                    this.$forceUpdate();
                });
                this.$forceUpdate();
            }
			/*if (this.item.dates_of_questions_the_day.length) {
				this.item['dates_if_the_day_preview'] = this.item.dates_of_questions_the_day.sort((a,b) => new Date(moment(b.date)).getTime() - new Date(moment(a.date)).getTime()).map((obj) => moment(obj.date, 'yyyy-mm-DD').format('DD.mm.yyyy')).filter((y, index) => index < 2).join(', ');
				this.item['dates_if_the_day_full'] = this.item.dates_of_questions_the_day.sort((a,b) => new Date(moment(b.date)).getTime() - new Date(moment(a.date)).getTime()).map((obj) => moment(obj.date, 'yyyy-mm-DD').format('DD.mm.yyyy')).filter((y, index) => index > 2).join(', ');
			}*/

            this.$forceUpdate();
            this.refreshVisibility(this.item.visibility);
			// console.log(this.item);
        },
        computed: {
            user () {
                return this.$store.state.authentication.user;
            },
	        hasSexTopic() {
		        return this.item.topics.filter((i) => {return i.name === 'Секс'}).length
	        }
        },
        methods: {
	        startVerification() {
		        this.$refs.confirmDialogue.show({
			        title: 'Delete Page',
			        message: 'Внимание! Вопросы данной категории предназначены исключительно лицам достигшим совершеннолетия. Вам Уже исполнилось 18 лет?',
			        okButton: 'Да',
		        }).then((result) => {
			        console.log(result);
			        if (result) {
				        this.hideContent = false;
			        }
		        });
	        },

			goToProfile(data) {
				console.log(this.item);
				let currentUser = JSON.parse(localStorage.getItem('user'));
				if (data.author.id === currentUser.id) {
					this.$router.push({path: '/me'});
				} else if (this.$route.name === 'user-profile') {
					this.$refs['question-details-modal'].hide();
					this.$router.push({path: `/profile/${data.author.id}`});
				} else {
					this.$router.push({path: `/profile/${data.author.id}`});
				}
			},

            showAskQuestionModal(item) {
                if (!item.has_answer) {
                    this.$store.dispatch('alert/warning', 'Сначала необходимо предоставить ответ', { root: true });
                } else {
                    this.loadAskQuestionModal = true;
                    this.$nextTick(() => {
                        this.$refs.askQuestionRef.showModal(item);
                    })
                }

            },
            showModal(scrollPosition, data) {
                this.loadQuestionModal = true;
                this.$nextTick(() => {
                    this.$refs['question-details-modal'].showModal(scrollPosition, this.item, this.list);
                })
            },
            refreshVisibility(data) {
                this.item.visibility = data;
            },

            submitAnswer(item) {
	            let method = null;
	            let objToSend = null;
				let test = () => {
					if (!item.has_answer) {
						if (this.topicGlobalId === 8) {
							method = questionsService.sendAnswerPQ(objToSend);
						} else {
							method = questionsService.sendAnswer(objToSend);
						}
					} else {
						if (this.topicGlobalId === 8) {
							method = questionsService.changeAnswerPQ(objToSend);
						} else {
							method = questionsService.changeAnswer(objToSend);
						}
					}
					this.loading = true;
					method.then((res) => {
						if (res) {
							this.$store.dispatch('alert/success', 'Ответ принят', { root: true });
							this.$store.dispatch('question/getTopics', this.topicGlobalId);
							this.item.has_answer = true;
							this.item.has_answer_mode = true;
							this.item.accepted = true;

							this.showModal('main');

							if (this.deleteAfterSubmit) {
								let index = this.list.findIndex(o => {return o.id === this.item.id});
								if (index !== -1) {
									this.list.splice(index, 1);
									if (this.list.length < 4) {
										this.$emit('loadMore');
									}
								}
							}
							this.$forceUpdate();
						} else {
							this.$store.dispatch('alert/error', 'Something unexpected happened', { root: true });
						}
						this.loading = false;
					});
				}


                if (item.selected.id) {
	                objToSend = {
		                question_id: item.selected.question_id || item.selected.personal_question_id,
		                answer_ids: [item.selected.id],
		                is_important: item.is_important,
		                visibility: item.visibility,
		                from_user_id: item.visibility === 0 ? ((item.who_asked && item.who_asked.length) ? item.who_asked[0].id : '') : '',
		                accept: (this.isFeed) ? 1 : 0
	                };
	                console.log(item);
	                console.log(this.hasConfirmationModal);
	                if (item.author && item.visibility === 0 && this.hasConfirmationModal) {
		                console.log('IFFFFFFFFFFFFFFFFFFFFFF');
		                this.openCustomModal(item.author.name)
		                    .then(() => {
								console.log('resolve');
			                    test();
		                    }).catch(() => {
								console.log('reject')
			                })
	                } else {
		                console.log('ELSSSSSSSSSSSE');
						test();
	                }


                    /*this.loading = true;*/
                    /*let objToSend = {
                        question_id: item.selected.question_id || item.selected.personal_question_id,
                        answer_ids: [item.selected.id],
                        is_important: item.is_important,
                        visibility: item.visibility,
                        accept: (this.isFeed) ? 1 : 0
                    };*/

                    /*let method = null;
                    if (!item.has_answer) {
                        if (this.topicGlobalId === 8) {
                            method = questionsService.sendAnswerPQ(objToSend);
                        } else {
                            method = questionsService.sendAnswer(objToSend);
                        }
                    } else {
                        if (this.topicGlobalId === 8) {
                            method = questionsService.changeAnswerPQ(objToSend);
                        } else {
                            method = questionsService.changeAnswer(objToSend);
                        }
                    }*/
                    /*method.then((res) => {
                        if (res) {
                            this.$store.dispatch('alert/success', 'Ответ принят', { root: true });
                            this.$store.dispatch('question/getTopics', this.topicGlobalId);
                            this.item.has_answer = true;
                            this.item.has_answer_mode = true;
                            this.item.accepted = true;
                            if (this.deleteAfterSubmit) {
                                let index = this.list.findIndex(o => {return o.id === this.item.id});
                                if (index !== -1) {
                                    this.list.splice(index, 1);
                                    if (this.list.length < 4) {
                                        this.$emit('loadMore');
                                    }
                                }
                            }
                            this.$forceUpdate();
                        } else {
                            this.$store.dispatch('alert/error', 'Something unexpected happened', { root: true });
                        }
                        this.loading = false;
                    });*/
                }
            },
            acceptAnswer(item){
	            let method = null;
				let test = () => {
					this.loading = true;
					if (this.topicGlobalId === 8) {
						method = questionsService.changeAnswerPQ(objToSend);
					} else {
						method = questionsService.changeAnswer(objToSend);
					}
					method.then((res) => {
						if (res) {
							this.$store.dispatch('alert/success', 'Ответ принят', { root: true });
							this.$store.dispatch('question/getTopics', this.topicGlobalId);
							this.item.has_answer = true;
							this.item.has_answer_mode = true;
							this.item.accepted = true;

							if (this.deleteAfterSubmit) {
								let index = this.list.findIndex(o => {return o.id === this.item.id});
								if (index !== -1) {
									this.list.splice(index, 1);
								}
							}


							this.$forceUpdate();
						} else {
							this.$store.dispatch('alert/error', 'Something unexpected happened', { root: true });
						}

						this.loading = false;
					});
				}
                let objToSend = {
                    question_id: item.selected.question_id || item.selected.personal_question_id,
                    answer_ids: [item.selected.id],
                    is_important: item.is_important,
                    visibility: item.visibility,
	                from_user_id: item.visibility === 0 ? ((item.who_asked && item.who_asked.length) ? item.who_asked[0].id : '') : '',
                    accept: (this.isFeed) ? 1 : 0
                };

	            if (item.author && item.visibility === 0 && this.hasConfirmationModal) {
					console.log('IFFFFFFFFFFFFFFFFFFFFFF');
		            this.openCustomModal(item.author.name)
			            .then(() => {
				            console.log('resolve');
				            test();
			            }).catch(() => {
				            console.log('reject')
			            })
	            } else {
		            console.log('ELSSSSSSSSSSSE');
		            test();
	            }

            },
            resetAnswer() {
                this.item['selected'] = this.item.answers.filter(i => {
                    if (i.checked) {
                        return i;
                    }
                })[0];
                this.item.has_answer_mode = true;
                this.$forceUpdate();
            },
            setQuestionUnanswered(item) {
                questionsService.deleteAnswerAPI({
                    question_id: this.item.question_id,
                    is_personal: this.topicGlobalId === 8 ? 1 : 0,
                }).then((res) => {
                    this.item.has_answer_mode = false;
                    this.item.has_answer = false;
                    this.item.selected = null;
                    this.item.answers.forEach(i => {
                        i.checked = false;
                    });
                });;
            },
            setCheckedManually(i, index) {
                this.item.answers.forEach(obj => {
                    obj.my_checked = false;
                    obj.checked = false;
                });
                this.item.answers[index].checked = true;
                this.item.has_answer_mode = false;
                if (!this.item.selected) {
                    this.item.selected = this.item.answers[index];
                } else if (this.item.selected && this.item.selected.id !== i.id) {
                    this.item.selected = this.item.answers[index];
                } else {
                    this.item.selected = null;
                }
                this.$forceUpdate();
            },
            changeAnswer() {
                this.item.has_answer_mode = false;
                this.item.selected = null;
                this.$forceUpdate();
            },

            showItemBlock(item) {
				console.log(item);
                item['show_item'] = true;
                this.$forceUpdate();
            },
            deleteQuestion(data) {
                let q = confirm('Are you sure?');
                if (q) {
                    questionsService.deletePersonalQuestion({
                        question_id: data.question_id
                    }).then(res => {
                        if (res) {
                            /*let index = this.list.findIndex((obj) => {return obj.id === data.id});
                            this.list.splice(index, 1);*/
                            this.$emit('onItemEdit');
                        }
                    })
                }
            },
            editQuestion(data) {
                EventBus.$emit('editPQ', data);
            },

	        openCustomModal(author_name) {
                console.log(this.$refs);
                this.loadCustomModal = true;
                this.$nextTick(() => {
                    return new Promise((resolve, reject) => {
                        this.$refs['customModal'].show({
                            message: `Ответ получит только ${author_name}`,
                            okButton: 'OK',
                            cancelButton: 'Отмена'
                        }).then((result) => {
                            console.log(result);
                            if (result) {
                                resolve();
                            } else {
                                reject();
                            }
                        });
                    })
                })
            },
        },
        watch: {
            item: function(res) {},
            user: function (res) {}
        }
    }
</script>

<style lang="less" scoped>

    #question {
        max-width: 465px;
	    &.blurred-option {
		    .post-item-wrapper {
			    z-index: 1;
			    position: relative;
			    filter: blur(4px);
		    }
	    }
        &.has-margin {
            padding-bottom: 20px;
        }
	    .blurred-foreground {
		    position: absolute;
		    left: 0;
		    top: 0;
		    height: calc(100% - 20px);
		    width: 100%;
		    z-index: 766;
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    padding: 20%;
		    background-color: rgba(0,0,0,0.3);
		    color: #fff;
		    cursor: pointer;
	    }
        .post-item-wrapper {
            //padding-bottom: 18px;
            .post-item {
                box-shadow: 0 0 6px -4px black;
                border-radius: 1px;
                background: #FFFFFF;
                z-index: 0;
                &.visible {
                    z-index: 99;
                    // box-shadow: 0 0 11px -4px black;
                    position: relative;
                }
                .post-header {
                    padding: 10px 25px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    min-height: 60px;
                    max-height: 100%;
                    .post-header-title {
                        text-align: left;
                        font-size: 13px;
                        font-weight: 500;
                        color: #999999;
                        display: flex;
                        align-items: center;
                        .avatar-wrapper {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 13px;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .post-header-time {
                        font-size: 11px;
                        color: #999999;
                        font-weight: 500;
                        min-width: 60px;
                        div {
                            cursor: pointer;
                            &:first-child {
                                margin-right: 25px;
                            }
                            &.date {
                                margin: 0;
                                color: #999999;
                            }
                            &.header-controls {
                                margin-right: 0;
                                .control-icons {
                                    /*width: 20px;*/
                                    .fa-edit, .fa-trash-alt {
                                        font-size: 18px;
                                        color: #999999;
                                    }
                                    &:first-child {
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                .post-body-wrapper {
                    .post-body {
                        padding: 26px 26px 52px 26px;
                        position: relative;
                        overflow: hidden;
                        cursor: pointer;
                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: auto;
                            opacity: 0.6;
                        }
                        .post-body-tags {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-wrap: wrap;
                            margin-bottom: 36px;
                            z-index: 2;
                            .tag {
                                font-size: 9px;
                                text-align: center;
                                color: #000000cc;
                                font-weight: 500;
                                padding: 2px 22px;
                                background-color: transparent;
                                border: 1px solid rgba(0,0,0,0.1);
                                border-radius: 16px;
                                margin: 0 5px 5px;
                                text-transform: uppercase;
                            }
                        }
                        .post-body-main-title {
                            font-size: 18px;
                            color: #000000;
                            text-align: center;
                            font-weight: 500;
                            z-index: 2;
	                        word-break: break-word;
                        }
                    }
                    .post-body-questions {
                        padding: 20px 38px;
                        text-align: left;
                        .question {
                            &:not(:last-child) {
                                margin-bottom: 20px;
                            }
                            label {
                                margin: 0;
                                cursor: pointer;
                            }
                            .custom-checkbox {
                                cursor: pointer;
                                -webkit-appearance: none;
                                width: 15px;
                                height: 15px;
                                position: relative;
                                margin-right: 10px;
                                border: 2px solid rgba(113, 128, 156, 0.7);
                                box-shadow: none;
                                outline: none;
                                border-radius: 3px;
                                &:disabled {
                                    opacity: 0.7;
                                    cursor: not-allowed;
                                }
                                &:checked {
                                    &:after {
                                        content: url('../../assets/img/custom-checkbox.svg');
                                        position: absolute;
                                        top: -7px;
                                        left: 0;
                                        width: 10px;
                                        height: 8px;
                                    }
                                }
                            }
                        }
                        .radio-buttons-wrapper {
                            .u-radiobutton-wr {
                                margin-bottom: 10px;
                                &.read-only {
                                    label {
                                        padding-left: 10px;
                                    }
                                }
                                &.radiobutton-with-avatar {
                                    padding-left: 45px;
                                    position: relative;
                                }
                                .avatar-wrapper {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: 0;
                                    width: 30px;
                                    height: 30px;
                                    border: 3px solid #fff;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    overflow: hidden;
                                    border-radius: 50%;
                                    margin-top: -2px;
                                    &.my-checked {
                                        left: 15px;
                                    }
                                    &.previous-answer {
                                        opacity: 0.6;
                                    }
                                    &.single-avatar {
                                        left: 15px;
                                    }
                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                                label {
                                    // padding-left: 10px;
                                    color: #666666;
                                    &.previous_answer {
                                        opacity: 0.6;
                                    }
                                    /*&.current_answer {
                                        color: #4495CE;
                                    }*/
                                }
                            }
                        }
                    }
                }
                .post-pre-footer {
                    display: flex;
                    padding: 0 38px 0;
                    justify-content: space-between;
                    transition: all 500ms;
                    align-items: flex-start;
                    flex-wrap: nowrap;
                    .pre-post-actions {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        width: 100%;
                        max-width: 300px;
                        .submit-answer {
                            text-transform: uppercase;
                            border-radius: 35px;
                            border: 1px solid lightgrey;
                            background-color: transparent;
                            color: #333333;
                            text-align: center;
                            padding: 7px 30px;
                            font-size: 10px;
                            font-weight: 500;
                            outline: none;
                            position: relative;
                            &.save-answer {
                                margin-right: 5px;
                            }
                            &.accept-answer {
                                &:not(:first-child) {
                                    margin-top: 7px;
                                }
                            }
                            &.loading {
                                &:after {
                                    content: '';
                                    background-image: url(../../assets/img/Infinity-1s-200px.svg);
                                    background-size: 30px 20px;
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    width: 30px;
                                    height: 20px;
                                    margin-top: -10px;
                                }
                                // background-image: url(../../assets/img/Infinity-1s-200px.svg);
                            }
                        }
                    }
                    .pre-post-visibility {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        flex-wrap: wrap;
                        width: 100%;
                        max-width: 260px;
                        .visibility-wrapper {
                            display: flex;
                            align-items: center;
                            margin-bottom: 7px;
                            .text {
                                font-size: 12px;
                            }
                        }
                        .is-important {
                            &.u-checkbox-wr {
                                padding-right: 33px;
                                .checkbox-container {
                                    font-size: 12px;
                                    &.disabled {
                                        cursor: not-allowed;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
                .post-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 20px 38px;
                    .post-counters {
                        display: flex;
                        cursor: pointer;
                        .post-footer-likes {
                            display: flex;
                            margin-right: 15px;
                            font-size: 12px;
                            font-weight: 500;
                            align-items: center;
                            span {
                                &.icon {
                                    width: 24px;
                                    height: 24px;
                                    background-image: url("../../assets/img/Radio button gray.svg");
                                    display: flex;
                                    margin-right: 4px;
                                }
                                &.all-count {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #666666;
                                    margin-right: 8px;
                                    position: relative;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        right: -4px;
                                        top: 0;
                                        height: 100%;
                                        width: 1px;
                                        background-color: #E0E3EA;
                                    }
                                }
                                &.inner-circle-count {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #9B9B9B;
                                }
                            }
                        }
                        .post-footer-comments {
                            display: flex;
                            font-size: 12px;
                            font-weight: 500;
                            align-items: center;
                            span {
                                &.icon {
                                    display: flex;
                                    width: 24px;
                                    height: 24px;
                                    background-image: url("../../assets/img/Comment Gray.svg");
                                    margin-right: 4px;
                                }
                                &.all-count {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #666666;
                                    margin-right: 8px;
                                    position: relative;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        right: -4px;
                                        top: 0;
                                        height: 100%;
                                        width: 1px;
                                        background-color: #E0E3EA;
                                    }
                                }
                                &.inner-circle-count {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #9B9B9B;
                                }
                            }
                        }
                    }
                    .post-actions {
                        button {
                            text-transform: uppercase;
                            border-radius: 35px;
                            border: 1px solid lightgrey;
                            background-color: transparent;
                            color: #333333;
                            text-align: center;
                            padding: 7px 30px;
                            font-size: 10px;
                            font-weight: 500;
                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
	    .c-modal-backdrop-wr {
		    height: calc(100% - 20px)!important;
		    padding: 0 5%;
	    }
    }




    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {

    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #question {
            // margin: 0 auto 20px auto;
            margin-left: auto;
            margin-right: auto;
            .post-item-wrapper {
                .post-item {
                    .post-pre-footer {
                        padding: 0 20px 0;
                    }
                    .post-footer {
                        padding: 20px 20px;
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #question {
            .post-item-wrapper {
                .post-item {
                    .post-header {
                        padding: 12px 15px;
                    }
                    .post-body-wrapper {
                        .post-body {
                            padding: 16px 16px 25px 16px;
                            .post-body-tags {
                                margin-bottom: 15px;
                            }
                        }
                        .post-body-questions {
                            padding: 15px 20px;
                            .radio-buttons-wrapper {
                                .u-radiobutton-wr {
                                    margin-bottom: 5px;
                                    &.radiobutton-with-avatar {
                                        padding-left: 30px;
                                    }
                                    .avatar-wrapper {
                                        left: -15px;
                                        &.my-checked {
                                            left: 0;
                                        }
                                        &.single-avatar {
                                            left: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .post-pre-footer {
                        padding: 0 10px 0;
                        flex-wrap: wrap;
                        .pre-post-actions, .pre-post-visibility {
                            max-width: unset;
                        }
                        .pre-post-actions {
                            justify-content: center;
                            margin-bottom: 10px;
                            button {
                                margin: 0!important;
                                padding: 7px 15px!important;
                                &:not(:last-child) {
                                    margin-right: 5px!important;
                                }
                            }
                        }
                        .pre-post-visibility {
                            align-items: center;
                            justify-content: center;
                            .is-important, .visibility-wrapper {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .post-footer {
                        padding: 10px 15px;
                        flex-wrap: nowrap;
                        .post-counters {
                            .post-footer-likes {
                                margin-right: 8px;
                            }
                        }
                        .post-actions {
                            button {
                                padding: 7px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

