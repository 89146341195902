<template>
    <transition name="c-modal-fade">
        <div class="c-modal-backdrop-wr" v-if="isVisible">
            <div class="c-modal">
                <div class="window">
                    <!--<h2 style="margin-top: 0">{{ title }}</h2>-->
                    <p>{{ message }}</p>
                    <div class="btns">
                        <button class="c-cancel-btn" @click="_cancel">{{ cancelButton }}</button>
                        <button class="с-ok-btn" @click="_confirm">{{ okButton }}</button>
                    </div>
                </div>
            </div>
            <div class="c-modal-backdrop"></div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'ConfirmationModal',
        data () {
            return {
                isVisible: false,
                title: undefined,
                message: undefined, // Main text content
                okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
                cancelButton: 'Нет', // text for cancel button

                // Private variables
                resolvePromise: undefined,
                rejectPromise: undefined,
            }
        },
        watch: {
            isVisible: function(res) {
                if (res) {
                    document.body.classList.add("modal-open");
                } else {
                    setTimeout(() => {
	                    document.body.classList.remove("modal-open");
                    }, 300)
                }
            },
        },

        methods: {
            open() {
                this.isVisible = true
            },
            close() {
                this.isVisible = false
            },
            show(opts = {}) {
                this.title = opts.title;
                this.message = opts.message;
                this.okButton = opts.okButton;
                if (opts.cancelButton) {
                    this.cancelButton = opts.cancelButton
                }
                // Once we set our config, we tell the popup modal to open
                this.open();
                // Return promise so the caller can get results
                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                })
            },

            _confirm() {
                this.close();
                this.resolvePromise(true);
            },

            _cancel() {
                this.close();
                this.resolvePromise(false);
                // Or you can throw an error
                // this.rejectPromise(new Error('User cancelled the dialogue'))
            },
        },
    }
</script>

<style lang="less" scoped>
    @import "../../../assets/styles/_vars.less";

    /* css class for the transition */
    .c-modal-fade-enter-active,
    .c-modal-fade-leave-active {
        transition: opacity 0.3s;
    }
    .c-modal-fade-enter,
    .c-modal-fade-leave-to {
        opacity: 0;
    }

    .c-modal-backdrop-wr {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        .c-modal {
            overflow-x: auto;
            display: flex;
            flex-direction: column;
            z-index: 1041;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            .window {
                background: #fff;
                border-radius: 5px;
                box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
                max-width: 480px;
                margin-left: auto;
                margin-right: auto;
                padding: 1rem;
                .btns {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    justify-content: flex-end;
                    .с-ok-btn {
                        border: 1px solid @theme_color;
                        box-shadow: 0 0 2px 0 @theme_color;
                        background: transparent;
                        border-radius: 5px;
                        padding: 2px 10px;
                        cursor: pointer;
                        color: @theme_color;
                        transition: all 250ms;
                        width: 50px;
                        text-align: center;
                        &:hover {
                            color: #fff;
                            background-color: @theme_color;
                        }
                    }
                    .c-cancel-btn {
                        border: 1px solid white;
                        box-shadow: 0 0 2px 0 red;
                        margin-right: 20px;
                        background: transparent;
                        border-radius: 5px;
                        padding: 2px 10px;
                        cursor: pointer;
                        color: red;
                        transition: all 250ms;
                        width: 50px;
                        text-align: center;
                        &:hover {
                            border: 1px solid red;
                        }
                    }
                }
            }
        }
        .c-modal-backdrop {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1040;
            width: 100vw;
            height: 100vh;
            background-color: #000;
            opacity: 0;
        }
    }
</style>
