<template>
    <div id="friends">
        <search-filter :show-error="true" :placeholderText="'Введите имя'" :items="friends.list" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" :search-disabled="this.friends.loading"></search-filter>
        <div class="top-bar">
            <div class="friends-count">{{friends.count}} друзей Up&Up</div>
            <user-dropdown-filter :defaultSelected="friends.orderBy" @onChange="onOrderByChange"></user-dropdown-filter>
        </div>
        <div class="users-list-wrapper__row" v-if="friends.list.length">
            <DynamicScroller :items="friends.list" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="500">
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem :item="item" :active="active" :data-index="item.id" :size-dependencies="[item]">
                        <User :user="item" :avatar="item.avatar_parsed" @configureList="configureList(index)" :send-request="false" :delete-friend="false" :delete-request="false" :accept-request="false"></User>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>
        </div>
    </div>
</template>

<script>
    import searchFilter from '../../_shared/SearchFilter';
    import {handleAWSImage} from "../../../_helpers/aws-handler";
    import {usersService} from "../../../_api";
    import avatar from "../../_shared/Avatar";
    import userComplexFilter from "../../_shared/UserComplexFilter";
    import userDropdownFilter from "../../_shared/UserFilterDropdown";
    import EventBus from "../../../_helpers/event-bus";
    import PersonProgress from "../../_shared/UserProgress";
    import User from "../User";

    export default {
        name: 'friendsList',
        data () {
            return {
                loadingChat: false,
                friends: {
                    loading: true,
                    loadIndexThreshold: 10,
                    loadIndex: 10,
                    page: 0,
                    count: 0,
                    list: [],
                    search_str: '',
                    orderBy: 1
                }
            }
        },
        components: {
            User,
            PersonProgress,
            searchFilter,
            userDropdownFilter,
            avatar,
            userComplexFilter,
        },
        created() {
            console.log('CREATED');
        },
        mounted() {
            console.log('MOUNTED');
            this.getFriends(true);
        },
        methods: {
            onOrderByChange(data) {
                console.log(data);
                this.friends.orderBy = data;
                this.friends.list = [];
                this.friends.loadIndex = 10;
                this.getFriends(true);
            },
            /*startChat(data) {
                this.loadingChat = true;
                usersService.getChatIdAPI(data).then((res) => {
                    this.loadingChat = false;
                    if (res) {
                        this.$emit('startChat', res);
                        if (this.isModal) {
                            if (this.$route.query.t && this.$route.query.t !== res) {
                                this.$router.push({query: {'t': res}});
                            }
                        } else {
                            this.$router.push({ path: '/messages', query: { t: res, ff: true } });
                        }
                    }
                })
            },*/
            filteredList(data) {
                console.log(data);
                this.friends.search_str = data.searchStr;
                this.friends.list = [];
                this.friends.loadIndex = 10;
                this.getFriends(true);
            },
            onSearchChange(data) {
                console.log(data);
                this.friends.search_str = data;
            },
            handleScroll(startIndex, endIndex) {
                // console.log(startIndex, this.inner-circle.loadIndex);
                if (endIndex === this.friends.loadIndex) {
                    this.friends.loadIndex += this.friends.loadIndexThreshold;
                    this.getFriends(false);
                }
            },
            getFriends(reload) {
                this.friends.loading = true;
                // console.log(reload);
                (reload) ? this.friends.page = 0 : '';
                // this.inner-circle.list = [];
                usersService.getFriendsAPI({
                    perPage: 10,
                    page: (!this.friends.page) ? 1 : this.friends.page + 1,
                    query: this.friends.search_str,
	                orderBy: this.friends.orderBy
                }).then((res) => {
                    if (res) {
                        // this.friends.list=[]
                        this.friends.count = res.total;
                        // this.inner-circle.list = [...this.inner-circle.list, ...res.data];
                        res.data.forEach((obj) => {
                            obj['avatar_parsed'] = null;
                            this.friends.list.push(obj);
                            handleAWSImage(obj.avatar_135).then((res) => {
                                obj['avatar_parsed'] = res;
                            });
                        });
                        this.friends.page = res.current_page;
                    }
                    setTimeout(() => {
                        this.friends.loading = false;
                    }, 500)
                })
            },
            configureList(index) {
                console.log(index);
                this.friends.list.splice(index, 1);
                this.friends.count -= 1;
                /*
                EventBus.$emit('friendsChanged');*/
            }
            /*removeFromFriends(data, index){
                let q = confirm('Are you sure?');
                if (q) {
                    usersService.removeFromFriendsAPI({
                        id: data.id
                    }).then((res) => {
                        if (res !== undefined) {
                            this.inner-circle.list.splice(index, 1);
                            this.inner-circle.count -= 1;
                            EventBus.$emit('friendsChanged');
                        }
                    })
                }
            },*/
            /*goToProfile(data) {
                this.$router.push({path: `/profile/${data.id}`});
            },*/
            /*askQuestion(item) {
                this.$router.push({path: '/questions/personal-questions', query: {user_id: item.id}});
            }*/
        }
    }
</script>

<style lang="less" scoped>
    #friends {
        width: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 1px;
        margin-bottom: 34px;
        background: #fff;
        //padding: 16px 0;
        margin-top: 22px;
        .search-filter {
            border-bottom: 1px solid #e3e6eb;
            margin-bottom: 16px;
        }
        .top-bar {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            // margin-bottom: 32px;
            padding: 0 16px 32px 16px;
            .friends-count {
                font-size: 14px;
                line-height: 14px;
                color: #666666;
            }
            .sort-wrapper {
                font-size: 12px;
                line-height: 14px;
                color: #333333;
            }
        }
        .users-list-wrapper__row {
            /*width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;*/
            .user-item {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 8px 25px;
                border-bottom: 1px solid #e3e6eb;
                .avatar-wrapper {
                    margin-right: 25px;
                    cursor: pointer;
                }
                .user-info-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    .main-info {
                        width: 68%;
                        text-align: left;
                        border-right: 1px solid #e3e6eb;
                        margin-bottom: 9px;
                        .user-fullname {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            color: #666666;
                            margin-bottom: 8px;
                            cursor: pointer;
                        }
                        .user-description {
                            font-size: 14px;
                            line-height: 16px;
                            color: #999999;
                            margin-bottom: 8px;
                        }
                        .person-progress {
                            margin-bottom: 8px;
                        }
                        .answers-wrapper {
                            span {
                                font-size: 14px;
                                line-height: 16px;
                                color: #71809C;
                                &.green-text-quantity {
                                    color: #AFD4AB;
                                    font-weight: bold;
                                }
                                &.red-text-quantity {
                                    color: #EDA2A4;
                                    font-weight: bold;
                                }
                                &.gray-text-quantity {
                                    color: #9B9B9B;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    .common-preferences {
                        width: 25%;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 9px;
                        text-align: left;
                        justify-content: center;
                        span {
                            font-size: 13px;
                            line-height: 18px;
                            color: #333333;
                            margin-bottom: 14px;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                    .actions-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        /*justify-content: space-between;*/
                        width: 100%;
                        .def-btn {
                            &:not(:last-child) {
                                margin-right: 10px;
                            }
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 12px;
                            margin-bottom: 8px;
                            width: 100%;
                            max-width: 150px;
                            &.disabled {
                                background: rgba(0, 0, 0, 0.1)!important;
                                color: #A9BAD4!important;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

