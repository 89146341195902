const googleId = process.env.VUE_APP_GOOGLE_APP_ID;
export function initGoogleSdk() {
	return new Promise(resolve => {
		/*window.vkAsyncInit = function() {
			VK.init({
				apiId: vkAppId,
				status: true
			});
			resolve();
			// console.log('init VK');
		};*/
		
		var el = document.createElement("script");
		el.type = "text/javascript";
		el.src = `https://maps.googleapis.com/maps/api/js?key=${googleId}&libraries=places&language=ru-RU`;
		// el.async = false;
		document.head.appendChild(el);
		console.log(document);
	})
}
