<template>
	<div id="app" v-bind:class="{'registration': isRegistration, 'chat': isChat}">
		<!--<button @click="openModal('greeting-modal')">Greetings</button>
		<button @click="openModal('similarPeople')">similarPeople</button>
		<button @click="openModal('initQuestionsModalRef')">initQuestionsModalRef</button>
		<button @click="openModal('similarPeople')">similarPeople</button>
		<button @click="reset()">reset</button>-->
		<!--<button @click="triggerAlert()">asdfasdf</button>
		<button @click="triggerAler1t()">asdfasdf</button>-->
		<div id="wrapper">
			<global-alert></global-alert>
			<global-header></global-header>
			<div class="content u-container">
				<div class="u-row">
					<div class="u-col u-col-12">
						<left-sidebar v-if="isAuthenticated"></left-sidebar>
						<div id="main">
							<transition name="fade">
								<router-view/>
							</transition>
						</div>
					</div>
				</div>
			</div>
		</div>
		<global-footer></global-footer>

		<question-detailed-modal ref="question-details-modal" :topic-global-id="topicGlobalId" :modal-title="questionData ? questionData.title : ''" :has-confirmation-modal="true" :is-question-feed="true"></question-detailed-modal>
		<b-modal ref="greeting-modal" size="lg" hide-header hide-header-close hide-footer id="greeting-modal" :no-close-on-backdrop="true">
			<h1>Приветствуем Вас!</h1>
			<p>Ответьте, пожалуйста, на несколько вопросов, чтобы система UP&UP смогла начать пополнять Ваш круг друзей Up&Up и найти именно того, с кем Вам будет интересно общаться на общие темы.</p>
			<button class="def-btn" @click="showQuestionsModal()">НАЧАТЬ</button>
		</b-modal>
		<similar-people-modal ref="similarPeople"></similar-people-modal>
		<init-questions-modal ref="initQuestionsModalRef" @submitModal="onSubmitQuestionsModal" ></init-questions-modal>
		<confirmation-modal ref="confirmDialogue"></confirmation-modal>
	</div>
</template>

<script>
	import {questionsService, authService, usersService} from "./_api";
	import Header from "./components/_shared/global/TheHeader.vue";
	import Footer from "./components/_shared/global/TheFooter.vue";
	import LeftSidebar from "./components/_shared/global/TheLeftSidebar.vue";
	import alert from "./components/_shared/global/TheAlert.vue";
	import questionDetailedModal from "./components/_shared/modals/_PostDetailedModal";
	import similarPeopleModal from "./components/_shared/modals/_SimilarPeopleModal";
	import initQuestionsModal from "./components/_shared/modals/_InitQuestionsModal";
	import {parsePostBody} from "./_helpers/post-body-parser";
	import { handleAWSImage } from './_helpers/aws-handler';
	import EventBus from "@/_helpers/event-bus";
	import ConfirmationModal from "@/components/_shared/modals/_ConfirmationDialogue";

	export default {
		name: 'main-app',
		components: {
			ConfirmationModal,
			'global-header': Header,
			'global-footer': Footer,
			'left-sidebar': LeftSidebar,
			'global-alert': alert,
			'question-detailed-modal': questionDetailedModal,
			'similar-people-modal': similarPeopleModal,
			'init-questions-modal': initQuestionsModal,
		},
		data() {
			return {
				isRegistration: '',
				isChat: true,
				questionData: null,
				topicGlobalId: null,
				introQuestions: [],
				showQuestionModal: false,
				showFirstLoginModal: false
			}
		},
		computed: {
			isAuthenticated () {
				return this.$store.state.authentication.status.loggedIn
			},
			isFirebaseAuth () {
				return this.$store.state.firebase.status.loggedIn
			},
			fn () {
				return this.$route.query.fn
			},
			user () {
				return this.$store.state.authentication.user;
			}
		},
		beforeCreate() {},
		created() {
			/*let title_el = document.querySelector("title");
			document.addEventListener("visibilitychange", function() {
				title_el.innerHTML = document.hidden ? "Come back... we miss you" : "Up&Up";
			});*/

			const isSupported = () =>
					'Notification' in window &&
					'serviceWorker' in navigator &&
					'PushManager' in window;
			if (isSupported()) {
				if (Notification.permission !== 'granted') {
					Notification.requestPermission().then(res => {})
				}
			}
			if (this.isAuthenticated && !this.isFirebaseAuth) {
				this.$store.dispatch('firebase/getUserData');
				this.$store.dispatch('authentication/handleGlobalUserImage', this.user);
				this.$store.dispatch('firebase/getUnreadCount', {stopListener: false});
				this.$store.dispatch('firebase/getNotificationsCount', {stopListener: false});
			}
		},
		mounted() {
			EventBus.$on('openConfirmationModal', (data) => {
				console.log(data);
				this.$refs.confirmDialogue.show({
					title: data.title,
					message: data.message,
					okButton: data.okButton,
				}).then((result) => {
					console.log(result);
					EventBus.$emit('confirmationModalResponse', result);
				});
			});
		},
		methods: {

			triggerAlert() {
				this.$store.dispatch('alert/success', 'This is an alertr message');
			},
			triggerAler1t() {
				this.$store.dispatch('alert/warning', 'This is an alertr message');
			},

			openModal(ref) {
				this.showQuestionModal = true;
				this.showFirstLoginModal = true;
				this.$refs[ref].show();
				// this.$refs.similarPeople.showModal();
			},
			//:::START INTRO QUESTIONS MODAL
			showQuestionsModal() {
				this.$refs['greeting-modal'].hide();
				this.$store.dispatch('authentication/changeIsFirstLogin', this.user);
				this.introQuestions = [];
				questionsService.getFirstQuestions().then((res) => {
					res.forEach(obj => {
						parsePostBody(obj, true).then(res_q => {
							this.introQuestions.push(res_q);
							if (res.length === this.introQuestions.length) {
								this.$refs.initQuestionsModalRef.show(this.introQuestions);
							}
						});
					});

				});
			},
			onSubmitQuestionsModal (questions) {
				let checkedAmount = questions.filter((o) => {return o.selected}).length;
				let promiseArr = [];
				if (checkedAmount) {
					questions.forEach(obj => {
						promiseArr.push(
								new Promise(((resolve, reject) => {
									if (obj.selected && obj.selected.id) {
										let objToSend = {
											question_id: obj.selected.question_id,
											answer_ids: [obj.selected.id],
											is_important: obj.is_important,
											visibility: obj.visibility
										};
										questionsService.sendAnswer(objToSend).then((res) => {
											resolve();
                                        });
									} else {
										resolve();
									}

								}))
						)
					});
					Promise.all(promiseArr).then((res) => {
						this.$store.dispatch('question/getTopics', 7);
						this.$store.dispatch('alert/success', 'Ответы отправлены', { root: true });
						this.$refs.initQuestionsModalRef.hide();
						this.$refs.similarPeople.showModal();
					})

				} else {
					this.$refs.initQuestionsModalRef.hide();
				}
			},
			//:::END INTRO QUESTIONS MODAL

			reset() {
				authService.updateUser({
					is_first_login: null
				})
			},
			getUserData(data) {
				return new Promise((resolve,reject) => {
					usersService.getUsersData({
						user_id: data
					}).then((res) => {
						console.log(res);
						if (res) {
							resolve(res);
						} else {
							reject()
						}
					})
				})
			},
			getUser(data) {
				return new Promise(((resolve, reject) => {
					if (data) {
						this.$firebaseUsers.where('fb_id', '==', data).get().then(querySnapshot => {
							if (!querySnapshot.empty) {
								resolve(querySnapshot.docs[0].data());
							} else {
								resolve(null);
							}
						})
					} else {
						resolve(null);
					}
					
				}))
			},
		},
		watch:{
			isAuthenticated(res) {
				if (res) {
					if (this.user.is_first_login) {
						this.showFirstLoginModal = true;
						this.$refs['greeting-modal'].show();
					}
				}
			},
			fn (res) {
				if (res) {
					// {?fn=1&qi=360&ip=1/0&pos=main/comments}
					console.log(res);
					console.log(this.$route.query.firebaseId);
					this.$firebaseNotifications.doc(this.$route.query.firebaseId).get().then(response => {
						console.log(response);
						console.log(response.data());
						if (response.exists) {
							this.showQuestionModal = true;
							const notificationData = response.data();
							let temp = this.$route.query;
							if (notificationData.type === 10) {
								usersService.getGalleryAPI({
									post_id: notificationData.object_id,
									user_id: null,
									page: 1,
									perPage: 1
								}).then((response) => {
									console.log(response.result);
									parsePostBody(response.result).then((res_p) => {
										res_p['notification_type'] = notificationData.type;
										this.getUserData(temp.from_id).then((res1) => {
											res_p['author'] = res1;
											res_p.redirected = 1;
											res_p['title'] = `${res_p['author'].name} ${(res_p['author'].sex === 'm') ? 'добавил' : 'добавила'} комментарий к Вашему фото`;
											res_p['author']['avatar_parsed'] = null;
											handleAWSImage(res_p['author'].avatar).then(res => {
												res_p['author']['avatar_parsed'] = res;
												res_p['avatar_parsed'] = res;
												this.$forceUpdate();
											});
											res_p.photos.forEach((obj_c, index) => {
												obj_c['photo_parsed'] = null;
												obj_c['photo_parsed_min'] = null;
												handleAWSImage(obj_c.photo).then(response => {
													obj_c['photo_parsed'] = response;
													obj_c['photo_parsed_min'] = response;
													this.$forceUpdate();
												});
												this.$forceUpdate();
											});
											
											this.$refs['question-details-modal'].showModal(this.$route.query.pos, res_p, null);
											this.$router.replace({});
										})
									})
								})
							} else {
								questionsService.getSingleQuestionDataAPI({
									question_id: this.$route.query.qi,
									is_personal: notificationData.is_personal ? 1 : 0,
								}).then((res) => {
									if (res) {
										console.log(res);
										this.topicGlobalId = (notificationData.is_personal) ? 8 : 7;
										parsePostBody(res).then(res_q => {
											console.log(res_q);
											res_q['notification_type'] = notificationData.type;
											if (res_q.my_personal_answers.length) {
												let f_a = res_q.my_personal_answers.filter((c) => {
													return c.id === notificationData.from_user_id
												});
												if (f_a.length) {
													res_q.answers.forEach((f) => {
														f['checked'] = f.id === f_a[0].answer_id;
													});
												}
												
												res_q.visibility = 0
											}
											res_q['has_answer'] = res_q.answers.some(i => {
												if (i.checked) {
													res_q['selected'] = i;
												}
												return i.checked;
											});
											res_q['has_answer_mode'] = res_q['has_answer'];
											
											if (notificationData.answer) {
												res_q.answers.forEach((h) => {
													h['another_checked'] = h.id === notificationData.answer.id;
												})
											}
											
											if (res_q.author) {
												// PERSONAL QUESTION
												console.log('IFFFFFFF');
												res_q.redirected = 1;
												res_q.author['avatar_parsed'] = null;
												res_q['another_checked'] = true;
												
												if (notificationData.answer) {
													// MEANS THAT SOMEONE ANSWERED ON MY PERSONAL QUESTION THAT I REDIRECTED
													this.getUser(notificationData.from_user_fb_id).then(c_obj => {
														res_q['author'] = c_obj;
														res_q['title'] = `${res_q.author.name} ${(res_q['author'].sex === 'm') ? 'ответил' : 'ответила'} на Ваш персональный вопрос который Вы переслали `;
														res_q['who_asked'] = [res_q['author']];
														handleAWSImage(res_q.author.avatar_36).then(res => {
															res_q.author['avatar_parsed'] = res;
															res_q['avatar_parsed'] = res;
															this.$forceUpdate();
														});
														
														this.$refs['question-details-modal'].showModal(this.$route.query.pos, res_q, null);
														this.$router.replace({});
													})
												} else {
													// REGULAR PERSONAL QUESTION NOTIFICATION
													res_q['title'] = `${res_q['author'].name} ${(res_q['author'].sex === 'm') ? 'переслал' : 'переслалa'} Вам персональный вопрос`;
													res_q['who_asked'] = [res_q['author']];
													handleAWSImage(res_q.author.avatar_36).then(res => {
														res_q.author['avatar_parsed'] = res;
														//res_q['avatar_parsed'] = res;
														this.$forceUpdate();
													});
													this.$refs['question-details-modal'].showModal(this.$route.query.pos, res_q, null);
													this.$router.replace({});
												}
												
											} else {
												console.log('ELSEEEEEEEE');
												// IF AUTHOR == NULL IT SHOULD MEAN THAT THIS IS A MAIN QUESTION NOT PERSONAL
												this.getUserData(temp.from_id).then((res1) => {
													res_q['author'] = res1;
													res_q.redirected = 1;
													if (notificationData.answer) {
														res_q['title'] = `${res_q['author'].name} ${(res_q['author'].sex === 'm') ? 'ответил' : 'ответила'} на ${res_q.is_day ? 'вопрос дня' : 'основной вопрос'} который Вы переслали`;
														res_q['author']['avatar_parsed'] = null;
														res_q['another_checked'] = true;
														handleAWSImage(res_q['author'].avatar).then(res => {
															res_q['author']['avatar_parsed'] = res;
															res_q['avatar_parsed'] = res;
															this.$forceUpdate();
														});
													} else {
														if (res_q.notification_type === '9') {
															res_q['title'] = res_q.is_day ? 'Вопрос дня' : 'Основной вопрос';
															res_q['author'] = null;
														} else {
															res_q['title'] = `${res_q['author'].name} ${(res_q['author'].sex === 'm') ? 'переслал' : 'переслалa'} Вам ${res_q.is_day ? 'вопрос дня' : 'основной вопрос'}`;
															res_q['author']['avatar_parsed'] = null;
															res_q['another_checked'] = true;
															handleAWSImage(res_q['author'].avatar).then(res => {
																res_q['author']['avatar_parsed'] = res;
																res_q['avatar_parsed'] = res;
																this.$forceUpdate();
															});
														}
													}
													
													res_q['who_asked'] = [res_q['author']];
													this.$refs['question-details-modal'].showModal(this.$route.query.pos, res_q, null);
													this.$router.replace({});
												})
											}
										})
									}
								})
							}
							
						}
					})
					
				}
			},
			$route (to, from){
				this.isRegistration = to.name.includes('registration') || to.name.includes('password-setup');
				this.isChat = to.name.includes('messages');
				this.$store.dispatch('alert/clear');
			}
		}
	}
</script>

<style lang="less">
	@import "assets/styles/_vars.less";
	/*@import "assets/styles/_fonts.less";*/
	@import "assets/styles/_unified.less";
	@import "assets/styles/_grid.less";
	@import "assets/styles/_btns.less";

	.vue-recycle-scroller__item-view {
		&:hover {
			z-index: 998;
		}
	}

	.fade-enter-active {
		transition-duration: .3s;
		transition-property: opacity;
		transition-timing-function: ease;
	}
	.fade-enter,
	.fade-leave-active {
		opacity: 0
	}

	&::-webkit-scrollbar{
		height: 4px;
		width: 4px;
		background: gray;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb{
		background: lightgrey;
		border-radius: 10px;
	}

	html {
		scroll-behavior: smooth;
	}
	body {
		overflow-y: scroll;
		scroll-behavior: smooth;
		padding-right: 0;
		// transition: all 300ms;
		&::-webkit-scrollbar{
			height: 4px;
			width: 4px;
			background: gray;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb{
			background: lightgrey;
			border-radius: 10px;
		}
		&.modal-open {
			overflow-y: hidden;
			padding-right: 4px!important;
			#nav {
				&.fixed {
					right: 4px!important;
				}
			}
			#questions-right-sidebar {
				position: unset;
			}
		}
		&.no-left-sidebar {
			#main {
				width: 100%!important;
				padding: 0!important;
			}
			#left-sidebar {
				display: none;
			}
		}
		&.sidebar-menu-opened {
			overflow-y: hidden;
		}
		#app {
			display: flex;
			flex-direction: column;
			font-family: Roboto, Helvetica, Arial, sans-serif;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			text-align: center;
			color: #2c3e50;
			background-color: #f4f4f4!important;
			p, a, li, div {
				color: @default_text_color;
				text-decoration: none;
			}
			&.registration {
				background-color: #ffffff!important;
			}
			#wrapper {
				min-height: calc(100vh - 100px);
				.content {
					z-index: 998;
					.u-row {
						.u-col {
							display: flex;
							flex-wrap: nowrap;
							justify-content: flex-start;
							align-items: flex-start;
							#left-sidebar {
								// display: none;
							}
							#main {
								width: 76%;
								padding-left: 24px;
							}
						}
					}
				}
			}
		}
	}
	.modal {
		padding-left: 0!important;
		.modal-dialog {
			.modal-content {
				.modal-header {
					position: relative;
					.close {
						padding: 0;
						margin: 0;
						right: 10px;
						top: 10px;
						color: #0a0a0a!important;
					}
				}
			}
		}
		&::-webkit-scrollbar{
			height: 4px;
			width: 4px;
			background: gray;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb{
			background: lightgrey;
			border-radius: 10px;
		}
	}

	input, textarea {
		border: 1px solid @border;
		&.border-none {
			border: none;
		}
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: @placeholder;
		}
		&::-moz-placeholder { /* Firefox 19+ */
			color: @placeholder;
		}
		&:-ms-input-placeholder { /* IE 10+ */
			color: @placeholder;
		}
		&:-moz-placeholder { /* Firefox 18- */
			color: @placeholder;
		}
		&:focus {
			box-shadow: none!important;
			outline: none!important;
		}
		&.untouched {
			&.pristine {
				&.invalid {
					background-image: none!important;
					border: 1px solid @input_error!important;
				}
			}
		}
		&.touched {
			&.invalid {
				background-image: none!important;
				border: 1px solid @input_error!important;
			}
		}
	}
	.err-wrapper {
		text-align: left;
		font-size: 12px;
		div {
			color: #840000;
		}
	}
	.input-wrapper {
		position: relative;
		.e-wrapper {
			position: absolute;
			display: flex;
			right: -20px;
			top: 50%;
			margin-top: -8px;
			.errors-tooltip {
				background-image: url("assets/img/all-done.svg");
				width: 16px;
				height: 16px;
			}
		}
	}

	// global styles for registration section and edit data modal in my profile section
	.c-wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		align-items: flex-start;
		.form-wrapper {
			width: 100%;
			form {
				background-color: #F8F8F8;
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				padding: 48px 57px;
				margin-bottom: 30px;
				.regular-label {
					font-size: 11px;
					font-weight: 500;
					text-transform: uppercase;
					color: @text_color;
					text-align: left;
					display: flex;
				}
				.form-control {
					&[type='text'] {
						border-radius: 3px;
						padding: 11px 16px;
						height: auto;
					}
					&:focus {
						border: 1px solid #ced4da;
					}
				}
				.form-group-wrapper {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					flex-wrap: wrap;
					margin-bottom: 40px;
				}
				.privacy-settings {
					width: 144px;
					&.no-label {
						label {
							display: none;
						}
					}
					.form-group {
						margin: 0;
					}
				}
			}
			.controls-wrapper {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				flex-wrap: wrap;
				.postpone-btn {
					margin-right: 20px;
					text-transform: uppercase;
					font-weight: 500;
					font-size: 17px;
					color: @theme_color;
					background-color: transparent;
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 5px 10px;
					min-width: 330px;
					min-height: 50px;
					border-radius: 35px;
					transition: all 250ms;
					&:focus {
						outline: none;
					}
					&.loading {
						border: 1px solid @theme_color;
					}
				}
				.submit-btn {
					font-size: 17px;
					font-weight: 500;
					padding: 13px 51px;
					&:focus {
						outline: none;
					}
				}
			}
		}
	}
	// google place autocomplete dropdown
	.pac-container {
		z-index: 1041!important;
	}

	#greeting-modal {
		.modal-dialog {
			max-width: 900px;
			top: 48%;
			transform: translateY(-50%);
			.modal-body {
				padding: 40px;
				text-align: center;
				h1 {
					font-size: 36px;
					color: #666666;
					margin-bottom: 32px;
				}
				p {
					font-size: 24px;
					line-height: 28px;
					font-weight: 500;
					color: #666666;
				}
				button {
					margin: 48px auto 0;
					font-size: 18px;
					font-weight: 500;
					padding: 5px 60px;
				}
			}
		}
	}

	.tooltip {
		&.chat-interests-tooltip {
			&.bs-tooltip-top {
				.arrow {
					&:before {
						border-top-color: @theme_color;
					}
				}
			}
			&.bs-tooltip-left {
				.arrow {
					&:before {
						border-left-color: @theme_color;
					}
				}
			}
			&.bs-tooltip-right {
				.arrow {
					&:before {
						border-right-color: @theme_color;
					}
				}
			}
			&.bs-tooltip-bottom {
				.arrow {
					&:before {
						border-bottom-color: @theme_color;
					}
				}
			}
			.tooltip-inner {
				font-size: 12px;
				background-color: @theme_color;
				ul {
					margin: 0;
					list-style-type: none;
					padding: 0;
					li {
						text-align: left;
					}
				}
			}
		}
	}




	// Large devices (desktops, less than 1200px)
	@media (max-width: 1200px) {}
	// Medium devices (tablets, less than 992px)
	@media (max-width: 991px) {}
	// Small devices (landscape phones, less than 768px)
	@media (max-width: 768px) {
		body {
			#app {
				&.chat {
					#wrapper {
						min-height: 100vh;
					}
				}
				#wrapper {
					.content {
						.u-row {
							.u-col {
								#main {
									width: 100%;
									padding-left: 0;
								}
							}
						}
					}
				}
			}
			&.modal-open {
				#app {
					&.chat {
						.content {
							padding: 0!important;
						}
					}
				}
				#chat-window-page {
					position: initial!important;
					.c-modal-backdrop-wr {
						background-color: rgba(0, 0, 0, 0.7);
					}
				}
			}
		}
		#greeting-modal {
			.modal-dialog {
				.modal-body {
					padding: 20px;
					border: 12px solid #e0e3ea;
					h1 {
						font-size: 22px;
						font-weight: 500;
					}
					p {
						font-size: 14px;
						line-height: 18px;
						margin-bottom: 40px;
					}
					button {
						margin: 0 auto;
						font-size: 18px;
						width: 100%;
						max-width: 320px;
						padding: 5px 60px;
					}
				}
			}
		}

		.modal {
			.modal-dialog {
				.modal-content {
					.modal-header {

					}
				}
			}
		}
	}
	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 576px) {}
</style>
