import Vue from 'vue';
initGoogleSdk();
Vue.config.productionTip = false;
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
Vue.use(VueVirtualScroller);

import moment from 'moment'
Vue.prototype.moment = moment;

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('multiselect', {
	validate: (value, args) => {
		return (parseInt(args.list) > 0)
	},
	params: ['list'],
	message: 'Поле должно содержать не менее {min} символа(ов) и не более {max} символа(ов)'
});
extend('min-max', {
	validate: (value, args) => {
		const length = value.length;
		return length >= args.min && length <= args.max
	},
	params: ['min', 'max'],
	message: 'Поле должно содержать не менее {min} символа(ов) и не более {max} символа(ов)'
});
extend('strong', {
	validate: (value, args) => {
		return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/.test(value);
	},
	message: 'Длина пароля должна быть не менее 8 символов. Пароль должен состоять из букв латинского алфавита (A-z), арабских цифр (0-9) и специальных символов. Буквенная часть пароля должна содержать как строчные, так и прописные (заглавные) буквы.'
});
extend('required', {
	...required,
	message: 'Обязательное поле'
});
extend('email', {
	...email,
	message: 'Email должен быть такого типа - email@example.com'
});
extend('password', {
	params: ['target'],
	validate(value, {target}) {
		return value === target
	},
	message: 'Пароли не совпадают'
});

import App from './App.vue';
import router from './router';
import store from './_store/store';

import 'bootstrap';
import { ModalPlugin, DropdownPlugin, ButtonPlugin, InputGroupPlugin, FormPlugin, FormDatepickerPlugin, FormGroupPlugin, FormInputPlugin, TooltipPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);
Vue.use(ButtonPlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(TooltipPlugin);
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js'

import fire from "./_helpers/init-firebase-sdk";
Vue.prototype.$firebase = fire.firestore();
Vue.prototype.$storage = fire.storage();
Vue.prototype.$firebaseChats = fire.firestore().collection('chats');
Vue.prototype.$firebaseUsers = fire.firestore().collection('users');
Vue.prototype.$firebaseNotifications = fire.firestore().collection('notifications');
Vue.prototype.$firebaseComments = fire.firestore().collection('simple_comments');
Vue.prototype.$firebasePersonalComments = fire.firestore().collection('personal_comments');
Vue.prototype.$firebasePostComments = fire.firestore().collection('post_comments');

import interceptors from './_helpers/http-interceptor';
interceptors();

import scrollBottomDirective from './_directives/scrolledToBottomDirective';
import clickOutsideDirective from './_directives/clickOutsideDirective';
Vue.directive('scroll-bottom', scrollBottomDirective);
Vue.directive('click-outside', clickOutsideDirective);

import {indexedDbHandler} from "./_helpers/indexed-db-handler";
indexedDbHandler();
import {deviceWidth} from "@/_helpers/is-mobile";
deviceWidth();


import {initFacebookSdk} from './_helpers/init-facebook-sdk.js';
import {initVkSdk} from './_helpers/init-vk-sdk.js';
import {initGoogleSdk} from "@/_helpers/init-google-sdk";
initFacebookSdk();
/*initVkSdk();*/


export default new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
