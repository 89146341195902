<template>
	<b-modal ref="invite-users-modal" @hide="resetModal()" id="invite-users-modal" :no-close-on-backdrop="true" hide-footer>
		<template v-slot:modal-header="{ close }">
			<div class="title">
				Пригласить друзей
			</div>
			<button type="button" @click="close()" class="close">×</button>
		</template>
		<div class="description">
			Вы можете пригласить новых друзей зарегистрироваться отправив им приглашение удобным для Вас способом. Только получатель приглашения сможет зарегистрироваться.
		</div>
		<div class="options-to-invite">
			<div class="email" @click="inviteUser('email')">
				<img src="../../../assets/img/email-invite.svg" alt="email-invite">
				<span>Email</span>
			</div>
			<div class="fb" @click="inviteUser('fb')">
				<img src="../../../assets/img/facebook-invite.svg" alt="facebook-invite">
				<span>Facebook</span>
			</div>
			<div class="vk" @click="inviteUser('vk')">
				<img src="../../../assets/img/vk-invite.svg" alt="vk-invite">
				<a ref="vk_link" :href="'http://vk.com/share.php?url=' + inviteLink" target="_blank" :style="{'display': 'none'}">Share in VK</a>
				<span>VK</span>
			</div>
		</div>
	</b-modal>
</template>

<script>
	import {usersService} from "@/_api";
	
	export default {
		name: 'invite-users-modal',
		components: {},
		props: {},
		data() {
			return {
				inviteLink: null,
			}
		},
		created() {},
		mounted() {},
		computed: {},
		methods: {
			showModal() {
				this.$refs['invite-users-modal'].show();
				this.getDynamicInviteLink();
			},
			getDynamicInviteLink() {
				usersService.getInviteLinkAPI().then(res => {
					if (res) {
						this.inviteLink = res.result;
					}
				})
			},
			inviteUser(type) {
				if (type === 'email') {
					var email = '';
					var subject = 'Вас пригласили в систему Up%26Up';
					var emailBody = 'Дорогой друг,%0D%0AПриходи на сайт Up%26Up. Там можно искать людей по их взглядам на жизнь, на отношения, на секс, на работу и т. п. И мы с тобой сможем прекрасно там общаться.%0D%0A' + this.inviteLink;
					document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
				} else if (type === 'fb') {
					FB.ui({
						method: 'share',
						href: this.inviteLink,
						display: 'popup',
					}, function(response){
						// console.log(response);
					});
				} else if (type === 'vk') {
					this.$refs.vk_link.click();
				}
			},
			resetModal() {
				this.inviteLink = null;
			},
		},
		watch: {}
	}

</script>

<style lang="less" scoped>
	@import "../../../assets/styles/_vars.less";
	::v-deep #invite-users-modal {
		.modal-dialog {
			width: 640px!important;
			max-width: 640px;
			.modal-header {
				.title {
					color: #666666;
					font-size: 13px;
					font-weight: 500;
				}
			}
			.modal-body {
				padding: 0!important;
				.description {
					font-weight: 400;
					font-size: 15px;
					color: #666666;
					letter-spacing: 0;
					line-height: 16px;
					border-bottom: 1px solid #dee2e6;
					padding: 1rem;
				}
				.steps-description {
					font-weight: normal;
					font-size: 13px;
					color: #666666;
					border-bottom: 1px solid #dee2e6;
					padding: 1rem;
					background: #F7F7F7;
				}
				.options-to-invite {
					display: flex;
					justify-content: space-around;
					align-items: center;
					flex-wrap: nowrap;
					padding: 1rem;
					&>div {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-wrap: wrap;
						cursor: pointer;
						img {
							width: 50px;
						}
						span {
							width: 100%;
							text-align: center;
						}
					}
				}
			}
		}
	}
	
	// Large devices (desktops, less than 1200px)
	@media (max-width: 1200px) {}
	// Medium devices (tablets, less than 992px)
	@media (max-width: 991px) {}
	// Small devices (landscape phones, less than 768px)
	@media (max-width: 768px) {}
	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 576px) {
		::v-deep #invite-users-modal {
			.modal-dialog {
				width: auto!important;
			}
			
		}
	}
</style>
