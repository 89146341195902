<template>
    <b-modal ref="search-by-question" size="lg" hide-footer id="search-by-question" :no-close-on-backdrop="true" @shown="onModalShown" @hidden="onModalHidden">
        <template v-slot:modal-header="{ close }">
            <div class="title">
	            Выберите вопросы и ответы для поиска людей
            </div>
            <button type="button" @click="close()" class="close">×</button>
        </template>

        <div class="modal-content">
            <search-filter :show-error="true" :placeholderText="'Поиск вопросов'" :items="[]" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" ref="search"></search-filter>
            <div class="as">
                <div class="content">
	                <!--<ul id="nav" class="nav nav-tabs mb-3">
		                <li
				                v-for="tab in tabs"
				                :key="tab.name"
				                :class="['nav-item', { active: currentTab.name === tab.name }]"
				                @click="changeTab(tab)">
			                <a class="nav-link" :class="[{ active: currentTab.name === tab.name }]">
				                <span>{{ tab.name }}</span>
			                </a>
		                </li>
	                </ul>-->
                    <!--<all-questions ref="all-questions" :topicId="activeTopicId" :is-modal="true" :searchStr="search_str"></all-questions>-->
	                <div id="selected-list-wrapper" class="selected-list-wrapper" v-if="!selected_view && selectedQuestions.length" ref="selected-list-wrapper">
		                <div class="list-title">
			                Вопросы выбранные для поиска
		                </div>
		                <question-people-search-modal-body v-for="i in selectedQuestions" :key="i.id" class="pinned-question" :item="i" :block-title="i.title" :show-item="false" :has-delete="false" :topic-global-id="7" :show-avatars="true" :show-title-avatar="true" :selected-list="true"></question-people-search-modal-body>
	                </div>
	                
	                <div class="unselected-list-wrapper">
		                <div class="list-title" v-if="selectedQuestions.length && !selected_view">
			                <!--{{ !listSelected ? 'Все' : listSelected.name }}-->Остальные вопросы
		                </div>
		                <combined-questions @scroll="scrollListener" ref="combined-questions" :topicId="activeTopicId" :is-modal="true" :searchStr="search_str" :selected-list="listSelected" :pinnedQuestionId="pinnedQuestionId" :selected-questions="selectedQuestions"></combined-questions>
	                </div>
                </div>
                <div class="right-content">
                    <div class="selected-list" :class="{'active': selected_view}" v-if="selectedQuestions.length" @click="showSelected()">Выбранные <span>{{selectedQuestions.length}}</span></div>
	                <!--v-if="selectedQuestions.length"-->
                    <div class="all-list" :class="{'active': !selected_view && !listSelected}" @click="showAll()">Все <span v-if="topicList.length && globalTopicId === 12">{{topicList[0].count_all}}</span></div>
                    <div class="main-questions-list all-list" :class="{'active': listSelected && listSelected.id === 1}" @click="selectList(1)">Основные <span v-if="topicList.length && globalTopicId === 7">{{topicList[0].count_all}}</span></div>
                    <div class="qotd-list all-list" :class="{'active': listSelected && listSelected.id === 2}" @click="selectList(2)">Вопросы дня <span v-if="topicList.length && globalTopicId === 9">{{topicList[0].count_all}}</span></div>
                    <questions-right-sidebar ref="questions-right-sidebar" :topicList="topicList" :activeTab="2" :questionType="this.globalTopicId" :is-modal="true" @topicChange="onTopicChange" :deselected="selected_view" :class="{'selected_view': selectedQuestions.length}"></questions-right-sidebar>
                    <button class="def-btn start-search-btn" @click="close()" v-if="selectedQuestions.length">Начать поиск</button>
                </div>
	            <div class="scroll-top" @click="scrollTop()" v-if="selectedQuestions.length && !selected_view">
		            К выбранным
	            </div>
            </div>
        </div>

    </b-modal>
</template>

<script>
    import AllQuestions from "../../questions/main-questions/all";
    import SearchFilter from "../SearchFilter";
    import QuestionsRightSidebar from "../../questions/QuestionsRightSidebar";
    import EventBus from "../../../_helpers/event-bus";
    import CombinedQuestions from "../../../components/questions/CombinedQuestions";
    import {questionsService} from "@/_api";
    import QuestionPeopleSearchModalBody from "@/components/questions/QuestionPeopleSearchModalBody";
    import {parsePostBody} from "@/_helpers/post-body-parser";
    
    export default {
        name: 'searchByQuestionModal',
        props: {
            selectedQuestions: Array,
	        pinnedQuestionId: String || null
        },
        data() {
            return {
				globalTopicId: null,
                topicList: [],
                activeTopicId: null,
                search_str: '',
                selected_view: false,
	            listTypes: [
		            {
						id: 1,
			            name: 'Основные',
			            selected: false
		            },
		            {
						id: 2,
			            name: 'Вопросы дня',
			            selected: false
		            },
	            ],
	            pinnedQuestion: null
            }
        },
        components: {CombinedQuestions, QuestionsRightSidebar, SearchFilter, AllQuestions, QuestionPeopleSearchModalBody},
        beforeCreate() {},
        created() {
            console.log('CREATED');
        },
        mounted() {
            console.log('MOUNTED');
			console.log(this.pinnedQuestion);
			if (this.pinnedQuestionId) {
				this.getSingleQuestion();
			}
            this.getTopicList();
        },
        beforeDestroy() {
            EventBus.$off('addQuestionToFilter');
        },

        computed: {
	        questionForSeach () {
		        return this.$route.query.question_for_search
	        },
            topic_list() {
	            if (!this.listSelected) {
		            return this.$store.state.question.topic_list_combined
	            } else {
					if (this.listSelected.id === 1) {
						return this.$store.state.question.topic_list_main
					} else if (this.listSelected.id === 2) {
						return this.$store.state.question.topic_list_qotd
					}
	            }
            },
	        listSelected() {
				return this.listTypes.filter((o) => {if (o.selected) {return o}})[0]
	        }
        },
        methods: {
	        selectList(id) {
				this.listTypes.forEach((obj) => {
					obj.selected = false;
					if (id === obj.id) {
						obj.selected = true;
					}
				});
				this.selectedQuestions.forEach((o) => {
					console.log(o);
					o.padding = 0;
					this.$forceUpdate();
				})
	        },
            close() {
                this.$refs['search-by-question'].hide();
	            EventBus.$emit('searchModalHidden');
            },
            showSelected() {
                this.selected_view = true;
                this.selectedQuestions.forEach(o => {o.show_item = false});
				this.$forceUpdate();
                EventBus.$emit('showSelected', this.selectedQuestions);
            },
            showAll() {
                this.selected_view = false;
				this.listTypes.forEach((o) => {
					o.selected = false;
					this.$forceUpdate();
				});
	            this.selectedQuestions.forEach((o) => {
		            console.log(o);
		            o.padding = 0;
		            this.$forceUpdate();
	            })
                this.$refs['questions-right-sidebar'].setActiveTopic(this.topicList[0]);
	            this.getTopicList();
            },
            showModal(show_selected) {
                this.$refs['search-by-question'].show();
                this.selected_view = show_selected;
            },
            onModalHidden() {
				this.searchClear();
                EventBus.$off('addQuestionToFilter');
            },
            onModalShown() {
                console.log('SHOWN');
                EventBus.$on('addQuestionToFilter', (data, remove) => {
                    console.log(data);
                    console.log(remove);
                    let index = this.selectedQuestions.findIndex((o) => {
                        return o.question_id === data.question_id
                    });

                    if (remove) {
                        this.selectedQuestions.splice(index, 1);
                    } else {
                        if (index === -1) {
                            this.selectedQuestions.push(data);
                        }
                    }
                    console.log(this.selectedQuestions);
                    if (this.selected_view && !this.selectedQuestions.length) {
                        this.showAll();
                    }
                });
                // THIS IS USED TO TRANSFER ALREADY SELECTED QUESTIONS TO 'QUESTIONFORSEARCHMODAL.VUE'
                console.log(this.selectedQuestions.length);
                if (this.selected_view) {
                    this.showSelected();
                } else {
                    EventBus.$emit('passingAlreadySelectedQ', this.selectedQuestions);
                }
            },
            getTopicList() {
				if (!this.listSelected) {
					this.globalTopicId = 12
				} else {
					this.globalTopicId = (this.listSelected.id === 1) ? 7 : 9
				}
                this.$store.dispatch('question/getTopics', this.globalTopicId);
            },
            onTopicChange(data) {
                this.topicList.forEach((o) => {
                    o['active'] = data.item.id === o.id;
                });
                this.activeTopicId = data.item.id;
                this.selected_view = false;
                this.filteredList({
                    searchStr: this.search_str
                });
            },
            onSearchChange(data) {
                this.search_str = data;
            },
            filteredList(data) {
				console.log(this.listSelected);
                this.search_str = data.searchStr;
                if (this.$refs['combined-questions']) {
		            this.$refs['combined-questions'].resetPage(this.activeTopicId);
	                if (this.search_str.length > 0) {
		                this.$refs['combined-questions'].searchQuestions(this.activeTopicId, this.search_str, this.listSelected);
	                } else {
		                this.$refs['combined-questions'].getQuestions(this.activeTopicId, this.listSelected);
	                }
	            }
            },
            searchClear() {
                this.search_str = '';
                if (this.$refs.search) {
                    this.$refs.search.remoteClearSearch(true);
                }
            },
	        getSingleQuestion() {
		        questionsService.getSingleQuestionDataAPI({
			        question_id: this.$route.query.question_for_search,
			        is_personal: this.$route.query.is_personal,
		        }).then((res) => {
			        parsePostBody(res).then(res_q => {
				        res_q['has_answer'] = res_q.answers.some(i => {
					        if (i.checked) {
						        res_q['selected'] = i;
					        }
					        return i.checked;
				        });
				        res_q['title'] = 'Основной вопрос';
				        res_q['has_answer_mode'] = res_q['has_answer'];
				
				        // data for filter
				        res_q['padding'] = 0;
				        /*if (index === res.data.length - 1 && res.last_page === res.current_page) {
					        res_q['padding'] = 20;
				        }*/
				        res_q['added_to_filter'] = true;
				        res_q['selected_filter'] = null;
						
						this.getSingleQuestionsCounters(res_q).then((t) => {
							res_q.selected_filter = res_q.answers.filter((obj) => {
								obj['question_name'] = res_q.name;
								if (obj.checked) {
									return obj
								}
							})[0];
							this.pinnedQuestion = res_q;
							console.log(res_q);
							this.selectedQuestions.push(res_q);
						})
						
				        /*this.selectedForFilter.forEach((c_obj) => {
					        if (c_obj.question_id === res_q.question_id) {
						        res_q['added_to_filter'] = true;
						        res_q['selected_filter'] = c_obj.selected_filter;
						        this.$forceUpdate();
						        let index = res_q.answers.findIndex((o) => {return o.id === res_q.selected_filter.id});
						        if (index !== -1) {
							        res_q.answers[index] = c_obj.selected_filter;
						        }
						        this.$forceUpdate();
					        }
				        });*/
						
						
				        // this.list.push(res_q);
			        });
		        })
	        },
	        getSingleQuestionsCounters(item) {
		        return new Promise((resolve) => {
			        let checked = item.answers.filter((obj) => {
				        if (obj.checked) {
					        return obj
				        }
			        })[0];
			        questionsService.getSingleQuestionsCountersAPI({
				        question_id: item.question_id,
				        checked_id: (checked) ? checked.id : null,
				        is_personal: 0
			        }).then(res => {
				        if (res) {
					        item.answers.forEach((obj, index) => {
						        let rexp = 'ABCDEFGHIKLMNOPQRSTVXYZ';
						        let q = res.answers[obj.id];
						        obj['count'] = q.count;
						        obj['friend_count'] = q.friend_count;
						        obj['percent'] = q.percent;
						        obj['friend_percent'] = q.friend_percent;
						        obj['letter'] = rexp[index];
					        });
							
							resolve()
					        this.$forceUpdate();
				        }
			        });
		        })
	        },
	        scrollTop() {
				this.$refs['selected-list-wrapper'].scrollIntoView({behavior: 'smooth'});
		        /*let el = document.getElementById('selected-list');
		        setTimeout(() => {
			        elQ.scrollTo({
				        top: el.scrollHeight, // could be negative value
				        left: 0,
				        behavior: 'smooth'
			        });
		        }, 200)*/
	        },
	        scrollListener(e) {
		        console.log(e);
		        console.log(window.top.scrollY);
	        }
        },
        watch: {
	        questionForSeach: function (val) {
		        console.log(val);
	        },
            topic_list: function(val) {
				console.log(val);
	            const selectedTopic = this.topicList.filter((o) => {return o.active});
				if (selectedTopic.length) {
					console.log(selectedTopic[0].name);
				}
                this.topicList = val;
	            if (selectedTopic.length) {
		            this.topicList.forEach((o) => {
			            o['active'] = selectedTopic[0].id === o.id;
		            });
	            }
            },
	        listSelected: function(val) {
                console.log(val);
		        if (val) {
					
			        this.getTopicList();
			        this.selected_view = false;
			        this.filteredList({
				        searchStr: this.search_str
			        });
			        /*if (selectedTopic.length) {
				        this.topicList.forEach((o) => {
					        o['active'] = selectedTopic[0].id === o.id;
				        });
			        }*/
		        }
            }
        }
    }
</script>

<style lang="less" scoped>
	@import '../../../assets/styles/_vars.less';
    ::v-deep #search-by-question {
        max-height: 95vh;
        .modal-body {
            padding: 0;
            .modal-content {
                display: flex;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                flex-direction: unset;
                #search-filter {
                    width: 100%;
                    border-bottom: 1px solid lightgrey;
                }
	            .nav-tabs {
		            li {
			            .nav-link {
				            background-color: #ffffff;
				            color: #4495CE;
				            &.active {
					           background-color: #4495CE;
					           color: #ffffff;
				            }
			            }
		            }
	            }
                .as {
                    display: flex;
                    -webkit-box-pack: start;
                    -ms-flex-pack: start;
                    justify-content: flex-start;
                    -webkit-box-align: start;
                    -ms-flex-align: start;
                    align-items: flex-start;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    flex-direction: unset;
                    width: 100%;
                    .content {
                        width: 75%;
                        // border-right: 1px solid lightgrey;
                        max-height: 70vh;
                        overflow-y: auto;
                        // padding-bottom: 20px;
	                    position: relative;
	                    .list-title {
		                    text-align: left;
		                    padding: 5px 10px;
		                    color: #5E6F8F;
		                    font-weight: 600;
		                    background-color: #e3e3e3;
		                    font-style: italic;
		                    font-size: 14px;
	                    }
	                    .selected-list-wrapper {
		                    // margin-bottom: 30px;
	                    }
                    }
                    .right-content {
                        width: 25%;
                        position: relative;
                        border-left: 1px solid lightgrey;
                        height: 70vh;
                        max-height: 70vh;
	                    .start-search-btn {
		                    color: #D6585B!important;
		                    border-color: #D6585B;
		                    background-color: white!important;
		                    font-weight: bold!important;
		                    border-width: 2px;
	                    }
                        .selected-list, .all-list {
                            border-bottom: 1px solid lightgrey;
                            padding: 10px;
                            font-size: 13px;
                            color: #666666;
                            display: flex;
                            justify-content: space-between;
                            cursor: pointer;
                            &.active {
                                background-color: #DDDDDD;
                                color: #333;
                            }
                            span {
                                margin-left: 10px;
                            }
                        }
                        #questions-right-sidebar {
                            width: 100%;
                            padding: 10px;
	                        height: calc(~"100% - 120px");
                            &.selected_view {
                                height: calc(~"100% - 240px");
                            }
                            .topic-wrapper {
                                height: 100%;
                                overflow-y: auto;
                            }
                            // max-height: calc(~"100vh - 150px");
                        }
                        .def-btn {
                            margin: 0 auto;
                            position: absolute;
	                        bottom: 30px;
                            right: 0;
                            left: 0;
                        }
                    }
	                .scroll-top {
		                position: absolute;
		                right: calc(25% + 15px);
		                bottom: 15px;
		                cursor: pointer;
		                z-index: 5555555;
		                border: 1px solid @theme_color;
		                padding: 2px 10px;
		                border-radius: 10px;
		                font-size: 14px;
		                color: @theme_color;
		                background-color: white;
	                }
                }
            }
        }
    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        ::v-deep #search-by-question {
            .modal-body {
                .modal-content {
                    display: flex;
                    .as {
                        flex-wrap: wrap-reverse!important;
                        .content, .right-content {
                            width: 100%!important;
                        }
                        .content {
                            #questions {
                                #question {
                                    .post-body {
                                        padding: 20px 20px 40px 20px;
                                        .color-indicator {
                                            width: 10px;
                                        }
                                    }
                                    .post-body-questions {
                                        padding: 10px 15px;
                                        .radio-buttons-wrapper {
                                            .u-radiobutton-wr {
                                                label {
                                                    .label-name {
                                                        width: 100%;
                                                    }
                                                    .answers-stats {
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .right-content {
                            height: unset!important;
                            max-height: unset;
                            #questions-right-sidebar {
                                padding: 10px 0;
                                .topic-wrapper {
                                    // flex-wrap: wrap;
                                    overflow-x: auto;
                                    -webkit-box-orient: unset;
                                    -webkit-box-direction: unset;
                                    -ms-flex-direction: unset;
                                    flex-direction: unset;
                                    .topic-item {
                                        // width: auto;
                                        .description {
                                            // margin-right: 5px;
                                            width: max-content;
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
	    ::v-deep #search-by-question {
		    .modal-body {
			    .modal-content {
				    .as {
					    .right-content {
						    .def-btn {
							    bottom: -10px;
						    }
					    }
					    .scroll-top {}
				    }
			    }
		    }
	    }
    }
</style>
