<template>
    <div id="chat-window-wrapper" class="chat-window-wrapper">
        <div class="chat-window">
            <div class="day-wrapper" v-for="q in chatMessagesSorted">
                <div class="day-breakpoint"><span>{{q.timestamp_title || moment(q.timestamp, 'YYYY-MM-DD').format('DD-MM-YYYY')}}</span></div>
                <div class="message" v-for="(i, mIndex) in q.messages" v-bind:key="i.firebaseId" :class="{'my-message': i.isMyMessage && !i.type, 'not-my-message': !i.isMyMessage && !i.type, 'service-message': i.type}">
                    <div class="author-name">
                        <span v-if="i.isMyMessage">Me</span>
                        <span v-if="!i.isMyMessage">{{i.authorFull.name}}</span>
                    </div>
                    <div class="author-avatar" v-if="!i.isMyMessage">
                        <avatar v-if="(!q.messages[mIndex - 1] || ((q.messages[mIndex - 1].isMyMessage == true)))" :width="25" :height="25" :img="activeChat.friendData.avatar_parsed"></avatar>
                    </div>
                    <div class="message-text" :class="{'read': i.status, 'unread': !i.status}">
                        <b-dropdown :disabled='!i.isMyMessage' v-if="(i.attachment && i.attachment.type === 1) || !i.attachment || i.isMyMessage " id="chat-actions-dd" class="chat-actions-dd" :class="{'not-my-message': !i.isMyMessage}" no-caret>
                            <template v-slot:button-content>
                                <img v-if="i.isMyMessage" class="more-actions-hor" src='../../../assets/img/more-horizontal.svg' alt="">
                                <!--<img v-if="i.isMyMessage" class="more-actions-hor" src='../../../assets/img/replyMesssage.png' alt="">-->
                                <img v-else class="more-actions-hor" src='../../../assets/img/replyMesssage.png' alt="" @click="messageAction(i, friend_actions[0])">
                            </template>
                            <template v-for="ii of (!i.isMyMessage) ? friend_actions : my_actions">
                                <b-dropdown-item :key="ii.id" @click="messageAction(i, ii)" v-if="(i.attachment && i.attachment.type === 1) || (i.attachment && i.attachment.type === 2 && ii.id === 2) || !i.attachment">{{ii.description}}</b-dropdown-item>
                            </template>
                        </b-dropdown>
                        <div v-if="i.attachment && i.attachment.type === 1" class="message-attachment-answer">
                            <div class="answer">
                                <span class="attach-author">{{i.attachment.data.authorName}}</span>
                                <span class="attach-text">{{i.attachment.data.text}}</span>
                            </div>
                        </div>
                        <div v-if="i.attachment && i.attachment.type === 2" class="message-attachment-image">
                            <div class="image">
                                <img :src="i.attachment.data.path || '../../../assets/img/7VE.gif'" alt="img" @click="showModal1(i)">
                            </div>
                        </div>
                        <span v-html="i.message" class="actual-message-text"> </span>
                    </div>
                    <div class="message-timestamp" :class="{'show-timestamp': (q.messages[mIndex +1] && (q.messages[mIndex +1].authorId !== i.authorId)) || (!q.messages[mIndex +1])}">
	                    {{i.timestamp_formatted1}}
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-bottom-wrapper" id="scroll-bottom-wrapper" @click="scrollBottom()">
            <img src="../../../assets/img/scroll-down.svg" alt="scroll" class="scroll-bottom" id="scroll-bottom-btn">
            <span class="unread-count" v-if="getUnreadMessages > 0">{{this.getUnreadMessages}}</span>
        </div>
        <imageSlider ref="imageSlider" :img-index="0" :img-array="imageForModal" @close="closeModal1" :is-open="isModalVisible" :has-comments-section="false" :is-slider="false" :two-level-modal="false"></imageSlider>
    </div>
</template>

<script>

    import avatar from "../../_shared/Avatar";
    import imageSlider from "../../_shared/ImageSlider";
    import {toBase64} from "../../../_helpers/aws-handler";
	import moment from "moment";

    export default {
        name: 'messages-window',
        props: {
            chatMessagesSorted: {
                type: Array
            },
            getUnreadMessages: {},
            loadPreviousMessages: {
                type: Function,
                required: true
            },
            scrollDown: {},
            activeChat: {}
        },
        components: {
            avatar,
            imageSlider
        },
        data(){
            return {
                el: null,
                qwe: null,
                friend_actions: [
                    {
                        id: 3,
                        description: 'Ответить'
                    }
                ],
                my_actions: [
                    {
                        id: 1,
                        description: 'Редактировать'
                    },
                    {
                        id: 2,
                        description: 'Удалить'
                    }
                ],
                scrollBot: true,
                unreadMes: 0,
                init: true,
                interval: null,
                isModalVisible: false,
                imageForModal: []
            }
        },
        computed: {
            getFireUserData() {
                return this.$store.state.firebase.firebaseUserData
            }
        },
        mounted() {
            window.addEventListener("resize", function() {
                let el = document.getElementById('chat-window-wrapper');
                let screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
                console.log(el);
                if (el && screenWidth <= 768) {
                    el.style['height'] = window.innerHeight-230 + 'px';
                }
            });
			this.scrollBot = true;
            this.interval = setInterval(() => {
                this.qwe = document.getElementById('chat-window-wrapper').scrollHeight;
            }, 100);
            this.el = document.getElementById('chat-window-wrapper');
            if (this.el) {
                this.el.isBottom = false;
                this.el.scrolledFromBottom = 0;

                // Preserve the initial scroll height to know when the user gets to the top of the element
                this.el.scsr = this.el.scrollHeight;

                this.el.addEventListener('scroll', (element) => {
                    // // console.log(this.el.clientHeight);
                    // // console.log(this.el.scrollHeight);
                    let scr = document.getElementById('scroll-bottom-wrapper');
                    this.el.scrolledFromBottom = this.el.scrollHeight - this.el.scrollTop - this.el.clientHeight;
                    this.el.ss = this.el.scrollHeight - this.el.scrollTop - this.el.clientHeight;
                    // // // console.log(this.el.ss);
                    // // // console.log(this.el.scrolledFromBottom);
                    if (this.el.ss && (this.el.ss - (this.el.scrollHeight - this.el.scrollTop - this.el.clientHeight)) > 150 ) {
                        // // // console.log("IF");
                        this.el.ss = this.el.scrollHeight - this.el.scrollTop - this.el.clientHeight;
                        this.el.scrollTo({
                            top: this.el.scrollHeight, // could be negative value
                            left: 0,
                            behavior: 'smooth'
                        });
                    }

                    if (this.el.scrollHeight - this.el.scrollTop - this.el.clientHeight <= 150) {
                        // // // console.log("IF 2");
                        // Hide 'scroll button' icon because it's bottom of the chat window
                        this.el.isBottom = true;
                        scr.style.opacity = 0;
                    } else {
                        // // // console.log("ELSE 2");
                        // Hide/show 'scroll button' icon
                        this.el.isBottom = false;
                        if (this.el.scrolledFromBottom > 100) {
                            scr.style.opacity = 1;
                        } else {
                            scr.style.opacity = 0;
                        }

                        if (!this.el.scrollTop) {
                            // // // console.log("IF IF 3");
                            if (this.el.scsr !== this.el.scrollHeight) {
                                this.el.scsr = this.el.scrollHeight;
                            }

                            // We trigger parent 'Load prev messages' func passed as prop to have a promise result and handle scroll position properly
                            this.scrollBot = false;
                            this.loadPreviousMessages().then(() => {
                                // // console.log('PROMISE');
                                // // // console.log("RESOLVE");
                                /*this.el.scrollTo({
                                    top: this.el.scrollHeight - this.el.scsr, // could be negative value
                                    left: 0,
                                    behavior: 'smooth'
                                });*/
                                this.el.scrollTop = this.el.scrollHeight - this.el.scsr;
                            })
                        }
                    }
                });
            }
        },
        beforeDestroy() {
            clearInterval(this.interval);
        },
        created() {},
        watch: {
            chatMessagesSorted: function(val) {
                if (val.length === 1) {
                    if (val[0].messages[val[0].messages.length - 1].authorId !== this.getFireUserData.id) {
                        if (this.init) {
                            this.scrollBot = true;
                            this.init = false;
                        } else {}
                    }
                }
            },
            activeChat: function() {
                this.scrollBot = true;
            },
            qwe: function(val) {
                if (this.scrollBot) {
                    this.el.scrollTop = this.el.scrollHeight;
                }
            }
        },
        methods: {
            handleMobileHeight() {
                let el = document.getElementById('chat-window-wrapper');
                let screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
                console.log(el);
                if (screenWidth <= 768) {
                    el.style['height'] = window.innerHeight-230 + 'px';
                }
            },
            scrollBottom(){
                this.el.scrollTo({
                    top: this.el.scrollHeight, // could be negative value
                    left: 0,
                    behavior: 'smooth'
                })
            },
            messageAction(data, action) {
                this.$emit('messageAction', data, action);
            },
            showModal1(data) {
                console.log(data);
                let blob = new Blob([data.attachment.data.path['Body']], {type: data.attachment.data.path['ContentType']});
                this.imageForModal[0] = {
                    photos: [{
                        photo_parsed: data.attachment.data.path
                    }]
                };
                this.isModalVisible = true;
                /*setTimeout(() => {
                    if (this.$refs['imageSlider']) {
                        this.$refs['imageSlider'].open(0);
                    }
                }, 50);*/
                if (this.$refs['imageSlider']) {
                    this.$refs['imageSlider'].open(0);
                }
                console.log(this.$refs['imageSlider']);
                console.log(this.isModalVisible);
                toBase64(blob).then((res) => {
                    this.imageForModal[0] = {
                        photos: [{
                            photo_parsed: data.attachment.data.path
                        }]
                    };
                    console.log(this.imageForModal);
                });

            },
            closeModal1() {
                console.log('CLOSED');
                this.isModalVisible = false;
                this.imageForModal = [];
            },
        }
    }
</script>

<style lang="less" scoped>
    #chat-window-wrapper {
        padding: 25px;
        height: calc(100vh - 215px);
        // overflow-x: auto;
        overflow-y: scroll;
        &::-webkit-scrollbar{
            height: 4px;
            width: 4px;
            background: gray;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb{
            background: lightgrey;
            border-radius: 10px;
        }
        .chat-window {
            .day-wrapper {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-wrap: wrap;
                .day-breakpoint {
                    width: 100%;
                    position: relative;
                    margin-bottom: 9px;
                    span {
                        color: rgba(112, 124, 151, 0.7);
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        &:after {
                            content: '';
                            flex: 1;
                            border-bottom: 1px solid rgba(112, 124, 151, 0.15);
                            margin-left: 5px;
                        }
                        &:before {
                            content: '';
                            flex: 1;
                            border-bottom: 1px solid rgba(112, 124, 151, 0.15);
                            margin-right: 5px;
                        }
                    }
                }
                .message {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    // margin-bottom: 8px;
                    .message-text {
                        color: #707C97;
                        font-size: 13px;
                        font-weight: 400;
                        border-radius: 11px;
                        padding: 9px 15px;
                        position: relative;
                        text-align: left;
                        max-width: 75%;
                        white-space: pre-line;
	                    .more-actions-hor {
		                    cursor: pointer;
	                    }
                        .actual-message-text {
                            word-break: break-word;
                            white-space: pre-line;
                        }
                        .message-attachment-answer {
                            position: relative;
                            padding-left: 10px;
                            margin-bottom: 4px;
                            max-width: 100px;
                            &:before {
                                content: '';
                                width: 2px;
                                height: 100%;
                                background-color: #014f91;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                            &>div {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-wrap: wrap;
                                span {
                                    width: 100%;
                                    color: #707C97;
                                    display: block;
                                    max-width: 100px;
                                    white-space: nowrap;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis;
                                    &.attach-author {
                                        color: #2c3e50;
                                    }
                                }
                            }
                        }
                        &:hover + .message-timestamp {
                            opacity: 1;
                        }
                        .message-attachment-image {
                            width: 100%;
                            max-width: 300px;
                            &>div {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-wrap: wrap;
	                            width: 200px;
	                            height: 200px;
	                            overflow: hidden;
                                img {
                                    width: 100%;
                                    color: #707C97;
                                    display: block;
                                    max-width: 300px;
                                    white-space: nowrap;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis;
                                    &.attach-author {
                                        color: #2c3e50;
                                    }
                                }
                            }
                        }
                    }
                    .message-timestamp {
                        color: rgba(112, 124, 151, 0.7);
                        font-size: 12px;
                        width: 100%;
                        text-align: right;
                        opacity: 0;
                        &.show-timestamp {
                            opacity: 1;
                            margin-bottom: 15px;
                        }
                    }
                    &.my-message {
                        justify-content: flex-end;
                        padding-right: 24px;
                        padding-left: 24px;
                        .author-name {
                            display: none;
                        }
                        .message-text {
                            position: relative;
                            border: 1px solid rgba(112, 124, 151, 0.25);
                            background: #FFFFFF;
                            &:after {
                                position: absolute;
                                content: "";
                                right: -24px;
                                top: 50%;
                                margin-top: -8px;
                                width: 16px;
                                height: 16px;
                            }
                            &.read {
                                &:after {
                                    content: url("../../../assets/img/double-checkmark.svg");
                                }
                            }
                            &.unread {
                                &:after {
                                    content: url("../../../assets/img/checkmark.svg");
                                }
                            }
                        }
                    }
                    &.not-my-message {
                        justify-content: flex-start;
                        padding-right: 24px;
                        .author-name {
                            display: none;
                        }
                        .author-avatar {
                            /*width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            overflow: hidden;*/
                            width: 25px;
                            height: 25px;
                            margin-right: 15px;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .message-text {
                            color: #333333;
                            background: #e3e6eb;
                            &.unread {
                                background: #c9ddff;
                            }
                        }
                        .message-timestamp {
                            text-align: left;
                            padding-left: 40px;
                        }
                    }
                    &.service-message {
                        justify-content: center;
                        margin-bottom: 9px;
                        .author-name {
                            font-size: 13px;
                            color: rgba(112, 124, 151, 0.7);
                            margin-right: 10px;
                        }
                        .author-avatar {
                            display: none;
                        }
                        .message-text {
                            font-size: 13px;
                            color: rgba(112, 124, 151, 0.7);
                            padding: 0;
                        }
                        .message-timestamp {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .scroll-bottom-wrapper {
        position: absolute;
        bottom: 20px;
        right: 20px;
        animation: new-message 1s linear infinite alternate;
        transition: all 1.5s;
        cursor: pointer;
        opacity: 0;
        width: 40px;
        height: 40px;
        img {
            width: 100%;
        }
        span {
            position: absolute;
            width: 18px;
            height: 18px;
            font-size: 10px;
            background-color: #d6585bde;
            color: white;
            top: 0;
            right: 0;
            border-radius: 50%;
            border: 1px solid #ffffff;
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #chat-window-wrapper {
            // height: calc(100vh - 222px);
            padding: 10px;
            .chat-window {
                .day-wrapper {
                    .message {
                        .message-text {
                            padding: 5px 9px;
                        }
                    }
                }
            }
        }
	    /*#chat-window-wrapper {
		    height: calc(100vh - 320px);
	    }*/
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {}

</style>
