<template>
	<b-modal ref="share-photos" size="md" id="share-photos" hide-footer :no-close-on-backdrop="true" title="Кому отправить эту фотографию?" @hidden="resetUsersModal">
		<search-filter :show-error="true" :placeholderText="'Найти пользователя среди друзей Up&Up'" :items="arr" @filtered="filteredUsersList" class="search-filter" :is-server-side="false"></search-filter>
		<div class="users-list-wrapper" :class="{'loading': usersList.loading}">
			<div class="users-list chat-users" v-if="!usersList.loading && usersList.users.length">
				<div class="divider">
					Ваши друзья Up&Up:
				</div>
				<div class="user" v-for="u in usersList.users" @click="selectUser(u)" :class="{'selected': u.selected}" v-if="u.filtered">
					
					<avatar :width="36" :height="36" :img="u.avatar_parsed"></avatar>
					<div class="name">{{u.name}}</div>
					<img v-if="u.selected" class="control-icons" src="../../../assets/img/check_new.svg" alt="delete">
				</div>
			</div>
		</div>
		
		<div class="modal-controls" v-if="!usersList.loading">
			<!--<button v-if="isSelected().length || selected_users.length" class="add-users" @click="submit()">
				<span v-if="isSelected().length">Поделиться</span>
			</button>-->
			<c_button v-if="isSelected().length || selected_users.length" :c_text="'Поделиться'" :c_loading="shareLoading" :c_class="'def-btn add-users'" @clickEvent="submit()"></c_button>
			<button class="add-users" @click="submit(true)">
				<span v-if="!isSelected().length || isSelected().length && isSelected().length !== arr.length">Добавить всех</span>
				<span v-else-if="isSelected().length > 0 && isSelected().length === arr.length">Убрать всех</span>
			</button>
		</div>
	</b-modal>
</template>

<script>
import {usersService} from "@/_api";
import {handleAWSImage} from "@/_helpers/aws-handler";
import searchFilter from '../SearchFilter';
import avatar from "../Avatar";
import C_button from "@/components/_shared/Button";

export default {
	name: 'SharePhotosModal',
	props: {
		singlePhotoShare: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			postData: null,
			usersList: {
				users: [],
				loading: false
			},
			selected_users: [],
			shareLoading: false,
		}
	},
	components: {C_button, avatar, searchFilter},
	computed: {
		arr() {
			return this.usersList.users
		},
		getFireUserData() {
			return this.$store.state.firebase.firebaseUserData
		},
	},
	methods: {
		showUsersModal(data) {
			this.postData = data;
			this.usersList = {
				users: [],
				loading: false
			};
			this.selected_users = [];
			this.shareLoading = false;
			this.getUserForShare();
			this.$refs['share-photos'].show();
			console.log(this.usersList);
		},
		resetUsersModal() {
			this.usersList = {
				users: [],
				loading: false
			};
			this.shareLoading = false;
			this.selected_users = [];
		},
		getUserForShare () {
			this.usersList.loading = true;
			usersService.getFriendsAPI({
				perPage: 1000,
				page: 1,
				query: ''
			}).then(res => {
				console.log(res);
				this.usersList.users = res['data'];
				console.log(this.usersList.users);
				this.usersList.users.forEach(obj => {
					obj['selected'] = false;
					this.selected_users.forEach(user => {
						if (obj.id === user.id) {
							obj['selected'] = true;
						}
					});
					obj['filtered'] = true;
					obj['avatar_parsed'] = null;
					handleAWSImage(obj.avatar_36).then((res) => {
						obj['avatar_parsed'] = res;
						this.$forceUpdate();
					});
				});
				console.log(this.usersList.users);
				console.log(this.selected_users);
				if (this.selected_users?.length) {
					this.selected_users.forEach((o) => {
						this.usersList.users.forEach(obj => {
							if (o.selected) {
								if (obj.id === o.id) {
									obj['selected'] = true;
								}
							}
						});
					})
				}
				this.usersList.loading = false;
			});
		},
		selectUser(data) {
			data.selected = !data.selected;
			this.$forceUpdate();
		},
		filteredUsersList(data) {
			this.searchStr = data.searchStr;
			if (data.searchStr.length) {
				data.arr.forEach(obj => {
					this.usersList.users.forEach(c_obj => {
						if (c_obj.id === obj.id) {
							c_obj['filtered'] = true;
						} else {
							c_obj['filtered'] = false;
						}
						this.$forceUpdate()
					});
				});
			} else {
				this.usersList.users.forEach(c_obj => {
					c_obj['filtered'] = true;
					this.$forceUpdate()
				});
			}
			
		},
		isSelected() {
			return this.arr.filter((obj) => {return obj.selected})
		},
		submit(select_all) {
			if (select_all) {
				if (this.isSelected().length === this.arr.length) {
					this.usersList.users.forEach(obj => {
						obj['selected'] = false;
						this.$forceUpdate();
					});
				} else {
					this.usersList.users.forEach(obj => {
						obj['selected'] = true;
						this.$forceUpdate();
					});
				}
			} else {
				this.selected_users = [...this.isSelected()];
				console.log(this.selected_users);
				console.log(this.postData);
				let count = this.selected_users.length * ((this.singlePhotoShare) ? 1 : this.postData.photos.length);
				console.log(count);
				this.shareLoading = true;
				for (let ii = 0; ii < this.selected_users.length; ii++) {
					usersService.getChatIdAPI({
						id: this.selected_users[ii].id
					}).then(response => {
						console.log(response);
						this.$firebaseChats.doc(response).get().then((querySnapshot) => {
							if (querySnapshot.exists) {
								if (this.singlePhotoShare) {
									this.firebaseShare(this.postData, querySnapshot).then(() => {
										count -= 1;
										console.log(count);
										if (count === 0) {
											console.log('REAAAAAAAAAAAAAAADY');
											this.shareLoading = false;
											this.$refs['share-photos'].hide();
										}
									})
								} else {
									for (let i = 0; i < this.postData.photos.length; i++) {
										this.firebaseShare(this.postData.photos[i], querySnapshot).then(() => {
											count -= 1;
											console.log(count);
											if (count === 0) {
												console.log('REAAAAAAAAAAAAAAADY');
												this.shareLoading = false;
												this.$refs['share-photos'].hide();
											}
										})
										/*var ref = this.$storage.ref();
										var pathRef = ref.child(`images/${this.moment().format('YYYY_MM_DD_hh_mm_ss')}_${this.postData.photos[i].id}`);
										pathRef.putString(this.postData.photos[i].photo_parsed.split(',')[1], 'base64', {contentType: 'image/jpg'}).then((snapshot) => {
											snapshot.ref.getDownloadURL().then((URL) => {
												let ref = this.$firebaseChats.doc(querySnapshot.id).collection('messages').doc();
												let refId = ref.id;
												let temp = {
													attachment: {
														type: 2,
														type_name: 'image',
														data: {
															authorId: this.getFireUserData.id,
															authorName: this.getFireUserData.name,
															text: null,
															path: URL
														}
													},
													authorId: this.getFireUserData.id,
													authorFull: this.getFireUserData,
													chatId: querySnapshot.id,
													id: refId,
													message: '',
													timestamp: this.$firebase.serverTimestamp,
													type: 0,
													editedAt: null,
													status: 0
												};
												this.$firebaseChats.doc(querySnapshot.id).collection('messages').doc(refId).set(temp).then(() => {
													this.$firebaseChats.doc(querySnapshot.id).get().then(c_obj => {
														this.$firebaseChats.doc(querySnapshot.id).update({
															updatedAt: this.$firebase.serverTimestamp,
															unreadCount: c_obj.data().unreadCount + 1,
															is_active: 1,
															lastMessage: {
																messageId: refId,
																text: 'фото',
																authorId: this.getFireUserData.id,
																authorName: this.getFireUserData.name,
																date: this.$firebase.serverTimestamp
															}
														}).then(() => {
															count -= 1;
															console.log(count);
															if (count === 0) {
																console.log('REAAAAAAAAAAAAAAADY');
																this.shareLoading = false;
																this.$refs['share-photos'].hide();
															}
														})
													})
												})
											})
										})*/
									}
								}
							}
						})
					})
				}
				// this.$refs['add-users'].hide();
			}
		},
		firebaseShare(photo, querySnapshot) {
			return new Promise((resolve, reject) => {
				var ref = this.$storage.ref();
				var pathRef = ref.child(`images/${this.moment().format('YYYY_MM_DD_hh_mm_ss')}_${photo.id}`);
				pathRef.putString(photo.photo_parsed.split(',')[1], 'base64', {contentType: 'image/jpg'}).then((snapshot) => {
					snapshot.ref.getDownloadURL().then((URL) => {
						let ref = this.$firebaseChats.doc(querySnapshot.id).collection('messages').doc();
						let refId = ref.id;
						let temp = {
							attachment: {
								type: 2,
								type_name: 'image',
								data: {
									authorId: this.getFireUserData.id,
									authorName: this.getFireUserData.name,
									text: null,
									path: URL
								}
							},
							authorId: this.getFireUserData.id,
							authorFull: this.getFireUserData,
							chatId: querySnapshot.id,
							id: refId,
							message: '',
							timestamp: this.$firebase.serverTimestamp,
							type: 0,
							editedAt: null,
							status: 0
						};
						this.$firebaseChats.doc(querySnapshot.id).collection('messages').doc(refId).set(temp).then(() => {
							this.$firebaseChats.doc(querySnapshot.id).get().then(c_obj => {
								this.$firebaseChats.doc(querySnapshot.id).update({
									updatedAt: this.$firebase.serverTimestamp,
									unreadCount: c_obj.data().unreadCount + 1,
									is_active: 1,
									lastMessage: {
										messageId: refId,
										text: 'фото',
										authorId: this.getFireUserData.id,
										authorName: this.getFireUserData.name,
										date: this.$firebase.serverTimestamp
									}
								}).then(() => {
									resolve();
									/*count -= 1;
									console.log(count);
									if (count === 0) {
										console.log('REAAAAAAAAAAAAAAADY');
										this.shareLoading = false;
										this.$refs['share-photos'].hide();
									}*/
								})
							})
						})
					})
				})
			})
		}
	}
}
</script>

<style lang="less" scoped>
	#share-photos {
		.modal-body {
			background-color: red;
		}
	}
</style>
