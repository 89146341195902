import Vue from "vue";
import VueRouter from "vue-router";
import Messages from './views/chat/Chat.vue'
import Home from './views/home/Home.vue'

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		meta: {
			requiresAuth: true,
			hasSidebar: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: Home
	},
	{
		path: "/login",
		name: "login",
		meta: {
			requiresAuth: false,
			hasSidebar: false,
			preventUnauth: true
		},
		component: () => import(/* webpackChunkName: "login" */ "./views/auth/login/Login.vue")
	},
	{
		path: "/about",
		name: "about",
		meta: {
			requiresAuth: false,
			hasSidebar: false
		},
		component: () => import(/* webpackChunkName: "about" */ "./views/_static/about/About.vue")
	},
	{
		path: "/privacy-policy",
		name: "privacy-policy",
		component: () => import(/* webpackChunkName: "privacy-policy" */ "./views/_static/privacy-policy/PrivacyPolicy"),
		meta: {
			requiresAuth: false,
			hasSidebar: false
		},
	},
	{
		path: "/user-agreement",
		name: "user-agreement",
		component: () => import(/* webpackChunkName: "user-agreement" */ "./views/_static/user-agreement/UserAgreement"),
		meta: {
			requiresAuth: false,
			hasSidebar: false
		},
	},
	{
		path: "/delete-instruction",
		name: "delete-instruction",
		component: () => import(/* webpackChunkName: "delete-instruction" */ "./views/_static/deletion-instruction/DeleteInstruction"),
		meta: {
			requiresAuth: false,
			hasSidebar: false
		},
	},
	{
		path: "/registration",
		name: "registration",
		redirect: '/registration/step-one',
		meta: {
			requiresAuth: false,
			hasSidebar: false,
			preventUnauth: true
		},
		component: () => import(/* webpackChunkName: "registration" */ "./views/auth/registration/Registration.vue"),
		children: [
			{
				path: 'step-one',
				name: 'registration-step-one',
				meta: {
					requiresAuth: false,
					hasSidebar: false,
					preventUnauth: true
				},
				component: () => import(/* webpackChunkName: "registration" */ './views/auth/registration/step-one/StepOne.vue')
			},
			{
				path: 'step-two',
				name: 'registration-step-two',
				meta: {
					requiresAuth: false,
					hasSidebar: false,
					preventUnauth: true
				},
				component: () => import(/* webpackChunkName: "registration" */ './views/auth/registration/step-two/StepTwo.vue')
			},
			{
				path: 'step-three',
				name: 'registration-step-three',
				meta: {
					requiresAuth: false,
					hasSidebar: false,
					preventUnauth: true
				},
				component: () => import(/* webpackChunkName: "registration" */ './views/auth/registration/step-three/StepThree.vue')
			},
			/*{
				path: 'password-setup',
				name: 'registration-password-setup',
				component: () => import('./components/auth/registration/PasswordSetup.vue')
			},*/
			{
				path: 'email-confirmation',
				name: 'registration-email-conf',
				meta: {
					requiresAuth: false,
					hasSidebar: false,
					preventUnauth: true
				},
				component: () => import(/* webpackChunkName: "registration" */ './views/auth/registration/email-confirmation/EmailConfirmation.vue')
			},
		]
	},
	{
		path: "/password-setup",
		name: "registration-password-setup",
		meta: {
			requiresAuth: false,
			hasSidebar: false,
			preventUnauth: true
		},
		component: () => import(/* webpackChunkName: "registration-password-setup" */ "./views/auth/registration/password-setup/PasswordSetup.vue")
	},
	
	{
		path: '/messages',
		name: 'messages',
		meta: {
			requiresAuth: true,
			hasSidebar: true
		},
		component: Messages
	},
	{
		path: '/notifications',
		name: 'notifications',
		meta: {
			requiresAuth: true,
			hasSidebar: true
		},
		component: () => import(/* webpackChunkName: "about" */ "./views/notifications/Notifications.vue")
	},
	{
		path: '/me',
		name: 'my-profile',
		meta: {
			requiresAuth: true,
			hasSidebar: true
		},
		component: () => import(/* webpackChunkName: "my-profile" */ "./views/users/my-profile/MyProfile.vue")
	},
	{
		path: '/profile/:id',
		name: 'user-profile',
		meta: {
			requiresAuth: true,
			hasSidebar: true
		},
		component: () => import(/* webpackChunkName: "user-profile" */ "./views/users/user-profile/UserProfile.vue")
	},
	{
		path: '*',
		name: 'pageNotFound',
		meta: {
			requiresAuth: false,
			hasSidebar: false
		},
		component: () => import(/* webpackChunkName: "404" */ "./views/_static/not-found/NotFound.vue")
	},
	{
		path: '/users',
		name: 'users',
		meta: {
			requiresAuth: true,
			hasSidebar: true
		},
		component: () => import(/* webpackChunkName: "users" */ './views/users/Users.vue'),
		children: [
			{
				path: 'my-circle',
				name: 'my-circle',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "users" */ './views/users/inner-circle/InnerCircle.vue')
			},
			{
				path: 'my-circle-2',
				name: 'my-circle-2',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "users" */ './views/users/inner-circle/InnerCircle.vue')
			},
			{
				path: 'social-networks',
				name: 'social-networks',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "users" */ './views/users/social-networks/SocialNetworks.vue')
			},
			{
				path: 'similar-people',
				name: 'similar-people',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "users" */ './views/users/similar-people/SimilarPeople.vue')
			},
			{
				path: 'search',
				name: 'search-people',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "users" */ './views/users/people-search/PeopleSearch.vue')
			},
			{
				path: 'friends-answers',
				name: 'friends-answers',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "users" */ './views/users/friends-activity/FriendsActivity.vue')
			},
		]
	},
	{
		path: '/questions',
		name: 'questions',
		meta: {
			requiresAuth: true,
			hasSidebar: true
		},
		component: () => import(/* webpackChunkName: "questions" */ './views/questions/Questions.vue'),
		children: [
			{
				path: 'main-questions',
				name: 'main-questions',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "questions" */ './views/questions/main-questions/MainQuestions.vue')
			},
			{
				path: 'personal-questions',
				name: 'personal-questions',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "questions" */ './views/questions/personal-questions/PersonalQuestions.vue')
			},
			{
				path: 'questions-of-the-day',
				name: 'questions-of-the-day',
				meta: {
					requiresAuth: true,
					hasSidebar: true
				},
				component: () => import(/* webpackChunkName: "questions" */ './views/questions/questions-of-the-day/QuestionsOfTheDay.vue')
			}
		]
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		// console.log(to);
		// console.log(from);
		// console.log(savedPosition);
		if (to.hash) {
			// console.log('AAAAAA');
			// return { selector: to.hash }
		} else if (savedPosition) {
			// console.log(savedPosition);
			return savedPosition;
		} else {
			return { x: 0, y: 0 }
		}
	}
});

router.beforeEach((to, from, next) => {
	if (!to.meta.hasSidebar) {
		document.body.classList.add('no-left-sidebar');
	} else {
		document.body.classList.remove('no-left-sidebar');
	}
	if (to.matched.some(record => record.meta.requiresAuth) && localStorage.getItem('user') == null) {
		next({path: '/login', replace: true});
		// document.body.classList.add('no-left-sidebar');
	} else if (to.matched.some(record => record.meta.requiresAuth) && localStorage.getItem('user') != null) {
		next()
	} else if (!to.matched.some(record => record.meta.requiresAuth) && localStorage.getItem('user') == null) {
		next()
	} else if (!to.matched.some(record => record.meta.requiresAuth) && localStorage.getItem('user') != null && to.matched.some(record => record.meta.preventUnauth)) {
		next({path: '/', replace: true})
	} else if (!to.matched.some(record => record.meta.requiresAuth) && localStorage.getItem('user') != null && !to.matched.some(record => record.meta.preventUnauth)) {
		next()
	}
});

export default router
