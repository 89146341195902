<template>
    <div class="track-container">
      <span class="range-value min">{{ 'с ' + minValue}} </span> <span class="range-value max">{{ 'по ' + maxValue }}</span>
      <div class="track" ref="_vpcTrack"></div>
      <div class="track-highlight" ref="trackHighlight"></div>
      <div class="track-btn track1" ref="track1"></div>
      <div class="track-btn track2" ref="track2"></div>
    </div>
</template>

<script>
    export default {
        name: 'rangeSlider',
        props: {
            min: Number,
            max: Number,
            defaultSelected: Object
        },
        data() {
            return {
                minValue: 0,
                maxValue: 100,
                step: 1,
                totalSteps: 0,
                percentPerStep: 1,
                trackWidth: null,
                isDragging: false,
                pos: {
                    curTrack: null
                }
            }
        },
        methods: {
            passData(minV, maxV) {
                this.$emit('updateRange', [minV, maxV]);
            },
            moveTrack(track, ev){
                let percentInPx = this.getPercentInPx();
                let trackX = Math.round(this.$refs._vpcTrack.getBoundingClientRect().left);
                let clientX = ev.clientX;
                let moveDiff = clientX-trackX;

                let moveInPct = moveDiff / percentInPx;

                if(moveInPct<1 || moveInPct>100) return;
                let value = ( Math.round(moveInPct / this.percentPerStep) * this.step ) + this.min;
                if(track==='track1'){
                    if(value >= (this.maxValue - this.step)) return;
                    this.minValue = value;
                }

                if(track==='track2'){
                    if(value <= (this.minValue + this.step)) return;
                    this.maxValue = value;
                }
                
                this.$refs[track].style.left = moveInPct + '%';
                this.setTrackHightlight();

            },
            mousedown(ev, track) {

                if(this.isDragging) return;
                this.isDragging = true;
                this.pos.curTrack = track;
            },

            touchstart(ev, track) {
                this.mousedown(ev, track);
            },

            mouseup(ev, track) {
                if(!this.isDragging) return;
                this.isDragging = false;
            },

            touchend(ev, track) {
                this.mouseup(ev, track);
            },

            mousemove(ev, track) {
                if(!this.isDragging) return;
                this.moveTrack(track, ev);
            },

            touchmove(ev, track) {
                this.mousemove(ev.changedTouches[0], track);
            },

            valueToPercent(value) {
                return ((value - this.min) / this.step) * this.percentPerStep;
            },

            setTrackHightlight() {
                this.$refs.trackHighlight.style.left = this.valueToPercent(this.minValue) + '%';
                this.$refs.trackHighlight.style.width = (this.valueToPercent(this.maxValue) - this.valueToPercent(this.minValue)) + '%';

                this.passData(this.minValue, this.maxValue);
            },

            getPercentInPx() {
                let trackWidth = this.$refs._vpcTrack.offsetWidth;
                let oneStepInPx = trackWidth / this.totalSteps;
                // 1 percent in px
                let percentInPx = oneStepInPx / this.percentPerStep;
                
                return percentInPx;
            },

            setClickMove(ev){
                let track1Left = this.$refs.track1.getBoundingClientRect().left;
                let track2Left = this.$refs.track2.getBoundingClientRect().left;
                if (ev.clientX < track1Left){
                    this.moveTrack('track1', ev);
                } else if ((ev.clientX - track1Left) < (track2Left - ev.clientX) ){
                    this.moveTrack('track1', ev);
                } else {
                    this.moveTrack('track2', ev);
                }
            },
            clear() {
                this.minValue = 0;
                this.maxValue = 100;
                this.setTrackHightlight();
                document.querySelector('.track1').style.left = this.valueToPercent(this.minValue) + '%';
                document.querySelector('.track2').style.left = this.valueToPercent(this.maxValue) + '%';
            }
        },
        created() {
            if (this.defaultSelected) {
                this.minValue = this.defaultSelected.age_from;
                this.maxValue = this.defaultSelected.age_to;
            }
        },
        mounted() {
            // calc per step value
            this.totalSteps = (this.max - this.min) / this.step;

            // percent the track button to be moved on each step
            this.percentPerStep = 100 / this.totalSteps;

            // set track1 initilal
            document.querySelector('.track1').style.left = this.valueToPercent(this.minValue) + '%';
            // track2 initial position
            document.querySelector('.track2').style.left = this.valueToPercent(this.maxValue) + '%';
            // set initila track highlight
            this.setTrackHightlight();

            var self = this;

            ['mouseup', 'mousemove'].forEach( type => {
            document.body.addEventListener(type, (ev) => {
                // ev.preventDefault();
                if(self.isDragging && self.pos.curTrack){
                self[type](ev, self.pos.curTrack);
                }
            })
            });

            ['mousedown', 'mouseup', 'mousemove', 'touchstart', 'touchmove', 'touchend'].forEach( type => {
            document.querySelector('.track1').addEventListener(type, (ev) => {
                ev.stopPropagation();
                self[type](ev, 'track1');
            });

            document.querySelector('.track2').addEventListener(type, (ev) => {
                    ev.stopPropagation();
                    self[type](ev, 'track2');
                })
            });

            // on track clik
            // determine direction based on click proximity
            // determine percent to move based on track.clientX - click.clientX
            document.querySelector('.track').addEventListener('click', function(ev) {
                ev.stopPropagation();
                self.setClickMove(ev);
            });

            document.querySelector('.track-highlight').addEventListener('click', function(ev) {
                ev.stopPropagation();
                self.setClickMove(ev);
            });
        }
    }
</script>

<style lang="less" scoped>
.range-value{
    position: absolute;
    top: -20px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #8894AC;
  }
  .range-value.min{
    left: 0;
  }

  .range-value.max{
    right: 0;
  }
  .track-container{
    width: 100%;
    position: relative;
    cursor: pointer;
    height: 0.5rem;
  }

  .track,
  .track-highlight {
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    height: 2px;
    transform: translateY(-50%);
  }

  .track{
    background-color: #ddd;
  }

  .track-highlight{
    background-color: #AFD4AB;
    z-index: 2;
  }

  .track-btn{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    display: flex;
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    margin-left: -5px;
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    background-color: #AFD4AB;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
    transition: box-shadow .3s ease-out,background-color .3s ease,-webkit-transform .3s ease-out;
    transition: transform .3s ease-out,box-shadow .3s ease-out,background-color .3s ease;
    transition: transform .3s ease-out,box-shadow .3s ease-out,background-color .3s ease,-webkit-transform .3s ease-out;
  }
</style>
