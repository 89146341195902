export const WORK_AND_STUDY_OPTIONS = [
    {
        id: 1,
        label: 'Нет'
    }, {
        id: 2,
        label: 'Среднее'
    }, {
        id: 3,
        label: 'Незаконченное высшее'
    }, {
        id: 4,
        label: 'Высшее'
    }, {
        id: 5,
        label: 'Два и более высших'
    }
];
