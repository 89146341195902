import axios from 'axios';

export const usersService = {
    getDetailedSearchAPI,
    getFriendsAPI,
    getFriendRequestsAPI,
    getFriendDetailsAPI,
    sendFriendRequestAPI,
    acceptFriendRequestAPI,
    declineFriendRequestAPI,
    removeFromFriendsAPI,
    getChatIdAPI,
    getUsersData,
    getFriendsAnswersAPI,
    getDetailedSearchSocialsAPI,
    getInviteLinkAPI,
    getUserActivityAPI,
    getUserActivityCounters,
    getSimilarUsers,
    getUsersAnswers,
    searchChatsAPI,
    archiveUserAPI,
    uploadGalleryAPI,
    editGalleryPostAPI,
    getGalleryAPI,
    deleteGalleryPostAPI,
    deleteGalleryPhotoAPI,
    setUser18API,
    checkIfProfileFilledAPI
};

function checkIfProfileFilledAPI(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/user/is/complete/profile`)
            .then((res) => {
                return res.data;
            }).catch(err => {
                console.log(err);
            })
}

function uploadGalleryAPI(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/user/gallery`, data.files, {
            params: {
                description: data.description,
                visibility: data.visibility,
                is_comment_enabled: data.is_comment_enabled,
            },
            headers: { "Content-Type": "multipart/form-data" }
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
        })

}
function setUser18API(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/user/set/18`, data).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
        })

}
function editGalleryPostAPI(data) {
    return axios.put(
        `${process.env.VUE_APP_BASE_URL}v1/user/gallery`, {},{
            params: data
        }).then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}
function getGalleryAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/user/gallery`, {
            params: data
        }).then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}
function deleteGalleryPostAPI(data) {
    return axios.delete(
        `${process.env.VUE_APP_BASE_URL}v1/user/gallery`, {
            params: {
                post_id: data.post_id
            }
        }).then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}
function deleteGalleryPhotoAPI(data) {
    return axios.delete(
        `${process.env.VUE_APP_BASE_URL}v1/user/gallery`, {
            params: {
                photo_id: data.photo_id
            }
        }).then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}

function getDetailedSearchAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/search/users/by/params`,   {
            params: data
        }).then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}

function getDetailedSearchSocialsAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/invite/users`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}
function getInviteLinkAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/invite/dynamic/link`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}

function getChatIdAPI(data) {
    return axios.post(
        `${process.env.VUE_APP_BASE_URL}v1/chats`, {
            to_user_id: data.id
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}

function getFriendsAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/friends/my`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}
function getFriendRequestsAPI(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/friends/requests`, {
        params: data
    }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })

}
function getFriendDetailsAPI(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/friends/user/${data.id}`)
        .then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}
function sendFriendRequestAPI(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/friends/add/${data.id}`)
        .then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}
function acceptFriendRequestAPI(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/friends/accept/${data.id}`)
        .then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}
function declineFriendRequestAPI(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/friends/decline/${data.id}`)
        .then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}
function removeFromFriendsAPI(data) {
    return axios.delete(`${process.env.VUE_APP_BASE_URL}v1/friends/remove/${data.id}`)
        .then((res) => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
}

function getFriendsAnswersAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/friends/my/with/answers`,   {
            params: {
                perPage: data.perPage,
                page: data.page
            }
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}

function getUsersData(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/user/data`, {
        params: {
            user_id: data.user_id
        }
    }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}

function getUserActivityAPI(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/user/activity`, {
        params: {
            user_id: data.user_id,
            activity_id: data.activity_id,
            page: data.page,
            is_similar: data.is_similar
        }
    }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}

function getUserActivityCounters(data) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}v1/user/activity/counters`, {
        params: {
            user_id: data.user_id
        }
    }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}

function getSimilarUsers(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/matched/users`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}



function getUsersAnswers(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/friends/my/with/answers/feed`,   {
            params: {
                perPage: data.perPage,
                page: data.page,
                query: data.query
            }
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}


function searchChatsAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/search/chats`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}
function archiveUserAPI(data) {
    return axios.get(
        `${process.env.VUE_APP_BASE_URL}v1/chats/archive`,   {
            params: data
        }).then((res) => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}
