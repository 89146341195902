<template>
	<footer class="u-container-fluid" id="footer" v-if="currentRouteName !== 'messages' || (currentRouteName === 'messages' && !isMobile)">
		<div class="u-row">
			<div class="u-col u-col-12">
				<div class="helpful-links">
					<ul>
						<li>
							<router-link to="/about">О сервисе</router-link>
						</li>
						<li>
							<router-link to="/news">Новости</router-link>
						</li>
						<li>
							<router-link to="/blog">Blog</router-link>
						</li>
						<li>
							<router-link to="/help">Помощь</router-link>
						</li>
						<li>
							<router-link to="/privacy-policy">Политика конфиденциальности</router-link>
						</li>
						<li>
							<router-link to="/user-agreement">Пользовательское соглашение</router-link>
						</li>
						<li>
							<router-link to="/delete-instruction">Инструкция по удалению пользователя</router-link>
						</li>
					</ul>
				</div>
				<div class="socials">
					<p>Подпишитесь на нас</p>
					<img src="../../../assets/img/facebook.svg" alt="fb">
					<img src="../../../assets/img/vk.svg" alt="vk">
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
		data: function() {
			return {
				screenWidth: 1920
			}
		},
		computed: {
			currentRouteName() {
				return this.$route.name;
			},
			isMobile() {
				return this.screenWidth <= 768
			},
		},
		created() {
			this.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
			window.addEventListener("resize", this.screenWidthChange);
		},
		beforeDestroy() {
			if (this.chatListener) {
				this.chatListener();
			}
			if (this.prevChatListener) {
				this.prevChatListener();
			}
			window.removeEventListener("resize", this.screenWidthChange);
		},
		methods: {
			screenWidthChange(e) {
				this.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
			},
		}
	}
</script>

<style lang="less" scoped>
	#footer {
		height: 100px;
		.u-row {
			.u-col {
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;
				height: 100px;
				padding: 10px 0;
				.helpful-links {
					width: 100%;
					ul {
						list-style-type: none;
						margin: 0;
						padding: 0;
						li {
							display: inline-block;
							a {
								color: #333333e6;
								font-size: 12px;
								font-weight: 400;
								padding: 10px;
								margin: 10px;
							}
						}
					}
				}
				.socials {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: nowrap;
					p {
						color: #999999;
						font-size: 12px;
						font-weight: 400;
						padding: 0 10px;
						margin: 0;
					}
					img {
						// padding: 0 5px;
						margin: 0;
						cursor: pointer;
						width: 20px;
						height: 20px;
						&:first-of-type {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}

	// Large devices (desktops, less than 1200px)
	@media (max-width: 1200px) {}
	// Medium devices (tablets, less than 992px)
	@media (max-width: 991px) {
		#footer {
			height: 100%;
			.u-row {
				.u-col {
					height: 100%;
					padding-bottom: 20px;
				}
			}
		}
	}
	// Small devices (landscape phones, less than 768px)
	@media (max-width: 768px) {}
	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 576px) {}
</style>
