export function indexedDbHandler() {
    //prefixes of implementation that we want to test
    /*window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;*/
    //prefixes of window.IDB objects
    window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
    window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;

    if (!window.indexedDB) {
        window.alert("Your browser doesn't support a stable version of IndexedDB.")
    }

    let db;
    let request = window.indexedDB.open("user", 1);

    request.onerror = function(event) {
        console.log("error: ");
    };

    request.onsuccess = function(event) {
        window.db = request.result;
    };

    request.onupgradeneeded = function(event) {
        let db = event.target.result;
        db.createObjectStore("avatar", {keyPath: "id", autoIncrement: false});
    }
}
