<template>
    <div id="alert">
        <div :class="`global-alert alert ${i.type} ${i.fading}`" v-for="(i, index) in alert" :key="index" @click="clear(i, index)">
            <img :src="require(`../../../assets/img/${i.icon}`)" alt="alert-icon" class="alert-icon">
            <div class="alert-content">
                <p>{{i.message}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'alert',
        data() {
            return {
                count: 0
            }
        },
        computed: {
            alert() {
                return this.$store.state.alert
            }
        },
        methods: {
            clear(data, index) {
                if (data) {
                    data['fading'] = 'fading';
                    this.$forceUpdate();
                    setTimeout(() => {
                        this.$store.dispatch('alert/clear', index);
                    }, 500)
                }
            }
        },
        created() {},
        mounted() {},
        watch: {
            alert: function (res) {
                res.forEach((t, index) => {
                    switch (t.type) {
                        case 'alert-success':
                            t.icon = 'success-n.svg';
                            break;
                        case 'alert-danger':
                            t.icon = 'error-n.svg';
                            break;
                        case 'alert-warning':
                            t.icon = 'warning-n.svg';
                            break;
                    }
                    setTimeout(() => {
                        t.fading = 'show';
                        this.$forceUpdate();
                    }, 10);
                });
                if (res.length && (this.count === 0 || this.count < res.length)) {
                    setTimeout(() => {
                        this.clear(res[0], 0);
                    }, 2500);
                }
                this.count = res.length;
            }
        }
    }
</script>

<style scoped lang="less">
    #alert {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 999999999;
        max-width: 300px;
        min-width: 300px;
        word-break: break-word;
        .alert {
            background: #ffffff;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            padding: 0.75rem 1.8rem 0.75rem 1.8rem;
            border-radius: 6px;
            box-shadow: 0 0 8px 1px #cecece;
            max-width: 300px;
            min-width: 300px;
            cursor: pointer;
            opacity: 0;
            transition: opacity 250ms;
            &:hover {
                animation: glow 1s infinite;
            }
            &.alert-success {
                &:before {
                    background-color: #4caf50;
                }
            }
            &.alert-danger {
                &:before {
                    background-color: #f44336;
                }
            }
            &.alert-warning {
                &:before {
                    background-color: #f0a92e;
                }
            }
            &:before {
                content: '';
                width: 5px;
                height: 80%;
                top: 50%;
                left: 10px;
                position: absolute;
                border-radius: 10px;
                transform: translate(0, -50%);
            }
            &:after {
                content: '\2715';
                position: absolute;
                right: 10px;
                text-transform: inherit;
                top: 50%;
                color: #999999;
                transform: translate(0, -50%);
            }
            img {
                width: 20px;
                margin-right: 15px;
                height: 20px;
                overflow: hidden;
                min-width: 20px;
            }
            .alert-content {
                h5 {
                    font-size: 15px;
                    font-weight: 500;
                    color: #666;
                    text-align: left;
                    margin: 0;
                }
                p {
                    font-size: 13px;
                    font-weight: 400;
                    color: #666;
                    text-align: left;
                    margin: 0;
                    line-height: 13px;
                    word-break: break-word;
                }
            }
            &.show {
                opacity: 1;
            }
            &.fading {
                opacity: 0;
            }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #alert {
            right: 50%;
            margin-right: -150px;
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {

    }

    @keyframes glow {
        from {
            box-shadow: 0 0 8px 1px #cecece;
        }
        to {
            box-shadow: 0 0 8px 1px #dcdcdc;
        }
    }
</style>
