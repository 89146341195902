import S3 from 'aws-sdk/clients/s3';

export function handleAWSImage(data) {
    return new Promise((resolve, reject) => {
        let s3 = new S3({
            region: process.env.VUE_APP_AWS_REGION,
            signatureVersion: process.env.VUE_APP_AWS_SIGNATURE_VERSION,
            accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY
        });

        if (data) {
            let params = {
                Bucket: process.env.VUE_APP_AWS_BUCKET,
                Key: data.split("amazonaws.com/").pop()
            };

            s3.getObject(params, function (err, res) {
                let blob = new Blob([res['Body']], {type: res['ContentType']});
                toBase64(blob).then(response => {
                    resolve(response);
                });
            });
        } else {
            resolve(null)
        }


    })
}
export function toBase64(file) {
    return new Promise ((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
    });
}
