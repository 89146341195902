export const FAMILY_STATUS_OPTIONS = [
    {
        id: 1,
        label: 'В браке'
    }, {
        id: 2,
        label: 'Не в браке'
    }, {
        id: 3,
        label: 'Помолвлен(а)'
    }, {
        id: 4,
        label: 'В гражданском браке'
    }, {
        id: 5,
        label: 'Влюблен(а)'
    }, {
        id: 6,
        label: 'В активном поиске'
    }
];
