export default  {
    bind(el, binding, vnode) {
        el.initLoad = true;
        el.scrolledFromBottom = 0;
        el.scr = el.scrollHeight - el.scrollTop - el.clientHeight;
        el.loadingMoreItems = true;
        el.addEventListener('scroll', () => {
            // console.log('scroll');
            // console.log(el.scr);
            // console.log(el.scrolledFromBottom);
            el.scr = el.scrollHeight - el.scrollTop - el.clientHeight;
            el.scrolledFromBottom = el.scrollHeight - el.scrollTop - el.clientHeight;
            if (el.scr === 0) {
                el.isBottom = true;
                if (el.loadingMoreItems) {
                    vnode.context.loadMore();
                    el.loadingMoreItems = false;
                    // console.log('BOTTOM1');
                    setTimeout(() => {
                        el.loadingMoreItems = true;
                    }, 150);
                    // console.log(el.loadingMoreItems);
                }

                // loadingMoreItems = true;
                /*if (el.isBottom) {
                    el.isBottom = false;
                    console.log('BOTTOM2');
                    vnode.context.loadMore();
                }*/
            }
        }, false);
    },
    updated(el, binding, vnode) {},
    componentUpdated(el, binding, vnode, oldVnode) {
        if (el.initLoad === true) {
            el.initLoad = false;
        } else {
            if (el.isBottom) {
                /*el.scrollTo({
                    top: el.scrollHeight, // could be negative value
                    left: 0,
                    behavior: 'smooth'
                });*/
            } else {}
        }
    }
};
