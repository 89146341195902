<template>
    <!--:style="{'padding-bottom': hasMargin+'px'}"-->
    <div id="imagePost" :class="{'has-margin': hasMargin}">
        <div class="post-item-wrapper">
            <div class="post-item" :class="{ visible: item.show_item }" @click="showItemBlock(item)">
                <div class="post-header" v-show="item.show_item">
                    <div class="post-header-title">
                        <avatar v-if="showAvatars" class="avatar-wrapper" :width="35" :height="35" :img="item.author.avatar_parsed" :is-image="false" @clickEvent="goToProfile(item.comments && item.comments.length ? item.comments[0] : item)"></avatar>
                        {{blockTitle}}
                    </div>
                </div>
                <div class="post-body-wrapper" v-on="(item.show_item) ? {click: () => {showItemBlock(item); showModal('main', item)}} : {click: () => showItemBlock(item)}">
                    <div :class="`img-wrapper item_grid_${item.photos.length} ${item.photos.length > 4 ? 'item_grid_4' : ''}`">
                        <div class="image" v-for="(ii, index) in item.photos" v-if="index <= 3">
                            <img draggable="false" :src="ii.photo_parsed /*|| require('../../assets/img/300x300_no_image_placeholder.svg')*/" alt="">
                            <loading class="x2 absolute centered" v-if="!ii.photo_parsed"></loading>
                        </div>
                        <!--<img draggable="false" :src="ii.photo_parsed /*|| require('../../assets/img/300x300_no_image_placeholder.svg')*/" alt="" v-for="ii in item.photos">
                        <loading></loading>-->
                        <span class="more-indicator" v-if="item.photos.length > 4">+{{item.photos.length - 4}}</span>
                    </div>
                    <div class="img-description" v-if="item.description">
                        {{item.description}}
                    </div>
                </div>
                <div class="post-footer" v-show="item.show_item">
                    <div class="post-counters">
                        <div class="post-footer-comments" @click="showModal('comments', item)">
                            <span class="icon"></span>
                            <span class="all-count">{{item.comments_count}}</span>
                            <span class="inner-circle-count">{{item.friend_comments_count}}</span>
                        </div>
                    </div>
                </div>
	            
	            <div class="post-comment" v-if="item.comments && item.comments.length && isFeed">
		            <div class="comment">
			            <div class="main-comment-data">
				            <div class="avatar-wrapper" @click="goToProfile(item.comments[0])">
					            <avatar :width="36" :height="36" :img="item.author.avatar_parsed"></avatar>
				            </div>
				            <div class="comment-data">
					            <div class="name">
		                            <span class="name-text">
		                                {{item.comments[0].author.name}}
		                            </span>
						            <!--<span class="comment-timestamp">{{comment.timestamp_parsed}}</span>-->
					            </div>
					            <person-progress width="64" height="4" :green="item.comments[0].similar_answers_percent" :red="item.comments[0].diff_answers_percent" :gray="item.comments[0].not_answered_percent"></person-progress>
					            <div class="message">
								    <span>
		                                {{item.comments[0].body}}
		                            </span>
					            </div>
				            </div>
			            </div>
		            </div>
		            <div class="show-more-comments" @click="showModal('comments', item)">
			            Показать еще комментарии
		            </div>
	            </div>
            </div>
        </div>
        <question-details-modal ref="question-details-modal" :topic-global-id="topicGlobalId" :modal-title="blockTitle" :is-question-feed="showAvatars" :is-feed="isFeed"></question-details-modal>
    </div>
</template>

<script>
    import questionDetailsModal from "../_shared/modals/_PostDetailedModal";
    import avatar from "../_shared/Avatar";
    import {handleAWSImage} from "../../_helpers/aws-handler";
    import Loading from "../_shared/Loading";
    import PersonProgress from "../_shared/UserProgress";

    export default {
        name: 'ImagePost',
        props: {
            item: Object, // main question object
            list: Array, // array of question objects (to enable slider)
            blockTitle: String, // UI title
            topicGlobalId: Number, // either 7/8/9 (main/personal/qotd)
            hasMargin: Number, // distance between elements vertically
            isFeed: Boolean, // if component is rendered in home component
            showAvatars: Boolean, // show avatars near answers and in the title,
        },
        data () {
            return {
                rm: Math.random(),
                loading: false
            }
        },
        components: {
	        PersonProgress,
            Loading,
            questionDetailsModal,
            avatar,
        },
        created() {
            this.$forceUpdate();
        },
        mounted(){
            console.log(this.item.object_id);
            // this.handleImages(this.item);
            this.$forceUpdate();
        },
        computed: {
            user () {
                return this.$store.state.authentication.user;
            }
        },
        methods: {
            showModal(scrollPosition, data) {
                this.$refs['question-details-modal'].showModal(scrollPosition, this.item, this.list);
            },
	
	        goToProfile(data) {
				console.log(data);
		        let currentUser = JSON.parse(localStorage.getItem('user'));
		        if (data.author.id === currentUser.id) {
			        this.$router.push({path: '/me'});
		        } else if (this.$route.name === 'user-profile') {
			        this.$refs['question-details-modal'].hide();
			        this.$router.push({path: `/profile/${data.author.id}`});
		        } else {
			        this.$router.push({path: `/profile/${data.author.id}`});
		        }
	        },
            showItemBlock(item) {
                item['show_item'] = true;
                this.$forceUpdate();
            },
            handleImages() {
                if (this.item.author) {
                    this.item.author['avatar_parsed'] = null;
                    handleAWSImage(this.item.author.avatar_36).then(res => {
                        this.item.author['avatar_parsed'] = res;
                        this.$forceUpdate();
                    })
                }
            }
        },
        watch: {
            item: function(res) {
                console.log(res);
                this.$forceUpdate();
            }
        }
    }
</script>

<style lang="less" scoped>
    #imagePost {
        max-width: 465px;
        &.has-margin {
            padding-bottom: 20px;
        }
        .post-item-wrapper {
            //padding-bottom: 18px;
            .post-item {
                box-shadow: 0 0 6px -4px black;
                border-radius: 1px;
                background: #FFFFFF;
                z-index: 0;
                &.visible {
                    z-index: 99;
                    // box-shadow: 0 0 11px -4px black;
                    position: relative;
                }
                .post-header {
                    padding: 10px 25px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    min-height: 60px;
                    max-height: 100%;
                    .post-header-title {
                        text-align: left;
                        font-size: 13px;
                        font-weight: 500;
                        color: #999999;
                        display: flex;
                        align-items: center;
                        .avatar-wrapper {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 13px;
	                        cursor: pointer;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .post-header-time {
                        font-size: 11px;
                        color: #999999;
                        font-weight: 400;
                        min-width: 60px;
                        div {
                            cursor: pointer;
                            &:first-child {
                                margin-right: 25px;
                            }
                            &.date {
                                margin: 0;
                            }
                            &.header-controls {
                                margin-right: 0;
                                .control-icons {
                                    /*width: 20px;*/
                                    .fa-edit, .fa-trash-alt {
                                        font-size: 18px;
                                        color: #999999;
                                    }
                                    &:first-child {
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                .post-body-wrapper {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 5px;
                    grid-auto-flow: row dense;
                    .img-wrapper {
                        max-height: 300px;
                        min-height: 300px;
                        overflow: hidden;
                        margin: 5px;
                        border-radius: 5px;
                        display: grid;
                        grid-gap: 1px;
                        grid-template-columns: 100% 100%;
                        grid-template-rows: 100% 100%;
                        position: relative;
                        border: 1px solid lightgrey;
                        image-orientation: from-image;
                        .image {
                            position: relative;
                        }
                        &.item_grid_2 {
                            grid-template-columns: 50% 50%;
                        }
                        &.item_grid_3 {
                            grid-template-columns: repeat(3, 1fr);
                        }
                        &.item_grid_4 {
                            grid-template-columns: 50% 50%;
                            grid-template-rows: 50% 50%;
                        }
                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            animation: fade 1000ms;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -o-user-select: none;
                            user-select: none;
                        }
                        .more-indicator {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            background-color: rgba(0,0,0,0.7);
                            color: white;
                            padding: 0 10px;
                            font-size: 12px;
                            border-top-left-radius: 5px;
                        }
                        &:hover {
                            .post-controls {
                                right: 0;
                                opacity: 1;
                            }
                        }
                        .post-controls {
                            position: absolute;
                            top: 0;
                            cursor: pointer;
                            right: -20px;
                            opacity: 0;
                            transition: opacity, right 250ms;
                            background-color: rgba(0,0,0,0.7);
                            padding: 0 5px;
                            border-bottom-left-radius: 5px;
                            .fa-trash-alt, .fa-edit {
                                color: #ffffff;
                            }
                            .fa-edit {
                                margin-right: 5px;
                            }
                        }
                    }
                    .img-description {
                        background-color: whitesmoke;
                        border-radius: 10px;
                        margin: 0 10px;
                        font-size: 12px;
                        text-align: left;
                        padding: 10px;
                        line-break: revert;
                        white-space: pre-line;
                        word-break: break-word;
                        max-height: 50px;
                        overflow-y: auto;
                    }
                }
                .post-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 20px 38px;
                    .post-counters {
                        display: flex;
                        cursor: pointer;
                        .post-footer-likes {
                            display: flex;
                            margin-right: 15px;
                            font-size: 12px;
                            font-weight: 500;
                            align-items: center;
                            span {
                                &.icon {
                                    width: 24px;
                                    height: 24px;
                                    background-image: url("../../assets/img/Radio button gray.svg");
                                    display: flex;
                                    margin-right: 4px;
                                }
                                &.all-count {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #666666;
                                    margin-right: 8px;
                                    position: relative;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        right: -4px;
                                        top: 0;
                                        height: 100%;
                                        width: 1px;
                                        background-color: #E0E3EA;
                                    }
                                }
                                &.inner-circle-count {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #9B9B9B;
                                }
                            }
                        }
                        .post-footer-comments {
                            display: flex;
                            font-size: 12px;
                            font-weight: 500;
                            align-items: center;
                            span {
                                &.icon {
                                    display: flex;
                                    width: 24px;
                                    height: 24px;
                                    background-image: url("../../assets/img/Comment Gray.svg");
                                    margin-right: 4px;
                                }
                                &.all-count {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #666666;
                                    margin-right: 8px;
                                    position: relative;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        right: -4px;
                                        top: 0;
                                        height: 100%;
                                        width: 1px;
                                        background-color: #E0E3EA;
                                    }
                                }
                                &.inner-circle-count {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #9B9B9B;
                                }
                            }
                        }
                    }
                    .post-actions {
                        button {
                            text-transform: uppercase;
                            border-radius: 35px;
                            border: 1px solid lightgrey;
                            background-color: transparent;
                            color: #333333;
                            text-align: center;
                            padding: 7px 30px;
                            font-size: 10px;
                            font-weight: 500;
                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }
	            .post-comment {
		            padding: 10px 20px 10px 20px;
		            border-top: 1px solid lightgray;
		            .show-more-comments {
			            color: gray;
			            font-size: 12px;
			            text-align: left;
			            cursor: pointer;
		            }
		            .comment {
			            display: flex;
			            justify-content: flex-start;
			            align-items: flex-start;
			            flex-wrap: wrap;
			            margin-bottom: 10px;
			            transition: opacity 500ms;
			            .main-comment-data {
				            display: flex;
				            justify-content: flex-start;
				            align-items: flex-start;
				            flex-wrap: nowrap;
				            width: 100%;
				            &.has-answers {
					            margin-bottom: 30px;
				            }
				            .avatar-wrapper {
					            /*min-width: 36px;
								min-height: 36px;
								max-width: 36px;
								max-height: 36px;
								overflow: hidden;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 50%;
								margin: 0 14px 0 0;
								img {
									height: 100%;
									width: 100%;
								}*/
					            margin-right: 14px;
					            cursor: pointer;
				            }
				            .comment-data {
					            &:hover {
						            .name {
							            .edit, .delete {
								            visibility: visible;
							            }
						            }
					            }
					            .name {
						            font-weight: 500;
						            font-size: 12px;
						            color: #666666;
						            display: flex;
						            justify-content: flex-start;
						            align-items: center;
						            flex-wrap: wrap;
						            .name-text {
							            margin-right: 10px;
							            &.deleted {
								            color: darkgray;
								            font-style: italic;
							            }
						            }
						            .comment-timestamp {
							            font-size: 11px;
							            font-weight: 400;
							            color: #9B9B9B;
							            margin-right: 10px;
						            }
						            .edit, .delete {
							            visibility: hidden;
							            cursor: pointer;
							            margin-right: 5px;
							            .control-icons {
								            .fa-edit, .fa-trash-alt {
									            font-size: 15px;
									            color: #999999;
								            }
							            }
							            img {
								            width: 15px;
							            }
						            }
					            }
					            .person-progress {}
					            .message {
						            font-weight: 400;
						            font-size: 12px;
						            color: #666666;
						            white-space: pre-line;
						            display: flex;
						            flex-wrap: wrap;
						            justify-content: flex-start;
						            align-items: flex-start;
						            text-align: left;
						            span {
							            &.deleted {
								            font-style: italic;
							            }
						            }
						            label {
							            span {
								            cursor: pointer;
								            color: #8e8eff;
								            &.deleted {
									            font-style: italic;
								            }
							            }
							            textarea {
								            height: 50px;
								            width: 100%;
								            margin-top: 2px;
								            margin-bottom: 0;
								            resize: none;
								            border-radius: 8px;
								            padding: 2px 5px;
								            &::-webkit-scrollbar{
									            height: 4px;
									            width: 4px;
									            background: gray;
									            border-radius: 10px;
								            }
								            &::-webkit-scrollbar-thumb{
									            background: lightgrey;
									            border-radius: 10px;
								            }
							            }
						            }
					            }
					            .comment-actions {
						            display: flex;
						            justify-content: flex-start;
						            align-items: center;
						            flex-wrap: wrap;
						            img {
							            cursor: pointer;
						            }
						            .answer {
							            font-weight: 400;
							            font-size: 12px;
							            color: #666666;
							            margin-right: 15px;
							            position: relative;
							            cursor: pointer;
							            /*&:after {
											content: '';
											height: 100%;
											width: 1px;
											background-color: #E0E3EA;
											position: absolute;
											right: -7px;
											top: 0;
										}*/
						            }
						            .answer-input {
							            min-width: 220px;
							            width: 100%;
							            display: flex;
							            justify-content: center;
							            align-items: center;
							            flex-wrap: nowrap;
							            .delete-action {
								            color: #999999;
								            cursor: pointer;
							            }
							            .reply-message-input {
								            width: 100%;
								            max-height: 50px;
								            height: 100%;
								            margin-right: 5px;
								            padding: 5px 30px 5px 5px;
								            border: 1px solid #E0E3EA;
								            color: darkslategray;;
								            border-radius: 8px;
								            line-height: 18px;
								            font-size: 13px;
								            resize: none;
								            /*overflow-x: hidden;
											overflow-y: hidden;*/
								            &::-webkit-scrollbar{
									            height: 4px;
									            width: 4px;
									            background: gray;
									            border-radius: 10px;
								            }
								            &::-webkit-scrollbar-thumb{
									            background: lightgrey;
									            border-radius: 10px;
								            }
								            &::placeholder {
									            font-weight: normal;
									            font-size: 13px;
									            line-height: 15px;
									            color: #9B9B9B;
								            }
							            }
						            }
						
					            }
				            }
			            }
		            }
	            }
            }
        }
    }




    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #imagePost {
            // margin: 0 auto 20px auto;
            margin-left: auto;
            margin-right: auto;
            .post-item-wrapper {
                .post-item {
                    .post-pre-footer {
                        padding: 0 20px 0;
                    }
                    .post-footer {
                        padding: 20px 20px;
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #imagePost {
            .post-item-wrapper {
                .post-item {
                    .post-header {
                        padding: 12px 15px;
                    }
                    .post-body-wrapper {
                        .post-body {
                            padding: 16px 16px 25px 16px;
                            .post-body-tags {
                                margin-bottom: 15px;
                            }
                        }
                        .post-body-questions {
                            padding: 15px 20px;
                            .radio-buttons-wrapper {
                                .u-radiobutton-wr {
                                    margin-bottom: 5px;
                                    &.radiobutton-with-avatar {
                                        padding-left: 30px;
                                    }
                                    .avatar-wrapper {
                                        left: -15px;
                                        &.my-checked {
                                            left: 0;
                                        }
                                        &.single-avatar {
                                            left: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .post-pre-footer {
                        padding: 0 10px 0;
                        flex-wrap: wrap;
                        .pre-post-actions, .pre-post-visibility {
                            max-width: unset;
                        }
                        .pre-post-actions {
                            justify-content: center;
                            margin-bottom: 10px;
                            button {
                                margin: 0!important;
                                padding: 7px 15px!important;
                                &:not(:last-child) {
                                    margin-right: 5px!important;
                                }
                            }
                        }
                        .pre-post-visibility {
                            align-items: center;
                            justify-content: center;
                            .is-important, .visibility-wrapper {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .post-footer {
                        padding: 10px 15px;
                        flex-wrap: nowrap;
                        .post-counters {
                            .post-footer-likes {
                                margin-right: 8px;
                            }
                        }
                        .post-actions {
                            button {
                                padding: 7px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

