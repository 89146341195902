<template>
	<div id="home" class="home">
		<!--<button @click="openCustomModal()"></button>-->
		<div class="page-content">
			<DynamicScroller :items="feed" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="1000">
				<template v-slot="{ item, index, active }">
					<DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item]">
						<QuestionPost v-if="item.type !== 101" :item="item" :list="feed" :block-title="item.title" :has-delete="false" :topic-global-id="item.topic_global_id" :has-margin="20" :delete-after-submit="false" :isFeed="true" :show-title-avatar="true" @loadMore="getQuestionsFeedAPI" :has-confirmation-modal="true"></QuestionPost>
						<ImagePost :item="item" :list="feed" :block-title="item.title" :has-margin="20" :isFeed="true" :show-avatars="true" v-else></ImagePost>
					</DynamicScrollerItem>
				</template>
			</DynamicScroller>
		</div>

		<right-sidebar @topicChangeEvent="topicChange"></right-sidebar>
		
		<custom-modal ref="custom-modal" :has-cancel-btn="false"></custom-modal>
	</div>
</template>

<script>
	import RightSidebar from "../../components/home/RightSidebar";
	import {questionsService} from '../../_api/questions.service.js';
	import visibilityItemSelect from "../../components/_shared/VisibilityItemSelect";
	import QuestionPost from "../../components/feed/QuestionPostBody";
	import EventBus from "../../_helpers/event-bus";
	import {parsePostBody} from "../../_helpers/post-body-parser";
	import ImagePost from "../../components/feed/ImagePostBody";
	import {handleAWSImage} from "../../_helpers/aws-handler";
	import Loading from "@/components/_shared/Loading";
	import CustomModal from "@/components/_shared/modals/_CustomModal";

	export default {
		name: 'home',
		data () {
			return {
				loadingg: false,
				loadIndexThreshold: 10,
				loadIndex: 10,
				feed: [],
				page: 0,

				timeStartOf: this.moment().subtract(4, 'minutes'),
				uniqueId: 0,
				arr: [],
				colorsArray: ['#AFD4AB', '#FEF192', '#D6585B', '#C675AE', '#5EBD9C'],
			}
		},
		components: {
			CustomModal,
			Loading,
			ImagePost,
			RightSidebar,
			visibilityItemSelect,
			QuestionPost
		},
		created () {},
		mounted() {
			EventBus.$on('loadMoreQuestions', (data) => {
				console.log('LISTENER ON');
				this.getQuestionsFeedAPI()
			});
			this.getQuestionsFeedAPI();
			// this.genRandomObj();
			console.log(this.user);
		},
		beforeDestroy() {
			EventBus.$off('loadMoreQuestions');
		},
		computed: {
			user () {
				return this.$store.state.authentication.user;
			}
		},
		watch: {},
		methods: {
			topicChange(data) {
				console.log(data);
				this.page = 0;
				this.feed = [];
				this.loadIndex = this.loadIndexThreshold;
				this.getQuestionsFeedAPI(data);
			},
			openCustomModal() {
				this.$refs['custom-modal'].show({
					message: 'Ответ получит только Путин',
					okButton: 'Ok',
				}).then((result) => {
					console.log(result);
					if (result) {
					
					}
				});
			},
			handleScroll(startIndex, endIndex) {
				if (endIndex === this.loadIndex) {
					this.loadIndex += this.loadIndexThreshold;
					this.getQuestionsFeedAPI();
				}
			},
			getQuestionsFeedAPI(topic_id) {
				questionsService.getQuestionsFeed({
					page: (!this.page) ? 1 : this.page + 1,
					perPage: this.loadIndexThreshold,
					topic_id: topic_id !== 9999999 ? topic_id : null
				}).then((res) => {
					if (res) {
						res.result.forEach((obj, index) => {
							parsePostBody(obj, true).then(res_q => {
								if (res_q.type === 101) {
									res_q['title'] = `${res_q.author.name} добавил фото`;
									res_q.photos.forEach((obj_c, index) => {
										obj_c['photo_parsed'] = null;
										obj_c['photo_parsed_min'] = null;
										handleAWSImage(obj_c.photo).then(response => {
											obj_c['photo_parsed'] = response;
											obj_c['photo_parsed_min'] = response;
											this.$forceUpdate();
										});
										this.$forceUpdate();
									});
									console.log(res_q.comments);
									if (res_q.comments && res_q.comments.length) {
										
										if (res_q.comments[0].author) {
											res_q['title'] = `${res_q.comments[0].author.name} добавил комментарий к Вашему фото`;
											res_q.author['avatar_parsed'] = null;
											res_q.comments[0]['similar_answers_percent'] = null;
											res_q.comments[0]['diff_answers_percent'] = null;
											res_q.comments[0]['not_answered_percent'] = null;
											questionsService.getUserProgressBarAPI({
												user_ids: [res_q.comments[0].author.id]
											}).then(response => {
												if (response) {
													console.log(response);
													res_q.comments[0]['similar_answers_percent'] = response[0].similar_answers_percent;
													res_q.comments[0]['diff_answers_percent'] = response[0].diff_answers_percent;
													res_q.comments[0]['not_answered_percent'] = response[0].not_answered_percent;
													this.$forceUpdate();
												}
											});
											handleAWSImage(res_q.comments[0].author.avatar_36).then(res => {
												res_q.author['avatar_parsed'] = res;
												this.$forceUpdate();
											})
										} else {
											console.log(res_q);
											res_q['title'] = 'Удаленный пользователь';
											res_q.comments[0]['author'] = {
												is_deleted: 1,
												name: 'Удаленный пользователь'
											}
										}
										
										if (res_q.unique_count_authors > 1) {
											res_q['title'] = `${res_q.comments[0].author.name} и еще ${res_q.unique_count_authors - 1} ${((res_q.unique_count_authors - 1) === 1) ? 'пользователь' : 'пользователей'} добавили комментарии к Вашему фото`;
										}
										
									} else {
										if (res_q.author) {
											res_q.author['avatar_parsed'] = null;
											handleAWSImage(res_q.author.avatar_36).then(res => {
												res_q.author['avatar_parsed'] = res;
												this.$forceUpdate();
											})
										}
									}
									
								} else {
									res_q['is_question_of_the_day'] = false;
									switch (res_q.type) {
										case 1:
											res_q['title'] = 'Вопрос дня';
											res_q['is_question_of_the_day'] = true;
											res_q['topic_global_id'] = 9;
											break;
										case 2:
											res_q['title'] = 'Основной вопрос';
											res_q['topic_global_id'] = 7;
											break;
										case 3:
											res_q['title'] = `Персональный вопрос от пользователя ${res_q.author.name}`;
											res_q['topic_global_id'] = 8;
											break;
										default:
											res_q['title'] = 'Основной вопрос';
											res_q['topic_global_id'] = 7;
											break;
									}
									if (res_q.type !== 1) {
										if (res_q.redirected === 1) {
											let users_name = '';
											if (res_q.who_asked.length === 1) {
												res_q['title'] = `Пользователь ${res_q.who_asked[0].name} интересуется Вашим ответом на вопрос`;
											} else {
												users_name = 'Пользователи ';
												res_q.who_asked.forEach((p, index) => {
													if (index === 0) {
														users_name += p.name;
													} else {
														users_name += `, ${p.name}`
													}
												});
												res_q['title'] = `${users_name} интересуются Вашим ответом на вопрос`;
											}
										}
										if (res_q.type === 3 && res_q.who_asked.length) {
											let users_name = '';
											//obj.who_asked.length === 1 ? users_name = `Пользователь ${obj.who_asked[0].name}` : users_name = 'Пользователи ';
											if (res_q.who_asked.length === 1) {
												if (res_q.who_asked[0].id === res_q.author.id) {
													res_q['title'] = `Пользователь ${res_q.who_asked[0].name} интересуется Вашим ответом на свой персональный вопрос`;
												} else {
													res_q['title'] = `Пользователь ${res_q.who_asked[0].name} интересуется Вашим ответом на персональный вопрос пользователя ${res_q.author.name}`;
													
												}
											}/* else {
												users_name = 'Пользователи ';
												res_q.who_asked.forEach((p, index) => {
													if (index === 0) {
														users_name += p.name;
													} else {
														users_name += `, ${p.name}`
													}
												});
												res_q['title'] = `${users_name} интересуются Вашим ответом на персональный вопрос ${res_q.author.name}`;
											}*/
										}
									}
									console.log(res_q);
									res_q['has_answer'] = !!res_q.my_answers.length;
									if (res_q.my_answers.length) {
										console.log('qweqweqwe');
										res_q.answers.forEach((obj) => {
											console.log(res_q.my_answers[0].answer_id);
											console.log(obj);
											if (obj.id === res_q.answers.filter((re) => {return re.id === res_q.my_answers[0].answer_id})[0].id) {
												obj.checked = true;
												res_q['selected'] = obj;
											} else {
												obj.checked = false
											}
											this.$forceUpdate();
										});
									}
									res_q['has_answer_mode'] = res_q['has_answer'];
									res_q['accepted'] = false;
									res_q['isFeed'] = true;
									if (res_q.author) {
										res_q.author['avatar_parsed'] = null;
										handleAWSImage(res_q.author.avatar_36).then(res => {
											res_q.author['avatar_parsed'] = res;
											this.$forceUpdate();
										})
									}
								}

								// console.log(res_q);
								this.feed.push(res_q);
							});
						});

						if (res.result.length) {
							this.page += 1;
						}
					}
				});
			},

			genRandomObj() {
				let tempObj = {
					id: 0,
					index: 0,
					bg_color: null,
					user: {
						name: 'Иван',
						img: '../img/no-avatar.406cab90.jpg'
					},
					created_at: this.moment(this.timeStartOf).fromNow(),
					post_type: {
						id: 1
					},
					post_title: 'Какие ваши любимые фильмы жанра Научная Фантастика?',
					post_tags: [
						{id: 1, name: 'Фильмы'},
						{id: 2, name: 'Научная фантастика'},
					],
					post_questions_arr: [
						{id: 1, name: 'Shooters', is_selected: false},
						{id: 2, name: 'Fightings', is_selected: false},
						{id: 3, name: 'Beat them up', is_selected: false},
						{id: 4, name: 'Flatliners', is_selected: false},
						{id: 5, name: 'Labyrinth', is_selected: false},
					],
					post_answer_view_by: {
						id: 1,
						name: 'Everyone'
					},
					post_likes_counter: 375,
					post_comments_counter: 0,
					post_comments_arr: [
						{id: 1, message: 'Some comment', user_id: 43, user_name: 'Some name', user_photo: 'src'}
					]
				};
				for (let i = 0; i < 100; i++) {
					tempObj.bg_color = this.colorsArray[Math.floor(Math.random() * this.colorsArray.length)];
					this.arr.push(JSON.parse(JSON.stringify(tempObj)));
					this.uniqueId += 1;
					tempObj.id = this.uniqueId;
					tempObj.index = this.uniqueId;
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	#home {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: nowrap;
		.page-content {
			width: 69.6%;
			padding-right: 20px;
			.scroller {
				height: 100%;
			}
		}
	}
	::v-deep #greeting-modal {
		.modal-dialog {
			max-width: 900px;
			top: 48%;
			transform: translateY(-50%);
			.modal-body {
				padding: 40px;
				text-align: center;
				h1 {
					font-size: 36px;
					color: #666666;
					margin-bottom: 32px;
				}
				p {
					font-size: 24px;
					line-height: 28px;
					font-weight: 500;
					color: #666666;
				}
				button {
					margin: 48px auto 0;
					font-size: 18px;
					font-weight: 500;
				}
			}
		}
	}


	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 576px) {

	}
	// Small devices (landscape phones, less than 768px)
	@media (max-width: 768px) {

	}
	// Medium devices (tablets, less than 992px)
	@media (max-width: 991px) {
		#home {
			.page-content {
				width: 100%;
				padding-right: 0;
			}
		}
	}
	// Large devices (desktops, less than 1200px)
	@media (max-width: 1200px) {

	}
</style>
