import router from "../router";
import {handleAWSImage} from "@/_helpers/aws-handler";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user: user, fb_data: null, user_avatar: user.avatar_parsed }
    : { status: { loggedIn: false }, user: null, fb_data: null, user_avatar: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit, dispatch }, data) {
            commit('loginSuccess', data);
            dispatch('firebase/getUserData', null, {root:true});
            dispatch('firebase/getUnreadCount', {stopListener: false}, {root:true});
            dispatch('firebase/getNotificationsCount', {stopListener: false}, {root:true});

            localStorage.setItem('user', JSON.stringify(data));
            dispatch('handleGlobalUserImage', data);
            localStorage.removeItem('uReg');
            localStorage.removeItem('socialRegUser');
            window.db.transaction(["avatar"], "readwrite").objectStore("avatar").clear();
            router.push('/');
        },
        logout({ commit, dispatch }) {
            console.log('LOGOUT');
            console.log(window);
            dispatch('firebase/getUnreadCount', {stopListener: true}, {root:true});
            dispatch('firebase/getNotificationsCount', {stopListener: true}, {root:true});
            if (window.FB !== undefined) {
                FB.api('/me/permissions', 'delete', null, () => {
                    FB.logout();
                    //console.log('LOGGED OUT FROM FB');
                    // VK.Auth.logout(function (response) {});
                });
            }
            commit('logout');
            localStorage.removeItem('user');
            router.push('/login');
        },
        setFbData({ commit }, data) {
            //console.log(data);
            commit('getFbData', data);
            localStorage.setItem('socialRegUser', JSON.stringify(data));
        },
        changeIsFirstLogin({ commit }, data) {
            data['is_first_login'] = 0;
            commit('isFirstLogin', data);
            let q = JSON.parse(localStorage.getItem('user'));
            q['is_first_login'] = 0;
            localStorage.setItem('user', JSON.stringify(q));
        },

        handleGlobalUserImage({commit}, data) {
            // console.log(data);
            handleAWSImage(data.avatar_135).then((res) => {
                // console.log(res);
                data['avatar_parsed'] = res;
                commit('saveUpdatedUserData', data);
                commit('updateAvatar', res);
            });
        },
        updateUserData({commit, dispatch}, data) {
            let q = JSON.parse(localStorage.getItem('user'));
            q = data;
            localStorage.setItem('user', JSON.stringify(q));
            dispatch('handleGlobalUserImage', q);
        },
        editUserAuthToken({commit, dispatch}, data) {
            let q = JSON.parse(localStorage.getItem('user'));
            q['api_key'] = data;
            localStorage.setItem('user', JSON.stringify(q));
            commit('editUserAuthToken', data)
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        logout(state) {
            state.status = {};
            state.user = null;
            state.user_avatar = null;
        },
        getFbData(state, data) {
            state.fb_data = data
        },
        isFirstLogin(state, data) {
            state.user = data;
        },
        saveUpdatedUserData(state, data) {
            state.user = data;
        },
        updateAvatar(state, data) {
            state.user_avatar = data;
        },
        editUserAuthToken(state, data) {
            state.api_key = data;
        }
    }
};
