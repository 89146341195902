<template>
    <b-modal ref="similar-people-modal" size="xl" hide-footer id="similar-people-modal" :no-close-on-backdrop="true" title="Список людей с похожими ответами, подходящих Вам по своим интересам">
        <div class="loading-wrapper" v-if="!alreadyLoaded">
            <!--<img src="../../../assets/img/Infinity-1s-200px.svg" alt="loading">-->
	        <loading class="x2"></loading>
        </div>
        <div class="top-bar" v-if="alreadyLoaded">
            <div class="friends-count">{{users_data.count + ' людей'}}</div>
            <user-dropdown-filter :defaultSelected="orderBy" @onChange="onOrderByChange"></user-dropdown-filter>
        </div>
        <div class="users-list-wrapper" v-if="alreadyLoaded" @scroll="onScroll($event)">
            <div class="user-item" v-for="user in users_data.matched_users">
                <avatar @click="goToUserProfile(user.id)" :width="111" :height="111" :img="user.avatar_parsed"></avatar>
                <div @click="goToUserProfile(user.id)" class="user-fullname">{{user.name}}</div>
                <div class="user-description" v-if="user && (user.birthday || user.age_range || user.location || user.occupations)">
                    <span v-if="user.birthday">{{user.birthday + ', '}}</span>
                    <span v-if="user.age_range">{{user.age_range + ', '}}</span>
                    <span v-if="user.location">{{user.location.city.name + ', '}}</span>
                    <span v-if="user.occupations">{{user.occupations[0].name}}</span>
                </div>
                <person-progress width="180" height="4" :green="user.similar_answers_percent" :red="user.diff_answers_percent" :gray="user.not_answered_percent"></person-progress>
                <div class="interests" v-if="user.interests && user.interests.length">
                    <span v-for="i in interestsComp(user)">{{i.name}}</span>
                    <span v-if="user.interests.length > 3" class="more">+{{user.interests.length - 3}}</span>
                </div>

                <div class="actions-wrapper">
                    <button :class="{'def-btn': user.is_friend, 'def-btn disabled': !user.is_friend}" :disabled="!user.is_friend" @click="askQuestion(user)">Задать вопрос</button>
                    <button class="def-btn" @click="startChat(user)">Написать сообщение</button>
                    <button :disabled="user.is_friend_request === 1" v-if="!user.is_friend" :class="{'def-btn': !user.is_friend_request, 'def-btn disabled': user.is_friend_request}" @click="sendFriendRequest(user)">
                        {{(user.is_friend_request) ? 'Запрос отправлен' : 'Добавить в Друзья Up&Up'}}
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import {usersService} from "../../../_api";
    import {handleAWSImage} from "../../../_helpers/aws-handler";
    import userDropdownFilter from "../UserFilterDropdown";
    import avatar from "../Avatar";
    import moment from "moment";
    import PersonProgress from "../UserProgress";
    import Loading from "@/components/_shared/Loading";

    export default {
        name: 'similarPeopleModal',
        data() {
            return {
                alreadyLoaded: false,
                loadIndexThreshold: 16,
                loadIndex: 16,
                startIndex: 0,
                perPage: 16,
                page: 1,
                users_data: {
                    count: null,
                    matched_users: []
                },
                orderBy: 1
            }
        },
        components: {
	        Loading,
            PersonProgress,
            userDropdownFilter,
            avatar
        },
        methods: {
            interestsComp(q) {
                console.log(q);
                return q.interests.filter((o, index) => {
                    return index <= 2
                })
            },
            onScroll(e) {
                console.log(e);
                if (e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight) {
                    this.page += 1;
                    this.getUsers();
                }
            },
            showModal() {
                this.$refs['similar-people-modal'].show();
                this.getUsers();
            },
            onOrderByChange(data) {
                // console.log(data);
                this.orderBy = data;
                this.getUsers();
            },
            filteredList (data) {
                // console.log(data);
                this.similarPeople.matched_users_filtered = data.arr;
                this.search_str = data.searchStr;
                this.$refs.filter.submitFilter();
            },
            getUsers() {
                this.loading = true;
                let objToSend = {
                    perPage : this.perPage,
                    page : this.page,
                    orderBy: this.orderBy //1-BY_MATCHING; 2-BY_REG_DATE
                };
                usersService.getSimilarUsers(objToSend).then((res) => {
                    this.loading = false;
                    //console.log(res);
                    if (res) {
                        this.users_data.count = res.count;
                        // this.users_data.matched_users = res.matched_users;
                        res.matched_users.forEach((obj) => {
                            if (obj.birthday) {
                                obj.birthday = moment().diff(obj.birthday, 'years',false);
                            }
                            obj['avatar_parsed'] = null;
                            handleAWSImage(obj.avatar_64).then((res) => {
                                obj['avatar_parsed'] = res;
                                this.$forceUpdate();
                            });
                            this.users_data.matched_users.push(obj);
                        });
                        this.alreadyLoaded = true;                    }
                })
            },
            startChat(data) {
                this.loadingChat = true;
                usersService.getChatIdAPI(data).then((res) => {
                    this.loadingChat = false;
                    if (res) {
                        this.$router.push({ path: '/messages', query: { t: res, ff: true } });
                        this.$refs['similar-people-modal'].hide();
                    }
                })
            },
            sendFriendRequest(data) {
                usersService.sendFriendRequestAPI({
                    id: data.id
                }).then((res) => {
                    // console.log(res);
                    if (res) {
                        data['is_friend_request'] = 1;
                        this.$forceUpdate();
                    }
                })
            },
            askQuestion(item) {
                this.$router.push({path: '/questions/personal-questions',  query: { user_id: item.id }});
                this.$refs['similar-people-modal'].hide();
            },
            goToUserProfile(id) {
                this.$router.push({path: `/profile/${id}`});
                this.$refs['similar-people-modal'].hide();
            },
        },
        beforeCreate() {},
        created() {},
        mounted() {}
    }
</script>

<style lang="less">
    #similar-people-modal {
        .modal-dialog {
            &.modal-xl {
                max-width: 960px;
            }
            .modal-content {
                .modal-header {
                    .modal-title {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: rgba(51, 51, 51, 0.9);
                    }
                }
                .modal-body {
                    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);
                    .loading-wrapper {
                        width: 100%;
                        height: 80vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .top-bar {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 25px;
                        .friends-count {
                            font-size: 14px;
                            line-height: 14px;
                            color: #666666;
                        }
                        .sort-wrapper {
                            font-size: 12px;
                            line-height: 14px;
                            color: #333333;
                        }
                    }
                    .users-list-wrapper {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        overflow-y: auto;
                        max-height: 80vh;
                        .user-item {
                            width: 24%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: 25px;
                            margin-left: 0.5%;
                            margin-right: 0.5%;
                            .avatar-wrapper {
                                width: 111px;
                                height: 111px;
                                overflow: hidden;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                margin-bottom: 15px;
                                img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                            .user-fullname {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                text-align: center;
                                color: #333333;
                                margin-bottom: 8px;
                            }
                            .user-description {
                                min-height: 19px;
                                font-size: 14px;
                                line-height: 16px;
                                color: #999999;
                                margin-bottom: 8px;
                            }
                            .person-progress {
                                margin-bottom: 5px;
                            }
                            .common-preferences {
                                display: flex;
                                justify-content: center;
                                width: 100%;
                                margin-bottom: 15px;
                                border-top: 0.5px solid rgba(94, 111, 143, 0.3);
                                border-bottom: 0.5px solid rgba(94, 111, 143, 0.3);
                                padding: 1.5px;
                                span {
                                    border: 0.5px solid #E0E0E0;
                                    font-size: 13px;
                                    line-height: 15px;
                                    color: #828282;
                                    padding: 1px;
                                    margin: 0px 1.5px;
                                }
                            }
                            .interests {
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-items: center;
                                margin-bottom: 10px;
                                span {
                                    font-size: 13px;
                                    color: #9B9B9B;
                                    border: 1px solid #CBCBCB;
                                    padding: 1px 3px;
                                    margin-right: 2px;
                                    margin-bottom: 2px;
                                }
                            }
                            .actions-wrapper {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                .def-btn {
                                    font-weight: 500;
                                    font-size: 10px;
                                    line-height: 12px;
                                    margin-bottom: 8px;
                                    width: 100%;
                                    &.disabled {
                                        background: rgba(0, 0, 0, 0.1)!important;
                                        color: #A9BAD4!important;
                                        cursor: not-allowed;
                                    }
                                }
                            }
                        }
                    }
                }
                .modal-footer {
                
                }
            }
        }
    }


    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {
        #similar-people-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .users-list-wrapper {
                            max-height: 65vh;
                            .user-item {
                                width: 33%;
                                margin: 0 0 25px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #similar-people-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .users-list-wrapper {
                            .user-item {
                                width: 50%!important;
                            }
                        }
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #similar-people-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .users-list-wrapper {
                            .user-item {
                                width: 100%!important;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
