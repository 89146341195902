<template>
    <transition name="c-modal-fade">
        <div class="c-modal-backdrop-wr" v-if="show">
            <div class="c-modal">
                <img src="../../assets/img/cancel.svg" alt="close" @click="close()" class="c-btn-close">
                <section class="c-modal-body" v-if="currentPost" :class="{'has-comments-section': currentPost.is_comment_enabled}">
                    <div class="img-section" :class="{'full-width': !hasCommentsSection && !currentPost.is_comment_enabled}">
                        <div class="img-wrapper" v-for="i in [currentIndex]" :key="i">
                            <img draggable="false" class="img" :src="currentImage.photo_parsed" alt="sdasd" v-for="ii in [currentInnerIndex]" :key="ii"/>
                            <div class="indicators" v-if="currentPost.photos.length > 1">
                                <span v-for="(indi, index) in currentPost.photos" :key="index" @click="changeImageSlide(indi, index)" :class="{'active': currentImageInnerIndex === index}"></span>
                            </div>
                            <div v-if="currentPost.photos.length > 1 " class="inner-prev" @click="innerPrev"><img src="../../assets/img/chevron-left.svg" alt="inner-prev"></div>
                            <div v-if="currentPost.photos.length > 1" class="inner-next" @click="innerNext"><img src="../../assets/img/chevron-right.svg" alt="inner-prev"></div>
                        </div>
                        <div class="description" v-if="currentPost.description">
                            {{currentPost.description}}
                        </div>
                        <div v-if="isSlider && currentIndex > 0" class="outer-prev" @click="outerPrev"><img src="../../assets/img/chevron-left.svg" alt="outer-prev"></div>
                        <div v-if="isSlider && (currentIndex !== imgArray.length - 1)" class="outer-next" @click="outerNext"><img src="../../assets/img/chevron-right.svg" alt="outer-prev"></div>
	                    <div class="post-controls" v-if="showControls && hasControls && (hasCommentsSection && currentPost.is_comment_enabled)">
	                        <span class="share-icon" @click.stop="shareGalleryPost(currentPost, currentImage)">
	                            <i class="fas fa-share-alt"></i>
	                        </span>
		                    <span class="edit-icon" @click.stop="editGalleryPost(currentPost, currentImage)">
	                            <i class="far fa-edit" ></i>
	                        </span>
		                    <span class="delete-icon" @click.stop="deleteGalleryPost(currentPost, currentImage)">
	                            <i class="far fa-trash-alt" ></i>
	                        </span>
	                    </div>
                    </div>
	                <div class="post-controls no-comments" v-if="showControls && hasControls && (hasCommentsSection && !currentPost.is_comment_enabled)">
                        <span class="share-icon" @click.stop="shareGalleryPost(currentPost, currentImage)">
                            <i class="fas fa-share-alt"></i>
                        </span>
		                <span class="edit-icon" @click.stop="editGalleryPost(currentPost, currentImage)">
                            <i class="far fa-edit" ></i>
                        </span>
		                <span class="delete-icon" @click.stop="deleteGalleryPost(currentPost, currentImage)">
                            <i class="far fa-trash-alt" ></i>
                        </span>
	                </div>
                    <div class="c-section" v-if="hasCommentsSection && currentPost.is_comment_enabled">
                        <comments ref="comments" v-if="objectData" :object-data="objectData" :topic-global-id="7" :all-tabs="false" :is-post="true" @goToProfile="goToProfile" :is-gallery="true"></comments>
                    </div>
                </section>
            </div>
            <div class="c-modal-backdrop"></div>
	        <share-photos-modal ref="share-photos-modal" :single-photo-share="true"></share-photos-modal>
	        <upload-image-modal ref="upload-image-modal" @imageEdited="imageEdited"></upload-image-modal>
        </div>
    </transition>
</template>

<script>
    import comments from "./Comments";
    import {usersService} from "@/_api";
    import SharePhotosModal from "@/components/_shared/modals/_SharePhotosModal";
    import UploadImageModal from "@/components/_shared/modals/_UploadImageToGallery";

    export default {
        name: "imageSlider",
        props: {
            ['img-array']: {
                type: Array,
                default: []
            },
            ['imgIndex']: Number,
            ['isOpen']: Boolean,
            ['hasCommentsSection']: Boolean,
            ['isSlider']: Boolean,
            ['twoLevelModal']: Boolean,
	        ['hasControls']: {
				type: Boolean,
		        default: false
	        }
        },
        components: {
	        UploadImageModal,
	        SharePhotosModal,
            comments
        },
        data() {
            return {
                currentIndex: 0,
                currentInnerIndex: 0,
                objectData: null,
                show: false
            };
        },
        watch: {
            /*imgIndex: function(res) {
                console.log(res);
                this.currentIndex = res;
                if (this.currentIndex >= this.imgArray.length - 3) {
                    this.$emit('loadMore');
                }
            },*/
            objectData: function(res) {
                // console.log(res);
            },
            currentIndex: function(res) {
                // console.log(res);
            },
            show: function(res) {
                if (res) {
                    document.body.classList.add("modal-open");
                } else {
                    if (!this.twoLevelModal) {
                        setTimeout(() => {
                            document.body.classList.remove("modal-open");
                            this.currentInnerIndex = 0;
                            this.currentIndex = 0;
                        }, 100)
                    }
                }
            },
        },
        mounted: function() {},
        methods: {
	        goToProfile(data) {
		        this.close();
		        setTimeout(() => {
			        let currentUser = JSON.parse(localStorage.getItem('user'));
			        if (data.id === currentUser.id) {
				        if (this.$route.name !== 'my-profile') {
					        this.$router.push({path: '/me'});
				        }
			        } else if (this.$route.name === 'user-profile') {
				        this.$router.push({path: `/profile/${data.id}`});
			        } else {
				        this.$router.push({path: `/profile/${data.id}`});
			        }
		        }, 250)
	        },
            open(data) {
                this.$emit('open');
                this.show = true;
                this.currentIndex = data;
                if (this.isSlider) {
                    this.currentIndex = data;
                } else {
                    this.currentInnerIndex = data;
                }
                if (this.currentIndex >= this.imgArray.length - 3 && this.imgArray.length >= 8) {
                    this.$emit('loadMore');
                }
            },
            close() {
                this.$emit('close');
                this.show = false;
            },
            changeImageSlide(data, index) {},
            innerNext: function() {
                if (this.currentInnerIndex !== this.currentPost.photos.length - 1) {
                    this.currentInnerIndex += 1;
                }
            },
            innerPrev: function() {
                if (this.currentInnerIndex !== 0) {
                    this.currentInnerIndex -= 1;
                }
            },
            outerNext: function() {
                this.currentIndex += 1;
                this.currentInnerIndex = 0;
                this.objectData = null;
                if (this.currentIndex >= this.imgArray.length - 3 && this.imgArray.length >= 8) {
                    this.$emit('loadMore');
                }

            },
            outerPrev: function() {
                this.currentIndex -= 1;
                this.currentInnerIndex = 0;
                this.objectData = null;
            },
	        deleteGalleryPost(post, image) {
		        let q = confirm('Вы уверены что хотите удалить это фото?');
		        if (q) {
			        usersService.deleteGalleryPhotoAPI({
				        photo_id: image.id
			        }).then((res) => {
				        if (res) {
					        this.currentPost.photos.splice(this.currentImageInnerIndex, 1);
					        this.$store.dispatch('alert/success', 'Вы успешно удалили фото', { root: true });
					        if (!this.currentPost.photos.length) {
						        let ind = this.imgArray.findIndex((obj) => obj.id === this.currentPost.id);
						        if (ind !== -1) {
							        this.imgArray.splice(ind, 1);
									if (!this.imgArray.length) {
										this.close();
									}
						        }
					        }
				        }
			        })
		        }
	        },
	        shareGalleryPost(post, image) {
		        this.$refs['share-photos-modal'].showUsersModal(image);
	        },
	        editGalleryPost(post, image) {
		        console.log(post);
		        console.log(image);
		        this.$refs['upload-image-modal'].showModal(post);
	        },
	        imageEdited(data) {
		        let index = this.imgArray.findIndex(x => x.id === data.id);
		        this.imgArray[index] = Object.assign({}, this.imgArray[index], data);
		        this.imgArray = [...this.imgArray];
	        },
        },
        computed: {
			showControls() {
				return this.$route.path === '/me'
			},
	        getFireUserData() {
		        return this.$store.state.authentication.user.id
	        },
            currentPost: function() {
                this.objectData = this.imgArray[Math.abs(this.currentIndex) % this.imgArray.length];
                return this.imgArray[Math.abs(this.currentIndex) % this.imgArray.length];
            },
            currentImage: function () {
                return this.currentPost.photos[Math.abs(this.currentInnerIndex) % this.currentPost.photos.length];
            },
            currentImageInnerIndex: function () {
                return Math.abs(this.currentInnerIndex) % this.currentPost.photos.length
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../assets/styles/_vars.less";
    .c-modal-backdrop-wr {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        .c-modal {
            overflow-x: auto;
            display: flex;
            flex-direction: column;
            z-index: 1041;
            width: 100%;
            height: 100%;
            .c-modal-body {
                position: relative;
                padding: 20px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
	            .post-controls {
		            position: absolute;
		            top: 0;
		            cursor: pointer;
		            right: 0;
		            opacity: 1;
		            transition: opacity, right 250ms;
		            background-color: rgba(0,0,0,0.7);
		            padding: 5px 10px;
		            border-bottom-left-radius: 5px;
		            &.no-comments {
			            top: 20px;
			            right: 20px;
		            }
		            .fa-trash-alt, .fa-edit, .fa-share-alt {
			            color: #ffffff;
			            font-size: 20px;
		            }
		            .fa-edit, .fa-share-alt {
			            margin-right: 10px;
		            }
	            }
                .img-section {
                    position: relative;
                    width: 68%;
                    margin-right: 2%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &.full-width {
                        width: 100%;
                    }
                    .img-wrapper {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .img {
                            image-orientation: from-image
                        }
                        .indicators {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-wrap: nowrap;
                            width: 100%;
                            span {
                                width: 10px;
                                height: 10px;
                                border-radius: 10px;
                                margin: 4px;
                                border: 1px solid white;
                                background-color: black;
                                &.active {
                                    background-color: @theme_color;
                                }
                            }
                        }
                    }
                    &:hover {
                        .description {
                            opacity: 1;
                        }
                    }
                    .description {
                        position: absolute;
                        min-height: 50px;
                        max-height: 80px;
                        overflow-y: auto;
                        height: 100%;
                        padding: 10px;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        color: white!important;
                        // display: flex;
                        font-size: 14px;
                        justify-content: center;
                        align-items: center;
                        background-color: rgba(0,0,0,0.7);
                        border-radius: 10px;
                        opacity: 0;
                        transition: opacity 1s;
                        text-align: center;
                    }
                    .img {
                        height: 100%;
                        width: 100%;
                        max-height: 80vh;
                        object-fit: contain;
                        min-height: 90vh;
                        min-width: 50vw;
                    }
                    div {
                        img {
                            width: 20px;
                        }
                    }
                }
                .c-section {
                    width: 30%;
                    height: 100%;
                    ::v-deep .comments-section {
                        padding: 0;
                        background-color: white;
                        border-radius: 10px;
                        height: 100%;
                        #comments-content {
                            height: 100%;
                            max-height: calc(100% - 131px);
                        }
                    }
                }
            }
        }
        .c-modal-backdrop {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1040;
            width: 100vw;
            height: 100vh;
            background-color: #000;
            opacity: 0.8;
        }
    }

    .c-btn-close {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
        width: 40px;
        height: 40px;
        border: 1px solid white;
        border-radius: 100%;
        z-index: 1060;
    }
    .c-modal-fade-enter,
    .c-modal-fade-leave-to {
        opacity: 0;
    }
    .c-modal-fade-enter-active,
    .c-modal-fade-leave-active {
        transition: opacity .5s ease;
    }


    // slider
    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.9s ease;
        overflow: hidden;
        visibility: visible;
        position: absolute;
        width:100%;
        opacity: 1;
    }
    .fade-enter,
    .fade-leave-to {
        visibility: hidden;
        width:100%;
        opacity: 0;
    }
    .outer-prev, .outer-next, .inner-prev, .inner-next {
        cursor: pointer;
        position: absolute;
        top: 40%;
        padding: 20px;
        color: red!important;
        border: 1px solid #ffffff;
        font-weight: bold;
        font-size: 18px;
        background-color: black;
        // transition: 0.7s ease;
        text-decoration: none;
        user-select: none;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
    }
    .outer-next {
        right: 0;
    }
    .outer-prev {
        left: 0;
    }
    .inner-prev, .inner-next {
        bottom: 130px;
        width: 20px;
        height: 20px;
        padding: 15px;
        top: unset;
    }
    .inner-next {
        right: 45%;
    }
    .inner-prev {
        left: 45%;

    }
    .outer-prev:hover, .outer-next:hover, .inner-prev:hover, .inner-next:hover {
        background-color: rgba(0,0,0,0.9);
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {
        .inner-next {
            right: 35%;
        }
        .inner-prev {
            left: 35%;
        }
        .c-modal-backdrop-wr {
            .c-modal {
                .c-modal-body {
                    .img-section {
                        width: 58%;
                        &.full-width {}
                        .img-wrapper {
                            .indicators {
                                span {
                                    &.active {}
                                }
                            }
                        }
                        &:hover {
                            .description {}
                        }
                        .description {}
                        .img {}
                        div {
                            img {}
                        }
                    }
                    .c-section {
                        width: 40%;
                        ::v-deep .comments-section {
                            #comments-content {}
                        }
                    }
                }
            }
            .c-modal-backdrop {}
        }
    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        .c-btn-close {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 20px;
            padding: 10px;
            cursor: pointer;
            font-weight: bold;
            color: #4AAE9B;
            background: transparent;
            width: 60px;
            height: 60px;
            border: 1px solid white;
            border-radius: 100%;
            z-index: 1060;
            transform: scale(0.5);
        }
        .inner-next {
            right: 25%;
        }
        .inner-prev {
            left: 25%;
        }
        .c-modal-backdrop-wr {
            .c-modal {
                .c-modal-body {
                    &.has-comments-section {
                        flex-direction: column;
                        .img-section {
                            .img {
                                min-height: 40vh;
                                max-height: 40vh;
                            }
                        }
                    }
                    justify-content: flex-start;
                    .img-section {
                        width: 100%;
                        height: 50%;
                        margin-right: 0;
                        &.full-width {
                            height: 100%;
                        }
                        &.full-width {}
                        .img-wrapper {
                            .indicators {
                                bottom: 65px;
                                span {
                                    &.active {}
                                }
                            }
                            .inner-next, .inner-prev {
                                bottom: 85px;
                            }
                        }
                        &:hover {
                            .description {}
                        }
                        .description {
                            max-height: 60px;
                            font-size: 12px;
                        }
                        .img {
                            min-height: 80vh;
                            max-height: 80vh;
                            /*min-height: 90vh;
                            max-height: 90vh;*/
                        }
                        div {
                            img {}
                        }
                    }
                    .c-section {
                        width: 100%;
                        height: 50%;
                        /*min-height: 60vh;
                        max-height: 60vh;*/
                        ::v-deep .comments-section {
                            //border-radius: 0px;
                            #comments-content {
                                // min-height: 150px;
                                /*padding-bottom: 70px;
                                background-color: #fff;*/
                            }
                            .input {
                                /*position: fixed;
                                bottom: 0px;
                                left: 0px;
                                right: 0px;
                                background-color: #fff;
                                border-top: 1px solid #ddd;*/
                            }
                        }
                    }
                }
            }
            .c-modal-backdrop {}
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {}
    @media (max-width: 350px) {}
</style>
