export const CHILDREN_OPTIONS = [
    {
        id: 1,
        label: 'Есть дети'
    }, {
        id: 2,
        label: 'Нет детей'
    }, {
        id: 3,
        label: 'Нет детей, но хотел(а) бы'
    }, {
        id: 4,
        label: 'Есть дети, но хочу еще'
    }, {
        id: 5,
        label: 'Нет и не хочу'
    }, {
        id: 6,
        label: 'Пока и не задумываюсь об этом'
    }
];
