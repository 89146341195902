<template>
    <b-modal ref="friends-modal" size="lg" hide-footer id="friends-modal" :no-close-on-backdrop="true" title="Друзья Up&Up">
        <friends-list :is-modal="true" @startChat="startChat"></friends-list>
    </b-modal>
</template>

<script>
    import friendsList from "../../users/inner-circle/InnerCircleList";
    export default {
        name: 'friendsModal',
        data() {
            return {

            }
        },
        components: {friendsList},
        methods: {
            showModal() {
                this.$refs['friends-modal'].show();
                setTimeout(this.loadContent, 800);
            },
            startChat(data) {
                this.$emit('setChatActive', data, true);
                this.$refs['friends-modal'].hide();
            }
        },
        beforeCreate() {},
        created() {

        }
    }
</script>

<style lang="less" scoped>
    #friends-modal {}
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        ::v-deep #friends-modal {
            .modal-content {
                .modal-header {
                    .modal-title {
                        font-size: 16px;
                    }
                }
                .modal-body {
                    padding: 0;
                    #friends {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {

    }
</style>
