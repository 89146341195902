<template>
    <div id="multiselect-directive">
        <div class="selected-list" v-if="listSelected.length">
            <div class="list-item" v-for="(i, index) of listSelected" :key="i.id" @click="deselectItem(i, index, 'selected')">
                <span>{{i.name}}</span>
                <img src="../../assets/img/delete-x.svg" alt="del">
            </div>
        </div>

        <div class="input-wrapper" @click="qwe()" style="z-index: 1;">
            <ValidationProvider :rules="(validation && !listSelected.length) ? `required|multiselect:${listSelected.length}` : ''" v-slot="{classes, errors}">
                <div class="a">
                    <input type="text" ref="multiselect-search" :id="id" :placeholder="placeholder" :class="classes" class="form-control" v-model="searchText" @change="onChange" @keypress="onKeyPress" @keyup="onKeyUp" @focus="onFocus()" @blur="onBlur()" autocomplete="off">
                    <span :class="{'search-icon': !searchText, 'delete-icon': searchText}" @click="searchText ? clearInput() : null"></span>
                </div>
            </ValidationProvider>
        </div>
        <div id="desktop-select-wrapper" class="search-list" v-if="show">
            <div v-if="filteredData.length" class="list-item" v-for="(i, index) of filteredData" :key="i.id" @click="!i.selected ? selectItem(i) : deselectItem(i, index, 'list')" :class="{'selected': i.selected}">{{i.name}}</div>
            <div v-if="!filteredData.length" class="no-data-found">No results</div>
        </div>

        <div ref="select1" id="mobile-select-wrapper" class="search-list" style="opacity: 0; z-index: 20;">
            <label for="mobile-select"></label>
            <select id="mobile-select" v-model="mobileSelected" multiple @change="onMobileSelectChange($event)">
                <!--<option value="" disabled selected>{{placeholder}}</option>-->
                <option :value="i" v-for="(i, index) of filteredData">{{i.name}}</option>
            </select>
        </div>

    </div>

</template>

<script>
    export default {
        name: 'multiselect-directive',
        props: {
            id: {
                type: String,
                required: true
            },
            classes: {
                type: [String, Array],
                default: 'form-control'
            },
            validation: {
                type: [String, Array],
                default: null
            },
            placeholder: {
                type: String,
                default: 'Start typing'
            },
            list: {
                type: Array,
                default: () => [],
                required: true
            },
            listSelected: {
                type: Array,
                default: () => [],
                required: true
            }
        },
        data() {
            return {
                searchText: '',
                show: false,
                mobileSelected: []
            }
        },
        watch: {
            mobileSelected: function(w) {
                console.log(w);
                console.log(this.mobileSelected);
                function comparer(otherArray){
                    return function(current){
                        return otherArray.filter(function(other){
                            return other.id === current.id
                        }).length === 0;
                    }
                }
                let onlyInA = this.listSelected.filter(comparer(w));
                let onlyInB = w.filter(comparer(this.listSelected));
                let result = onlyInA.concat(onlyInB);
                if (w.length) {
                    if (w.length > this.listSelected.length) {
                        result.forEach(obj => {
                            this.listSelected.push(obj);
                        });
                        console.log(result);
                    } else {
                        result.forEach(obj => {
                            let index = this.listSelected.indexOf(obj);
                            if (index !== -1) {
                                this.listSelected.splice(index, 1);
                            }
                        });
                    }
                } else {
                    result.forEach(obj => {
                        let index = this.listSelected.indexOf(obj);
                        if (index !== -1) {
                            this.listSelected.splice(index, 1);
                        }
                    });
                }

            },
            isValid: function (res) {}
        },
        computed: {
            filteredData() {
                return this.list.filter(item => {
                    return item.name.toLowerCase().includes(this.searchText.toLowerCase())
                })
            },
            isValid() {
                if (this.validation?.length) {
                    if (this.listSelected.length) {
                        return ''
                    } else {
                        return null
                    }
                } else {
                    return ''
                }
            }
        },
        mounted() {
            console.log(this.list);
            console.log(this.listSelected);
            document.addEventListener('click', this.closeDropdown)
        },
        beforeDestroy () {
            document.removeEventListener('click', this.closeDropdown)
        },
        methods: {
            onMobileSelectChange(e) {
                console.log(e);
                /*if (e.target.value) {
                    let q = this.filteredData.filter(o => {return o.id === parseInt(e.target.value)});
                    this.selectItem(q);
                } else {
                    console.log(this.listSelected);
                    function comparer(otherArray){
                        return function(current){
                            return otherArray.filter(function(other){
                                return other.id === current.id
                            }).length === 0;
                        }
                    }

                    let onlyInA = this.listSelected.filter(comparer(this.mobileSelected));
                    let onlyInB = this.mobileSelected.filter(comparer(this.listSelected));

                    let result = onlyInA.concat(onlyInB);
                    result.forEach(obj => {
                        let index = this.listSelected.indexOf(obj);
                        if (index !== -1) {
                            this.listSelected.splice(index, 1);
                        }
                    });

                    console.log(result);
                }*/
            },
            closeDropdown(e) {
                let input = this.$el.getElementsByTagName('input');
                let search = this.$el.getElementsByClassName('search-list');
                ((!input[0].contains(e.target) && (!search.length)) || (!input[0].contains(e.target) && (!search[0].contains(e.target)))) ? this.show = false : this.show = true;
            },
            selectItem(i) {
                if (!i['selected']) {
                    this.listSelected.push(i);
                }
                i['selected'] = true;
	            this.$forceUpdate();
				this.$emit('itemSelected');
                // console.log(i);
                // console.log(this.listSelected);
            },
            deselectItem(i, index, from) {
                if (from === 'list') {
                    let indexx = this.listSelected.findIndex((s) => s.id === i.id);
                    if (indexx !== -1) {
                        this.listSelected.splice(indexx, 1);
                    }
                    i.selected = false;
                    this.$forceUpdate();
                } else {
                    this.listSelected.splice(index, 1);
                    i.selected = false;
	                this.$forceUpdate();
                }
            },
            onChange() {
                this.$emit('change', this.searchText);
            },
            onKeyPress(event) {
                this.$emit('keypress', event);
            },
            onKeyUp(event) {
                this.$emit('keyup', event, this.searchText);
                if (!this.searchText.length) {
                    this.clearInput();
                } else {
                    this.show = true;
                }
            },
            onFocus() {
                this.$emit('focus');
            },
            onBlur() {
                this.$emit('blur');
            },
            clearInput() {
                this.searchText = '';
            },
            qwe() {
                this.$refs['select1'].focus();
            }
        }
    }

</script>

<style lang="less" scoped>
    @import "../../assets/styles/_vars";
    #multiselect-directive {
        position: relative;
        .selected-list {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 10px;
            .list-item {
                font-weight: 400;
                font-size: 15px;
                color: #828282;
                border: 1px solid #ced4da;
                background-color: #ffffff;
                padding: 3px 7px 3px 10px;
                border-radius: 5px;
                margin-bottom: 5px;
                &:not(:last-child) {
                    margin-right: 10px;
                }
                span {
                    position: relative;
                    margin-right: 10px;
                    &:after {
                        content: '';
                        background-color: #ced4da;
                        width: 1px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: -7px;
                    }
                }
                img {
                    width: 15px;
                    height: 15px;
                    transform: rotate(45deg);
                    cursor: pointer;
                }
            }
        }
        .input-wrapper {
            span {
                .a {
                    position: relative;
                    input {
                        padding-right: 36px!important;
                    }
                    .search-icon {
                        background-image: url("../../assets/img/search.svg");
                        background-repeat: no-repeat;
                        position: absolute;
                        right: 10px;
                        margin-top: -12px;
                        top: 50%;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }
                    .delete-icon {
                        background-image: url("../../assets/img/delete-x.svg");
                        background-repeat: no-repeat;
                        transform: rotate(45deg);
                        position: absolute;
                        right: 10px;
                        margin-top: -12px;
                        top: 50%;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }
                }

            }
        }
        .search-list {
            position: absolute;
            width: 100%;
            left: 0;
            height: auto;
            max-height: 150px;
            overflow-y: auto;
            padding: 0;
            background-color: white;
            border: 1px solid #ced4da;
            z-index: 3;
            &#desktop-select-wrapper {}
            &#mobile-select-wrapper {
                display: none;
            }
            label {
                display: none;
            }
            .no-data-found {
                text-align: left;
                font-size: 13px;
                color: lightcoral;
                padding: 5px 15px;
            }
            .list-item {
                text-align: left;
                font-size: 13px;
                color: #828282;
                font-weight: 400;
                cursor: pointer;
                padding: 5px 15px;
                &:hover {
                    background-color: #E8F1F9;
                }
                &.selected {
                    color: @default_selection;
                    font-weight: 500;
                }
            }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {  }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #multiselect-directive {
            .search-list {
                &#desktop-select-wrapper {
                    display: none;
                }
                &#mobile-select-wrapper {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {}
</style>
