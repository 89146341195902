<template>
    <div id="question" :style="{'margin-bottom': hasMargin+'px', 'padding-bottom': item.padding+'px', 'padding-bottom': lastElement ? '20px': 0}">
        <div class="post-item-wrapper">
            <div class="post-item" :class="{ visible: item.show_item }">
                <div class="post-body-wrapper" @mouseenter="setHoverState(true)" @mouseleave="setHoverState(false)">
                    <div class="post-body" :style="[(item.show_item || item.hover || item.added_to_filter) ? {'background-color': item.bg_color} : {}]" :class="{'opened': item.show_item, 'hover': item.hover}" @click="showItemBlock(item)" >
	                     <div class="delete-icon" v-if="item.added_to_filter" @click.prevent.stop="deselect(item)">
                            <i class="far fa-trash-alt" ></i>
                        </div>
                        <div class="color-indicator" v-if="!item.show_item" :style="{'background-color': item.bg_color}"></div>
                        <div class="post-body-main-title">
                            {{item.name}}
                        </div>
                        <div class="post-body-counters" v-if="item.show_item">
                            <div class="post-counters">
                                <div class="post-footer-likes">
                                    <span class="icon"></span>
                                    <span class="all-count">{{item.answer_count}}</span>
                                    <span class="inner-circle-count">{{item.friend_answers_count}}</span>
                                </div>
                                <div class="post-footer-comments">
                                    <span class="icon"></span>
                                    <span class="all-count">{{item.comments_count}}</span>
                                    <span class="inner-circle-count">{{item.friend_comments_count}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--v-show="item.hover && !item.show_item"-->
                    <div class="share-ui" :style="{'background-color': item.bg_color}" v-show="item.hover && !item.show_item">
                        <button :disabled="loading" class="def-btn in-app-share" :class="{'loading': loading, 'inverted': item.selected_filter}" @click="!item.selected_filter ? showItemBlock(item) : null">{{!item.selected_filter ? 'Добавить вопрос для поиска' : 'Этот вопрос уже выбран для поиска'}}</button>
                        <!--<button class="socials-share"><img src="../../assets/img/Icon-social-facebook.svg" alt="facebook"></button>
                        <button class="socials-share"><img src="../../assets/img/Icon-social-vk.svg" alt="vk"></button>-->
                        <div class="post-counters">
                            <div class="post-footer-likes">
                                <span class="icon"></span>
                                <span class="all-count">{{item.answer_count}}</span>
                                <span class="inner-circle-count">{{item.friend_answers_count}}</span>
                            </div>
                            <div class="post-footer-comments">
                                <span class="icon"></span>
                                <span class="all-count">{{item.comments_count}}</span>
                                <span class="inner-circle-count">{{item.friend_comments_count}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="post-body-questions" v-show="item.show_item">
                        <div class="radio-buttons-wrapper" v-if="item.is_radio === 1">
                            <div class="u-radiobutton-wr" :class="{'radiobutton-with-avatar': showAvatars, 'read-only': readOnly}" v-for="(i, index) of item.answers" :key="i.id" @click="handleSelectClick(item, index)" :disabled="item.added_to_filter || hasAnswers">
                                <avatar class="avatar-wrapper my-checked" v-if="i.checked && showAvatars" :width="30" :height="30" :img="user.avatar_parsed"></avatar>
                                <input :id="i.name + i.id + rm" type="radio" :value="i" v-model="item.selected_filter" :name="item.name + item.id + rm" v-if="!readOnly" :disabled="item.added_to_filter || hasAnswers"/>
                                <label class="label-as-input" :class="{'previous_answer': item.changed === 1 && i.previous_checked, 'current_answer': (item.changed === 1 && i.another_checked) || (item.changed === 0 && i.another_checked) || (item.is_personal === 1 && i.another_checked), 'active': item.selected && i.id === item.selected.id}" :for="i.name + i.id + rm" :disabled="item.added_to_filter">
                                    <span class="checkmark"></span>
                                    <span class="label-name">{{i.name}}</span>
                                    <span class="answers-stats">
                                        <span class="answer-count">
                                            {{i.count}}
                                            <img :src="(item.selected && i.id === item.selected.id) ? require('../../assets/img/persons_shape_active.svg') : require('../../assets/img/persons_shape.svg')" alt="">
                                        </span>
                                        <span class="answer-percent">
                                            {{i.percent}}%
                                        </span>
                                    </span>
                                    <span class="answer-percentage-line">
                                        <span class="answer-percentage" :style="{'width': i.percent + '%'}"></span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="post-pre-footer" v-if="!readOnly" v-show="item.show_item">
                    <div class="pre-post-actions">
                        <button :disabled="loading" class="def-btn inverted" :class="{'loading': loading}" @click="deselect(item)" v-if="item.selected_filter">Удалить</button>
	                    <button :disabled="loading" class="def-btn" :class="{'loading': loading}" @click="submitSelect(item)" v-if="item.selected_filter">Добавить вопрос для поиска</button>
	                    <button :disabled="loading" class="def-btn inverted" :class="{'loading': loading}" @click="openQuestionModal(item)" v-if="!hasAnswers">Сначала необходимо ответить на вопрос</button>
                    </div>
                </div>
            </div>
        </div>
        <question-details-modal v-if="loadQuestionModal" ref="question-details-modal" :topic-global-id="topicGlobalId" :modal-title="blockTitle" :is-question-feed="showAvatars" @selectQuestion="selectQuestionAfterModal" :opened-from-search-section="true"></question-details-modal>
        <ask-question-modal ref="askQuestionRef"></ask-question-modal>
	    <custom-modal ref="questionSelectionConfirmation" :has-cancel-btn="false"></custom-modal>
    </div>
</template>

<script>
    import {questionsService} from "../../_api/questions.service";
    import visibilityItemSelect from "../_shared/VisibilityItemSelect";
    import multiselect from "../_shared/Multiselect";
    import questionDetailsModal from "../_shared/modals/_PostDetailedModal";
    import _askQuestionModal from "../_shared/modals/_AskQuestionModal";
    import avatar from "../_shared/Avatar";
    import Vue from 'vue';
    import EventBus from "../../_helpers/event-bus";
    import CustomModal from "@/components/_shared/modals/_CustomModal";
    export default {
        name: 'questionForSearchModal',
        props: {
            item: Object, // main question object
            list: Array, // array of question objects (to enable slider)
            blockTitle: String, // UI title
            hasDelete: Boolean, // used for my PQ questions
            hasEdit: Boolean, // used for my PQ questions
            topicGlobalId: Number, // either 7/8/9 (main/personal/qotd)
            hasMargin: Number, // distance between elements vertically
            deleteAfterSubmit: Boolean, // delete item from list after submitting/accepting answer
            isFeed: Boolean, // if component is rendered in home component
            readOnly: Boolean, // no way to answer the questiom,
            showAvatars: Boolean, // show avatars near answers and in the title,
	        selectedList: Boolean,
	        lastElement: Boolean
        },
        data () {
            return {
                rm: Math.random(),
                loading: false,
	            loadQuestionModal: false
            }
        },
        components: {
	        CustomModal,
            questionDetailsModal,
            visibilityItemSelect,
            multiselect,
            avatar,
            'ask-question-modal': _askQuestionModal
        },
        created() {},
        mounted(){
			console.log(this.hasAnswers);
			/*if (this.hasAnswers) {
				this.item['added_to_filter'] = true;
			}*/
        },
        computed: {
            user () {
                return this.$store.state.authentication.user;
            },
	        hasAnswers() {
				return this.item.answers.filter((i) => {return i.checked}).length
	        }
        },
        methods: {
            setHoverState(val) {
                this.item['hover'] = val;
                this.$forceUpdate();
            },
            submitSelect(item, from_modal) {
                console.log(item);
	            this.item['added_to_filter'] = true;
				this.item.show_item = false;
	            this.item.selected_filter['question_name'] = this.item.name;
	            EventBus.$emit('addQuestionToFilter', this.item, false);
	            if (from_modal) {
		            this.$refs['questionSelectionConfirmation'].show({
			            message: 'Вопрос добавлен для поиска людей',
			            okButton: 'OK',
		            }).then((result) => {
			            console.log(result);
		            });
	            } else {
		            this.$store.dispatch('alert/success', 'Вопрос добавлен в выбранные', { root: true });
	            }
	           
                this.$forceUpdate();
            },
	        deselect(item) {
                console.log(item);
		        this.item['added_to_filter'] = false;
		        this.item['selected_filter'] = null;
		        this.item.show_item = false;
		        EventBus.$emit('addQuestionToFilter', this.item, true);
		        if (item.object_id === parseInt(this.$route.query.question_for_search)) {
			        this.$router.replace({});
		        }
		        this.$forceUpdate();
            },
	        selectQuestionAfterModal() {
				console.log(this.item);
		        this.item.selected_filter = this.item.answers.filter((obj) => {
			        obj['question_name'] = this.item.name;
			        if (obj.checked) {
				        return obj
			        }
		        })[0];
				this.submitSelect(this.item, true);
		        
		        // this.item.selected_filter['question_name'] = this.item.name;
	        },
	        handleSelectClick(item, index) {
				console.log(this.hasAnswers);
				console.log(item);
				console.log(index);
				if (this.hasAnswers) {
					(item.has_answer_mode) ? this.setCheckedForFilterManually(i, index) : this.setCheckedManually(i, index)
				}
	        },

            getSingleQuestionsCounters() {
                let checked = this.item.answers.filter((obj) => {
                    if (obj.checked) {
                        return obj
                    }
                })[0];
                questionsService.getSingleQuestionsCountersAPI({
                    question_id: this.item.question_id,
                    checked_id: (checked) ? checked.id : null,
                    is_personal: 0
                }).then(res => {
                    if (res) {
                        this.item.answers.forEach((obj, index) => {
                            let rexp = 'ABCDEFGHIKLMNOPQRSTVXYZ';
                            let q = res.answers[obj.id];
                            obj['count'] = q.count;
                            obj['friend_count'] = q.friend_count;
                            obj['percent'] = q.percent;
                            obj['friend_percent'] = q.friend_percent;
                            obj['letter'] = rexp[index];
                        });
                        this.$forceUpdate();
                    }
                });
            },

            showAskQuestionModal(item) {
                this.$refs.askQuestionRef.showModal(item);
            },
            setCheckedManually(i, index) {
                this.item.answers.forEach((obj) => {
                    obj.my_checked = false;
                    obj.checked = false;
                });
                this.item.answers[index].checked = true;
                this.item.selected = this.item.answers[index];
                this.item.has_answer_mode = false;
                this.$forceUpdate();
            },
            setCheckedForFilterManually(i, index) {
                if (!this.item.added_to_filter) {
                    this.item.selected_filter = this.item.answers[index];
                    this.$forceUpdate();
                }
            },

            showItemBlock(item) {
                console.log(item);
                item['show_item'] = !item['show_item'];
                if (item['show_item']) {
                    this.getSingleQuestionsCounters();
                }
                this.$forceUpdate();
            },
	
	        openQuestionModal(data) {
				console.log(data);
		        this.loadQuestionModal = true;
		        this.$nextTick(() => {
			        this.$refs['question-details-modal'].showModal('main', data, []);
		        })
	        }
        },
        watch: {}
    }
</script>

<style lang="less" scoped>
    @import "../../assets/styles/_vars.less";

    #question {
	    &:hover {
		    z-index: 1;
	    }
        .post-item-wrapper {
            //padding-bottom: 18px;
            .post-item {
                box-shadow: 0 0 6px -4px black;
                border-radius: 1px;
                background: #FFFFFF;
                z-index: 0;
                &.visible {
                    z-index: 99;
                    // box-shadow: 0 0 11px -4px black;
                    position: relative;
                }
                .post-header {
                    padding: 20px 38px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .post-header-title {
                        text-align: left;
                        font-size: 13px;
                        font-weight: 500;
                        color: #999999;
                        display: flex;
                        align-items: center;
                        .avatar-wrapper {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 13px;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .post-header-time {
                        font-size: 11px;
                        color: #999999;
                        font-weight: 400;
                        min-width: 60px;
                        div {
                            cursor: pointer;
                            &:first-child {
                                margin-right: 25px;
                            }
                            &.date {
                                margin: 0;
                            }
                            &.header-controls {
                                margin-right: 0;
                                .control-icons {
                                    width: 20px;
                                    &:first-child {
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                .post-body-wrapper {
                    position: relative;
                    .share-ui {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        position: absolute;
                        bottom: 0;
                        border-radius: 35px;
                        left: 50%;
                        width: max-content;
                        padding: 5px 8px;
                        box-shadow: 0 0 8px 0 #ffffff;
                        background: white;
                        transform: translate(-50%, 50%);
	                    z-index: 1;
                        button {
                            border: none;
                            outline: none;
                            margin-right: 3px;
                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }
                        }
                        .in-app-share {
                            /*background-color: @theme_color;
                            color: #ffffff;
                            font-size: 10px;
                            font-weight: 500;
                            text-transform: uppercase;*/
                            border-radius: 50px;
                            // padding: 7px 14px;
                        }
                        .socials-share {
                            border-radius: 50px;
                            background-color: #4495CE;
                            padding: 0!important;
                            height: 32px;
                            width: 32px;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .post-counters {
                            display: flex;
                            cursor: pointer;
                            .post-footer-likes {
                                display: flex;
                                margin-right: 15px;
                                font-size: 12px;
                                font-weight: 500;
                                align-items: center;
                                span {
                                    &.icon {
                                        width: 24px;
                                        height: 24px;
                                        background-image: url("../../assets/img/Radio button gray.svg");
                                        display: flex;
                                        margin-right: 4px;
                                    }
                                    &.all-count {
                                        font-weight: 500;
                                        font-size: 12px;
                                        color: #666666;
                                        margin-right: 8px;
                                        position: relative;
                                        &:after {
                                            content: '';
                                            position: absolute;
                                            right: -4px;
                                            top: 0;
                                            height: 100%;
                                            width: 1px;
                                            background-color: #E0E3EA;
                                        }
                                    }
                                    &.inner-circle-count {
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: #9B9B9B;
                                    }
                                }
                            }
                            .post-footer-comments {
                                display: flex;
                                font-size: 12px;
                                font-weight: 500;
                                align-items: center;
                                span {
                                    &.icon {
                                        display: flex;
                                        width: 24px;
                                        height: 24px;
                                        background-image: url("../../assets/img/Comment Gray.svg");
                                        margin-right: 4px;
                                    }
                                    &.all-count {
                                        font-weight: 500;
                                        font-size: 12px;
                                        color: #666666;
                                        margin-right: 8px;
                                        position: relative;
                                        &:after {
                                            content: '';
                                            position: absolute;
                                            right: -4px;
                                            top: 0;
                                            height: 100%;
                                            width: 1px;
                                            background-color: #E0E3EA;
                                        }
                                    }
                                    &.inner-circle-count {
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: #9B9B9B;
                                    }
                                }
                            }
                        }
                    }
                    .post-body {
                        // padding: 26px 26px 52px 26px;
                        padding: 26px 40px 26px 40px;
                        position: relative;
                        overflow: hidden;
                        cursor: pointer;
                        transition: all 200ms;
                        &.opened {
                            padding: 52px 40px 52px 40px;
                        }
	                    .delete-icon {
		                    position: absolute;
		                    right: 10px;
		                    top: 10px;
	                    }
                        .color-indicator {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 26px;
                            height: 100%;
                        }
                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: auto;
                            opacity: 0.6;
                        }
                        .post-body-tags {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-wrap: wrap;
                            margin-bottom: 36px;
                            z-index: 2;
                            .tag {
                                font-size: 9px;
                                text-align: center;
                                color: #000000cc;
                                font-weight: 500;
                                padding: 2px 22px;
                                background-color: transparent;
                                border: 1px solid rgba(0,0,0,0.1);
                                border-radius: 16px;
                                margin: 0 5px 5px;
                                text-transform: uppercase;
                            }
                        }
                        .post-body-main-title {
                            font-size: 18px;
                            color: #000000;
                            text-align: center;
                            font-weight: 500;
                            z-index: 2;
                        }
                        .post-body-counters {
                            position: absolute;
                            right: 20px;
                            bottom: 10px;
                            .post-counters {
                                display: flex;
                                cursor: pointer;
                                .post-footer-likes {
                                    display: flex;
                                    margin-right: 15px;
                                    font-size: 12px;
                                    font-weight: 500;
                                    align-items: center;
                                    span {
                                        &.icon {
                                            width: 24px;
                                            height: 24px;
                                            background-image: url("../../assets/img/Radio button gray.svg");
                                            display: flex;
                                            margin-right: 4px;
                                        }
                                        &.all-count {
                                            font-weight: 500;
                                            font-size: 12px;
                                            color: #666666;
                                            margin-right: 8px;
                                            position: relative;
                                            &:after {
                                                content: '';
                                                position: absolute;
                                                right: -4px;
                                                top: 0;
                                                height: 100%;
                                                width: 1px;
                                                background-color: #E0E3EA;
                                            }
                                        }
                                        &.inner-circle-count {
                                            font-size: 12px;
                                            font-weight: 400;
                                            color: #9B9B9B;
                                        }
                                    }
                                }
                                .post-footer-comments {
                                    display: flex;
                                    font-size: 12px;
                                    font-weight: 500;
                                    align-items: center;
                                    span {
                                        &.icon {
                                            display: flex;
                                            width: 24px;
                                            height: 24px;
                                            background-image: url("../../assets/img/Comment Gray.svg");
                                            margin-right: 4px;
                                        }
                                        &.all-count {
                                            font-weight: 500;
                                            font-size: 12px;
                                            color: #666666;
                                            margin-right: 8px;
                                            position: relative;
                                            &:after {
                                                content: '';
                                                position: absolute;
                                                right: -4px;
                                                top: 0;
                                                height: 100%;
                                                width: 1px;
                                                background-color: #E0E3EA;
                                            }
                                        }
                                        &.inner-circle-count {
                                            font-size: 12px;
                                            font-weight: 400;
                                            color: #9B9B9B;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .post-body-questions {
                        padding: 20px 38px;
                        text-align: left;
                        .question {
                            &:not(:last-child) {
                                margin-bottom: 20px;
                            }
                            label {
                                margin: 0;
                                cursor: pointer;
                            }
                            .custom-checkbox {
                                cursor: pointer;
                                -webkit-appearance: none;
                                width: 15px;
                                height: 15px;
                                position: relative;
                                margin-right: 10px;
                                border: 2px solid rgba(113, 128, 156, 0.7);
                                box-shadow: none;
                                outline: none;
                                border-radius: 3px;
                                &:disabled {
                                    opacity: 0.7;
                                    cursor: not-allowed;
                                }
                                &:checked {
                                    &:after {
                                        content: url('../../assets/img/custom-checkbox.svg');
                                        position: absolute;
                                        top: -7px;
                                        left: 0;
                                        width: 10px;
                                        height: 8px;
                                    }
                                }
                            }
                        }
                        .radio-buttons-wrapper {
                            .u-radiobutton-wr {
                                margin-bottom: 10px;
                                &.read-only {
                                    label {
                                        padding-left: 10px;
                                    }
                                }
                                &.radiobutton-with-avatar {
                                    padding-left: 45px;
                                    position: relative;
                                }
                                .avatar-wrapper {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: 0;
                                    width: 30px;
                                    height: 30px;
                                    border: 3px solid #fff;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    overflow: hidden;
                                    border-radius: 50%;
                                    margin-top: -2px;
                                    &.my-checked {
                                        left: 15px;
                                    }
                                    &.previous-answer {
                                        opacity: 0.6;
                                    }
                                    &.single-avatar {
                                        left: 15px;
                                    }
                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                                label {
                                    // padding-left: 10px;
                                    color: #666666;
                                    &.previous_answer {
                                        opacity: 0.6;
                                    }

                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    flex-wrap: wrap;
                                    &.active {
                                        color: @theme_color;
                                        .answer-percentage-line {
                                            background-color: #c7dff0;
                                        }
                                    }
                                    .label-name {
                                        width: 80%;
                                    }
                                    .answers-stats {
                                        width: 20%;
                                        text-align: right;
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                        flex-wrap: nowrap;
                                        &>span {
                                            font-size: 11px;
                                            font-weight: 400;
                                        }
                                        .answer-count {
                                            margin-right: 20px;
                                            position: relative;
                                            &:after {
                                                content: '';
                                                position: absolute;
                                                width: 1px;
                                                background-color: #E0E3EA;
                                                height: 100%;
                                                top: 0;
                                                right: -10px;
                                            }
                                        }
                                    }
                                    .answer-percentage-line {
                                        width: 100%;
                                        height: 1px;
                                        background-color: #E0E3EA;
                                        margin-top: 5px;
                                        display: flex;
                                        position: relative;
                                        .answer-percentage {
                                            width: 20%;
                                            height: 3px;
                                            position: absolute;
                                            top: -3px;
                                            left: 0;
                                            background-color: @theme_color;
                                        }
                                    }
                                    /*&.current_answer {
                                        color: #4495CE;
                                    }*/
                                }
                            }
                        }
                    }
                }
                .post-pre-footer {
                    display: flex;
                    padding: 0 38px 20px;
                    justify-content: space-between;
                    transition: all 500ms;
                    align-items: flex-start;
                    flex-wrap: nowrap;
                    .pre-post-actions {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        width: 100%;
	                    button {
		                    &:not(:last-child) {
			                    margin-right: 10px;
		                    }
	                    }
                        .submit-answer {
                            text-transform: uppercase;
                            border-radius: 35px;
                            border: none;
                            background-color: @theme_color;
                            color: #fff;
                            text-align: center;
                            padding: 7px 30px;
                            font-size: 10px;
                            font-weight: 500;
                            outline: none;
                            position: relative;
                            &.save-answer {
                                margin-right: 5px;
                            }
                            &.accept-answer {
                                &:not(:first-child) {
                                    margin-top: 7px;
                                }
                            }
                            &.loading {
                                &:after {
                                    content: '';
                                    background-image: url(../../assets/img/Infinity-1s-200px.svg);
                                    background-size: 30px 20px;
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    width: 30px;
                                    height: 20px;
                                    margin-top: -10px;
                                }
                                // background-image: url(../../assets/img/Infinity-1s-200px.svg);
                            }
                        }
                    }
                    .pre-post-visibility {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        flex-wrap: wrap;
                        width: 100%;
                        .visibility-wrapper {
                            display: flex;
                            align-items: center;
                            margin-bottom: 7px;
                            .text {
                                font-size: 12px;
                            }
                        }
                        .is-important {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            // margin-top: 7px;
                            padding-right: 33px;
                            .custom-checkbox {
                                cursor: pointer;
                                -webkit-appearance: none;
                                width: 15px;
                                height: 15px;
                                min-width: 15px;
                                position: relative;
                                // margin-right: 10px;
                                border: 2px solid rgba(113, 128, 156, 0.7);
                                box-shadow: none;
                                outline: none;
                                border-radius: 3px;
                                &:disabled {
                                    opacity: 0.7;
                                    cursor: not-allowed;
                                }
                                &:checked {
                                    &:after {
                                        content: url('../../assets/img/custom-checkbox.svg');
                                        position: absolute;
                                        top: -7px;
                                        left: 0;
                                        width: 10px;
                                        height: 8px;
                                    }
                                }
                            }
                            label {
                                font-size: 12px;
                                margin-bottom: 0;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .post-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 20px 38px;
                    .post-counters {
                        display: flex;
                        cursor: pointer;
                        .post-footer-likes {
                            display: flex;
                            margin-right: 15px;
                            font-size: 12px;
                            font-weight: 500;
                            align-items: center;
                            span {
                                &.icon {
                                    width: 24px;
                                    height: 24px;
                                    background-image: url("../../assets/img/Radio button gray.svg");
                                    display: flex;
                                    margin-right: 4px;
                                }
                                &.all-count {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #666666;
                                    margin-right: 8px;
                                    position: relative;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        right: -4px;
                                        top: 0;
                                        height: 100%;
                                        width: 1px;
                                        background-color: #E0E3EA;
                                    }
                                }
                                &.inner-circle-count {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #9B9B9B;
                                }
                            }
                        }
                        .post-footer-comments {
                            display: flex;
                            font-size: 12px;
                            font-weight: 500;
                            align-items: center;
                            span {
                                &.icon {
                                    display: flex;
                                    width: 24px;
                                    height: 24px;
                                    background-image: url("../../assets/img/Comment Gray.svg");
                                    margin-right: 4px;
                                }
                                &.all-count {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #666666;
                                    margin-right: 8px;
                                    position: relative;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        right: -4px;
                                        top: 0;
                                        height: 100%;
                                        width: 1px;
                                        background-color: #E0E3EA;
                                    }
                                }
                                &.inner-circle-count {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #9B9B9B;
                                }
                            }
                        }
                    }
                    .post-actions {
                        button {
                            text-transform: uppercase;
                            border-radius: 35px;
                            border: 1px solid lightgrey;
                            background-color: transparent;
                            color: #333333;
                            text-align: center;
                            padding: 7px 30px;
                            font-size: 10px;
                            font-weight: 500;
                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
	    #question {
		    .post-item-wrapper {
			    .post-item {
				    .post-body-wrapper {
					    .post-body-questions {
						    padding: 5px 5px 5px 0;
						    .radio-buttons-wrapper {
							    .u-radiobutton-wr {
								    label {
									    .label-name {
										   
									    }
								    }
							    }
						    }
					    }
				    }
			    }
		    }
	    }
    }
</style>

