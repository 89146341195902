export const ORIENTATIONS_OPTIONS = [
    {
        id: 1,
        label: 'Гетеро'
    }, {
        id: 2,
        label: 'Гомо'
    }, {
        id: 3,
        label: 'Би'
    }, {
        id: 4,
        label: 'Асексуальная'
    }, {
        id: 5,
        label: 'Другая'
    }
];
