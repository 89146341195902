import Vue from "vue";

const initialState = {
    status: {
        loggedIn: false
    },
    firebaseUserData: null,
    unreadCount: 0,
    notificationsCount: 0,
    unreadListener: null,
    notificationsListener: null
};

export const firebase = {
    namespaced: true,
    state: initialState,
    actions: {
        getUserData({commit, dispatch, rootState}) {
            let q = rootState.authentication.user;
            Vue.prototype.$firebaseUsers.where('id', '==', q.id).get().then(obj => {
                if (obj.docs.length) {
                    commit('user', {
                        firebaseData: obj.docs,
                        localUserData: q
                    });
                }
            })
        },
        getUnreadCount({state, commit, dispatch, rootState}, data) {
            if (data.stopListener) {
                if (state.unreadListener) {
                    state.unreadListener();
                }
            } else {
                let q = rootState.authentication.user;
                state.unreadListener = Vue.prototype.$firebaseChats.where('chatMembers', 'array-contains', q.fb_id).where('unreadCount', '>', 0).onSnapshot(snapshot => {
                    let count = snapshot.docs.length;
                    let arr = [];
                    snapshot.docs.forEach(obj => {
                        arr.push(
                            new Promise((resolve, reject) => {
                                Vue.prototype.$firebaseChats.doc(obj.id).collection('messages').where('status', '==', 0).where('authorId', '!=', q.id).get().then(s => {
                                    let filtered = s.docs.filter(ob => {
                                        return ob.data().authorId !== q.id;
                                    });
                                    if (!filtered.length) {
                                        count -= 1;
                                    }
                                    resolve(true);
                                });
                            })
                        )
                    });

                    Promise.all(arr).then((res) => {
                        commit('unread', count);
                    })
                });
            }
        },
        getNotificationsCount({state, commit, dispatch, rootState}, data) {
            if (data.stopListener) {
                if (state.notificationsListener) {
                    state.notificationsListener();
                }
            } else {
                let getUser = function(data) {
                    return new Promise(((resolve, reject) => {
                        Vue.prototype.$firebaseUsers.where('fb_id', '==', data).get().then(querySnapshot => {
                            if (!querySnapshot.empty) {
                                resolve(querySnapshot.docs[0].data());
                            } else {
                                resolve(null);
                            }
                        })
                    }))
                };
                let q = rootState.authentication.user;
                state.notificationsListener = Vue.prototype.$firebaseNotifications.where('to_user_fb_id', '==', q.fb_id).where('is_checked', '==', false).orderBy('timestamp', 'desc').onSnapshot(snapshot => {

                    let count = 0;
                    snapshot.docs.forEach((o, index) => {
                        // console.log(o.data());
                        if (o.data().type !== 2 && o.data().type !== 7) {
                            count += 1;
                        }
                        if (index === snapshot.docs.length - 1) {
                            commit('notifications', count);
                        }
                    });
                    if (!snapshot.docs.length) {
                        init = false;
                        commit('notifications', count);
                    }
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === 'added') {
                            counter += 1;
                            if (counter === snapshot.docs.length && !init) {
                                getUser(change.doc.data().from_user_fb_id).then((res) => {
                                    let q = snapshot.docs[0].data();
                                    q['id'] = snapshot.docs[0].id;
                                    q['from_user'] = res;
                                    q['author'] = null;
                                    if (change.doc.data().author_fb_id) {
                                        getUser(change.doc.data().author_fb_id).then(c_obj => {
                                            q['author'] = c_obj;
                                            windowsNotifications(q);
                                        })
                                    } else {
                                        windowsNotifications(q);
                                    }
                                    console.log(q);

                                })
                            }
                            if (counter === snapshot.docs.length && init) {
                                init = false;
                            }
                        } else if (change.type === 'modified') {
                            // console.log('modified');
                        } else if (change.type === 'removed') {
                            // console.log('removed');
                        }
                    })
                });


                let init = true;
                let counter = 0;
                let windowsNotifications = function(nt) {
                    // console.log(nt);
                    // console.log(Notification.permission);
                    const isSupported = () =>
                        'Notification' in window &&
                        'serviceWorker' in navigator &&
                        'PushManager' in window;
                    if (isSupported()) {
                        if (Notification.permission !== 'granted') {
                            Notification.requestPermission().then(res => {
                            })
                        } else {
                            let obj = {
                                title: 'Up&Up',
                                icon: require('../assets/img/favicon.png'),
                                body: null,
                                requireInteraction: false,
                                tag: nt.id,
                                url: process.env.VUE_APP_BASE_APP_URL
                            };
                            console.log(nt);

                            switch (nt.type) {
                                case 1:
                                    // obj['body'] = 'chat notification';
                                    break;
                                case 2:
                                    if (nt.is_personal) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'} на ваш комментарий в персональном вопросе`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/personal-questions?tab=1&fn=1&qi=${nt.parent_comment.question_id}&ip=${nt.is_personal ? 1 : 0}&pos=comments&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    } else {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'} на ваш комментарий`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/main-questions?tab=1&fn=1&qi=${nt.parent_comment.question_id}&ip=${nt.is_personal ? 1 : 0}&pos=comments&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    }
                                    break;
                                case 3:
                                    if (!nt.is_friend && nt.is_friend_request) {
                                        obj['body'] = `${nt.from_user.name}  ${(nt.from_user.sex === 'f') ? 'отправила' : 'отправил'} запрос в друзья Up&Up`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}users/my-circle?tab=1`;
                                    } else if (nt.is_friend && !nt.is_friend_request) {
                                        obj['body'] = `${nt.from_user.name}  ${(nt.from_user.sex === 'f') ? 'приняла' : 'принял'} ваш запрос в друзья Up&Up`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}users/my-circle?tab=0`;
                                    }
                                    break;
                                case 4:
                                    if (nt.is_personal_question) {
                                        obj['body'] = `Ваш персональный вопрос ${nt.body} удалили по причине: ${nt.message}`
                                    } else if (nt.is_comment && nt.is_personal_comment) {
                                        obj['body'] = `Ваш комментарий ${nt.body} на персональном вопросе удалили по причине: ${nt.message}`
                                    } else if (nt.is_comment && !nt.is_personal_comment) {
                                        obj['body'] = `Ваш комментарий ${nt.body} удалили по причине: ${nt.message}`
                                    }
                                    break;
                                case 5:
                                    if (!nt.is_personal) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'переслала' : 'переслал'} вам вопрос: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/main-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    } else if ((nt.is_personal && !nt.is_redirected) || (nt.is_personal && nt.is_redirected && (nt.author_fb_id === nt.from_user_fb_id))) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'переслала' : 'переслал'} вам персональный вопрос: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/personal-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    } else if (nt.is_personal && nt.is_redirected && (nt.author_fb_id !== nt.from_user_fb_id)) {
                                        obj['body'] = `${nt.from_user.name} переслал(-a) вам ${(q.fb_id === nt.author_fb_id) ? 'Ваш' : ''} персональный вопрос ${(q.fb_id !== nt.author_fb_id) ? 'пользователя' : ''} ${(nt.author && (q.fb_id !== nt.author_fb_id)) ? nt.author.name : ''}: ${nt.question.name}`;

                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/main-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    }
                                    break;
                                case 6:
                                    if ((nt.is_personal && !nt.is_changed && !nt.is_redirected) && (q.fb_id === nt.author_fb_id)) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'} на Ваш персональный вопрос: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/personal-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    }
                                    else if ((nt.is_personal && !nt.is_changed && !nt.is_redirected) && (nt.author_fb_id === nt.from_user_fb_id)) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'} на свой персональный вопрос который Вы переслали: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/personal-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    }
                                    else if (nt.is_personal && !nt.is_changed && nt.is_redirected && (nt.author_fb_id !== nt.from_user_fb_id)) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'} на ${(q.fb_id === nt.author_fb_id) ? 'Ваш' : ''} персональный вопрос ${(q.fb_id !== nt.author_fb_id) ? 'пользователя' : ''} ${(nt.author && (q.fb_id !== nt.author_fb_id)) ? nt.author.name : ''} который Вы пересылали: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/personal-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    }
                                    else if (!nt.is_personal && !nt.is_changed) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'ответила' : 'ответил'} на вопрос который Вы переслали: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/main-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main`;
                                    }
                                    else if (!nt.is_personal && nt.is_changed) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'изменила' : 'изменил'} ответ на вопрос который Вы переслали: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/main-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    }
                                    else if ((nt.is_personal && nt.is_changed && !nt.is_redirected) || ((nt.is_personal && nt.is_changed && nt.is_redirected) && (nt.author_fb_id === nt.from_user_fb_id))) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'изменила' : 'изменил'} ответ на ваш персональный вопрос: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/personal-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    }
                                    else if (nt.is_personal && nt.is_changed && nt.is_redirected && (nt.author_fb_id !== nt.from_user_fb_id)) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'изменила' : 'изменил'} ответ на ${(q.fb_id === nt.author_fb_id) ? 'Ваш' : ''} персональный вопрос ${(q.fb_id !== nt.author_fb_id) ? 'пользователя' : ''} ${(nt.author && (q.fb_id !== nt.author_fb_id)) ? nt.author.name : ''} который Вы пересылали: ${nt.question.name}`;
                                        obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/personal-questions?tab=1&fn=1&qi=${nt.question.id}&ip=${nt.is_personal ? 1 : 0}&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    }
                                    break;
                                case 7:
                                    /*if (!nt.question.is_comment_enabled) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'выключила' : 'выключил'} возможность комментировать вопрос: ${nt.question.name}`
                                    } else if (!nt.question.is_comment_enabled) {
                                        obj['body'] = `${nt.from_user.name} ${(nt.from_user.sex === 'f') ? 'включила' : 'включил'} возможность комментировать вопрос: ${nt.question.name}`
                                    }*/
                                    break;
                                case 8:
                                    obj['body'] = `Вопрос ${nt.question.name} был изменен админом и переведен в Основные. Просьба проверить и актуализировать ранее предоставленный ответ`;
    
                                    obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/main-questions?tab=2&fn=1&qi=${nt.question.id}&ip=0&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}`;
                                    break;
                                case 9:
                                    obj['body'] = `Ваш персональный вопрос ${nt.question.name} был переведен админом в Основные вопросы`;
    
                                    obj['url'] = `${process.env.VUE_APP_BASE_APP_URL}questions/main-questions?tab=2&fn=1&qi=${nt.question.id}&ip=0&pos=main&from_id=${nt.from_user.id}&from_name=${nt.from_user.name}&type=${nt.type}`;
                                    break;
                            }

                            if (obj.body !== null) {
                                let notification = new Notification(obj.title, obj);
                                notification.onclick = function () {
                                    window.open(obj.url);
                                };
                            }
                        }
                    }
                };
            }
        }
    },
    mutations: {
        user(state, data) {
            if (data.firebaseData.length) {
                state.firebaseUserData = data.firebaseData[0].data(); // firebase data obj of the current logged in user
            }
        },
        unread(state, data) {
            state.unreadCount = data;
        },
        notifications(state, data) {
            state.notificationsCount = data;
        }
    },
    getters: {}
};
