import { render, staticRenderFns } from "./QuestionPeopleSearchModalBody.vue?vue&type=template&id=7022ee7b&scoped=true&"
import script from "./QuestionPeopleSearchModalBody.vue?vue&type=script&lang=js&"
export * from "./QuestionPeopleSearchModalBody.vue?vue&type=script&lang=js&"
import style0 from "./QuestionPeopleSearchModalBody.vue?vue&type=style&index=0&id=7022ee7b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7022ee7b",
  null
  
)

export default component.exports