<template>
    <b-modal ref="init-questions-modal" size="xl" @hide="resetModal()" id="init-questions-modal" :no-close-on-backdrop="true">
        <template v-slot:modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <div class="question-counter-wrapper">
                <div class="prev arrow" @click="prev()" v-bind:class="{ disabled: firstEl }"></div>
                <div class="question-counter">
                    {{'вопрос ' + index + ' из ' + listOfQuestions.length}}
                </div>
                <div class="next arrow" @click="next('top')" v-bind:class="{ disabled: lastEl }"></div>
            </div>

            <button type="button" @click="close()" class="close">×</button>
        </template>
        <div class="question-item">
            <div class="question-body" v-bind:style="{'background-color': activeQuestion.color}">
                <div class="question-body-tags">
                    <div class="tag" v-for="tag of activeQuestion.topics">
                        {{tag.name}}
                    </div>
                </div>
                <div class="question-body-main-title">
                    {{activeQuestion.name}}
                </div>
            </div>
            <div class="radio-buttons-wrapper" v-if="activeQuestion.is_radio === 1">
                <div class="u-radiobutton-wr" v-for="i of activeQuestion.answers" :class="{'invalid': err}">
                    <input :id="i.name + i.id + activeQuestion.id" type="radio" :value="i" v-model="activeQuestion.selected" :name="i.name + i.id + activeQuestion.id"/>
                    <label class="label-as-input" :for="i.name + i.id + activeQuestion.id">
                        <span class="checkmark"></span>
                        {{i.name}}
                    </label>
                </div>
            </div>
        </div>
        <template v-slot:modal-footer="{ ok, cancel, hide }">
            <div class="visibility-wrapper">
                <span class="text">Ответ могут видеть:</span>
                <visibility-item-select v-bind:visibilityType="activeQuestion.visibility" v-on:updateType="refreshVisibility($event)" class="def-icon" :custom-visibility-list="customVisibilityList"></visibility-item-select>
            </div>
            <div class="is-important">
                <label :for="activeQuestion.id" class="checkbox-container" :class="{'disabled': activeQuestion.has_answer_mode}">
                    <input type="checkbox" class='custom-checkbox' :disabled="activeQuestion.has_answer_mode" :id="activeQuestion.id" v-model="activeQuestion.is_important">
                    <span class="checkmark"></span>
                    Мне это важно
                </label>
            </div>
            <!-- <b-button size="sm" variant="secondary-text">
                Пропустить вопрос
            </b-button> -->
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="btns-wrapper">
                <b-button size="sm" variant="secondary-text" v-bind:class="{ disabled: lastEl }" @click="skip()">
                    Пропустить вопрос
                </b-button>
                <b-button size="sm" variant="primary" v-if="!lastEl" @click="next('bottom')">
                    Перейти к следующему вопросу
                </b-button>
                <b-button size="sm" variant="primary" v-if="lastEl" v-bind:class="{ disabled: loadingIndicator }" @click="submitModal">
                    Ок
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import multiselect from "../Multiselect";
    import visibilityItemSelect from '../VisibilityItemSelect';
    export default {
        name: 'initQuestionsModal',
        data() {
            return {
                index: 1,
                activeQuestion: {},
                firstEl: false,
                lastEl: false,
                loadingIndicator: false,
                listOfQuestions: [],
                err: false,
	            customVisibilityList: [
		            {
			            description: 'Все',
			            class: 'eye',
			            type: 1
		            },
		            {
			            description: 'Друзья Up&Up',
			            class: 'circle',
			            type: 2
		            }/*,
		            {
			            description: 'По запросу',
			            class: 'secret',
			            type: 0
		            }*/
	            ],
            }
        },
        components: {
            'multiselect': multiselect,
            'visibility-item-select': visibilityItemSelect
        },
        methods: {
            show(questions) {
                this.listOfQuestions = [...questions];
                this.checkIndex();
                this.activeQuestion = this.listOfQuestions[this.index - 1];
                this.$refs['init-questions-modal'].show();
            },
            hide() {
                this.resetModal();
                this.loadingIndicator = false;
                this.$refs['init-questions-modal'].hide();
            },

            resetModal() {
                this.index = 1;
                this.checkIndex();
                this.activeQuestion = this.listOfQuestions[this.index - 1];
                this.loadingIndicator = false;
            },
            checkIndex() {
                console.log(this.index, this.listOfQuestions.length);
                if (this.index === this.listOfQuestions.length && this.index === 1) {
                    this.firstEl = true;
                    this.lastEl = true;
                } else if (this.index === this.listOfQuestions.length) {
                    this.firstEl = false;
                    this.lastEl = true;
                }else if (this.index === 1) {
                    this.firstEl = true;
                    this.lastEl = false;
                } else {
                    this.firstEl = false;
                    this.lastEl = false;
                }
            },
            refreshVisibility(e) {
                this.activeQuestion.visibility = e;
            },

            skip() {
                this.err = false;
                this.activeQuestion['selected'] = null;
                if (this.index < this.listOfQuestions.length) {
                    this.index++;
                    this.activeQuestion = this.listOfQuestions[this.index - 1];
                    this.checkIndex();
                }
            },
            prev() {
                if (this.index > 1) {
                    this.index--;
                    this.activeQuestion = this.listOfQuestions[this.index - 1];
                    this.checkIndex();
                }
            },
            next(pos) {
                if (this.activeQuestion.selected === null && pos === 'bottom') {
                    this.err = true;
                } else {
                    this.err = false;
                    if (this.index < this.listOfQuestions.length) {
                        this.index++;
                        this.activeQuestion = this.listOfQuestions[this.index - 1];
                        this.checkIndex();
                    }
                }

            },

            submitModal() {
                this.loadingIndicator = true;
                this.$emit('submitModal', this.listOfQuestions);
            },
        },
        mounted() {},
        beforeCreate() {},
        created() {},
        watch: {
            ['activeQuestion.selected']: function (res) {
                console.log(res);
                this.err = false;
            }
        }
    }
</script>

<style lang="less">
    @import "../../../assets/styles/_vars";
    #init-questions-modal {
        .modal-dialog {
            &.modal-xl {
                max-width: 960px;
            }
            .modal-content {
                .modal-header {
                    .question-counter-wrapper {
                        margin: 0 auto;
                        position: relative;
                        .question-counter {
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 28px;
                            color: #666666;
                        }
                        .arrow {
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            top: 50%;
                            border-bottom: 2px solid @theme_color;
                            cursor: pointer;
                            &.prev {
                                left: -30px;
                                border-left: 2px solid @theme_color;
                                transform: rotate(45deg) translateY(-50%);
                            }
                            &.next {
                                right: -30px;
                                border-right: 2px solid @theme_color;
                                transform: rotate(-45deg) translateY(-50%);
                            }
                            &.disabled {
                                border-color: #E0E0E0!important;
                                cursor: not-allowed;
                            }
                        }
                    }
                    .close {
                        color: #ffffff;
                    }
                }
                .modal-body {
                    padding: 0px;
                    .question-item {
                        .question-body {
                            padding: 26px 26px 52px 26px;
                            .question-body-tags {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-wrap: wrap;
                                margin-bottom: 36px;
                                .tag {
                                    font-size: 9px;
                                    text-align: center;
                                    color: #000000cc;
                                    font-weight: 500;
                                    padding: 2px 22px;
                                    background-color: transparent;
                                    border: 1px solid rgba(0,0,0,0.1);
                                    border-radius: 16px;
                                    margin: 0 5px 5px;
                                    text-transform: uppercase;
                                }
                            }
                            .question-body-main-title {
                                font-size: 18px;
                                color: #000000;
                                text-align: center;
                                font-weight: 500;
                            }
                        }
                        .question-multiselect-wrapper {
                            padding: 30px 50px;
                        }
                        .radio-buttons-wrapper {
                            padding: 30px 50px;
                            .u-radiobutton-wr {
                                margin-bottom: 10px;
                            }
                            .error {
                                color: #dc3545;
                                font-size: 13px;
                            }
                        }

                    }
                }
                .modal-footer {
                    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);
                    display: flex;
                    justify-content: space-between;
                    .visibility-wrapper {
                        display: flex;
                        align-items: center;
                        .text {
                            font-size: 12px;
                            margin-right: 10px;
                        }
                    }
                    .is-important {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .checkbox-container {
                            font-size: 12px;
                            margin-bottom: 0;
                            display: block;
                            position: relative;
                            cursor: pointer;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            &.disabled {
                                opacity: 0.7;
                                cursor: not-allowed;
                            }
                            .custom-checkbox {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                height: 0;
                                width: 0;
                                &:checked {
                                    &~.checkmark {
                                        &:after {
                                            display: block;
                                        }
                                    }
                                }
                            }
                            .checkmark {
                                position: absolute;
                                top: 0;
                                right: -20px;
                                height: 15px;
                                width: 15px;
                                background-color: white;
                                border: 2px solid #9ba6ba;
                                border-radius: 3px;
                                &:after {
                                    content: "";
                                    position: absolute;
                                    display: none;
                                    left: 3px;
                                    top: 0;
                                    width: 5px;
                                    height: 9px;
                                    border: solid #9ba6ba;
                                    border-width: 0 2px 2px 0;
                                    -webkit-transform: rotate(45deg);
                                    -ms-transform: rotate(45deg);
                                    transform: rotate(45deg);
                                }
                            }
                        }
                        /*margin-top: 7px;
                        .custom-checkbox {
                            cursor: pointer;
                            -webkit-appearance: none;
                            width: 15px;
                            height: 15px;
                            min-width: 15px;
                            position: relative;
                            margin-right: 10px;
                            border: 2px solid rgba(113, 128, 156, 0.7);
                            box-shadow: none;
                            outline: none;
                            border-radius: 3px;
                            &:disabled {
                                opacity: 0.7;
                                cursor: not-allowed;
                            }
                            &:checked {
                                &:after {
                                    content: url('../../assets/img/custom-checkbox.svg');
                                    position: absolute;
                                    top: -7px;
                                    left: 0;
                                    width: 10px;
                                    height: 8px;
                                }
                            }
                        }
                        label {
                            font-size: 12px;
                            margin-bottom: 0;
                            margin-right: 10px;
                        }*/
                    }
                    .btns-wrapper {
                        display: flex;
                        button {
                            &.disabled {
                                color: #E0E0E0;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
        }
    }


    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {

    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #init-questions-modal {
            .modal-dialog {
                .modal-content {
                    .modal-header {
                        .question-counter-wrapper {
                            .question-counter {
                                font-size: 18px;
                                line-height: 18px;
                            }
                        }
                    }
                    .modal-body {
                        .question-item {
                            .question-body {
                                padding: 16px 16px 26px 16px;
                                .question-body-tags {
                                    margin-bottom: 12px;
                                }
                            }
                            .question-multiselect-wrapper {
                                padding: 30px 50px;
                            }
                            .radio-buttons-wrapper {
                                padding: 20px 20px;
                            }

                        }
                    }
                    .modal-footer {
                        .visibility-wrapper {
                            justify-content: space-between;
                            align-items: center;
                        }
                        .is-important {
                            margin-right: 17px;
                        }
                        .btns-wrapper {
                            margin-top: 20px;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            button {
                                width: 100%;
                                max-width: 320px;
                                font-size: 13px;
                                margin: 0 auto 10px auto;
                                &:first-child {
                                    border: 1px solid gray;
                                    color: gray;
                                    border-radius: 35px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
    }
</style>
