<template>
    <div id="questions" v-if="!loading">
        <DynamicScroller :items="(!selectedForFilterView) ? list : selectedForFilterList" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="1000">
            <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item]" :emitResize="true">
                    <QuestionPost v-if="!isModal" :is-feed="false" :item="item" :list="list" :block-title="item.title" :show-item="false" :has-delete="false" :topic-global-id="7" ></QuestionPost>
                    <question-for-search-modal v-if="isModal" :item="item" :list="(!selectedForFilterView) ? list : selectedForFilterList" :block-title="item.title" :show-item="false" :has-delete="false" :topic-global-id="7" :show-avatars="true" :show-title-avatar="true"></question-for-search-modal>
                </DynamicScrollerItem>
            </template>
        </DynamicScroller>
    </div>
</template>

<script>
    import {questionsService} from "@/_api";
    import QuestionPost from "../../feed/QuestionPostBody";
    import QuestionForSearchModal from "../QuestionPeopleSearchModalBody";
    import EventBus from "../../../_helpers/event-bus";
    import {parsePostBody} from "@/_helpers/post-body-parser";

    export default {
        name: 'all-questions',
        props: {
            topicId: Number,
            isModal: Boolean,
            searchStr: String,
            activeSearch: Boolean
        },
        data () {
            return {
                loading: true,
                list: [],
                loadIndexThreshold: 10,
                loadIndex: 10,
                page: 0,
                selectedForFilter: [],
                selectedForFilterView: false,
                selectedForFilterList: []
            }
        },
        components: {
            QuestionForSearchModal,
            QuestionPost
        },
        created() {
            if (!this.activeSearch) {
                this.getQuestions(this.topicId);
            }
        },
        mounted() {
            EventBus.$on('passingAlreadySelectedQ', (data) => {
                this.selectedForFilterView = false;
                this.selectedForFilter = data;
            });
            EventBus.$on('showSelected', (data) => {
                this.selectedForFilterView = true;
                this.selectedForFilter = data;
                data.forEach((obj, index) => {
                    (index === data.length - 1) ? obj['padding'] = 20 : obj['padding'] = 0;
                });
                this.selectedForFilterList = data;
            });
        },
        beforeDestroy() {
            EventBus.$off('passingAlreadySelectedQ');
            EventBus.$off('showSelected');
        },
        computed: {},
        methods: {
            handleScroll(startIndex, endIndex) {
                if (endIndex === this.loadIndex) {
                    this.loadIndex += this.loadIndexThreshold;
                    (!this.searchStr.length) ? this.getQuestions(this.topicId) : this.searchQuestions(this.topicId, this.searchStr);
                }
            },
            getQuestions(top_id) {
                questionsService.getAllQuestions({
                    page: (!this.page) ? 1 : this.page + 1,
                    topic_id: top_id !== 9999999 ? top_id : null
                }).then((res) => {
                    if (res) {
                        res.data.forEach((obj, index) => {
                            parsePostBody(obj).then(res_q => {
                                res_q['has_answer'] = res_q.answers.some(i => {
                                    if (i.checked) {
                                        res_q['selected'] = i;
                                    }
                                    return i.checked;
                                });
                                res_q['title'] = 'Основной вопрос';
                                res_q['has_answer_mode'] = res_q['has_answer'];

                                // data for filter
                                res_q['padding'] = 0;
                                if (index === res.data.length - 1 && res.last_page === res.current_page) {
                                    res_q['padding'] = 20;
                                }
                                res_q['added_to_filter'] = false;
                                res_q['selected_filter'] = null;
                                this.selectedForFilter.forEach((c_obj) => {
                                    if (c_obj.question_id === res_q.question_id) {
                                        res_q['added_to_filter'] = true;
                                        res_q['selected_filter'] = c_obj.selected_filter;
                                        this.$forceUpdate();
                                        let index = res_q.answers.findIndex((o) => {return o.id === res_q.selected_filter.id});
                                        if (index !== -1) {
                                            res_q.answers[index] = c_obj.selected_filter;
                                        }
                                        this.$forceUpdate();
                                    }
                                });
                                this.list.push(res_q);
                            });
                        });
                        if (res.data.length) {
                            //this.page = res.current_page;
                            this.page += 1;
                        }
                    }
                    this.loading = false;
                });


            },
            searchQuestions(top_id, search_str) {
                questionsService.searchQuestionsAPI({
                    page: (!this.page) ? 1 : this.page + 1,
                    perPage: 10,
                    topic_id: top_id !== 9999999 ? top_id : null,
                    query: search_str,
                    type: null, // opened/closed/blank if all,
                    is_day: 0
                }).then((res) => {
                    console.log(res);
                    if (res) {
                        res.data.forEach((obj, index) => {
                            parsePostBody(obj).then(res_q => {
                                res_q['title'] = 'Основной вопрос';
                                res_q['has_answer'] = res_q.answers.some(i => {
                                    if (i.checked) {
                                        res_q['selected'] = i;
                                    }
                                    return i.checked;
                                });
                                res_q['has_answer_mode'] = res_q['has_answer'];

                                // data for filter
                                res_q['padding'] = 0;
                                if (index === res.data.length - 1 && res.last_page === res.current_page) {
                                    res_q['padding'] = 20;
                                }
                                res_q['added_to_filter'] = false;
                                res_q['selected_filter'] = null;
                                this.selectedForFilter.forEach((c_obj) => {
                                    if (c_obj.question_id === res_q.question_id) {
                                        res_q['added_to_filter'] = true;
                                        res_q['selected_filter'] = c_obj.selected_filter;
                                        this.$forceUpdate();
                                        let index = res_q.answers.findIndex((o) => {return o.id === res_q.selected_filter.id});
                                        if (index !== -1) {
                                            res_q.answers[index] = c_obj.selected_filter;
                                        }
                                        this.$forceUpdate();
                                    }
                                });

                                this.list.push(res_q);
                            });

                        });
                        if (res.data.length) {
                            this.page += 1;
                        }
                    }
                    this.loading = false;
                });
            },
            resetPage(id) {
                this.page = 0;
                this.list = [];
                this.selectedForFilterView = false;
                this.loadIndex = this.loadIndexThreshold;
            }
        },

        watch: {}
    }
</script>

<style lang="less" scoped>
    #questions {}
</style>
