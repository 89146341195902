<template>
    <div id="list-of-chats-page">
        <div class="list-title-wrapper">
            <div class="list-title">
                Сообщения
            </div>
            <div class="list-controls">
                <img src="../../../assets/img/pencil-create.svg" alt="write" @click="showFriendsModal">
                <img src="../../../assets/img/more-vertical.svg" alt="more">
            </div>
        </div>
        <div class="search-wrapper">
            <search-filter :show-error="false" :placeholderText="'Поиск людей'" :items="arr" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" ref="search" :search-disabled="searchLoading"></search-filter>
            <transition name="fade">
                <div v-if="!searchLoading && search_str.length && searchResults.length" class="search-results">
                    <div class="chat-item" v-for="i of searchResults" @click="setActive(i.id, true)" :class="{'active-chat': i.active}">
                        <avatar :width="46" :height="46" :img="i.friendData.avatar_parsed"></avatar>
                        <div class="person-data-wrapper">
                            <div class="name-date">
                                <p class="person-name">{{i.friendData.name}}</p>
                                <p class="last-message-date" v-if="i.lastMessage">{{i.lastMessage.timestamp_formatted}}</p>
                            </div>
                            <div class="progress-notification">
                                <person-progress width="82" height="5" :green="i.similar_answers_percent" :red="i.diff_answers_percent" :gray="i.not_answered_percent"></person-progress>
                                <p :style="[ i.unreadCount < 1 || i.active ? {visibility:'hidden'}: {visibility:'visible'}]" class="unread-count">{{i.unreadCount}}</p>
                            </div>
                            <div class="last-message-preview">
                                <div class="last-message" v-if="i.lastMessage">
                                    <p class="last-message-author">{{i.lastMessage.authorName}}</p>
                                    <span>: "</span>
                                    <p class="last-message-text">{{i.lastMessage.text}}</p>
                                    <span>"</span>
                                </div>
                                <div class="no-messages" v-if="!i.lastMessage">
	                                {{i.isFriend ? 'Новый друг' : ''}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!searchLoading && search_str.length && !searchResults.length" class="search-results no-results">
                    По данному запросу не найдено пользователей с которыми вы обменивались сообщениями
                </div>
            </transition>
        </div>
	    <loading v-if="isLoading" class="x2"></loading>
        <!--<div class="loading-list-of-chats" v-if="isLoading"><img src="../../../assets/img/Infinity-1s-200px.svg" alt="loading"></div>-->

        <div class="chat-is-not-selected" v-show="showTip && isMobile">
	        Для того, чтобы начать общение, добавьте собеседников в Друзья Up&Up.
            <br/>
            Новых друзей Up&Up Вы можете найти через “Поиск”, а также среди предложенных Пользователей в разделе "Похожие"
            <button class="def-btn" @click="redirectTo()">Перейти</button>
        </div>

        <div id="list-of-chats-wrapper" class="list-of-chats-wrapper" v-if="!isLoading" v-scroll-bottom>
            <div class="chat-item" v-for="i of chatsList" @click="setActive(i)" :class="{'active-chat': i.active}">
                <avatar :width="46" :height="46" :img="i.friendData.avatar_parsed"></avatar>
                <div class="person-data-wrapper">
                    <div class="name-date">
                        <p class="person-name">{{i.friendData.name}}</p>
                        <p class="last-message-date" v-if="i.lastMessage">{{moment(i.lastMessage.timestamp_formatted, 'YYYY-MM-DD').format('DD.MM.YYYY')}}</p>
                    </div>
                    <div class="progress-notification">
                        <person-progress width="82" height="5" :green="i.similar_answers_percent" :red="i.diff_answers_percent" :gray="i.not_answered_percent"></person-progress>
                        <p :style="[ i.unreadCount < 1 || i.active ? {visibility:'hidden'}: {visibility:'visible'}]" class="unread-count">{{i.unreadCount}}</p>
                    </div>
                    <div class="last-message-preview">
                        <div class="last-message" v-if="i.lastMessage">
                            <p class="last-message-author">{{(user.id !== i.lastMessage.authorId) ? (i.lastMessage.authorName.name) ? i.lastMessage.authorName.name : i.lastMessage.authorName : 'Вы'}}</p>
                            <span>: "</span>
                            <p class="last-message-text">{{i.lastMessage.text}}</p>
                            <span>"</span>
                        </div>
                        <div class="no-messages" v-if="!i.lastMessage">
	                        {{i.isFriend ? 'Новый друг' : ''}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <friends-modal ref="friendsModal" @setChatActive="setActive"></friends-modal>
    </div>
</template>

<script>
    import personProgress from "../../_shared/UserProgress";
    import friendsModal from '../../_shared/modals/_FriendsModal'
    import avatar from "../../_shared/Avatar";
    import searchFilter from '../../_shared/SearchFilter';
    import Loading from "@/components/_shared/Loading";

    export default {
        name: 'ListOfChats',
        components: {Loading, personProgress, friendsModal, avatar, searchFilter},
        props: ['chatsList', 'isLoading', 'searchResults', 'searchLoading', 'showTip', 'isMobile'],
        data() {
            return {
                arr: [],
                search_str: ''
            }
        },
        computed: {
	        deviceWidth() {
		        return this.$store.state.misc.device_width
	        },
	        user () {
		        return this.$store.state.authentication.user;
	        }
	        
        },
        methods: {
            handleMobileHeight() {
                let el = document.getElementById('list-of-chats-wrapper');
                if (this.deviceWidth <= 768) {
                    el.style['height'] = window.innerHeight-180 + 'px';
                }
            },
            redirectTo() {
                this.$router.push({ path: '/users/search'});
            },
            onSearchChange(data) {
                console.log(data);
                this.search_str = data;
            },
            filteredList(data) {
                console.log(data);
                this.search_str = data.searchStr;
                this.$emit('searchChat', this.search_str);
            },
            setActive(chat, from_modal) {
	            this.$emit('setActive', chat, false, from_modal);
	            this.$refs.search.remoteClearSearch();
	            this.search_str = '';
	            this.$emit('searchChat', this.search_str);
            },
            showFriendsModal() {
                this.$refs.friendsModal.showModal();
            },
            loadMore() {
                console.log('LOAD MORE');
                this.$emit('loadMore');
            }
        },
        mounted() {
			console.log(this.user);
            window.addEventListener("resize", function() {
                let el = document.getElementById('list-of-chats-wrapper');
                if (this.deviceWidth <= 768) {
                    el.style['height'] = window.innerHeight-180 + 'px';
                }
            });
        },
        created() {},
        watch: {
	        chatsList: function (res) {
				//console.log(res);
	        }
        },
    }
</script>

<style lang="less" scoped>
    @import "../../../assets/styles/_vars";
    #list-of-chats-page {
        // max-width: 233px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
	    .loading-grid {
		    margin-top: 40px;
	    }
        .chat-is-not-selected {
            padding: 40px;
            color: #666;
            .def-btn {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                width: 100%;
                max-width: 150px;
                margin: 20px auto 0 auto;
            }
        }
        .search-wrapper {
            position: relative;
            margin-bottom: 10px;
	        width: 100%;
            .search-filter {
                width: 100%;
                background: #fff;

                border-radius: 5px;
            }
            .search-results {
                position: absolute;
                left: 0;
                top: 100%;
                background-color: #fff;
                width: 100%;
                min-height: 50px;
                z-index: 20;
                padding: 7px 3px;
                max-height: 500px;
                overflow-y: auto;
                overflow-x: hidden;
                box-shadow: 0 1px 12px -4px black;
                &.no-results {
                    color: gray;
                    text-align: left;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                }
                &::-webkit-scrollbar{
                    height: 4px;
                    width: 4px;
                    background: gray;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb{
                    background: lightgrey;
                    border-radius: 10px;
                }
                .chat-item {
                    width: 100%;
                    border-radius: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: nowrap;
                    padding: 4px 13px 4px 4px;
                    background: transparent;
                    transition: all 250ms;
                    cursor: pointer;
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                    &:hover {
                        background: #e2e4e8;
                    }
                    &.active-chat {
                        background: #e2e4e8;
                        .name-date {
                            .person-name {
                                font-weight: 500;
                            }
                        }
                    }
                    .image-as-bg {
                        margin-right: 14px;
                    }
                    .person-data-wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        p {
                            font-size: 12px;
                            color: #333333;
                            font-weight: 400;
                            margin: 0;
                            text-align: left;
                            letter-spacing: 0.2px;
                            line-height: 14px;
                        }
                        &>div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: nowrap;
                        }
                        .name-date {
                            width: 100%;
                            .person-name {
                                // max-width: 85px;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                display: block;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        .progress-notification {
                            width: 100%;
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            .person-progress {
                                margin-bottom: 8px;
                            }
                            .unread-count {
                                color: #DE5E56;
                            }
                        }
                        .last-message-preview {
                            width: 100%;
                            justify-content: flex-start;
                            font-size: 12px;
                            color: #333333;
                            font-weight: 400;
                            .last-message {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: nowrap;
                                p {
                                    &.last-message-author {
                                        display: block;
                                        //max-width: 44px;
                                        white-space: nowrap;
                                        overflow: hidden !important;
                                        text-overflow: ellipsis;
                                    }
                                    &.last-message-text {
                                        display: block;
                                        max-width: 250px;
                                        white-space: nowrap;
                                        overflow: hidden !important;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                            .no-messages {
                                color: lightgrey;
                            }
                        }

                    }
                    .notifications-count {
                        color: #999999;
                    }
                }
            }
        }
        .list-title-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .list-title {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 400;
                color: @text_color;
            }
            .list-controls {
                img {
                    cursor: pointer;
                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
        }
        .search-bar {
            width: 100%;
            margin-bottom: 16px;
            position: relative;
            label {
                position: absolute;
                cursor: pointer;
                margin: 0;
                top: 50%;
                margin-top: -10px;
                left: 15px;
                &:before {
                    content: url("../../../assets/img/search1.svg");
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
            input {
                border: none;
                background: #FFFFFF;
                border-radius: 4px;
                width: 100%;
                // height: 40px;
                padding: 12px 16px 13px 50px;
                font-size: 13px;
                line-height: 15px;
                color: @text_color;
                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    font-size: 13px;
                    line-height: 15px;
                    color: #999999;
                    opacity: 1; /* Firefox */
                }
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    font-size: 13px;
                    line-height: 15px;
                    color: #999999;
                }
                &::-ms-input-placeholder { /* Microsoft Edge */
                    font-size: 13px;
                    line-height: 15px;
                    color: #999999;
                }
            }
        }
        .loading-list-of-chats {
            img {
                width: 100px;
            }
        }
        .list-of-chats-wrapper {
            width: 100%;
            height: calc(100vh - 286px);
            overflow-x: auto;
            &::-webkit-scrollbar{
                height: 4px;
                width: 4px;
                background: gray;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb{
                background: lightgrey;
                border-radius: 10px;
            }
            .chat-item {
                width: 100%;
                border-radius: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                padding: 4px 13px 4px 4px;
                margin-bottom: 8px;
                background: transparent;
                transition: all 250ms;
                cursor: pointer;
                &:hover {
                    background: #e2e4e8;
                }
                &.active-chat {
                    background: #e2e4e8;
                    .name-date {
                        .person-name {
                            font-weight: 500;
                        }
                    }
                }
                .image-as-bg {
                    /*width: 46px;
                    height: 46px;
                    min-width: 46px;
                    max-width: 46px;
                    background-size: cover;
                    display: block;
                    border-radius: 100px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;*/
                    margin-right: 14px;
                }
                .person-data-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    p {
                        font-size: 12px;
                        color: #333333;
                        font-weight: 400;
                        margin: 0;
                        text-align: left;
                        letter-spacing: 0.2px;
                        line-height: 14px;
                    }
                    &>div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: nowrap;
                    }
                    .name-date {
                        width: 100%;
                        .person-name {
                            // max-width: 85px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                    .progress-notification {
                        width: 100%;
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        .person-progress {
                            margin-bottom: 8px;
                        }
                        .unread-count {
                            color: #DE5E56;
                        }
                    }
                    .last-message-preview {
                        width: 100%;
                        justify-content: flex-start;
                        font-size: 12px;
                        color: #333333;
                        font-weight: 400;
                        .last-message {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: nowrap;
                            p {
                                &.last-message-author {
                                    display: block;
                                    // max-width: 50px;
                                    white-space: nowrap;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis;
                                }
                                &.last-message-text {
                                    display: block;
                                    max-width: 250px;
                                    white-space: nowrap;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        .no-messages {
                            color: lightgrey;
                        }
                    }

                }
                .notifications-count {
                    color: #999999;
                }
            }
        }
    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
	    #list-of-chats-page {
		    .search-wrapper {
			    .search-results {
				    .chat-item {
					    .person-data-wrapper {
						    .progress-notification {
							    .last-message-preview {
								    .last-message {
									    p {
										    &.last-message-text {
											    max-width: 150px;
										    }
									    }
								    }
							    }
						    }
					    }
				    }
				
				    .list-of-chats-wrapper {
					    .chat-item {
						    .person-data-wrapper {
							    .last-message-preview {
								    .last-message {
									    p {
										    &.last-message-text {
											    max-width: 150px;
										    }
									    }
								    }
							    }
						    }
					    }
				    }
			    }
		    }
	    }
    }
</style>
