<template>
    <div id="right-sidebar">
        <!--<div class="block-wrapper">
            <img src="../../assets/img/icons8-cloud-user_1.svg" alt="cloud_user">
            <h4>Узнайте свои личностные особенности</h4>
            <p>Ответив еще на 15 вопросов</p>
            <router-link to="/questions/main-questions">Подробнее</router-link>
            &lt;!&ndash;<p>Узнайте свои личностные особенности Ответив еще на 15 вопросов <router-link to="/questions/type/main">Подробнее</router-link></p>
            <p>Заполните профиль полнее Чтобы люди, которым вы подходите, смогли вас найти <router-link to="/me">Подробнее</router-link></p>&ndash;&gt;
        </div>-->
        <div class="block-wrapper" v-if="!isProfileFilled">
            <img src="../../assets/img/icons8-cloud-user_1.svg" alt="cloud_user">
            <h4>Заполните профиль полнее</h4>
            <p>Чтобы люди, которым вы подходите, смогли вас найти</p>
            <router-link to="/me">Подробнее</router-link>
        </div>
	    <questions-right-sidebar :class="{'full': isProfileFilled}" :topicList="topic_list" :activeTab="1" :questionType="11"></questions-right-sidebar>
    </div>
</template>

<script>
	import {usersService} from "@/_api";
	import QuestionsRightSidebar from "@/components/questions/QuestionsRightSidebar";

    export default {
        name: 'rightSidebar',
	    components: {QuestionsRightSidebar},
	    data() {
			return {
				isProfileFilled: true,
				topic_list: [],
			}
	    },
	    mounted() {
			this.checkIfProfileFilled();
		    this.getTopicList();
	    },
	    computed: {
		    topicList() {
			    return this.$store.state.question.topic_list_home;
		    },
		    activeTopicId() {
			    return this.$store.state.question.active_home_topic_id;
		    }
		    
	    },
	    methods: {
			checkIfProfileFilled() {
				usersService.checkIfProfileFilledAPI().then((res) => {
					this.isProfileFilled = res;
				})
			},
		    getTopicList() {
			    this.$store.dispatch('question/getTopics', 11);
		    }
	    },
	    watch: {
		    topicList: function(val) {
			    console.log(val);
			    this.topic_list = val;
		    },
		    activeTopicId: function(val) {
			    console.log(val);
			    // this.$refs[this.currentTab.activeComp].resetPage(val);
			    // this.$refs[this.currentTab.activeComp].getQuestions(val);
			    this.$emit('topicChangeEvent', val);
		    }
	    }
    }
</script>

<style scoped lang="less">
    #right-sidebar {
        width: 30.4%;
        position: -moz-sticky;
        position: -o-sticky;
        position: -ms-sticky;
        position: sticky;
        position: -webkit-sticky;
        top: 94px;
        .block-wrapper {
            background-color: #ededed;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 22px;
            padding: 55px 22px 30px 22px;
            img {
                margin-bottom: 10px;
                width: 64px;
                height: 64px;
            }
            h4 {
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                color: #000000;
                margin-bottom: 10px;
                line-height: 21px;
            }
            p {
                font-size: 13px;
                font-weight: 400;
                text-align: center;
                color: #000000;
                margin-bottom: 15px;
                line-height: 15px;
            }
            a {
                text-transform: uppercase;
                border-radius: 36px;
                border: 1px solid lightgrey;
                background-color: transparent;
                color: #000000;
                text-align: center;
                padding: 7px 30px;
                font-size: 10px;
                font-weight: 500;
            }
        }
	    #questions-right-sidebar {
		    width: 100%;
		    max-height: calc(100vh - 450px);
		    overflow-y: auto;
		    &.full {
			    max-height: calc(100vh - 100px);
		    }
	    }
    }


    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {

    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {
        #right-sidebar {
            display: none;
        }
    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
</style>
