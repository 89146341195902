<template>
    <div id="notifications" :class="{'is-dropdown': isDropdown}" @scroll="onScroll($event, 'earlier')" v-click-outside="hideDropdown">
        <div class="latest notifications-wrapper">
            <div class="title">Новые</div>
            <div class="notification-list" @scroll="onScroll($event, 'latest')">
                <notification-body v-for="nt in filterLatest" :key="nt.id" :class="{'loaded': !loading}" :nt="nt" :is-earlier-nt="true" :loading="loading"></notification-body>
            </div>
        </div>
        <div class="earlier notifications-wrapper" :class="{'no-latest': !notificationList.latest.length}" v-if="notificationList.earlier.length">
            <div class="title">Ранее</div>
            <!--@scroll="onScroll($event, 'earlier')"-->
            <div class="notification-list">
                <notification-body v-for="nt in filterEarlier" :key="nt.id" :nt="nt" :can-change-status="false" :loading="loading"></notification-body>
            </div>
        </div>
        <div class="show-all-action" v-if="!loading && isDropdown" @click.stop.prevent="goToNotifications()">
            Все нотификации
        </div>
    </div>
</template>

<script>
    import NotificationBody from "../../components/notifications/NotificationBody";
	import {handleAWSImage} from "@/_helpers/aws-handler";
    import {questionsService, usersService} from "@/_api";

    export default {
        name: 'notifications',
        props: {
            isDropdown: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                loading: true,
                notificationListener: null,
                notificationList: {
                    latest: [],
                    earlier: []
                },
                limit: 10,
                lastVisEarlier: '',
                lastVisLatest: '',
            }
        },
        components: {NotificationBody},
        computed: {
            getFireUserData() {
                return this.$store.state.firebase.firebaseUserData
            },
            filterEarlier() {
                return this.notificationList.earlier.filter(o => o.type !== 7 && o.type !== 2)
            },
            filterLatest() {
                return this.notificationList.latest.filter(o => o.type !== 7 && o.type !== 2)
            }
        },
        mounted() {
            if (!this.getFireUserData) {
                this.$store.watch(
                    (state) => {
                        return this.$store.state.firebase.firebaseUserData
                    },
                    (newValue, oldValue) => {
                        this.getNotifications(true);
                        this.getNotifications(false);
                    }
                );
            } else {
                this.getNotifications(true);
                this.getNotifications(false);
            }
        },
        created() {},
        beforeDestroy() {
            if (this.notificationListener) {
                this.notificationListener();
            }
        },
        watch: {},
        methods: {
            onScroll(e, type) {
                if (!this.isDropdown) {
                    if (e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight) {
                        this.getNotifications((type === 'earlier') ? true : false);
                    }
                }
            },
            goToNotifications() {
                this.$router.push({ path: '/notifications'});
                this.hideDropdown();
            },
            getUser(data) {
                return new Promise(((resolve, reject) => {
                    if (data) {
                        this.$firebaseUsers.where('fb_id', '==', data).get().then(querySnapshot => {
                            if (!querySnapshot.empty) {
                                resolve(querySnapshot.docs[0].data());
                            } else {
                                resolve(null);
                            }
                        })
                    } else {
                        resolve(null);
                    }

                }))
            },
            getNotifications(type) {
                let limitCalc = () => {
                    return (this.isDropdown) ? ((!type) ? 1000 : this.limit) : this.limit;
                };
                this.notificationListener = this.$firebaseNotifications.where('to_user_fb_id', '==', this.getFireUserData.fb_id).where('is_checked', '==', type).orderBy('timestamp', 'desc').limit(limitCalc()).startAfter((type) ? this.lastVisEarlier : this.lastVisLatest).onSnapshot(snapshot => {
                    if (!type) {
                        this.lastVisLatest = snapshot.docs[snapshot.docs.length - 1];
                    } else {
                        this.lastVisEarlier = snapshot.docs[snapshot.docs.length - 1];
                    }
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === 'added') {
                            this.getUser(change.doc.data().from_user_fb_id).then((obj) => {
                                let data = change.doc.data();
                                data.id = change.doc.id;
                                data['timestamp_parsed'] = this.moment(data.timestamp.toDate()).fromNow();
                                data['from_user'] = obj || {};
                                if (obj === null) {
                                    data['from_user']['is_deleted'] = 1;
                                    data['from_user']['name'] = 'Удаленный пользователь';
                                } else {
									data['from_user']['avatar_parsed'] = null;
	                                if (data['from_user']['avatar'] !== null && data['from_user']['avatar'].includes('http')) {
		                                handleAWSImage(data['from_user']['avatar_64']).then((response) => {
			                                data['from_user']['avatar_parsed'] = response;
			                                this.$forceUpdate();
		                                })
	                                }
                                }
                                data['author'] = null;
                                if (data.author_fb_id) {
                                    this.getUser(change.doc.data().author_fb_id).then(c_obj => {
                                        data['author'] = c_obj;
                                    })
                                }
								
								/*if (data.type === 10) {
									usersService.getGalleryAPI({
										post_id: data.object_id,
										user_id: null,
										page: 1,
										perPage: 1
									}).then((response) => {
										console.log(response);
									})
								}*/
	                            // console.log(data);
								
                                if (!data.is_checked) {
                                    if (snapshot.docs.length === 1) {
                                        this.notificationList.latest.unshift(data);
                                    } else {
                                        this.notificationList.latest.push(data);
                                    }
                                } else {
                                    let index = this.notificationList.latest.findIndex((o) => {
                                        return o.id === data.id
                                    });
                                    if (index === -1) {
                                        this.notificationList.earlier.push(data);
                                    }
                                }
                                this.notificationList.latest.sort((a, b) => {return this.moment(b.timestamp.toDate()) - this.moment(a.timestamp.toDate())});
                                this.notificationList.earlier.sort((a, b) => {return this.moment(b.timestamp.toDate()) - this.moment(a.timestamp.toDate())});
                                setTimeout(() => {
                                    this.loading = false;
                                }, 500);
                            })
                        }
                        else if (change.type === 'modified') {}
                        else if (change.type === 'removed') {}
                    });
                });
            },
            hideDropdown: function (event) {
                this.$emit('handleDropdown');
            }
        }
    }
</script>

<style lang="less" scoped>
    #notifications {
        text-align: left;
        min-height: calc(100vh - 200px);
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        padding-right: 5px;
        &.is-dropdown {
            position: absolute;
            top: 20px;
            right: 0;
            z-index: 999;
            width: 315px;
            max-height: 400px;
            min-height: 400px;
            overflow-y: auto;
            background: #ffffff;
            border: 1px solid rgba(94, 111, 143, 0.3);
            box-shadow: 0 4px 8px rgba(39, 40, 51, 0.25);
            border-radius: 4px;
            padding-right: 0;
            .notifications-wrapper {
                .notification-list {
                    min-height: unset!important;
                    max-height: unset!important;
                }
            }

        }
        .notifications-wrapper {
            &.latest {
                .notification-list {
                    min-height: 0;
                    max-height: 35vh;
                    overflow-y: auto;
                }
            }
            &.earlier {
                .notification-list {}
            }
            .title {
                padding: 0 8px;
                font-weight: 500;
                font-size: 11px;
                line-height: 24px;
                text-transform: uppercase;
                color: #666666;

                border-bottom: 0.5px solid rgba(94, 111, 143, 0.3);
            }
            .notification-list {}
        }
        .show-all-action {
            text-align: right;
            text-decoration: underline;
            position: -moz-sticky;
            position: -o-sticky;
            position: -ms-sticky;
            position: sticky;
            position: -webkit-sticky;
            bottom: 0;
            background-color: #fff;
            color: #666;
            font-size: 14px;
            cursor: pointer;
            padding: 0 10px;
        }
    }
</style>
