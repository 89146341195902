<template>
    <div id="questions-right-sidebar">
        <div class="topic-wrapper">
            <div class="topic-item" v-for="i in topicList" :class="{ 'active-item': i.active && !deselected }" @click="setActiveTopic(i)" :style="{'background-color': i.layout_color, 'color': i.oposite_layout_color}">
                <span v-if="questionType === 7 && activeTab === 1 " class="description">{{i.name}}</span>
                <span v-if="questionType === 7 && activeTab === 1" class="amount">{{i.count_not_answered}}</span>
                <span v-if="questionType === 7 && activeTab === 2" class="description">{{i.name}}</span>
                <span v-if="questionType === 7 && activeTab === 2" class="amount">{{i.count_all}}</span>
                <span v-if="questionType === 7 && activeTab === 3" class="description">{{i.name}}</span>
                <span v-if="questionType === 7 && activeTab === 3" class="amount">{{i.count_answered}}</span>

                <span v-if="questionType === 8 && activeTab === 1" class="description">{{i.name}}</span>
                <span v-if="questionType === 8 && activeTab === 1" class="amount">{{i.my_count}}</span>
                <span v-if="questionType === 8 && activeTab === 2" class="description">{{i.name}}</span>
                <span v-if="questionType === 8 && activeTab === 2" class="amount">{{i.from_friends_count}}</span>

                <span v-if="questionType === 9 && activeTab === 1 " class="description">{{i.name}}</span>
                <span v-if="questionType === 9 && activeTab === 1" class="amount">{{i.count_not_answered}}</span>
                <span v-if="questionType === 9 && activeTab === 2" class="description">{{i.name}}</span>
                <span v-if="questionType === 9 && activeTab === 2" class="amount">{{i.count_all}}</span>
                <span v-if="questionType === 9 && activeTab === 3" class="description">{{i.name}}</span>
                <span v-if="questionType === 9 && activeTab === 3" class="amount">{{i.count_answered}}</span>
	
	            <span v-if="questionType === 11 " class="description">{{i.name}}</span>
	            <span v-if="questionType === 11" class="amount">{{i.count_all || i.count_not_answered}}</span>
	            
	            <span v-if="questionType === 12 " class="description">{{i.name}}</span>
	            <span v-if="questionType === 12" class="amount">{{i.count_all || i.count_not_answered}}</span>

            </div>
        </div>
        <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
    </div>
</template>

<script>
    import {usersService} from "../../_api";
    import ConfirmationModal from "../_shared/modals/_ConfirmationDialogue";
    export default {
        name: 'QuestionsRightSidebar',
        components: {ConfirmationModal},
        props: {
            topicList: Array,
            activeTab: Number,
            questionType: Number,
            isModal: Boolean,
            deselected: Boolean
        },
        data () {
            return {}
        },
        methods: {
			setAcceptableAge(data, topic_obj) {
				usersService.setUser18API({
					is_18: data
				}).then((res) => {
					if (res) {
						if (topic_obj) {
							this.$emit('topicChange', topic_obj);
							if (!this.isModal) {
								this.$store.dispatch('question/setActiveTopic', topic_obj);
							}
						}
						let userData = this.isAuthenticated;
						userData['is_18'] = data;
						
						this.$store.dispatch('authentication/updateUserData', userData);
					}
				});
			},
            setActiveTopic(item) {
                let obj = {
                    question_type: this.questionType,
                    item: item,
                    active_tab: this.activeTab
                };
                if (item.name === 'Секс') {
                    if (!this.isAuthenticated.is_18) {
	                    this.$refs.confirmDialogue.show({
		                    title: 'Delete Page',
		                    message: 'Внимание! Вопросы данной категории предназначены исключительно лицам достигшим совершеннолетия. Вам Уже исполнилось 18 лет?',
		                    okButton: 'Да',
	                    }).then((result) => {
		                    console.log(result);
		                    if (result) {
			                    this.setAcceptableAge(1, obj);
		                    }
	                    });
                    } else {
                        this.$emit('topicChange', obj);
                        if (!this.isModal) {
                            this.$store.dispatch('question/setActiveTopic', obj);
                        }
                    }
                } else {
                    this.$emit('topicChange', obj);
                    if (!this.isModal) {
                        this.$store.dispatch('question/setActiveTopic', obj);
                    }
                }

            },
        },
        created() {},
	    mounted() {
			console.log(this.topicList);
			console.log(this.activeTab);
			console.log(this.questionType);
	    },
	    computed: {
            isAuthenticated() {
                return this.$store.state.authentication.user
            }
        },
	    beforeDestroy() {
		    this.setActiveTopic(this.topicList[0]);
		    this.setAcceptableAge(0, null);
	    }
    }
</script>

<style scoped lang="less">

    ::v-deep {
        body {
            background-color: red;

        }
    }

    #questions-right-sidebar {
        width: 30.4%;
        position: -moz-sticky;
        position: -o-sticky;
        position: -ms-sticky;
        position: sticky;
        position: -webkit-sticky;
        top: 94px;
        .topic-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            .topic-item {
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 14px;
                padding: 2px 10px;
                border-radius: 30px;
                margin-bottom: 5px;
                cursor: pointer;
                &:hover {
                    background-color: #f0f0f0;
                }
                &.active-item {
                    cursor: auto;
                    background-color: #DDDDDD;
                    .description {
                        color: #333333;
                    }
                }
                .description {
                    width: 80%;
                    text-align: left;
                    // color: #5E6F8F;
                }
                .amount {
                    width: 20%;
                    text-align: right;
                }
            }
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #questions-right-sidebar {
            width: 100%;
            position: unset;
            margin-bottom: 10px;
            .topic-wrapper {
                overflow-x: auto;
                flex-direction: unset;
                &::-webkit-scrollbar{
                    height: 2px!important;
                    width: 2px!important;
                }
                .topic-item {
                    border: 1px solid lightgray;
                    font-size: 13px;
                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                    .description {
                        width: max-content;
                        margin-right: 10px;
                    }
                    .amount {
                        width: unset;
                    }
                }
            }
        }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
</style>
