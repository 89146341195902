<template>
    <div>
        <b-modal ref="question-details-modal" size="lg" hide-footer hide-header id="question-details-modal" :no-close-on-backdrop="true" @hidden="onModalHide()" @shown="onModalShown()" :hide-header-close="true">
            <div class="post-header" v-if="questionData">
                <div class="post-header-title">
                    <avatar v-if="questionData.author" class="avatar-wrapper" :width="35" :height="35" :img="questionData.author.avatar_parsed" @clickEvent="goToProfile(questionData.comments && questionData.comments.length ? questionData.comments[0].author : questionData.author)"></avatar>
                    {{questionData.title || 'Основной вопрос'}}
                </div>
                <div class="post-header-time">
                    <!--<span v-if="questionData.is_question_of_the_day || topicGlobalId === 9">{{questionData.date_q_of_day}}</span>-->
	                <div class="date" :id="'date'" v-if="showDate && questionData.dates_of_questions_the_day">
		                {{questionData.dates_if_the_day_preview}}
		                <b-tooltip v-if="questionData.dates_of_questions_the_day.length > 3" :target="'date'" custom-class="chat-interests-tooltip">{{questionData.dates_if_the_day_full}}</b-tooltip>
	                </div>
                </div>

                <div class="modal-close-btn" @click="closeModal()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </div>
            </div>
            <div class="post-wr" v-if="questionData">
                <div class="item-wrapper question-post" v-if="questionData.type !== 101">
                    <div class="post-item-wrapper" v-if="questionData">
                        <div class="post-item">
                            <div class="post-body-wrapper" :class="{'no-answer': !questionData.has_answer_mode}">
                                <div class="post-body" :style="{'background-color': questionData.color}">
                                    <div class="post-body-tags">
                                        <div class="tag" v-for="(tag, index) of questionData.topics" :key="index">
                                            {{tag.name}}
                                        </div>
                                    </div>
                                    <div class="post-body-main-title">
                                        {{questionData.name}}
                                    </div>
                                    <div class="post-body-tabs">
                                        <ul class="nav nav-tabs mb-3">
                                            <li v-for="a_tab in answersTabs"
                                                :key="a_tab.name"
                                                :class="['nav-item', { active: answersTabActive.id === a_tab.id }]"
                                                @click="questionData.has_answer_mode ? changeAnswerTab(a_tab) : null">
                                                <a class="nav-link" :class="[{ active: answersTabActive.id === a_tab.id }]">
			                                        <span v-if="a_tab.id === 1">
			                                            {{questionData.all_count}} Ответ(-ов)
			                                        </span>
                                                    <span v-else>
			                                            {{questionData.friends_count}} Ответ(-ов) от друзей Up&Up
			                                        </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="post-body-questions" :class="{'question-feed-option': isQuestionFeed}">
                                    <div class="radio-buttons-wrapper" v-if="questionData.is_radio === 1">
                                        <div class="u-radiobutton-wr" :class="{'has-answer': questionData.has_answer_mode}" v-for="(i, index) of questionData.answers" :key="i.id">
                                            <avatar class="avatar-wrapper 1" v-if="questionData.has_answer_mode && i.id === questionData.selected.id" :class="{'my-checked': i.my_checked}" :width="30" :height="30" :img="isAuthenticated.user.avatar_parsed"></avatar>
                                            <avatar class="avatar-wrapper 2" v-if="questionData.has_answer_mode && isQuestionFeed && i.another_checked" :class="{'single-avatar': i.another_checked && !i.my_checked}" :width="30" :height="30" :img="questionData.avatar_parsed"></avatar>
                                            <input :id="i.name + i.id" v-bind:disabled="questionData.has_answer_mode" type="radio" :value="i" v-model="questionData.selected" :name="questionData.name + questionData.id" @click="(questionData.has_answer_mode) ? null : setCheckedManually(i, index)"/>
                                            <label class="label-as-input" :for="i.name + i.id" :class="{'active': questionData.selected && i.id === questionData.selected.id}" @click="(questionData.has_answer_mode) ? showUsersModal(i) : null">
                                                <span class="checkmark"></span>
                                                <span class="label-name">{{i.name}}</span>
                                                <span class="answers-stats" @click="$event.preventDefault(); $event.stopPropagation(); showUsersModal(i)" v-if="questionData.has_answer_mode">
                                            <span class="answer-count">
                                                {{(answersTabActive.id === 1) ? i.count : i.friend_count}}
                                                <img :src="(i.id === questionData.selected.id) ? require('../../../assets/img/persons_shape_active.svg') : require('../../../assets/img/persons_shape.svg')" alt="">
                                            </span>
                                            <span class="answer-percent">
                                                {{(answersTabActive.id === 1) ? i.percent : i.friend_percent}}%
                                            </span>
                                        </span>
                                                <span class="answer-percentage-line" v-if="questionData.has_answer_mode">
                                            <span class="answer-percentage" :style="{'width': (answersTabActive.id === 1) ? i.percent + '%' : i.friend_percent + '%'}"></span>
                                        </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="post-pre-footer">
                                <div class="additional-options">
                                    <div v-if="topicGlobalId !== 8" class="is-important u-checkbox-wr right-side">
                                        <label :for="questionData.question_id" class="checkbox-container" :class="{'disabled': questionData.has_answer_mode}">
                                            <input type="checkbox" class='custom-checkbox' :disabled="questionData.has_answer_mode" :id="questionData.question_id" v-model="questionData.is_important">
                                            <span class="checkmark"></span>
                                            Мне это важно:
                                        </label>
                                    </div>
                                    <div class="visibility-wrapper">
                                        <span class="text">Ответ виден:</span>
                                        <visibility-item-select :disabled="questionData.has_answer_mode" :is-disabled="questionData.has_answer_mode" :visibilityType="questionData.visibility" @updateType="refreshVisibility($event)" class="def-icon" :custom-visibility-list="questionData.redirected ? customVisibilityListFull : customVisibilityList"></visibility-item-select>
                                    </div>
                                </div>
                                <div class="main-controls">
                                    <div class="main-controls_left">
                                        <button class="ask-friends" @click="showAskQuestionModal(questionData)">Cпросить других</button>
                                        <button class="comment" @click="scrollToComments(questionData)">Комментировать</button>
                                    </div>
                                    <div class="main-controls_right">
                                        <button :disabled="loading" v-if="questionData.has_answer_mode && questionData.selected" class="submit-answer" @click="changeAnswer(questionData)" :class="{'accept-answer': questionData.isFeed && !questionData.accepted}">Изменить ответ</button>
                                        <button :disabled="loading" v-if="(!questionData.has_answer && !questionData.has_answer_mode && questionData.selected) || (questionData.has_answer && !questionData.has_answer_mode && questionData.selected && !questionData.isFeed)" class="submit-answer" @click="submitAnswer(questionData)">Подтвердить ответ</button>

                                        <button :disabled="loading" v-if="questionData.has_answer && questionData.selected === null && !questionData.has_answer_mode" class="submit-answer save-answer accept-answer" :class="{'loading': loading}" @click="setQuestionUnanswered(questionData)">Сохранить</button>
                                        <button :disabled="loading" v-if="questionData.has_answer && questionData.selected === null && !questionData.has_answer_mode" class="submit-answer cancel-answer" :class="{'loading': loading}" @click="resetAnswer(questionData)">Отменить</button>

                                        <!--v-if="questionData.isFeed && questionData.has_answer_mode"-->
                                        <button :disabled="loading" v-if="questionData.isFeed && ((questionData.has_answer && questionData.selected && !questionData.accepted) || (questionData.has_answer && questionData.selected && questionData.accepted && !questionData.has_answer_mode))" class="submit-answer" :class="{'loading': loading}" @click="acceptAnswer(questionData)">Подтвердить ответ</button>
                                    </div>
                                </div>
	                            <div class="add-to-filter-controls" v-if="topicGlobalId !== 8 && questionData.has_answer_mode && questionData.selected">
		                            <button class="add-to-filter-btn" @click="addToQuestionFilter(questionData)">Использовать для поиска людей по их ответам</button>
		                            <button class="add-to-filter-btn" v-if="openedFromSearchSection" @click="closeModal(questionData)">Не использовать в поиске</button>
	                            </div>
                            </div>
                        </div>
                    </div>
                    <div hidden class="users-with-similar-answers" v-if="questionData && questionData.has_answer_mode">
                        <div class="block-title">
                            <span>{{usersList.count}} человек ответили так же, как вы</span>
                            <span v-if="usersList.count > 6" @click="showUsersModal()">Смотреть всех</span>
                        </div>
                        <div class="users-list">
                            <div class="user" v-for="(u, index) in usersList.list" @click="goToProfile(u)" :key="index">
                                <div class="avatar-wrapper">
                                    <!--<img :src="u.avatar_parsed || require('../../assets/img/no-avatar.jpg')" alt="avatar">-->
                                    <avatar :width="56" :height="56" :img="u.avatar_parsed"></avatar>
                                </div>
                                <person-progress height="4" :green="u.similar_answers_percent" :red="u.diff_answers_percent" :gray="u.not_answered_percent"></person-progress>
                                <div class="name">{{u.name}}</div>
                                <div class="city-and-age-range" v-if="u.birthday || (u.location && u.location.city.name)">
                                    <span v-if="(u.location && u.location.city.name)">{{u.location.city.name}}</span>
                                    <span v-if="u.birthday">{{moment().diff(u.birthday, 'years',false)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <comments ref="comments" v-if="questionData && questionData.has_answer_mode" :object-data="questionData" :is-post="false" :all-tabs="true" :topic-global-id="topicGlobalId" @goToProfile="goToProfile"></comments>
                </div>
                <div class="item-wrapper image-post" v-if="questionData.type === 101">
                    <div class="post-item-wrapper">
                        <div class="post-item">
                            <div class="post-body-wrapper">
                                <div :class="`img-wrapper item_grid_${questionData.photos.length} ${questionData.photos.length > 4 ? 'item_grid_4' : ''}`">
                                    <!--<img @click="showImageSliderModal(index)" draggable="false" :src="ii.photo_parsed" alt="def" v-for="(ii, index) in questionData.photos" :key="index">-->
                                    <div class="image" v-for="(ii, index) in questionData.photos" v-if="index <= 3" :key="index">
                                        <img @click="showImageSliderModal(index)" draggable="false" :src="ii.photo_parsed /*|| require('../../assets/img/300x300_no_image_placeholder.svg')*/" alt="">
                                        <loading class="x2 absolute centered" v-if="!ii.photo_parsed"></loading>
                                    </div>
                                    <span class="more-indicator" v-if="questionData.photos.length > 4">+{{questionData.photos.length - 4}}</span>
                                </div>
                                <div class="img-description" v-if="questionData.description">
                                    {{questionData.description}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <comments ref="comments" :object-data="questionData" :is-post="true" :is-feed="true" :all-tabs="false" :topic-global-id="topicGlobalId" @goToProfile="goToProfile" @onCommentsCountRefresh="getCommentsCounter()"></comments>
                </div>
            </div>


            <img v-if="questionsList && questionsList.length && questionsList.length > 1 && indexInList !== 0" class="chevrons left" src="../../../assets/img/chevron-left.svg" alt="chevron" @click="changeSliderQuestion('prev')"/>
            <img v-if="questionsList && questionsList.length && questionsList.length > 1 && indexInList !== questionsList.length - 1" class="chevrons right" src="../../../assets/img/chevron-right.svg" alt="chevron" @click="changeSliderQuestion('next')"/>

            <imageSlider ref="imageSlider" :img-index="img_index" :img-array="[questionData]" :has-comments-section="false" :is-slider="false" :two-level-modal="true" ></imageSlider>
	        <custom-modal ref="customModal"></custom-modal>
        </b-modal>

        <b-modal ref="users-modal" size="lg" hide-footer id="users-modal" :no-close-on-backdrop="true" @hidden="onUsersModalHide">
            <template #modal-title>
                <div class="post-answer-options">
                    <div class="post-answer-options_title">
                        Вариант
                    </div>
                    <div class="post-answer-options_list" v-if="questionData">
                        <span @click="selectedAnswerDetailed(a)" v-for="a in questionData.answers" :class="{'active': a.id === detailedAnswerData.id}">
                            {{a.letter}}
                        </span>
                    </div>
                    <div class="post-answer-options_users-count">
                        Выбрали {{answersTabActive.id === 2 ? detailedAnswerData.friend_count : detailedAnswerData.count}} пользователь(-я)
                    </div>
                </div>
                <div class="answer-text" v-if="detailedAnswerData">
                    <span>{{detailedAnswerData.name}}</span>
                </div>
            </template>
            <div v-if="questionData" class="post-body" :style="{'background-color': questionData.color}">
                <div class="post-body-main-title">
                    {{questionData.name}}
                </div>
            </div>
            <div class="users-list" v-if="detailedAnswerData">
                <!--{{detailedAnswerData}}-->
                <div class="user" v-for="(u, index) in detailedAnswerData.list" @click="goToProfile(u)" :key="index">
                    <!--{{u.id}}-->
                    <div class="avatar-wrapper">
                        <avatar :width="56" :height="56" :img="u.avatar_parsed"></avatar>
                    </div>
                    <person-progress height="4" :green="u.similar_answers_percent" :red="u.diff_answers_percent" :gray="u.not_answered_percent"></person-progress>
                    <div class="name">{{u.name}}</div>
                    <div class="city-and-age-range" v-if="u.birthday || (u.location && u.location.city.name)">
                        <span v-if="(u.location && u.location.city.name)">{{u.location.city.name}}</span>
                        <span v-if="u.birthday">{{moment().diff(u.birthday, 'years',false)}}</span>
                    </div>
                </div>
            </div>
        </b-modal>
        <ask-question-modal ref="askQuestionRef"></ask-question-modal>
    </div>

</template>

<script>
    import {questionsService, commentsService} from "../../../_api";
    import visibilityItemSelect from "../VisibilityItemSelect";
    import multiselect from "../Multiselect";
    import {handleAWSImage} from "@/_helpers/aws-handler";
    import avatar from "../Avatar";
    import _askQuestionModal from "./_AskQuestionModal";
    import PersonProgress from "../UserProgress";
    import Comments from "../Comments";
    import EventBus from "../../../_helpers/event-bus";
    import ImageSlider from "../ImageSlider";
    import Loading from "../Loading";
    import CustomModal from "@/components/_shared/modals/_CustomModal";

    export default {
        name: 'questionDetailsModal',
        props: {
            topicGlobalId: Number,
            isQuestionFeed: Boolean,
            modalTitle: String,
	        hasConfirmationModal: Boolean,
	        isFeed: Boolean,
	        showDate: Boolean,
	        openedFromSearchSection: Boolean
        },
        components: {
	        CustomModal,
            Loading,
            ImageSlider,
            Comments,
            PersonProgress,
            visibilityItemSelect,
            multiselect,
            avatar,
            'ask-question-modal': _askQuestionModal
        },
        data() {
            return {
                loading: false,
                answersTabActive: null,
                answersTabs: [
                    {
                        id: 1,
                        name: 'all'
                    },
                    {
                        id: 2,
                        name: 'inner-circle'
                    }
                ],
	            customVisibilityListFull: [
		            {
			            description: 'Все',
			            class: 'eye',
			            type: 1
		            },
		            {
			            description: 'Друзья Up&Up',
			            class: 'circle',
			            type: 2
		            },
		            {
			            description: 'По запросу',
			            class: 'secret',
			            type: 0
		            }
	            ],
	            customVisibilityList: [
		            {
			            description: 'Все',
			            class: 'eye',
			            type: 1
		            },
		            {
			            description: 'Друзья Up&Up',
			            class: 'circle',
			            type: 2
		            }
	            ],
                questionsList: [],
                questionData: null,
                detailedAnswerData: null,
                usersList: {
                    list: [],
                    count: 0
                },
                from_component: false,
                img_index: 0
            }
        },
        computed: {
            isAuthenticated() {
                return this.$store.state.authentication
            },
            indexInList() {
                if (this.questionData) {
                    return this.questionsList.findIndex((o) => {return o.question_id === this.questionData.question_id})
                }
            }
        },
        beforeCreate() {},
        created() {
            this.changeAnswerTab(this.answersTabs[0]);
        },
        mounted() {
        },
        methods: {
            // this is the init func that opens modal
            showModal(scrollPosition, data, list) {
                this.questionsList = list;
                this.questionData = data;
                this.questionData['scrollPosition'] = scrollPosition;
				console.log(scrollPosition);
				console.log(data);
                if (this.questionData.has_answer) {
	                this.getSingleQuestionsCounters(data);
                    setTimeout(() => {
                        // this.$refs['comments'].changeCommentsTab();
                    }, 0);
                }
                this.$refs['question-details-modal'].show();

            },
            closeModal() {
                this.$refs['question-details-modal'].hide();
            },
            onModalShown() {
                if (this.questionData['scrollPosition'] === 'comments') {
					if (this.questionData.has_answer || this.questionData.type === 101) {
						this.scrollToComments(false);
					}
                }
            },
            onModalHide() {
                this.loading = false;
                this.questionData = null;
                this.usersList = {
                    list: [],
                    count: 0
                };
            },
            onUsersModalHide() {
                this.detailedAnswerData = null;
            },
            showAskQuestionModal(item) {
                if (!item.has_answer) {
                    this.$store.dispatch('alert/warning', 'Сначала необходимо предоставить ответ', { root: true });
                } else {
                    this.$refs.askQuestionRef.showModal(item);
                }
            },

            showUsersModal(data) {
                this.detailedAnswerData = (data) ? data : this.questionData.selected;
                this.getSingleQuestionsUsersWhoAnswered(this.detailedAnswerData);
                this.$refs['users-modal'].show();
            },
            selectedAnswerDetailed(data) {
                this.detailedAnswerData = data;
                this.getSingleQuestionsUsersWhoAnswered(data);
                this.$forceUpdate();
            },

            changeAnswerTab(tab) {
                this.answersTabActive = tab;
            },
            getSingleQuestionsCounters() {
                let checked = this.questionData.answers.filter((obj) => {
                    if (this.isQuestionFeed) {
                        if (obj.my_checked) {
                            return obj
                        } else if (obj.my_answer) {
                            obj['my_checked'] = true;
                            return obj
                        } else if (obj.checked) {
                            obj['my_checked'] = true;
                            return obj
                        }
                    } else {
                        if (obj.checked) {
                            return obj
                        }
                    }
                })[0];
				console.log(checked);
                questionsService.getSingleQuestionsCountersAPI({
                    question_id: this.questionData.question_id,
                    checked_id: checked.id,
                    is_personal: (this.topicGlobalId === 8) ? 1 : 0
                }).then(res => {
                    console.log(res);
                    if (res) {
                        this.questionData.answers.forEach((obj, index) => {
                            let rexp = 'ABCDEFGHIKLMNOPQRSTVXYZ';
                            let q = res.answers[obj.id];
                            obj['count'] = q.count;
                            obj['friend_count'] = q.friend_count;
                            obj['percent'] = q.percent;
                            obj['friend_percent'] = q.friend_percent;
                            obj['letter'] = rexp[index];
							//this.questionData.selected.id
                            if (obj.id === checked) {
                                if (q.users !== undefined) {
                                    this.usersList.list = q.users;
                                    this.usersList.count = q.count;
                                    this.usersList.list.forEach((obj) => {
                                        obj['avatar_parsed'] = null;
                                        // console.log(obj);
                                        handleAWSImage(obj.avatar_64).then((res) => {
                                            obj['avatar_parsed'] = res;
                                            this.$forceUpdate()
                                        });
                                    });
                                }
                            }
                        });
                        // this.questionData['similar_count'] = 0;
                        this.questionData['friends_list'] = res.friends_list;
                        this.questionData['all_count'] = res.all_count;
                        this.questionData['friends_count'] = res.friends_count;
                        this.$forceUpdate();
                    }
                });
	            this.getCommentsCounter()
            },
	        
	        getCommentsCounter() {
		        let checked = this.questionData.answers.filter((obj) => {
			        if (this.isQuestionFeed) {
				        if (obj.my_checked) {
					        return obj
				        } else if (obj.my_answer) {
					        obj['my_checked'] = true;
					        return obj
				        } else if (obj.checked) {
					        obj['my_checked'] = true;
					        return obj
				        }
			        } else {
				        if (obj.checked) {
					        return obj
				        }
			        }
		        })[0];
		        commentsService.getCommentsCounterAPI({
			        object_id: this.questionData.question_id,
			        checked_id: checked.id
		        }).then(res => {
			        console.log(res);
			        this.questionData['similar_count'] = res.similar_count;
			        this.$forceUpdate();
		        });
	        },

            getSingleQuestionsUsersWhoAnswered(data) {
                this.detailedAnswerData['list'] = [];
                questionsService.getSingleQuestionsUsersWhoAnsweredAPI({
                    id: data.id,
                    is_personal: (this.topicGlobalId === 8) ? 1 : 0,
                    is_friend: this.answersTabActive.id === 2 ? 1 : 0
                }).then(res => {
                    console.log(res);
                    this.detailedAnswerData['list'] = [...res.users.data];
                    this.$forceUpdate();
                    this.detailedAnswerData['list'].forEach((obj) => {
                        obj['avatar_parsed'] = null;
                        console.log(obj);
                        handleAWSImage(obj.avatar_64).then((res) => {
                            obj['avatar_parsed'] = res;
                            this.$forceUpdate()
                        });
                    });
                })
            },
            changeAnswer(item) {
                item.has_answer_mode = false;
                item.selected = null;
                console.log(item);
                this.$forceUpdate();
            },
            submitAnswer(item) {
				console.log(item);
	            let method = null;
	            let objToSend = null;
				let test = () => {
					if (!item.has_answer) {
						if (item.type === 3) {
							method = questionsService.sendAnswerPQ(objToSend);
						} else {
							method = questionsService.sendAnswer(objToSend);
						}
					} else {
						if (item.type === 3) {
							method = questionsService.changeAnswerPQ(objToSend);
						} else {
							method = questionsService.changeAnswer(objToSend);
						}
					}
					method.then((res) => {
						if (res) {
							this.$store.dispatch('alert/success', 'Ответ принят', { root: true });
							this.$store.dispatch('question/getTopics', this.topicGlobalId);
							item['has_answer'] = true;
							item['has_answer_mode'] = true;
							item['accepted'] = true;
							console.log('asdasd');
							this.getSingleQuestionsCounters();
							setTimeout(() => {
								// this.$refs['comments'].changeCommentsTab();
							}, 0);
							this.$forceUpdate();
							// this.$refs['question-details-modal'].hide();
						} else {
							this.$store.dispatch('alert/error', 'Something unexpected happened', { root: true });
						}
					});
				}
	            
                if (item.selected.id) {
	                objToSend = {
		                question_id: item.selected.question_id || item.selected.personal_question_id,
		                answer_ids: [item.selected.id],
		                is_important: item.is_important,
		                visibility: item.visibility,
		                from_user_id: item.visibility === 0 ? ((item.who_asked && item.who_asked.length) ? item.who_asked[0].id : '') : '',
		                accept: (item.isFeed) ? 1 : 0
	                };
	
	                item.answers.forEach(c_obj => {
		                c_obj.checked = false;
		                if (c_obj.id === item.selected.id) {
			                c_obj.checked = true;
		                }
	                });
	
	                if (item.author && item.visibility === 0 && this.hasConfirmationModal) {
		                this.openCustomModal(item.author.name)
				                .then(() => {
					                console.log('resolve');
					                test();
				                }).catch(() => {
			                console.log('reject')
		                })
	                } else {
		                test();
	                }
                }
            },
            acceptAnswer(item){
	            let method = null;
				let test = () => {
					this.loading = true;
					if (item.type === 3) {
						method = questionsService.changeAnswerPQ(objToSend);
					} else {
						method = questionsService.changeAnswer(objToSend);
					}
					method.then((res) => {
						if (res) {
							this.$store.dispatch('alert/success', 'Ответ принят', { root: true });
							this.$store.dispatch('question/getTopics', this.topicGlobalId);
							item.has_answer = true;
							item.has_answer_mode = true;
							item['accepted'] = true;
							this.getSingleQuestionsCounters();
							this.$forceUpdate();
						} else {
							this.$store.dispatch('alert/error', 'Something unexpected happened', { root: true });
						}
						
						this.loading = false;
					});
				};
                
                let objToSend = {
                    question_id: item.selected.question_id || item.selected.personal_question_id,
                    answer_ids: [item.selected.id],
                    is_important: item.is_important,
                    visibility: item.visibility,
	                from_user_id: item.visibility === 0 ? ((item.who_asked && item.who_asked.length) ? item.who_asked[0].id : '') : '',
                    accept: (item.isFeed) ? 1 : 0
                };
                item.answers.forEach(c_obj => {
                    c_obj.checked = false;
                    if (c_obj.id === item.selected.id) {
                        c_obj.checked = true;
                    }
                });
                this.$forceUpdate();
	
	            if (item.author && item.visibility === 0 && this.hasConfirmationModal) {
		            console.log('IFFFFFFFFFFFFFFFFFFFFFF');
		            this.openCustomModal(item.author.name)
			            .then(() => {
				            console.log('resolve');
				            test();
			            }).catch(() => {
				            console.log('reject')
			            })
	            } else {
		            console.log('ELSSSSSSSSSSSE');
		            test();
	            }
            },
            resetAnswer(item) {
                item['selected'] = item.answers.filter(i => {
                    if (i.checked) {
                        return i;
                    }
                })[0];
                item.has_answer_mode = true;
                this.$forceUpdate();
            },
            setQuestionUnanswered(item) {
                questionsService.deleteAnswerAPI({
                    question_id: this.questionData.question_id,
                    is_personal: this.topicGlobalId === 8 ? 1 : 0,
                }).then((res) => {
                    this.questionData.has_answer_mode = false;
                    this.questionData.has_answer = false;
                    this.questionData.selected = null;
                    this.questionData.answers.forEach(i => {
                        i.checked = false;
                    });
                    this.$forceUpdate();
                });
            },
            refreshVisibility(e) {
                this.questionData.visibility = e;
            },
            setCheckedManually(i, index) {
                this.questionData.answers.forEach(obj => {
                    obj.my_checked = false;
                    obj.checked = false;
                });
                this.questionData.answers[index].checked = true;
                this.questionData.has_answer_mode = false;
                if (!this.questionData['selected']) {
                    this.questionData['selected'] = this.questionData.answers[index];
                } else if (this.questionData['selected'] && this.questionData['selected'].id !== i.id) {
                    this.questionData['selected'] = this.questionData.answers[index];
                } else {
                    this.questionData['selected'] = null;
                }
                this.$forceUpdate();
            },
            scrollToComments(item) {
                if (item && !item.has_answer) {
                    this.$store.dispatch('alert/warning', 'Сначала необходимо предоставить ответ', { root: true });
                } else {
                    let el = document.getElementById('comment-section');
                    let elQ = document.getElementById('question-details-modal');
                    setTimeout(() => {
                        elQ.scrollTo({
                            top: el.scrollHeight, // could be negative value
                            left: 0,
                            behavior: 'smooth'
                        });
                        setTimeout(() => {
                            this.$refs.comments.triggerFocus()
                        }, 200)
                    }, 200)
                }
            },
            changeSliderQuestion(type) {
                let index = this.questionsList.findIndex((o) => {return o.id === this.questionData.id});
                if (index !== -1) {
                    if (type === 'next') {
                        if (index < this.questionsList.length - 1) {
                            this.onModalHide();
                            this.showModal('main', this.questionsList[index + 1], this.questionsList, true);
                        }
                        if ((this.questionsList.length - 1 - index) === 3) {
                            //this.$root.$emit('loadMoreQuestions', 'asdasdasd')
                            EventBus.$emit('loadMoreQuestions');
                        }
                        this.$forceUpdate();
                    } else if (type === 'prev') {
                        if (index > 0) {
                            this.onModalHide();
                            this.showModal('main', this.questionsList[index - 1], this.questionsList, true);
                        }
                        this.$forceUpdate();
                    }
                }

                console.log(index);
                console.log(this.questionsList);
                console.log(this.questionsList.length > 1);
                console.log(this.indexInList !== 0);
                console.log(this.indexInList);
            },
            goToProfile(data) {
                let currentUser = JSON.parse(localStorage.getItem('user'));
                if (data.id === currentUser.id) {
                    this.$router.push({path: '/me'});
                } else if (this.$route.name === 'user-profile') {
                    this.$refs['question-details-modal'].hide();
                    this.$router.push({path: `/profile/${data.id}`});
                } else {
                    this.$router.push({path: `/profile/${data.id}`});
                }
            },


            showImageSliderModal(index) {
                console.log(index);
                this.img_index = index;
                this.$refs['imageSlider'].open(index);
            },
	
	        openCustomModal(author_name) {
		        console.log(this.$refs);
		        return new Promise((resolve, reject) => {
			        this.$refs['customModal'].show({
				        message: `Ответ получит только ${author_name}`,
				        okButton: 'OK',
				        cancelButton: 'Отмена'
			        }).then((result) => {
				        console.log(result);
				        if (result) {
					        resolve();
				        } else {
					        reject();
				        }
			        });
		        })
	        },
	
	        addToQuestionFilter(data) {
				console.log(data);
				this.closeModal();
		        this.$router.push({ path: '/users/search', query: { question_for_search: data.object_id, is_personal: (this.topicGlobalId === 8) ? 1 : 0 } });
				this.$emit('selectQuestion');
	        }
        },
        watch: {},
    }
</script>

<style lang="less">
    @import "../../../assets/styles/_vars.less";

    #question-details-modal {
        .modal-dialog {
            &.modal-xl {
                max-width: 960px;
            }
            .modal-content {
                .modal-header {
                    padding: 0;
                    .modal-title {
                        width: 100%;
                    }
                }
                .modal-body {
                    padding: 0;
                    .post-header {
                        padding: 15px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #efefef;
                        min-height: 65px;
                        position: relative;
                        .post-header-title {
                            font-size: 13px;
                            font-weight: 500;
                            color: #999999;
                            display: flex;
                            align-items: center;
                            .avatar-wrapper {
                                width: 36px;
                                height: 36px;
                                border-radius: 50%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-right: 13px;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                        .post-header-time {
                            font-size: 13px;
                            color: #999999;
                            font-weight: 500;
                            margin-right: 40px;
                        }
                        .modal-close-btn {
                            cursor: pointer;
                            position: absolute;
                            right: 15px;
                            top: 50%;
                            margin-top: -10px;
                            .fa-times {
                                font-size: 20px;
                                color: #999;
                            }
                        }
                    }
                    .post-wr {
                        .item-wrapper {
                            &.image-post {
                                .post-item-wrapper {
                                    .post-item {
                                        .post-body-wrapper {
                                            display: grid;
                                            grid-template-columns: repeat(1, 1fr);
                                            grid-gap: 5px;
                                            grid-auto-flow: row dense;
                                            .img-wrapper {
                                                max-height: 500px;
                                                min-height: 500px;
                                                overflow: hidden;
                                                margin: 5px;
                                                border-radius: 5px;
                                                display: grid;
                                                grid-gap: 1px;
                                                grid-template-columns: 100% 100%;
                                                grid-template-rows: 100% 100%;
                                                position: relative;
                                                border: 1px solid lightgrey;
                                                image-orientation: from-image;
                                                .image {
                                                    position: relative;
                                                }
                                                &.item_grid_2 {
                                                    grid-template-columns: 50% 50%;
                                                }
                                                &.item_grid_3 {
                                                    grid-template-columns: repeat(3, 1fr);
                                                }
                                                &.item_grid_4 {
                                                    grid-template-columns: 50% 50%;
                                                    grid-template-rows: 50% 50%;
                                                }
                                                img {
                                                    height: 100%;
                                                    width: 100%;
                                                    object-fit: cover;
                                                    animation: fade 1000ms;
                                                    -webkit-user-select: none;
                                                    -khtml-user-select: none;
                                                    -moz-user-select: none;
                                                    -o-user-select: none;
                                                    user-select: none;
                                                }
                                                .more-indicator {
                                                    position: absolute;
                                                    right: 0;
                                                    bottom: 0;
                                                    background-color: rgba(0,0,0,0.7);
                                                    color: white;
                                                    padding: 0 10px;
                                                    font-size: 12px;
                                                    border-top-left-radius: 5px;
                                                }
                                                &:hover {
                                                    .post-controls {
                                                        right: 0;
                                                        opacity: 1;
                                                    }
                                                }
                                                .post-controls {
                                                    position: absolute;
                                                    top: 0;
                                                    cursor: pointer;
                                                    right: -20px;
                                                    opacity: 0;
                                                    transition: opacity, right 250ms;
                                                    background-color: rgba(0,0,0,0.7);
                                                    padding: 0 5px;
                                                    border-bottom-left-radius: 5px;
                                                    .fa-trash-alt, .fa-edit {
                                                        color: #ffffff;
                                                    }
                                                    .fa-edit {
                                                        margin-right: 5px;
                                                    }
                                                }
                                            }
                                            .img-description {
                                                background-color: whitesmoke;
                                                border-radius: 10px;
                                                margin: 0 10px;
                                                font-size: 12px;
                                                text-align: left;
                                                padding: 10px;
                                                line-break: revert;
                                                white-space: pre-line;
                                                word-break: break-word;
                                                max-height: 150px;
                                                overflow-y: auto;
                                            }
                                        }
                                    }
                                }
                            }
                            .post-item-wrapper {
                                .post-item {
                                    border-radius: 1px;
                                    background: #FFFFFF;
                                    z-index: 0;
                                    .post-header {
                                        padding: 20px 38px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        .post-header-title {
                                            font-size: 13px;
                                            font-weight: 500;
                                            color: #999999;
                                        }
                                        .post-header-time {
                                            font-size: 11px;
                                            color: #999999;
                                            font-weight: 400;
                                        }
                                    }
                                    .post-body-wrapper {
                                        &.no-answer {
                                            .post-body {
                                                /*padding: 26px 26px 26px 26px;
                                                justify-content: center;*/
                                            }
                                            .post-body-questions {
                                                .radio-buttons-wrapper {
                                                    label {
                                                        .label-name {
                                                            width: 100%!important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .post-body {
                                            /*padding: 26px 26px 52px 26px;*/
                                            padding: 26px 26px 0 26px;
                                            min-height: 200px;
                                            width: 100%;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: space-between;

                                            .post-body-tags {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                flex-wrap: wrap;
                                                // margin-bottom: 36px;
                                                .tag {
                                                    font-size: 9px;
                                                    text-align: center;
                                                    color: #000000cc;
                                                    font-weight: 500;
                                                    padding: 2px 22px;
                                                    background-color: transparent;
                                                    border: 1px solid rgba(0,0,0,0.1);
                                                    border-radius: 16px;
                                                    margin: 0 5px 5px;
                                                    text-transform: uppercase;
                                                }
                                            }
                                            .post-body-main-title {
                                                font-size: 18px;
                                                color: #000000;
                                                text-align: center;
                                                font-weight: 500;
                                            }
                                            .post-body-tabs {
                                                ul {
                                                    border: none;
                                                    justify-content: center;
                                                    margin: 0!important;
                                                    li {
                                                        a {
                                                            &.active {
                                                                border-bottom: 2px solid #666666!important;
                                                            }
                                                            span {
                                                                font-weight: bold;
                                                                font-size: 11px;
                                                                color: #666666;
                                                                text-transform: uppercase;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .post-body-questions {
                                            padding: 20px 38px;
                                            text-align: left;
                                            &.question-feed-option {
                                                .radio-buttons-wrapper {
                                                    .u-radiobutton-wr {
                                                        &.has-answer {
                                                            padding-left: 50px;
                                                        }
                                                        .avatar-wrapper {
                                                            position: absolute;
                                                            top: 50%;
                                                            transform: translateY(-50%);
                                                            left: 0;
                                                            width: 30px;
                                                            height: 30px;
                                                            border: 3px solid #fff;
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            overflow: hidden;
                                                            border-radius: 50%;
                                                            margin-top: -2px;
                                                            &.my-checked {
                                                                left: 15px;
                                                            }
                                                            &.previous-answer {
                                                                opacity: 0.6;
                                                            }
                                                            &.single-avatar {
                                                                left: 15px;
                                                            }
                                                            img {
                                                                width: 100%;
                                                                height: auto;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .question {
                                                &:not(:last-child) {
                                                    margin-bottom: 20px;
                                                }
                                                label {
                                                    margin: 0;
                                                    cursor: pointer;
                                                }
                                                .custom-checkbox {
                                                    cursor: pointer;
                                                    -webkit-appearance: none;
                                                    width: 15px;
                                                    height: 15px;
                                                    position: relative;
                                                    margin-right: 10px;
                                                    border: 2px solid rgba(113, 128, 156, 0.7);
                                                    box-shadow: none;
                                                    outline: none;
                                                    border-radius: 3px;
                                                    &:disabled {
                                                        opacity: 0.7;
                                                        cursor: not-allowed;
                                                    }
                                                    &:checked {
                                                        &:after {
                                                            content: url('../../../assets/img/custom-checkbox.svg');
                                                            position: absolute;
                                                            top: -7px;
                                                            left: 0;
                                                            width: 10px;
                                                            height: 8px;
                                                        }
                                                    }
                                                }
                                            }
                                            .radio-buttons-wrapper {
                                                .u-radiobutton-wr {
                                                    margin-bottom: 10px;
                                                    position: relative;
                                                    .image-as-bg {
                                                        position: absolute;
                                                        left: -30px;
                                                        top: 50%;
                                                        margin-top: -15px;
                                                        border: 3px solid #fff;
                                                    }
                                                    label {
                                                        display: flex;
                                                        justify-content: space-between;
                                                        align-items: center;
                                                        flex-wrap: wrap;
                                                        &.active {
                                                            color: @theme_color;
                                                            .answer-percentage-line {
                                                                background-color: #c7dff0;
                                                            }
                                                        }
                                                        .label-name {
                                                            width: 80%;
                                                        }
                                                        .answers-stats {
                                                            width: 20%;
                                                            text-align: right;
                                                            display: flex;
                                                            justify-content: flex-end;
                                                            align-items: center;
                                                            flex-wrap: nowrap;
                                                            &>span {
                                                                font-size: 11px;
                                                                font-weight: 400;
                                                            }
                                                            .answer-count {
                                                                margin-right: 20px;
                                                                position: relative;
                                                                &:after {
                                                                    content: '';
                                                                    position: absolute;
                                                                    width: 1px;
                                                                    background-color: #E0E3EA;
                                                                    height: 100%;
                                                                    top: 0;
                                                                    right: -10px;
                                                                }
                                                            }
                                                        }
                                                        .answer-percentage-line {
                                                            width: 100%;
                                                            height: 1px;
                                                            background-color: #E0E3EA;
                                                            margin-top: 5px;
                                                            display: flex;
                                                            position: relative;
                                                            .answer-percentage {
                                                                width: 20%;
                                                                height: 3px;
                                                                position: absolute;
                                                                top: -3px;
                                                                left: 0;
                                                                background-color: @theme_color;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .post-pre-footer {
                                        display: flex;
                                        padding: 10px 38px 15px 38px;
                                        justify-content: flex-start;
                                        position: relative;
                                        flex-wrap: wrap;
                                        .additional-options {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            flex-wrap: wrap;
                                            margin-bottom: 15px;
                                            .is-important {
                                                margin-bottom: 5px;
                                            }
                                            .visibility-wrapper {
                                                display: flex;
                                                align-items: center;
                                                width: 100%;
                                                justify-content: flex-start;
                                                .text {
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                        .main-controls {
                                            width: 100%;
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: flex-start;
	                                        margin-bottom: 10px;
                                            .main-controls_left {}
                                            .main-controls_right {}
                                        }
	                                    .add-to-filter-controls {
		                                    .add-to-filter-btn {
			                                    text-transform: uppercase;
			                                    border-radius: 35px;
			                                    border: 1px solid @theme_color;
			                                    background-color: transparent;
			                                    color: @theme_color;
			                                    text-align: center;
			                                    padding: 7px 30px;
			                                    font-size: 10px;
			                                    font-weight: 500;
			                                    &:focus {
				                                    outline: none;
			                                    }
			                                    &:first-child {
				                                    margin-right: 5px;
			                                    }
		                                    }
	                                    }

                                        .submit-answer {
                                            text-transform: uppercase;
                                            border-radius: 35px;
                                            border: 1px solid lightgrey;
                                            background-color: transparent;
                                            color: #333333;
                                            text-align: center;
                                            padding: 7px 30px;
                                            font-size: 10px;
                                            font-weight: 500;
                                            outline: none;
                                            &.accept-answer {
                                                /*position: absolute;
                                                right: 38px;
                                                top: -20px;*/
                                                margin-right: 10px;
                                            }
                                        }
                                        .ask-friends, .comment {
                                            text-transform: uppercase;
                                            border-radius: 35px;
                                            border: 1px solid lightgrey;
                                            background-color: transparent;
                                            color: #333333;
                                            text-align: center;
                                            padding: 7px 30px;
                                            font-size: 10px;
                                            font-weight: 500;
                                            &:focus {
                                                outline: none;
                                            }
                                        }
	                                    
                                        .ask-friends {
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .chevrons {
                        position: absolute;
                        cursor: pointer;
                        &.right {
                            top: 50px;
                            right: -65px;
                        }
                        &.left {
                            top: 50px;
                            left: -65px;
                        }
                    }
                    .loading-wrapper {
                        width: 100%;
                        height: 80vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .users-with-similar-answers {
                        padding: 20px 40px 20px 40px;
                        box-shadow: 0 2px 6px #b5b5b5;
                        border-radius: 1px;
                        position: relative;
                        &:after {
                            content: '';
                            background-color: white;
                            right: -4px;
                            top: 0;
                            position: absolute;
                            width: 4px;
                            height: 100%;
                        }
                        &:before {
                            content: '';
                            background-color: white;
                            left: -4px;
                            top: 0;
                            position: absolute;
                            width: 4px;
                            height: 100%;
                        }
                        .block-title {
                            font-weight: 400;
                            font-size: 12px;
                            color: #666;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: nowrap;
                            margin-bottom: 30px;
                            span {
                                &:last-child {
                                    cursor: pointer;
                                }
                            }
                        }
                        .users-list {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-wrap: nowrap;
                            .user {
                                text-align: center;
                                width: 90px;
                                &:not(:last-child) {
                                    margin-right: 36px;
                                }
                                cursor: pointer;
                                .avatar-wrapper {
                                    cursor: pointer;
                                    margin-bottom: 8px;
                                    .image-as-bg {
                                        margin: 0 auto;
                                    }
                                }
                                .person-progress {
                                    margin-bottom: 8px;
                                }
                                .name {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #666666;
                                    overflow-wrap: break-word;
                                    line-height: 14px;
                                }
                                .city-and-age-range {
                                    font-weight: 400;
                                    font-size: 11px;
                                    color: #9B9B9B;
                                    span {
                                        &:first-child {
                                            &:after {
                                                content: ', ';
                                            }
                                        }
                                        &:last-child {
                                            &:after {
                                                content: '';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #users-modal {
        .modal-dialog {
            &.modal-xl {
                max-width: 960px;
            }
            .modal-content {
                .modal-header {
                    border: none;
                    .modal-title {
                        width: 100%;
                        .answer-text {
                            font-size: 14px;
                        }
                        .post-answer-options {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: nowrap;
                            .post-answer-options_title {
                                font-weight: 400;
                                font-size: 12px;
                                color: #666;
                                margin-right: 11px;
                            }
                            .post-answer-options_list {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: nowrap;
                                margin-right: 11px;
                                span {
                                    width: 26px;
                                    height: 26px;
                                    background-color: #fff;
                                    color: #71809C;
                                    text-transform: uppercase;
                                    font-size: 15px;
                                    font-weight: 400;
                                    position: relative;
                                    text-align: center;
                                    cursor: pointer;
                                    &.active {
                                        background-color: #71809C;
                                        color: #fff;
                                        &:after {
                                            content: '';
                                            position: absolute;
                                            bottom: -4px;
                                            left: 9px;
                                            background-color: #71809C;
                                            width: 8px;
                                            height: 8px;
                                            -webkit-transform: rotate(45deg);
                                            -ms-transform: rotate(45deg);
                                            transform: rotate(45deg);
                                        }
                                    }

                                }
                            }
                            .post-answer-options_users-count {
                                font-weight: 400;
                                font-size: 12px;
                                color: #666;
                            }
                        }
                    }
                }
                .modal-body {
                    padding: 0;
                    .post-body {
                        padding: 20px 5%;
                        .post-body-main-title {
                            font-size: 18px;
                            color: #000000;
                            text-align: center;
                            font-weight: 500;
                        }
                    }

                    .users-list {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        padding: 30px 15px;
                        max-height: 400px;
                        min-height: 400px;
                        overflow-y: scroll;
                        &::-webkit-scrollbar{
                            height: 4px;
                            width: 4px;
                            background: gray;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-thumb{
                            background: lightgrey;
                            border-radius: 10px;
                        }
                        .user {
                            text-align: center;
                            width: 90px;
                            margin: 0 15px 30px 15px;
                            cursor: pointer;
                            .avatar-wrapper {
                                margin: 0 auto 8px auto;
                                .image-as-bg {
                                    margin: 0 auto;
                                }
                            }
                            .person-progress {
                                margin-bottom: 8px;
                            }
                            .name {
                                font-weight: 500;
                                font-size: 12px;
                                color: #666666;
                                overflow-wrap: break-word;
                                line-height: 14px;
                            }
                            .city-and-age-range {
                                font-weight: 400;
                                font-size: 11px;
                                color: #9B9B9B;
                                span {
                                    &:first-child {
                                        &:after {
                                            content: ', ';
                                        }
                                    }
                                    &:last-child {
                                        &:after {
                                            content: '';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {
        #question-details-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .post-item-wrapper {
                            .post-item {
                                .post-pre-footer {
                                    flex-wrap: wrap;
                                    padding: 10px 15px;
                                    button {
                                        width: 100%;
                                        max-width: 320px;
                                        margin: 0 auto 10px auto;
                                        &.accept-answer {
                                            position: initial!important;
                                            margin-right: auto!important;
                                        }
                                        &.ask-friends {
                                            margin-right: auto!important;
                                        }
                                    }
                                    .additional-options {
                                        margin-bottom: 10px;
                                        width: 100%;
                                        .is-important {
                                            margin-right: 0;
                                        }
                                    }
                                    .main-controls {
                                        flex-wrap: wrap;
                                        &>div {
                                            width: 100%;
                                            display: flex;
                                            flex-wrap: wrap;
                                            justify-content: center;
                                            align-items: flex-start;
                                        }
                                    }
                                }
                            }
                        }
                        .users-with-similar-answers {
                            .users-list {
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                .user {
                                    width: unset;
                                    max-width: 90px;
                                    min-width: 90px;
                                    margin: 0 5px 5px 5px!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #question-details-modal {
            .modal-dialog {
                .modal-content {
                    .modal-header {
                        .modal-title {
                            .post-header {
                                padding: 16px 40px 16px 20px;
                            }
                        }
                    }
                    .modal-body {
                        .post-item-wrapper {
                            .post-item {
                                .post-body-wrapper {
                                    .post-body {
                                        padding: 10px 10px 0 10px;
                                        .post-body-main-title {
                                            font-size: 16px;
                                        }
                                        .post-body-tabs {
                                            ul {
                                                li {
                                                    a {
                                                        &.nav-link {
                                                            padding: 5px 15px;
                                                        }
                                                        span {
                                                            font-size: 10px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .post-body-questions {
                                        padding: 20px 20px;
                                        .radio-buttons-wrapper {
                                            .u-radiobutton-wr {
                                                &.has-answer {
                                                    padding-left: 30px;
                                                    .image-as-bg {
                                                        left: -5px;
                                                    }
                                                }
                                                label {
                                                    .label-name {
                                                        font-size: 14px;
                                                        line-height: 14px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #question-details-modal {
            .modal-dialog {
                .modal-content {
                    .modal-header {
                        .modal-title {
                            .post-header {
                                padding: 16px 40px 16px 20px;
                            }
                        }
                    }
                    .modal-body {
                        .chevrons {
                            background: #e0e0e0;
                            width: 30px;
                            border-radius: 5px;
                            z-index: 9;
                            &.right {
                                top: 80px;
                                right: 10px;
                            }
                            &.left {
                                top: 80px;
                                left: 10px;
                            }
                        }
                        .post-item-wrapper {
                            .post-item {
                                .post-body-wrapper {
                                    .post-body-questions {
                                        padding: 20px 15px 20px 15px;
                                        .radio-buttons-wrapper {
                                            .u-radiobutton-wr {
                                                label {
                                                    .label-name {
                                                        width: 100%;
                                                    }
                                                    .answers-stats {
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #users-modal {
            .modal-dialog {
                .modal-content {
                    .modal-header {
                        .modal-title {
                            .post-answer-options {
                                flex-wrap: wrap;
                                .post-answer-options_users-count {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
