<template>
	<div id="messages-page" :class="{'is-mobile': deviceWidth}">
		<transition name="fade">
			<list-of-chats v-if="(deviceWidth && !activeChat) || !deviceWidth" :class="{'chat-selected': activeChat}" @setActive="setActive" @loadMore="getPreviousChats" :chatsList="chatsList" :isLoading="loading" :searchResults="searchResults" @searchChat="searchChat" :searchLoading="searchLoading" :showTip="!loading && !chatsList.length && !initLoad" :is-mobile="deviceWidth"></list-of-chats>
		</transition>
		<transition name="fade">
			<div class="mobile-chat-window" v-if="deviceWidth">
				<chat-window v-if="activeChat" :class="{'chat-not-selected': !activeChat}" :active-avatar="activeAvatar" :activeChat="activeChat" :friendData="friend_data" :isLoading="loading" :chatsList="chatsList" :showTip="!loading && !chatsList.length && !initLoad" :is-mobile="deviceWidth" @clearActiveChat="clearActiveChat()"></chat-window>
			</div>
		</transition>
		<ChatModal v-if="!deviceWidth" ref="chat-modal" @clearActiveChat="clearActiveChat()"></ChatModal>
	</div>
</template>

<script>
	import ChatWindow from "../../components/chat/chat-window/ChatWindow";
	import ListOfChats from "../../components/chat/list-of-chats/ListOfChats";
	import {usersService, questionsService} from "../../_api";
	import ChatModal from "@/components/_shared/modals/_ChatModal";
	import {handleAWSImage} from "@/_helpers/aws-handler";

	export default {
		name: 'Messages',
		components: {ChatModal, ChatWindow, ListOfChats},
		data: function () {
			return {
				activeAvatar: null,
				activeChat: null,
				cacheLoading: true,
				chatsLimit: 20,
				chatsList: [],
				chatListener: null,
				prevChatListener: null,
				lastVisible: null,
				initLoad: true,
				friend_data: null,
				searchResults: [],
				searchLoading: false,
				loading: false
			}
		},
		created() {},
		mounted() {
			console.log(this.deviceWidth);
			if (!this.getFireUserData) {
				this.$store.watch(
						(state) => {
							return this.$store.state.firebase.firebaseUserData
						},
						(newValue, oldValue) => {
							this.getChats();
						}
				);
			} else {
				this.getChats();
			}
		},
		beforeDestroy() {
			if (this.chatListener) {
				this.chatListener();
			}
			if (this.prevChatListener) {
				this.prevChatListener();
			}
		},
		watch: {
			activeChat: function(val) {
				if (val) {
					this.friend_data = null;
					this.getUserInfo(val.friendData.id);
				}
			}
		},
		computed: {
			deviceWidth() {
				return this.$store.state.misc.device_width <= 768
			},
			getFireUserData() {
				return this.$store.state.firebase.firebaseUserData
			},
			getActiveChat() {
				return this.$route.query.t
			}
		},
		methods: {
			closeModal() {
				this.$refs['chat-modal'].hideModal();
				this.clearActiveChat();
			},
			clearActiveChat() {
				this.$router.replace({'query': null});
				this.activeChat = null;
			},
			getUserInfo(id) {
				usersService.getUsersData({
					user_id: id
				}).then((res) => {
					if (res) {
						this.friend_data = res;
						// console.log(this.friend_data);
					}
				})
			},
			setActive(data, automated, modal) {
				console.log(this.deviceWidth);
				console.log(this.$store.state.misc.device_width);
				console.log(data);
				console.log(modal);
				if (!this.deviceWidth) {
					console.log(this.$refs);
					
					if (modal) {
						let q;
						if (data.id !== undefined) {
							q = data.id;
						} else {
							q = data;
						}
						
						let index = this.chatsList.findIndex((i) => {return i.id === data});
						if (index !== -1) { // IF USER IS IN THE ACTIVE LIST OF CHATS
							this.chatsList = this.chatsList.filter(obj => {
								if (obj.id === this.chatsList[index].id) {
									obj.active = true;
									this.activeChat = {...this.chatsList[index]};
									this.$router.push({path: 'messages', query: {'t': this.activeChat.id}});
									this.$refs['chat-modal'].showModal(this.activeChat, false);
									this.$forceUpdate();
								} else {
									obj.active = false
								}
								
								return obj
							});
							
							// arraymove(this.chatsList, index, 0);
							
						} else { // IF USER IS NOT IN THE ACTIVE LIST OF CHATS WE GET HIS FIREBASE DATA AS USUAL AND PERFORM REGULAR OBJ TRANSFORMATION
							let isInit = true;
							this.$firebaseChats.doc(data).get().then(querySnapshot => {
								if (!querySnapshot.empty) {
									this.getUser(querySnapshot.data()).then(res => {
										if (res) {
											this.chatObjFormation(querySnapshot.id, querySnapshot.data(), res).then((data) => {
												if (isInit) {
													// this.chatsList.unshift(data);
													this.activeChat = {...data};
													this.$router.push({path: 'messages', query: {'t': this.activeChat.id}});
													this.$refs['chat-modal'].showModal(data, false);
												}
											})
										}
									})
								}
								
							});
						}
					} else {
						this.$router.push({path: 'messages', query: {'t': data.id}});
						this.$refs['chat-modal'].showModal(data, false);
					}
				} else {
					function arraymove(arr, fromIndex, toIndex) {
						var element = arr[fromIndex];
						arr.splice(fromIndex, 1);
						arr.splice(toIndex, 0, element);
					}
					if (modal) {
						/// 1 check all the chats list if selected chat is in
						/// if in, simply perform actions from else condition
						/// if not, get it, and put in the list top
						let q;
						if (data.id !== undefined) {
							q = data.id;
						} else {
							q = data;
						}
						
						let index = this.chatsList.findIndex((i) => {return i.id === data});
						if (index !== -1) { // IF USER IS IN THE ACTIVE LIST OF CHATS
							this.chatsList = this.chatsList.filter(obj => {
								if (obj.id === this.chatsList[index].id) {
									obj.active = true;
									this.activeChat = {...this.chatsList[index]};
									this.$router.push({path: 'messages', query: {'t': this.activeChat.id}});
									this.$forceUpdate();
								} else {
									obj.active = false
								}
								
								return obj
							});
							
							arraymove(this.chatsList, index, 0);
							
						} else { // IF USER IS NOT IN THE ACTIVE LIST OF CHATS WE GET HIS FIREBASE DATA AS USUAL AND PERFORM REGULAR OBJ TRANSFORMATION
							let isInit = true;
							this.$firebaseChats.doc(data).get().then(querySnapshot => {
								if (!querySnapshot.empty) {
									this.getUser(querySnapshot.data()).then(res => {
										if (res) {
											this.chatObjFormation(querySnapshot.id, querySnapshot.data(), res).then((data) => {
												if (isInit) {
													// this.chatsList.unshift(data);
													this.activeChat = {...data};
													this.chatsList = this.chatsList.filter(obj => {
														obj.active = obj.id === this.activeChat.id;
														return obj
													});
													console.log(this.$route.query.t);
													console.log(this.activeChat.id);
													if (this.$route.query.t !== this.activeChat.id) {
														this.$router.push({path: 'messages', query: {'t': this.activeChat.id}});
													}
												}
											})
										}
									})
								}
								
							});
						}
					} else {
						this.chatsList = this.chatsList.filter(obj => {
							if (obj.id === data.id) {
								obj.active = true;
								this.activeChat = data;
								this.readMessages();
								this.$forceUpdate();
							} else {
								obj.active = false
							}
							this.$forceUpdate();
							return obj
						});
						if (!automated && this.getActiveChat !== data.id) {
							this.$router.push({path: 'messages', query: {'t': data.id}});
						}
					}
				}
				
				
				
				
				/*if (!this.deviceWidth && this.activeChat) {
					console.log(this.$refs);
					this.$refs['chat-modal'].showModal();
				}*/
			},
			unreadMessages(chat) {
				return new Promise((resolve, reject) => {
					this.$firebaseChats.doc(chat.id).collection('messages').where('status', '==', 0).get().then(obj => {
						let count = 0;
						if (!obj.empty) {
							count = obj.docs.length;
							async function processArray(array, nb) {
								for (const item of array) {
									if (nb === item.data().authorId) {
										count -= await 1;
									}
								}
								resolve(count);
							}

							processArray(obj.docs, this.getFireUserData.id);
						} else {
							resolve(count);
						}
					})
				})
			},
			getUser(data) {
				let idToFind = data.chatMembers.filter((o) => {return o !== this.getFireUserData.fb_id})[0];
				return new Promise(((resolve, reject) => {
					this.$firebaseUsers.where('fb_id', '==', idToFind).get().then(querySnapshot => {
						if (!querySnapshot.empty) {
							console.log(querySnapshot.docs[0].data());
							resolve(querySnapshot.docs[0].data());
						} else {
							resolve(null);
						}
					})
				}))
			},
			getChats() {
				// console.log('GET CHATS');
				// console.log(this.getFireUserData);
				this.chatsList = [];
				let casd = 0;
				this.cacheLoading = true;
				this.loading = true;
				this.chatListener = this.$firebaseChats.where('chatMembers', 'array-contains', this.getFireUserData.fb_id).orderBy('updatedAt', 'desc').limit(this.chatsLimit).onSnapshot(snapshot => {
					if (snapshot.empty) {
						this.loading = false;
						this.initLoad = false;
					}
					this.cacheLoading = 0;
					if (this.initLoad) {
						this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
					}
					snapshot.docChanges().forEach((change) => {
						if (change.type === 'added') {
							casd += 1;
							// console.log('ADDED');
							this.getUser(change.doc.data()).then(res => {
								// console.log('PERSON');
								if (res) {
									this.chatObjFormation(change.doc.id, change.doc.data(), res).then((data) => {
										// console.log(data);
										let t = this.chatsList.findIndex((i) => {return i.id === change.doc.id});
										// console.log(t);
										if (t === -1) {
											this.chatsList.push(data);
											this.chatsList.sort((a, b) => b.updated_at_readable - a.updated_at_readable);
											if (this.getActiveChat === change.doc.id) {
												this.setActive(data, true, false);
											}
											// console.log(this.chatsList.length);
											// console.log(casd);
											if (casd === this.chatsList.length || (casd - this.chatsList.length) < 3) {
												if (this.$route.query.ff) {
													this.setActive(this.$route.query.t, false, true)
												}
												this.initLoad = false;
												this.loading = false;
											}
										}
									})
								} else  {
									casd -= 1;
								}
							});
						} else if (change.type === 'modified') {
							let t = this.chatsList.findIndex((i) => {return i.id === change.doc.id});
							if (t !== -1) {
								this.chatsList[t].lastMessage = change.doc.data().lastMessage;
								this.chatObjFormation(change.doc.id, this.chatsList[t]).then((data) => {
									this.chatsList[t] = data;
									this.chatsList.unshift(this.chatsList[t]);
									this.chatsList.splice(t + 1, 1);
								})
							}
						} else if (change.type === 'removed') {}
					});

				});
			},
			getPreviousChats() {
				this.prevChatListener = this.$firebaseChats.where('chatMembers', 'array-contains', this.getFireUserData.fb_id).orderBy('updatedAt', 'desc').limit(this.chatsLimit).startAfter(this.lastVisible).onSnapshot(snapshot => {
					if (snapshot.docs.length) {
						this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
						let casd = snapshot.docChanges().length;
						snapshot.docChanges().forEach((change) => {
							if (change.type === 'added') {
								this.getUser(change.doc.data()).then(res => {
									if (res) {
										this.chatObjFormation(change.doc.id, change.doc.data(), res).then((data) => {
											let t = this.chatsList.findIndex((i) => {return i.id === data.id});
											if (t === -1) {
												// this.chatsList.sort((a, b) => b.updated_at_readable - a.updated_at_readable);
												this.chatsList.push(data);
												if (this.getActiveChat === change.doc.id) {
													this.setActive(data, true, false);
												}
											}
										})
									}
								})
							} else if (change.type === 'modified') {
								let t = this.chatsList.findIndex((i) => {return i.id === change.doc.id});
								if (t !== -1) {
									this.chatsList[t].lastMessage = change.doc.data().lastMessage;
									this.chatObjFormation(change.doc.id, this.chatsList[t]).then((data) => {
										this.chatsList[t] = data;
										this.chatsList = [...this.chatsList];
										casd -= 1;
										if (casd === 0 && t > 0) {
											this.chatsList.unshift(this.chatsList[t]);
											this.chatsList.splice(t + 1, 1);
										}
									})
								}
							} else if (change.type === 'removed') {}
						});
					}
				});
			},

			chatObjFormation(chat_id, chat_data, friend_data) {
				return new Promise((resolve, reject) => {
					let chatItemData = chat_data;
					chatItemData['id'] = chat_id;
					chatItemData['active'] = chat_data.active || false;
					chatItemData['unreadCount'] = 0;
					if (friend_data) {
						chatItemData['friendData'] = friend_data;
					}
					if (chatItemData['friendData']['avatar_parsed'] === undefined) {
						chatItemData['friendData']['avatar_parsed'] = null;
						if (chatItemData['friendData']['avatar'] !== null && chatItemData['friendData']['avatar'].includes('http')) {
							handleAWSImage(chatItemData['friendData']['avatar']).then((response) => {
								chatItemData['friendData']['avatar_parsed'] = response;
								this.$forceUpdate();
							})
						}
					}
					
					/*if (chatItemData['friendData']['avatar_parsed'] === undefined) {
						chatItemData['friendData']['avatar_parsed'] = null;
					}*/
					if (chatItemData.lastMessage && chatItemData.lastMessage.date) {
						chatItemData.lastMessage['timestamp_formatted'] = this.moment(chatItemData.lastMessage.date.toDate()).format("YYYY.MM.DD");
					}
					if (chatItemData.updatedAt) {
						chatItemData['updated_at_readable'] = chatItemData.updatedAt.toDate();
					}

					this.unreadMessages(chatItemData).then((count) => {
						chatItemData['unreadCount'] = count;
						this.$forceUpdate();
						let t = this.chatsList.findIndex((i) => {return i.id === chatItemData.id});
						if (t !== -1) {
							this.chatsList[t] = chatItemData;
							this.chatsList = [...this.chatsList];
							this.$forceUpdate();
						}
					});
					this.getUserProgressBar(chatItemData);
					resolve(chatItemData);
				})

			},
			getUserProgressBar(data) {
				questionsService.getUserProgressBarAPI({
					user_ids: [data.friendData.id]
				}).then(res => {
					if (res) {
						data = {...data, ...res[0]};
						let t = this.chatsList.findIndex((i) => {return i.id === data.id});
						if (t !== -1) {
							this.chatsList[t] = data;
							this.chatsList = [...this.chatsList];
						}
						let tt = this.searchResults.findIndex((i) => {return i.id === data.id});
						if (tt !== -1) {
							this.searchResults[tt] = data;
							this.searchResults = [...this.searchResults];
						}
					}
				});
			},

			readMessages() {
				this.$firebaseChats.doc(this.activeChat.id).collection('messages').where('status', '==', 0).get().then(obj => {
					if (!obj.empty) {
						obj.docs.forEach(c_obj => {
							if (this.getFireUserData.id !== c_obj.data().authorId) {
								this.$firebaseChats.doc(this.activeChat.id).collection('messages').doc(c_obj.id).update({
									status: 1
								})
							}
						});
						this.$firebaseChats.doc(this.activeChat.id).collection('messages').where('status', '==', 0).get().then(obj => {
							if (!obj.empty) {
								this.$firebaseChats.doc(this.activeChat.id).update({
									unreadCount: obj.docs.length
								})
							} else {
								this.$firebaseChats.doc(this.activeChat.id).update({
									unreadCount: 0
								})
							}
						})
					}
				})
			},

			searchChat(str) {
				this.searchLoading = true;
				setTimeout(() => {
					this.searchResults = [];
					if (str.length) {
						usersService.searchChatsAPI({
							query: str
						}).then((res) => {
							if (res) {
								if (!res.length) {
									this.searchLoading = false;
								}
								let count = 0;
								let length = res.length;
								res.forEach((data) => {
									count += 1;
									this.$firebaseChats.doc(data).get().then(chat => {
										if (chat.data()) {
											this.getUser(chat.data()).then(user => {
												if (user) {
													this.chatObjFormation(chat.id, chat.data(), user).then((data) => {
														this.searchResults.push(data);
														if (count === length) {
															this.searchLoading = false;
														}
													})
												} else {
													count -= 1;
												}
											});
										}
									});
								})
							} else {
								this.searchLoading = false;
							}
						})
					} else {
						this.searchLoading = false;
					}
				}, 50)
				
			}
		}
	}
</script>

<style lang="less" scoped>
	@import "../../assets/styles/_unified";
	#messages-page {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: nowrap;
		.mobile-back-btn {
			width: 100%;
			display: none;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: nowrap;
			color: @theme_color;
			i {
				margin-right: 5px;
			}
		}
	}
	
	::v-deep #desktop-chat-window {
		.modal-body {
			padding: 0;
			position: relative;
			.modal-close-btn {
				color: gray;
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;
			}
		}
	}

	// Large devices (desktops, less than 1200px)
	@media (max-width: 1200px) {

	}
	// Medium devices (tablets, less than 992px)
	@media (max-width: 991px) {
	}
	// Small devices (landscape phones, less than 768px)
	@media (max-width: 768px) {
		::v-deep #nav {
			margin-bottom: 10px;
		}
		::v-deep body {
			&.modal-open {
				#chat-window-page {
					position: initial;
				}
			}
		}
		#messages-page {
			overflow: hidden;
			flex-wrap: wrap;
			.mobile-back-btn {
				display: flex;
			}
			#list-of-chats-page {
				max-width: unset;
				position: fixed;
				top: 70px;
				width: 90%;
				left: 0;
				right: 0;
				margin: 0 auto;
				&.chat-selected {
					display: none;
				}
				::v-deep .search-wrapper {
					width: 100%;
				}
				::v-deep .list-of-chats-wrapper {
					height: calc(100vh - 250px);
				}
			}
			#chat-window-page {
				max-width: unset;
				position: fixed;
				top: 65px;
				width: 95%;
				left: 0;
				right: 0;
				margin: 0 auto;
				&.chat-not-selected {
					display: none;
				}
				::v-deep #chat-window-wrapper {
					height: calc(100vh - 320px);
				}
			}
		}
	}
	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 576px) {

	}
</style>
