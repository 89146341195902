<template>
	<div id="left-sidebar" :class="{'sidebarmenu': sidebarmenu}" @click.stop.prevent>
		<router-link class="my-profile-link" to="/me" v-if="sidebarmenu && isAuthenticated.status.loggedIn && isAuthenticated.user">
			<div class="my-profile-data">
				<avatar :width="36" :height="36" :img="myAvatar"></avatar>
				<span class="user-name">{{isAuthenticated.user.name}}</span>
			</div>
		</router-link>

		<router-link to="/" class="home-link notification-icon" exact>
			Главная
		</router-link>
		<div class="questions-wrapper block-wrapper">
			<div class="questions-title block-title">Вопросы</div>
			<ul class="questions-menu-list">
				<li>
					<router-link to="/questions/main-questions" class="notification-icon">
						Основные
					</router-link>
				</li>
				<li>
					<router-link to="/questions/personal-questions">Персональные</router-link>
				</li>
				<li>
					<router-link to="/questions/questions-of-the-day">Вопросы дня</router-link>
				</li>
			</ul>
		</div>
		<div class="people-wrapper block-wrapper">
			<div class="people-title block-title">Люди</div>
			<ul class="people-menu-list">
				<li>
					<router-link to="/users/social-networks" class="notification-icon">
						Друзья из соцсетей
						<span class="notifications-count" v-if="invitedCount">{{invitedCount}}</span>
					</router-link>
				</li>
				<li>
					<router-link to="/users/similar-people">Похожие</router-link>
				</li>
				<li>
					<router-link to="/users/my-circle" class="notification-icon">
						Друзья Up&Up
					</router-link>
				<li>
					<router-link to="/users/search">Поиск людей</router-link>
				</li>
				<!--<li>
					<router-link to="/messages" class="notification-icon">
						Сообщения
						<span class="notifications-count" v-if="unreadCount">{{unreadCount}}</span>
					</router-link>
				</li>-->
			</ul>
		</div>
		<div class="messages-wrapper block-wrapper">
			<div class="messages-title block-title">
				<router-link to="/messages" class="notification-icon">
					Сообщения
					<span class="notifications-count" v-if="unreadCount">{{unreadCount}}</span>
				</router-link>
			</div>
		</div>
		<div class="people-answers-wrapper block-wrapper" v-if="!friends.loading">
			<div class="people-answers-title block-title" v-if="friends.list.length">
				<router-link to="/users/friends-answers" class="notification-icon">
					Активность друзей Up&Up
					<div v-if="!sidebarmenu">
						<span>Все</span>
						<i class="fas fa-chevron-right"></i>
					</div>
				</router-link>
			</div>
			<ul v-if="!sidebarmenu">
				<li v-for="item in friends.list.slice(0, 5)">
					<router-link :to="'/profile/' + item.id + '#activities'" class="notification-icon">
						<!--<div class="person-data-wrapper" @click="goToUserProfile(item.id)">-->
						<div class="person-data-wrapper">
							<!--<img :src="item.avatar_parsed || require('../../../assets/img/no-avatar.jpg')" alt="no-avatar">-->
							<avatar :width="36" :height="36" :img="item.avatar_parsed"></avatar>
							<div class="person-data">
								<span class="person-title">{{item.name}}</span>
								<span class="answers-count">{{item.total_count}}</span>
								<person-progress height="4" :green="item.similar_answers_percent" :red="item.diff_answers_percent" :gray="item.not_answered_percent"></person-progress>
							</div>
						</div>
					</router-link>
				</li>
			</ul>
		</div>

		<div class="logout" @click="logout()" v-if="sidebarmenu">
			Выйти
		</div>
	</div>
</template>

<script>
	import personProgress from "../UserProgress";
	import {usersService} from "../../../_api";
	import {handleAWSImage} from "../../../_helpers/aws-handler";
	import avatar from "../Avatar";
	import EventBus from "../../../_helpers/event-bus";

	export default {
		name: 'LeftSidebar',
		props: ['sidebarmenu'],
		components: {personProgress, avatar},
		data () {
			return {
				friends: {
					loading: false,
					page: 1,
					perPage: 5,
					count: 0,
					list: []
				},
				invitedCount: 0
			}
		},
		created() {},
		mounted() {
			this.getUsersAnswers();
			this.getInvitedCount();
			EventBus.$on('friendsChanged', () => {
				this.getUsersAnswers();
			})
		},
		beforeDestroy() {
			EventBus.$off('friendsChanged');
		},
		computed: {
			isAuthenticated() {
				return this.$store.state.authentication
			},
			unreadCount() {
				return this.$store.state.firebase.unreadCount
			},
			myAvatar() {
				return this.$store.state.authentication.user_avatar
			},
		},
		methods: {
			logout() {
				this.$store.dispatch('authentication/logout')
			},
			goToUserProfile(id) {
				this.$router.push({path: `/profile/${id}`});
			},
			getUsersAnswers() {
				this.friends.loading = true;
				usersService.getFriendsAnswersAPI({
					page: this.friends.page,
					perPage: this.friends.perPage
				}).then(res => {
					if (res) {
						this.friends.count = res.count;
						this.friends.list = res.users;
						this.friends.list.forEach((obj, index) => {
							// console.log(obj);
							if (obj.avatar) {
								handleAWSImage(obj.avatar_36).then((res) => {
									// obj['avatar_parsed'] = res;
									this.friends.list[index]['avatar_parsed'] = res;
									this.$forceUpdate();
								});
							} else {
								obj['avatar_parsed'] = null;
							}
						});
					}

					this.friends.loading = false;
				})
			},
			getInvitedCount() {
				usersService.getDetailedSearchSocialsAPI({
					page: 1,
					perPage: 1000
				}).then(res => {
					this.invitedCount = res.count;
				})
			}
		},
		watch: {
			$route (to) {}
		}
	}
</script>

<style scoped lang="less">
	@import "../../../assets/styles/_vars";
	#left-sidebar {
		width: 24%;
		position: -moz-sticky;
		position: -o-sticky;
		position: -ms-sticky;
		position: sticky;
		position: -webkit-sticky;
		top: 94px;
		overflow-y: auto;
		max-height: 80vh;
		.my-profile-link {
			padding-bottom: 10px;
			margin-bottom: 10px;
			display: block;
			border-bottom: 1px solid lightgray;
			.my-profile-data {
				display: flex;
				justify-content: center;
				align-items: center;
				.image-as-bg {
					margin-right: 10px;
				}
				.user-name {
					max-width: 140px;
					word-break: break-word;
					line-height: 18px;
					font-size: 14px;
					font-weight: 500;
					color: #666666;
					text-align: left;
				}
			}
		}
		.notification-icon {
			position: relative;
			.notifications-count {
				position: absolute;
				right: 10px;
				top: 50%;
				color: #E96265;
				font-size: 12px;
				font-weight: 400;
				margin-top: -9px;
			}
		}
		.home-link {
			text-align: left;
			display: block;
			text-decoration: none;
			font-size: 14px;
			font-weight: 500;
			padding: 2px 10px;
			margin-bottom: 21px;
			cursor: pointer;
			color: #5E6F8F;
			&.router-link-active {
				background-color: #DDDDDD;
				border-radius: 30px;
			}
		}
		.block-wrapper {
			text-align: left;
			margin-bottom: 33px;
			&.messages-wrapper, &.people-answers-wrapper {
				.block-title {
					padding: 0;
					a {
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-wrap: nowrap;
						&>div {
							color: #9EA8B7;
							span {
								margin-right: 3px;
							}
						}
					}
				}
			}
			&.messages-wrapper {
				.block-title {
					a {
						padding: 2px 10px;
						display: block;
						text-decoration: none;
						cursor: pointer;
						width: 100%;
						color: #5E6F8F;
					}
				}
			}
			.block-title {
				text-transform: uppercase;
				color: #9EA8B7;
				font-weight: bold;
				font-size: 12px;
				margin-bottom: 5px;
				padding: 2px 10px;
				a {
					text-transform: uppercase;
					color: #9EA8B7;
					font-weight: bold;
					font-size: 12px;
					margin-bottom: 5px;
					display: flex;
					padding: 2px 10px;
					&.router-link-active {
						background-color: #DDDDDD;
						border-radius: 30px;
					}
					&:hover {
						border-radius: 30px;
						background-color: #f0f0f0;
					}
					// padding: 2px 10px;
				}
			}
			.people-menu-list, .questions-menu-list {
				list-style-type: none;
				margin: 0 0 0 0;
				padding: 0 0 0 0;
				li {
					font-size: 14px;
					font-weight: 500;
					margin-bottom: 5px;
					a {
						padding: 2px 10px;
						display: block;
						text-decoration: none;
						cursor: pointer;
						width: 100%;
						color: #5E6F8F;
						&.router-link-active {
							background-color: #DDDDDD;
							border-radius: 30px;
						}
						&:hover {
							border-radius: 30px;
							background-color: #f0f0f0;
						}
					}
					/*&:hover {
						border-radius: 30px;
						background-color: #f0f0f0;
					}*/
				}
			}
		}
		.people-answers-wrapper {
			.people-answers-title {
				/*a {
					text-transform: uppercase;
					color: #9EA8B7;
					font-weight: bold;
					font-size: 12px;
					padding: 0 0 0 0;
					span {
						&:first-child {
							margin-right: 3px;
						}
					}
					!*img {
						position: absolute;
						top: 50%;
						margin-top: -5.5px;
						right: 0;
					}*!
				}*/
			}
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					font-size: 13px;
					font-weight: 500;
					margin-bottom: 10px;
					a {
						display: flex;
						justify-content: space-between;
						align-items: flex-start;
						flex-wrap: nowrap;
						padding: 2px 10px;
						.person-data-wrapper {
							width: 100%;
							display: flex;
							justify-content: flex-start;
							align-items: center;
							flex-wrap: nowrap;
							.image-as-bg {
								margin-right: 15px;
							}
							.person-data {
								width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								flex-wrap: wrap;
								.person-title {
									margin: 0;
									font-size: 12px;
									font-weight: 500;
									color: #333333;
									margin-bottom: 5px;
									word-break: break-word;
									max-width: 130px;
								}
								.answers-count {
									color: #999999;
									margin-bottom: 5px;
								}
								.person-progress {
									margin-bottom: 8px;
								}
							}

						}
					}
				}
			}
		}
		.logout {
			padding: 2px 10px;
			display: block;
			text-decoration: none;
			cursor: pointer;
			width: 100%;
			color: #5E6F8F;
			font-size: 14px;
			font-weight: 500;
			text-align: left;
			border-top: 1px solid lightgray;
			padding-top: 10px;
		}
	}

	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 576px) {

	}
	// Small devices (landscape phones, less than 768px)
	@media (max-width: 768px) {
		#left-sidebar {
			display: none;
			width: 100%;
			max-height: 100vh;
			&.sidebarmenu {
				display: block;
			}
			a {
				&:not(.my-profile-link) {
					border-radius: 30px;
					&.router-link-active {
						background-color: white!important;
					}
					&:hover {
						background-color: white!important;
					}
				}
			}
		}
	}
	// Medium devices (tablets, less than 992px)
	@media (max-width: 991px) {

	}
	// Large devices (desktops, less than 1200px)
	@media (max-width: 1200px) {

	}
</style>
