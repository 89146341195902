<template>
	<div id="comment-section" class="comments-section">
		<div class="comments-tabs" :class="{'is_post': isFeed}">
			<ul class="nav nav-tabs mb-3">
				<li v-for="c_tab in commentsTabs"
				    :key="c_tab.name"
				    :class="['nav-item', { active: commentsTabActive && (commentsTabActive.id === c_tab.id) }]"
				    @click="changeCommentsTab(c_tab)">
					<a v-if="allTabs || (c_tab.id !== 3 && !allTabs)" class="nav-link" :class="[{ active: commentsTabActive && (commentsTabActive.id === c_tab.id) }]">
						<span v-if="c_tab.id === 1">{{ objectData.comments_count }} комментариев</span>
						<span v-else-if="c_tab.id === 2">{{ objectData.friend_comments_count }} от друзей Up&Up</span>
						<span v-else>{{ objectData.similar_count }} Кто ответил так же</span>
					</a>
				</li>
			</ul>
			<button v-if="isFeed" class="comment" @click="scrollToComments()">Комментировать</button>
		</div>
		<div class="comments-content" id="comments-content" :class="{'is-gallery': isGallery}">
			<!--:style="{'opacity': (commentsLoaded) ? 1 : 0}"-->
			<div :ref="'message_'+comment.fb_id" :style="{'opacity': (commentsLoaded) ? 1 : 0}" class="comment" v-for="(comment, index) in comments.fb_list" :key="index">
				<div :id="comment.fb_id" class="main-comment-data" :class="{'has-answers': comment.children.length}">
					<div class="avatar-wrapper" @click="goToProfile(comment.user)">
						<avatar :width="36" :height="36" :img="comment.user.avatar_parsed"></avatar>
					</div>
					<div class="comment-data">
						<div class="name">
                            <span class="name-text" :class="{'deleted': comment.user.is_deleted}">
                                {{ (!comment.user.is_deleted) ? comment.user.name : 'Удаленный пользователь' }}
                            </span>
							<span class="comment-timestamp">{{ comment.timestamp_parsed }}</span>
							<div class="edit">
                                <span v-if="comment.user.id === isAuthenticated.user.id" class="control-icons" @click="initEditComment(comment)">
                                    <i class="far fa-edit"></i>
                                </span>
								<!--<img v-if="comment.user.id === isAuthenticated.user.id" class="control-icons" @click="initEditComment(comment)" src="../../assets/img/writing-new.svg" alt="edit">-->
							</div>
							<div class="delete">
                                <span v-if="comment.user.id === isAuthenticated.user.id" class="control-icons" @click="deleteComment(comment)">
                                    <i class="far fa-trash-alt"></i>
                                </span>
								<!--<img v-if="comment.user.id === isAuthenticated.user.id" class="control-icons" @click="deleteComment(comment)" src="../../assets/img/delete-new.svg" alt="delete">-->
							</div>
						</div>
						<person-progress width="64" height="4" :green="comment.similar_answers_percent" :red="comment.diff_answers_percent" :gray="comment.not_answered_percent"></person-progress>
						<div class="message">
                            <span v-if="!comment.edit_mode && comment.is_deleted" class="deleted">
                                Комментарий удален модератором
                            </span>
							<span v-if="!comment.edit_mode && comment.is_deleted_by_user" class="deleted">
                                Пользователь удалил свой комментарий
                            </span>
							<span v-html="comment.body" v-if="!comment.edit_mode && !comment.is_deleted && !comment.is_deleted_by_user">

                            </span>
							<label v-if="comment.user.id === isAuthenticated.user.id && comment.edit_mode">
								<textarea :ref="'edit_' + comment.fb_id" name="" :id="comment.user.id" cols="30" rows="10" v-model="comment.body_edit" @keypress.enter="handleEnter($event, true, comment)" @keydown.ctrl.enter="preventCommentEdit($event, comment)"></textarea>
								<div v-if="comment.edit_mode" class="def-btn submit-changes" @click="handleEnter($event, true, comment)">
									Потвердить
								</div>
								Нажмите ctrl+enter для <span @click="preventCommentEdit($event, comment)">Отмены</span>
							</label>
						</div>
						<div class="comment-actions">
							<div class="answer" @click="answerToUser(comment)" v-if="!comment.user.is_deleted">
								<img src="../../assets/img/quote-icon.svg" alt="emoji">
								Ответить
							</div>
							<div class="answer-input-wrapper" v-show="(replyMessage.tag && (comment.fb_id === replyMessage.tag.fb_id))">
								<div class="answer-input">
									<textarea :ref="comment.fb_id" v-model="replyMessage.body" @keypress.enter="handleReplyEnter($event, false, comment)" @keyup="handleResizedTextarea($event)" rows="1" class="reply-message-input" id="reply-message-input"></textarea>
									<span @click="clearReplyMessage()" class="delete-action"><i class="far fa-trash-alt"></i></span>
								</div>
								<div class="def-btn submit-changes" @click="handleReplyEnter($event, false, ca)">
									Потвердить
								</div>
							</div>
							<!--<div class="emoji">
								<img src="../../assets/img/emoji-icon.svg" alt="emoji">
							</div>-->
						</div>
					</div>
				</div>
				<div :id="ca.fb_id" :ref="'message_'+ca.fb_id" class="answers-to-comment" v-for="ca in comment.children">
					<div class="avatar-wrapper" @click="goToProfile(ca.user)">
						<avatar :width="36" :height="36" :img="ca.user.avatar_parsed"></avatar>
					</div>
					<div class="comment-data">
						<div class="name">
                            <span class="name-text" :class="{'deleted': ca.user.is_deleted}">
                                {{ (!ca.user.is_deleted) ? ca.user.name : 'Удаленный пользователь' }}
                            </span>
							<span class="comment-timestamp">{{ ca.timestamp_parsed }}</span>
							<div class="edit">
                                <span v-if="ca.user.id === isAuthenticated.user.id" class="control-icons" @click="initEditComment(ca)">
                                    <i class="far fa-edit"></i>
                                </span>
								<!--<img v-if="ca.user.id === isAuthenticated.user.id" class="control-icons" @click="initEditComment(ca)" src="../../assets/img/writing-new.svg" alt="edit">-->
							</div>
							<div class="delete">
                                <span v-if="ca.user.id === isAuthenticated.user.id" class="control-icons" @click="deleteComment(ca)">
                                    <i class="far fa-trash-alt"></i>
                                </span>
								<!--<img v-if="ca.user.id === isAuthenticated.user.id" class="control-icons" @click="deleteComment(ca)" src="../../assets/img/delete-new.svg" alt="delete">-->
							</div>
						</div>
						<person-progress width="64" height="4" :green="ca.similar_answers_percent" :red="ca.diff_answers_percent" :gray="ca.not_answered_percent"></person-progress>
						<div class="message">
                            <span v-if="!ca.edit_mode && ca.is_deleted" class="deleted">
                                Комментарий удален модератором
                            </span>
							<span v-if="!ca.edit_mode && ca.is_deleted_by_user" class="deleted">
                                Пользователь удалил свой комментарий
                            </span>
							<span class="user-tagged" v-if="ca.tagged_user_fb_id && ca.tagged_user">@{{ ca.tagged_user.name }},</span>
							<span v-html="ca.body" v-if="!ca.edit_mode && !ca.is_deleted && !ca.is_deleted_by_user"> </span>
							<label v-if="ca.user.id === isAuthenticated.user.id && ca.edit_mode">
								<textarea :ref="'edit_' + comment.fb_id" name="" :id="ca.user.id" cols="30" rows="10" v-model="ca.body_edit" @keypress.enter="handleEnter($event, true, ca)" @keydown.ctrl.enter="preventCommentEdit($event, ca)"></textarea>
								<div v-if="ca.edit_mode" class="def-btn submit-changes" @click="handleEnter($event, true, ca)">
									Потвердить
								</div>
								Нажмите ctrl+enter для <span @click="preventCommentEdit($event, ca)">Отмены</span>
							</label>
						</div>
						<div class="comment-actions">
							<div class="answer" @click="answerToUser(comment, ca)" v-if="!ca.user.is_deleted">
								<img src="../../assets/img/quote-icon.svg" alt="emoji">
								Ответить
							</div>
							<div class="answer-input-wrapper" v-show="(replyMessage.tag && (ca.fb_id === replyMessage.tag.fb_id))">
								<div class="answer-input">
									<textarea :ref="ca.fb_id" v-model="replyMessage.body" @keypress.enter="handleReplyEnter($event, false, ca)" @keyup="handleResizedTextarea($event)" rows="1" class="reply-message-input" id="reply-message-input"></textarea>
									<span @click="clearReplyMessage()" class="delete-action"><i class="far fa-trash-alt"></i></span>
								</div>
								<div class="def-btn submit-changes" @click="handleReplyEnter($event, false, ca)">
									Потвердить
								</div>
							</div>
							
							<!--<div class="emoji">
								<img src="../../assets/img/emoji-icon.svg" alt="emoji">
							</div>-->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="input" v-if="(objectData.type !== 101 && topicGlobalId !== 8) || (objectData.type === 101 && objectData.is_comment_enabled) || (topicGlobalId === 8 && objectData.is_comment_enabled)">
			<avatar :width="36" :height="36" :img="isAuthenticated.user.avatar_parsed"></avatar>
			
			<textarea ref="comment_input" v-model="commentMessage.body" @keypress.enter="handleEnter($event, false, null)" rows="1" :placeholder="(topicGlobalId === 8 && !objectData.is_comment_enabled) ? 'Возможность коментировать отключена' : 'Напишите ваш комментарий'" class="write-message-input" id="write-message-input" :disabled="topicGlobalId === 8 && !objectData.is_comment_enabled"></textarea>
			<button class="submit-message-btn" @click="handleEnter($event, false, null)" :disabled="commentSubmitLoading" :class="{'disabled': commentSubmitLoading}"></button>
		</div>
	</div>
</template>

<script>
import {commentsService, questionsService} from "../../_api";
import {handleAWSImage} from "../../_helpers/aws-handler";
import avatar from "./Avatar";
import PersonProgress from "./UserProgress";
import sanitizeHtml from "sanitize-html";

export default {
	name: 'comments',
	props: {
		topicGlobalId: Number,
		objectData: {
			required: true
		},
		allTabs: Boolean,
		isPost: Boolean,
		isFeed: Boolean,
		isGallery: Boolean
	},
	components: {
		PersonProgress,
		avatar
	},
	data() {
		return {
			el: null,
			commentsTabActive: null,
			commentsTabs: [
				{
					id: 1,
					name: 'all'
				},
				{
					id: 2,
					name: 'inner-circle'
				},
				{
					id: 3,
					name: 'same-answer'
				}
			],
			comments: {
				fb_list: [],
				collection: null,
				question_type: null,
				query: null,
				page: 1
			},
			commentSubmitLoading: false,
			commentsLoaded: false,
			commentMessage: {
				body: '',
				tag: null
			},
			replyMessage: {
				body: '',
				tag: null
			},
			messageSubmitLoading: false,
			commentsListener: null,
			childCommentsListener: null,
			commentsLimit: 20,
			prevCommentsListener: null,
			lastVisibleComment: null,
			innerCommentsPrevLoading: false
		}
	},
	computed: {
		isAuthenticated() {
			return this.$store.state.authentication
		},
		getFireUserData() {
			return this.$store.state.firebase.firebaseUserData
		}
	},
	beforeCreate() {},
	created() {},
	mounted() {
		this.changeCommentsTab()
	},
	beforeDestroy() {
		if (this.childCommentsListener) {
			this.childCommentsListener();
		}
		if (this.commentsListener) {
			this.commentsListener();
		}
		if (this.prevCommentsListener) {
			this.prevCommentsListener();
		}
	},
	methods: {
		changeCommentsTab(tab) {
			this.commentsTabActive = (tab) ? tab : this.commentsTabs[0];
			this.comments.fb_list = [];
			this.comments.page = 1;
			this.commentsLoaded = false;
			if (this.childCommentsListener) {
				this.childCommentsListener();
			}
			if (this.commentsListener) {
				this.commentsListener();
			}
			if (this.prevCommentsListener) {
				this.prevCommentsListener();
			}
			
			if (this.isPost) {
				this.comments.collection = this.$firebasePostComments;
				this.comments.question_type = 'post_id';
			} else {
				if (this.topicGlobalId === 8) {
					this.comments.collection = this.$firebasePersonalComments;
					this.comments.question_type = 'personal_question_id';
				} else {
					this.comments.collection = this.$firebaseComments;
					this.comments.question_type = 'question_id';
				}
			}
			
			if (this.commentsTabActive) {
				if (this.isPost) {
					this.comments.query = this.comments.collection.where(this.comments.question_type, '==', this.objectData.object_id).orderBy('updated_at', 'desc').limit(this.commentsLimit);
				} else {
					if (this.commentsTabActive.id === 1) {
						this.comments.query = this.comments.collection.where(this.comments.question_type, '==', this.objectData.question_id).orderBy('updated_at', 'desc').limit(this.commentsLimit);
					} else if (this.commentsTabActive.id === 2) {
						// Trigger backed method
					} else if (this.commentsTabActive.id === 3) {
						this.comments.query = this.comments.collection.where(this.comments.question_type, '==', this.objectData.question_id).where('answer_id', '==', +this.objectData.selected.id).orderBy('updated_at', 'desc').limit(this.commentsLimit);
					}
				}
			}
			
			
			this.getComments(this.commentsTabActive);
			this.scrollListener();
		},
		
		getUser(data) {
			return new Promise(((resolve, reject) => {
				this.$firebaseUsers.where('fb_id', '==', data).get().then(querySnapshot => {
					if (!querySnapshot.empty) {
						resolve(querySnapshot.docs[0].data());
					} else {
						resolve(null);
					}
				})
			}))
		},
		getComments(tab, reload) {
			let isInit = true;
			let count = 0;
			if (tab.id !== 2) {
				this.commentsListener = this.comments.query.onSnapshot(snapshot => {
					if (snapshot.empty) {
						this.commentsLoaded = true;
					}
					count = snapshot.docs.length;
					// this.comments.fb_list = [];
					if (isInit) {
						this.lastVisibleComment = snapshot.docs[snapshot.docs.length - 1];
					}
					snapshot.docChanges().forEach((change) => {
						if (change.type === 'added') {
							this.getUser(change.doc.data().user_fb_id).then(res => {
								let d = change.doc.data();
								d['user'] = res || {};
								d['user']['avatar_parsed'] = null;
								handleAWSImage(d['user'].avatar_36).then((response) => {
									d['user']['avatar_parsed'] = response;
									this.$forceUpdate();
								})
								
								d['user']['is_deleted'] = res === null ? 1 : 0;
								d['timestamp_parsed'] = d.updated_at ? this.moment(d.updated_at.toDate()).fromNow() : this.moment().fromNow();
								d['children'] = [];
								
								/*d.body = d.body.replace(/(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/ig, '<a href="$&" style="color:#007bff" target="_blank">$&</a>');*/
								d.body = sanitizeHtml(d.body).replace(/(https?:\/\/[^\s]+)/g,'<a href="$&" style="color: blue"  target="_blank">$&</a>')
								this.comments.fb_list.unshift(d);
								this.getUserProgressBar(d, null, false);
								
								if (isInit && this.comments.fb_list.length === count) {
									this.comments.fb_list.sort((a, b) => {
										return a.updated_at.toDate() - b.updated_at.toDate()
									});
									getCommentsChildren();
									isInit = false;
								} else if (!isInit) {
									this.comments.fb_list.sort((a, b) => {return a.updated_at.toDate() - b.updated_at.toDate()});
									this.handleCommentsChildren(d.fb_id);
									if (this.el.isBottom) {
										setTimeout(() => {
											this.el.scrollTo({
												top: this.el.scrollHeight, // could be negative value
												left: 0,
												behavior: 'smooth'
											});
										}, 250)
									}
								}
								
								
								// this.commentsLoaded = true;
							});
						} else if (change.type === 'modified') {
							let index = this.comments.fb_list.findIndex((o) => {return o.fb_id === change.doc.data().fb_id});
							if (index !== -1) {
								this.comments.fb_list[index].body = sanitizeHtml(change.doc.data().body).replace(/(https?:\/\/[^\s]+)/g,'<a href="$&" style="color: blue"  target="_blank">$&</a>');
								this.comments.fb_list[index].timestamp_parsed = this.moment(change.doc.data().updated_at.toDate()).fromNow();
								this.$forceUpdate();
							}
						} else if (change.type === 'removed') {
							this.comments.fb_list.forEach((o, index) => {
								if (o.fb_id === change.doc.data().fb_id) {
									this.comments.fb_list.splice(index, 1);
								}
							});
						}
					});
					
				});
			} else {
				if (!this.innerCommentsPrevLoading) {
					this.innerCommentsPrevLoading = true;
					commentsService.getInnerCircleCommentsAPI({
						object_id: (this.isPost) ? this.objectData.object_id : this.objectData.question_id,
						is_personal: (this.topicGlobalId === 8) ? 1 : 0,
						is_post: (this.isPost) ? 1 : 0,
						page: (!reload) ? this.comments.page : this.comments.page + 1
					}).then(res => {
						if (res && res.data.length) {
							res.data.forEach((obj) => {
								this.comments.fb_list.push(obj);
							});
							
							this.comments.fb_list.forEach((obj, index) => {
								obj['timestamp_parsed'] = obj.updated_at ? this.moment(obj.updated_at).add(3, 'hours').fromNow() : this.moment().fromNow();
								obj.user['avatar_parsed'] = null;
								handleAWSImage(obj.user.avatar_36).then((res) => {
									obj.user.avatar_parsed = res;
									this.$forceUpdate();
								});
								obj.children.forEach(c_obj => {
									
									count += 1;
									c_obj['timestamp_parsed'] = c_obj.updated_at ? this.moment(c_obj.updated_at).add(3, 'hours').fromNow() : this.moment().fromNow();
									c_obj.user['avatar_parsed'] = null;
									handleAWSImage(c_obj.user.avatar_36).then((res) => {
										c_obj.user.avatar_parsed = res;
										this.$forceUpdate();
									});
								});
							});
							
							if (this.comments.fb_list.length === res.data.length) {
								console.log('this.comments.fb_list', this.comments.fb_list)
								
								let elQQ = document.getElementById('comments-content');
								setTimeout(() => {
									elQQ.scrollTop = elQQ.scrollHeight;
									this.commentsLoaded = true;
								}, 250);
							}
							if (res.data.length) {
								this.comments.page = res.current_page;
							}
						}
						this.innerCommentsPrevLoading = false;
					})
					
				}
				
			}
			
			let getCommentsChildren = () => {
				let isChildInit = true;
				this.comments.fb_list.forEach((obj, index) => {
					this.handleCommentsChildren(obj.fb_id, index, true).then(res => {
						setTimeout(() => {
							isChildInit = false;
							let elQQ = document.getElementById('comments-content');
							elQQ.scrollTop = elQQ.scrollHeight;
							this.commentsLoaded = true;
						}, 500);
					});
				});
			};
			
			
		},
		getPrevComments() {
			
			return new Promise((resolve, reject) => {
				let isInit = true;
				let count = 0;
				let arr = [];
				this.prevCommentsListener = this.comments.query.startAfter(this.lastVisibleComment).onSnapshot(snapshot => {
					if (snapshot.docs.length) {
						count = snapshot.docs.length;
						this.lastVisibleComment = snapshot.docs[snapshot.docs.length - 1];
						snapshot.docChanges().forEach((change) => {
							if (change.type === 'added') {
								this.getUser(change.doc.data().user_fb_id).then(res => {
									let d = change.doc.data();
									d['user'] = res || {};
									// d['user']['avatar_parsed'] = (!d['user'].avatar_36) ? null : 'data:image/png;base64,' + d['user'].avatar_36;
									d['user']['avatar_parsed'] = null;
									handleAWSImage(d['user'].avatar_36).then((response) => {
										d['user']['avatar_parsed'] = response;
										this.$forceUpdate();
									})
									d['user']['is_deleted'] = res === null ? 1 : 0;
									d['timestamp_parsed'] = d.updated_at ? this.moment(d.updated_at.toDate()).fromNow() : this.moment().fromNow();
									d['children'] = [];
									d.body = sanitizeHtml(d.body).replace(/(https?:\/\/[^\s]+)/g,'<a href="$&" style="color: blue"  target="_blank">$&</a>');
									this.comments.fb_list.unshift(d);
									this.getUserProgressBar(d, null, false);
									arr.push(d);
									if (isInit && arr.length === count) {
										getCommentsChildren(arr);
										isInit = false;
										resolve();
									}
								});
								
								
							} else if (change.type === 'modified') {
								let index = this.comments.fb_list.findIndex((o) => {return o.fb_id === change.doc.data().fb_id});
								if (index !== -1) {
									this.comments.fb_list[index].body = sanitizeHtml(change.doc.data().body).replace(/(https?:\/\/[^\s]+)/g,'<a href="$&" style="color: blue"  target="_blank">$&</a>');
									this.comments.fb_list[index].timestamp_parsed = this.moment(change.doc.data().updated_at.toDate()).fromNow();
									this.$forceUpdate();
								}
							} else if (change.type === 'removed') {
								this.comments.fb_list.forEach((o, index) => {
									if (o.fb_id === change.doc.data().fb_id) {
										this.comments.fb_list.splice(index, 1);
									}
								});
							}
						});
					}
				});
				
				let getCommentsChildren = (arr) => {
					let isInit = true;
					this.comments.fb_list.forEach((obj, index) => {
						this.handleCommentsChildren(obj.fb_id, index, true).then(res => {
							isInit = false;
							setTimeout(() => {
								this.commentsLoaded = true;
							}, 500);
						});
					});
				};
			})
		},
		handleCommentsChildren(parent_id, index, is_init) {
			return new Promise((resolve, reject) => {
				this.childCommentsListener = this.comments.collection.doc(parent_id).collection('children').orderBy('updated_at', 'asc').onSnapshot((snapshot_c) => {
					snapshot_c.docChanges().forEach((change_c) => {
						if (change_c.type === 'added') {
							this.getUser(change_c.doc.data().user_fb_id).then(res => {
								let dd = change_c.doc.data();
								dd['user'] = res || {};
								dd['user']['avatar_parsed'] = null;
								handleAWSImage(dd['user'].avatar_36).then((response) => {
									dd['user']['avatar_parsed'] = response;
									this.$forceUpdate();
								})
								dd['user']['is_deleted'] = res === null ? 1 : 0;
								// dd['user'] = res;
								dd['timestamp_parsed'] = dd.updated_at ? this.moment(dd.updated_at.toDate()).fromNow() : this.moment().fromNow();
								if (dd.tagged_user_fb_id) {
									this.getUser(dd.tagged_user_fb_id).then(res => {
										if (res) {
											dd['tagged_user'] = res;
										}
									})
								}
								let index = this.comments.fb_list.findIndex((o) => {return o.fb_id === parent_id});
								if (index !== -1) {
									let c_index = this.comments.fb_list[index].children.findIndex((i) => { return i.fb_id === dd.fb_id });
									if (c_index === -1) {
										/*dd.body = dd.body.replace(/(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/ig, '<a href="$&"  target="_blank">$&</a>');*/
										dd.body = sanitizeHtml(dd.body).replace(/(https?:\/\/[^\s]+)/g,'<a href="$&" style="color: blue"  target="_blank">$&</a>');
										this.comments.fb_list[index]['children'].push(dd);
										this.comments.fb_list[index]['children'].sort((a, b) => {return a.updated_at.toDate() - b.updated_at.toDate()});
										this.getUserProgressBar(dd, index, true);
										this.$forceUpdate();
										
									}
									this.$forceUpdate();
								}
							});
							
						} else if (change_c.type === 'modified') {
							let t = this.comments.fb_list.findIndex((i) => {return i.fb_id === parent_id});
							if (t !== -1) {
								let c_index = this.comments.fb_list[t].children.findIndex((i) => { return i.fb_id === change_c.doc.data().fb_id });
								if (c_index !== -1) {
									this.comments.fb_list[t].children[c_index].body = sanitizeHtml(change_c.doc.data().body).replace(/(https?:\/\/[^\s]+)/g,'<a href="$&" style="color: blue"  target="_blank">$&</a>');
									this.comments.fb_list[t].children[c_index].timestamp_parsed = this.moment(change_c.doc.data().updated_at.toDate()).fromNow();
									this.$forceUpdate();
								}
								this.$forceUpdate();
							}
						} else if (change_c.type === 'removed') {
							this.comments.fb_list.forEach((o, index) => {
								if (o.fb_id === parent_id) {
									o.children.forEach((c, c_index) => {
										if (c.fb_id === change_c.doc.data().fb_id) {
											this.comments.fb_list[index].children.splice(c_index, 1);
										}
									})
								}
							});
						}
					});
					resolve();
				})
			})
		},
		handleEnter(e, is_edit, data) {
			if (!is_edit) {
				if (e.keyCode === 13 && e.shiftKey) {
					e.preventDefault();
					const cursorPos = e.target.selectionStart;
					let strToArr = Array.from(this.commentMessage.body);
					strToArr.splice(e.target.selectionStart, 0, "\n")
					this.commentMessage.body = strToArr.join('');
					this.$nextTick(() => {
						if (!data) {
							this.$refs['comment_input'].selectionStart = cursorPos + 1;
							this.$refs['comment_input'].selectionEnd = cursorPos + 1;
						} else {
							this.$refs[data.fb_id][0].selectionStart = cursorPos + 1;
							this.$refs[data.fb_id][0].selectionEnd = cursorPos + 1;
						}
						this.$forceUpdate();
					})
					setTimeout(function () {
						document.getElementById('write-message-input').scrollTop = document.getElementById('write-message-input').scrollHeight - document.getElementById('write-message-input').clientHeight
					}, 0);
				} else if (e.keyCode === 13 && !e.shiftKey) {
					e.preventDefault();
					this.submitComment();
				} else {
					e.preventDefault();
					this.submitComment();
				}
			} else {
				if (e.keyCode === 13 && e.shiftKey) {
					this.$forceUpdate();
				} else if (e.keyCode === 13 && !e.shiftKey) {
					e.preventDefault();
					this.editComment(data);
				} else {
					this.editComment(data);
				}
			}
		},
		handleReplyEnter(e, is_edit, data) {
			if (!is_edit) {
				if (e.keyCode === 13 && e.shiftKey) {
					e.preventDefault();
					const cursorPos = e.target.selectionStart;
					let strToArr = Array.from(this.replyMessage.body);
					strToArr.splice(e.target.selectionStart, 0, "\n");
					this.replyMessage.body = strToArr.join('');
					this.$nextTick(() => {
						this.$refs[data.fb_id][0].selectionStart = cursorPos + 1;
						this.$refs[data.fb_id][0].selectionEnd = cursorPos + 1;
						this.$forceUpdate();
					})
					setTimeout(function () {
						e.target.scrollTop = e.target.scrollHeight - e.target.clientHeight;
					}, 0);
				} else if (e.keyCode === 13 && !e.shiftKey) {
					e.preventDefault();
					this.submitComment(true);
				} else {
					e.preventDefault();
					this.submitComment(true);
				}
			} else {
				if (e.keyCode === 13 && e.shiftKey) {
					this.$forceUpdate();
				} else if (e.keyCode === 13 && !e.shiftKey) {
					e.preventDefault();
					this.editComment(data);
				}
			}
		},
		clearReplyMessage() {
			this.replyMessage = {
				body: '',
				tag: null
			}
		},
		handleResizedTextarea(e) {
			setTimeout(() => {
				e.target.style.height = 'auto';
				e.target.style.height = (e.target.scrollHeight + 2) + 'px';
			}, 50)
		},
		preventCommentEdit(e, data) {
			data.body_edit = '';
			data.edit_mode = false;
			this.$forceUpdate();
		},
		answerToUser(comment, answer) {
			if (this.replyMessage.body.length && this.replyMessage.body.split(this.replyMessage.body_tag).pop().length > 0) {
				this.submitComment(true);
			} else {
				let objToHandle;
				if (answer) {
					objToHandle = {...answer};
				} else {
					objToHandle = {...comment};
				}
				this.replyMessage = {
					body: `@${objToHandle.user.name}, `,
					tag: objToHandle,
					body_tag: `@${objToHandle.user.name}, `,
				};
				this.$nextTick(() => {
					this.$refs[(answer) ? answer.fb_id : comment.fb_id][0].focus()
				})
			}
			
		},
		submitComment(is_reply) {
			let body = is_reply ? this.replyMessage : this.commentMessage;
			this.commentSubmitLoading = true;
			let objToSend = {
				object_id: (this.isPost) ? this.objectData.object_id : this.objectData.question_id,
				parent_id: (body.tag && body.tag.id) ? body.tag.id : null,
				body: (body.tag !== null && body.body.includes(body.body_tag)) ? body.body.split(body.body_tag).join("") : body.body,
				is_personal: (this.topicGlobalId === 8) ? 1 : 0,
				is_post: (this.isPost) ? 1 : 0
			};
			commentsService.sendCommentAPI(objToSend).then((res) => {
				if (res) {
					if (body.tag !== null && body.body.includes(body.body_tag)) {
						this.$nextTick(() => {
							if (is_reply) {
								let t = this.comments.fb_list.filter((o) => {
									return o.fb_id === body.tag.fb_id
								})[0];
								if (t.children.length) {
									let tt = t.children[t.children.length - 1].fb_id;
									if (this.$refs['message_' + tt]) {
										this.$refs['message_' + tt][0].scrollIntoView({behavior: "smooth"});
									}
								}
								
							}
						})
						
						this.$forceUpdate();
					} else {
						this.$nextTick(() => {
							this.scrollBottom(true);
						})
					}
					this.objectData.comments_count += 1;
					this.objectData.friend_comments_count += 1;
					this.commentMessage = {
						body: '',
						tag: null
					};
					this.replyMessage = {
						body: '',
						tag: null
					};
				}
				this.commentSubmitLoading = false;
			})
		},
		deleteComment(data) {
			let q = confirm('Are you sure?');
			if (q) {
				let objToSend = {
					comment_id: data.id,
					is_personal: (this.topicGlobalId === 8) ? 1 : 0,
					is_post: (this.isPost) ? 1 : 0
				};
				commentsService.deleteCommentAPI(objToSend).then((res) => {
					if (res !== undefined) {
						this.objectData.comments_count -= 1;
						this.objectData.friend_comments_count -= 1;
						
					}
					
				})
			}
		},
		initEditComment(data) {
			data['body_edit'] = data.body;
			data['edit_mode'] = true;
			this.$forceUpdate();
		},
		editComment(data) {
			let q = confirm('Are you sure?');
			if (q) {
				let objToSend = {
					comment_id: data.id,
					body: data.body_edit/*,
                        question_id: this.objectData.question_id*/
				};
				commentsService.updateCommentAPI(objToSend, (this.topicGlobalId === 8) ? 1 : 0, (this.isPost) ? 1 : 0).then((res) => {
					data.body_edit = '';
					data.edit_mode = false;
					this.$forceUpdate();
				})
			}
		},
		getUserProgressBar(data, index, is_child) {
			
			questionsService.getUserProgressBarAPI({
				user_ids: [data.user.id]
			}).then(res => {
				if (res) {
					data = {...data, ...res[0]};
					if (is_child) {
						let c_index = this.comments.fb_list[index]['children'].findIndex((i) => { return i.fb_id === data.fb_id });
						if (c_index !== -1) {
							this.comments.fb_list[index]['children'][c_index] = {...data};
							this.$forceUpdate();
						}
					} else {
						let t = this.comments.fb_list.findIndex((i) => {return i.fb_id === data.fb_id});
						if (t !== -1) {
							this.comments.fb_list[t] = data;
							this.comments.fb_list = [...this.comments.fb_list];
							this.$forceUpdate();
						}
					}
				}
			});
		},
		
		scrollBottom(comment_section) {
			let el = document.getElementById('comment-section');
			let elQQ = document.getElementById('comments-content');
			let elQ = document.getElementById('question-details-modal');
			if (elQ) {
				elQ.scrollTo({
					top: el.scrollHeight, // could be negative value
					left: 0,
					behavior: 'smooth'
				});
			}
			if (comment_section) {
				elQQ.scrollTo({
					top: elQQ.scrollHeight, // could be negative value
					left: 0,
					behavior: 'smooth'
				});
			}
		},
		scrollListener() {
			this.el = document.getElementById('comments-content');
			if (this.el) {
				this.el.isBottom = false;
				this.el.scrolledFromBottom = 0;
				this.el.scr = 0;
				this.el.loading = false;
				this.el.addEventListener('scroll', (element) => {
					this.el.scrolledFromBottom = this.el.scrollHeight - this.el.scrollTop - this.el.clientHeight;
					this.el.scr = this.el.scrollHeight;
					if (this.el.scrollHeight - this.el.scrollTop - this.el.clientHeight <= 150) {
						this.el.isBottom = true;
					} else {
						this.el.isBottom = false;
						if (!this.el.scrollTop) {
							
							if (this.el.scr !== this.el.scrollHeight) {
								this.el.scr = this.el.scrollHeight;
							}
							
							if (this.commentsTabActive.id === 2) {
								this.getComments(this.commentsTabActive, true);
							} else {
								if (this.el.loading === false) {
									this.el.loading = true;
									this.getPrevComments().then(() => {
										this.el.scrollTop = this.el.scrollHeight - this.el.scr;
										this.el.loading = false;
									})
								}
								
							}
							
						}
					}
				});
			}
		},
		
		triggerFocus() {
			this.$refs.comment_input.focus()
		},
		
		goToProfile(data) {
			this.$emit('goToProfile', data);
		},
		scrollToComments(item) {
			let el = document.getElementById('comment-section');
			let elQ = document.getElementById('question-details-modal');
			setTimeout(() => {
				elQ.scrollTo({
					top: el.scrollHeight, // could be negative value
					left: 0,
					behavior: 'smooth'
				});
			}, 200)
		},
	},
	watch: {
		// 'this.comments.fb_list': (res) => {
		//   console.log('watch',res)
		// },
		
		objectData: function (res) {
			this.changeCommentsTab();
		},
	},
}
</script>

<style lang="less">
@import "../../assets/styles/_vars.less";

.comments-section {
	padding: 36px 0 0 0;
	
	.comments-tabs {
		margin-bottom: 30px;
		
		&.is_post {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
			padding: 0 20px;
			
			ul {
				// width: auto;
				justify-content: flex-start;
			}
		}
		
		.comment {
			text-transform: uppercase;
			border-radius: 35px;
			border: 1px solid lightgrey;
			background-color: transparent;
			color: #333333;
			text-align: center;
			padding: 7px 30px;
			font-size: 10px;
			font-weight: 500;
			
			&:focus {
				outline: none;
			}
		}
		
		ul {
			justify-content: center;
			margin: 0 !important;
			
			li {
				a {
					&.active {
						border-bottom: 2px solid @theme_color !important;
					}
					
					span {
						font-weight: bold;
						font-size: 11px;
						color: @theme_color;
						text-transform: uppercase;
					}
				}
			}
		}
	}
	
	.comments-content {
		padding: 0 36px;
		border-bottom: 1px solid #E0E3EA;
		max-height: 400px;
		min-height: 400px;
		overflow-y: scroll;
		
		&.is-gallery {
			min-height: unset;
		}
		
		&::-webkit-scrollbar {
			height: 4px;
			width: 4px;
			background: gray;
			border-radius: 10px;
		}
		
		&::-webkit-scrollbar-thumb {
			background: lightgrey;
			border-radius: 10px;
		}
		
		.loading-comments {
			position: absolute;
			left: 50%;
			margin-left: -50px;
			
			img {
				width: 100px;
			}
		}
		
		.comment {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-bottom: 30px;
			transition: opacity 500ms;
			
			.main-comment-data {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: nowrap;
				width: 100%;
				
				&.has-answers {
					margin-bottom: 30px;
				}
				
				.avatar-wrapper {
					/*min-width: 36px;
					min-height: 36px;
					max-width: 36px;
					max-height: 36px;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					margin: 0 14px 0 0;
					img {
						height: 100%;
						width: 100%;
					}*/
					margin-right: 14px;
					cursor: pointer;
				}
				
				.comment-data {
					&:hover {
						.name {
							.edit, .delete {
								visibility: visible;
							}
						}
					}
					
					.name {
						font-weight: 500;
						font-size: 12px;
						color: #666666;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: wrap;
						
						.name-text {
							margin-right: 10px;
							
							&.deleted {
								color: darkgray;
								font-style: italic;
							}
						}
						
						.comment-timestamp {
							font-size: 11px;
							font-weight: 400;
							color: #9B9B9B;
							margin-right: 10px;
						}
						
						.edit, .delete {
							// visibility: hidden;
							cursor: pointer;
							margin-right: 5px;
							
							.control-icons {
								.fa-edit, .fa-trash-alt {
									font-size: 15px;
									color: #999999;
								}
							}
							
							img {
								width: 15px;
							}
						}
					}
					
					.person-progress {
					}
					
					.message {
						font-weight: 400;
						font-size: 12px;
						color: #666666;
						white-space: pre-line;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: flex-start;
						text-align: left;
						
						span {
							&.deleted {
								font-style: italic;
							}
						}
						
						.submit-changes {
							margin-bottom: 5px;
							font-size: 8px;
							min-height: 16px;
							padding: 3px 15px;
						}
						
						label {
							margin-bottom: 10px;
							
							span {
								cursor: pointer;
								color: #8e8eff;
								
								&.deleted {
									font-style: italic;
								}
							}
							
							textarea {
								height: 50px;
								width: 100%;
								margin-top: 2px;
								margin-bottom: 0;
								resize: none;
								border-radius: 8px;
								padding: 2px 5px;
								
								&::-webkit-scrollbar {
									height: 4px;
									width: 4px;
									background: gray;
									border-radius: 10px;
								}
								
								&::-webkit-scrollbar-thumb {
									background: lightgrey;
									border-radius: 10px;
								}
							}
						}
					}
					
					.comment-actions {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: wrap;
						
						img {
							cursor: pointer;
						}
						
						.answer {
							font-weight: 400;
							font-size: 12px;
							color: #666666;
							margin-right: 15px;
							position: relative;
							cursor: pointer;
							/*&:after {
								content: '';
								height: 100%;
								width: 1px;
								background-color: #E0E3EA;
								position: absolute;
								right: -7px;
								top: 0;
							}*/
						}
						
						.answer-input-wrapper {
							min-width: 220px;
							width: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							flex-wrap: wrap;
							max-width: 300px;
							
							.answer-input {
								width: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								flex-wrap: nowrap;
								margin-bottom: 5px;
							}
							
							.submit-changes {
								margin-bottom: 5px;
								font-size: 8px;
								min-height: 16px;
								padding: 3px 15px;
								width: 100%;
							}
							
							.delete-action {
								color: #999999;
								cursor: pointer;
							}
							
							.reply-message-input {
								width: 100%;
								max-height: 50px;
								height: 100%;
								margin-right: 5px;
								padding: 5px 30px 5px 5px;
								border: 1px solid #E0E3EA;
								color: darkslategray;;
								border-radius: 8px;
								line-height: 18px;
								font-size: 13px;
								resize: none;
								/*overflow-x: hidden;
								overflow-y: hidden;*/
								
								&::-webkit-scrollbar {
									height: 4px;
									width: 4px;
									background: gray;
									border-radius: 10px;
								}
								
								&::-webkit-scrollbar-thumb {
									background: lightgrey;
									border-radius: 10px;
								}
								
								&::placeholder {
									font-weight: normal;
									font-size: 13px;
									line-height: 15px;
									color: #9B9B9B;
								}
							}
						}
						
					}
				}
			}
			
			.answers-to-comment {
				margin-left: 50px;
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: nowrap;
				width: 100%;
				
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				
				.avatar-wrapper {
					margin-right: 14px;
					cursor: pointer;
				}
				
				.comment-data {
					&:hover {
						.name {
							.edit, .delete {
								visibility: visible;
							}
						}
					}
					
					.name {
						font-weight: 500;
						font-size: 12px;
						color: #666666;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: wrap;
						
						.name-text {
							margin-right: 10px;
							
							&.deleted {
								color: darkgray;
								font-style: italic;
							}
						}
						
						.comment-timestamp {
							font-size: 11px;
							font-weight: 400;
							color: #9B9B9B;
							margin-right: 10px;
						}
						
						.edit, .delete {
							// visibility: hidden;
							cursor: pointer;
							margin-right: 5px;
							
							.control-icons {
								.fa-edit, .fa-trash-alt {
									font-size: 15px;
									color: #999999;
								}
							}
							
							img {
								width: 15px;
							}
						}
					}
					
					.person-progress {
					}
					
					.message {
						font-weight: 400;
						font-size: 12px;
						color: #666666;
						white-space: pre-line;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: flex-start;
						text-align: left;
						
						.user-tagged {
							color: #8e8eff;
							width: 100%;
						}
						
						.submit-changes {
							margin-bottom: 5px;
							font-size: 8px;
							min-height: 16px;
							padding: 3px 15px;
						}
						
						span {
							&.deleted {
								font-style: italic;
							}
						}
						
						label {
							margin-bottom: 10px;
							
							span {
								cursor: pointer;
								color: #8e8eff;
							}
							
							textarea {
								height: 50px;
								width: 100%;
								margin-top: 2px;
								margin-bottom: 0;
								resize: none;
								border-radius: 8px;
								padding: 2px 5px;
								
								&::-webkit-scrollbar {
									height: 4px;
									width: 4px;
									background: gray;
									border-radius: 10px;
								}
								
								&::-webkit-scrollbar-thumb {
									background: lightgrey;
									border-radius: 10px;
								}
							}
						}
					}
					
					.comment-actions {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: wrap;
						
						img {
							cursor: pointer;
						}
						
						.answer {
							font-weight: 400;
							font-size: 12px;
							color: #666666;
							margin-right: 15px;
							position: relative;
							cursor: pointer;
							/*&:after {
								content: '';
								height: 100%;
								width: 1px;
								background-color: #E0E3EA;
								position: absolute;
								right: -7px;
								top: 0;
							}*/
						}
						
						.answer-input-wrapper {
							// min-width: 220px;
							width: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							flex-wrap: wrap;
							max-width: 300px;
							
							.answer-input {
								width: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								flex-wrap: nowrap;
								margin-bottom: 5px;
							}
							
							.submit-changes {
								margin-bottom: 5px;
								font-size: 8px;
								min-height: 16px;
								padding: 3px 15px;
								width: 100%;
							}
							
							.delete-action {
								color: #999999;
								cursor: pointer;
							}
							
							.reply-message-input {
								width: 100%;
								max-height: 50px;
								height: 100%;
								margin-right: 5px;
								padding: 5px 30px 5px 5px;
								border: 1px solid #E0E3EA;
								color: darkslategray;;
								border-radius: 8px;
								line-height: 18px;
								font-size: 13px;
								resize: none;
								/*overflow-x: hidden;
								overflow-y: hidden;*/
								
								&::-webkit-scrollbar {
									height: 4px;
									width: 4px;
									background: gray;
									border-radius: 10px;
								}
								
								&::-webkit-scrollbar-thumb {
									background: lightgrey;
									border-radius: 10px;
								}
								
								&::placeholder {
									font-weight: normal;
									font-size: 13px;
									line-height: 15px;
									color: #9B9B9B;
								}
							}
						}
					}
				}
			}
		}
	}
	
	.input {
		padding-left: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
		height: 69px;
		position: relative;
		background-color: #fff;
		
		.image-as-bg {
			margin-right: 14px;
		}
		
		.write-message-input {
			width: 100%;
			padding: 5px 30px 5px 5px;
			border: none;
			height: 100%;
			line-height: 18px;
			font-size: 13px;
			resize: none;
			overflow-x: hidden;
			overflow-y: hidden;
			
			&::-webkit-scrollbar {
				height: 4px;
				width: 4px;
				background: gray;
				border-radius: 10px;
			}
			
			&::-webkit-scrollbar-thumb {
				background: lightgrey;
				border-radius: 10px;
			}
			
			&::placeholder {
				font-weight: normal;
				font-size: 13px;
				line-height: 15px;
				color: #9B9B9B;
			}
		}
		
		.submit-message-btn {
			width: 55px;
			height: 100%;
			background: transparent url(../../assets/img/send-icon1.svg) center no-repeat;
			background-size: 20px;
			border: none;
			outline: none;
			
			&:disabled {
				background-size: 20px !important;
				background: transparent url(../../assets/img/Infinity-1s-200px.svg) center no-repeat;
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1200px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	#comment-section {
		.comments-tabs {
			ul {
				li {
					a {
						padding: 5px 15px;
						
						span {
							font-size: 10px;
						}
					}
				}
			}
		}
		
		.comments-content {
			padding: 0 15px;
			
			.comment {
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				
				.main-comment-data {
					&.has-answers {
						margin-bottom: 10px;
					}
				}
			}
		}
		
		.input {
			padding-left: 10px;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 768px) {
	/*#comment-section {
		.comments-tabs {
			ul {
				li {
					a {
						padding: 5px 15px;
						span {
							font-size: 10px;
						}
					}
				}
			}
		}
		.comments-content {
			padding: 0 15px;
			.comment {
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				.main-comment-data {
					.has-answers {
						margin-bottom: 10px;
					}
				}
			}
		}
		.input {
			padding-left: 10px;
		}
	}*/
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
	#comment-section {
		.comments-tabs {
			&.is_post {
				flex-wrap: wrap-reverse;
				justify-content: center;
				
				ul {
					justify-content: center;
				}
			}
		}
	}
}
</style>
