<template>
    <div>
        <b-modal ref="upload-image" size="lg" id="upload-image" hide-footer hide-header :no-close-on-backdrop="false" @hidden="onModalHide" v-if="show">
            <div class="uploaded-data left" :class="{'edit-mode': isEditMode}">
                <div v-if="!isEditMode" class="image-preview-list">
                    <div v-if="!imgList.length" class="empty-placeholder">
                        Выберите фото
                    </div>
                    <div v-if="imgList.length" class="image-preview-wrapper" v-for="(i, index) of imgList">
                        <div class="img-wrapper">
                            <img draggable="false" :src="i.url" alt="">
                        </div>
                        <div class="image-details">
                            <div class="img-name">{{i.name}}</div>
                            <div class="img-type">{{i.type}} ({{i.size}})</div>
                        </div>
                        <img src="../../../assets/img/cancel.svg" alt="close" @click="dropFromArray(i, index)" class="c-btn-close image-controls">
                    </div>
                </div>
                <div class="image-additional-config">
                    <div class="visibility-wrapper">
                        <span class="text">Пост виден:</span>
                        <visibility-item-select :visibilityType="visibility" @updateType="refreshVisibility($event)" class="def-icon"></visibility-item-select>
                    </div>
                    <!--<div class="selected-users-wrapper" v-if="selected_users.length">
                        <div @click="showUsersModal()" class="user" v-for="u in selected_users">
                            <avatar :width="36" :height="36" :img="u.avatar_parsed"></avatar>
                            <div class="name">{{u.name}}</div>
                        </div>
                    </div>-->
                    <div class="comment-option">
                        <label for="comment_option" class="checkbox-container">
                            <input type="checkbox" class='custom-checkbox' id="comment_option" v-model="commentOption">
                            <span class="checkmark"></span>
                            Возможность комментировать
                        </label>
                    </div>
                </div>
                <div class="image-description">
                    <label for="descr">Описание:</label>
                    <textarea name="descr" id="descr" v-model="description"></textarea>
                </div>
                <div class="upload-controls">
	                <c_button :c_class="'upload def-btn'" :c_text="!isEditMode ? 'Загрузить' : 'Сохранить'" :disabled="(!isEditMode && !imgList.length) || uploadLoading" :c_loading="uploadLoading" @clickEvent="submitUpload()"></c_button>
                    <!--<button class="upload def-btn has-loading-grid" @click="submitUpload()" :disabled="(!isEditMode && !imgList.length) || uploadLoading">
                        {{!isEditMode ? 'Загрузить' : 'Сохранить'}}
                        <loading :reverse="true" v-if="uploadLoading"></loading>
                    </button>-->
                </div>
            </div>
            <div v-if="!isEditMode" class="upload-area right" @click="triggerInput()" @dragover="dragOverE" @drop.prevent="handleUserPhoto($event, true)" @dragover.prevent>
                <div class="upload-explanation">
                    <i class="fas fa-upload"></i>
                    <span>Перетащите сюда фотографии</span>
                    <span>или</span>
                    <button>Выбрать фотографии для загрузки</button>
                </div>
                <input ref="image-upload-input" type="file" @change="handleUserPhoto($event, false)" class="image-upload-input" accept="image/png, image/jpeg" name="image-upload-input" multiple>
            </div>
        </b-modal>
        <!--<b-modal ref="add-users" size="md" id="add-users" hide-footer :no-close-on-backdrop="true" title="Кому отправить эту фотографию?" @hidden="resetUsersModal">
            <search-filter :show-error="true" :placeholderText="'Найти пользователя среди друзей'" :items="arr" @filtered="filteredUsersList" class="search-filter" :is-server-side="false"></search-filter>
            <div class="users-list-wrapper" :class="{'loading': usersList.loading}">
                <img src="../../../assets/img/Infinity-1s-200px.svg" alt="loading" style="width: 100px" v-if="usersList.loading">
                <div class="users-list chat-users" v-if="!usersList.loading && usersList.users.length">
                    <div class="divider">
                        Ваши друзья:
                    </div>
                    <div class="user" v-for="u in usersList.users" @click="selectUser(u)" :class="{'selected': u.selected}" v-if="u.filtered">
                        <avatar :width="36" :height="36" :img="u.avatar_parsed"></avatar>
                        <div class="name">{{u.name}}</div>
                        <img v-if="u.selected" class="control-icons" src="../../../assets/img/check_new.svg" alt="delete">
                    </div>
                </div>
            </div>

            <div class="modal-controls" v-if="!usersList.loading">
                <button v-if="isSelected().length || selected_users.length" class="add-users" @click="addUsersToQuestion()">
                    <span v-if="isSelected().length">Добавить {{isSelected().length}}</span>
                    <span v-else-if="!isSelected().length && selected_users.length">Подтвердить</span>
                </button>
                <button class="add-users" @click="addUsersToQuestion(true)">
                    <span v-if="!isSelected().length || isSelected().length && isSelected().length !== arr.length">Добавить всех</span>
                    <span v-else-if="isSelected().length > 0 && isSelected().length === arr.length">Убрать всех</span>
                </button>
            </div>
        </b-modal>-->
    </div>
</template>

<script>
    import {questionsService, usersService} from "../../../_api";
    import visibilityItemSelect from "../VisibilityItemSelect";
    import Loading from "../Loading";
    import {handleAWSImage} from "@/_helpers/aws-handler";
    import searchFilter from '../SearchFilter';
    import avatar from "../Avatar";
    import C_button from "@/components/_shared/Button";

    export default {
        name: 'UploadImageModal',
        data() {
            return {
                show: false,
                isEditMode: false,
                fileContainer: [],
                fd: null,
                imgList: [],
                editPostId: null,
                description: '',
                visibility: 1,
                commentOption: true,
                validTypes: ['image/png', 'image/jpeg'],
                uploadLoading: false,
                customListOfItems: [
                    {
                        description: 'Все',
                        class: 'eye',
                        type: 1
                    },
                    {
                        description: 'Круг',
                        class: 'circle',
                        type: 2
                    },
                    {
                        description: 'Никто',
                        class: 'secret',
                        type: 3
                    }
                ],
                selected_users: [],
                usersList: {
                    users: [],
                    loading: false
                },
            }
        },
        props: ['editData'],
        components: {C_button, Loading, visibilityItemSelect, searchFilter, avatar},
        beforeDestroy() {},
        methods: {
            onModalHide() {
                this.imgList = [];
                this.fileContainer = [];
                this.fd = null;
                this.description = '';
                this.visibility = 1;
                this.commentOption = true;
                this.isEditMode = false;
                this.editPostId = false;
                this.selected_users = [];
            },
            showModal(data) {
                if (data) {
                    this.isEditMode = true;
                    this.commentOption = (data.is_comment_enabled) ? 1 : 0;
                    this.visibility = data.visibility;
                    this.description = data.description;
                    this.editPostId = data.id;
                } else {
                    this.onModalHide();
                }
                this.show = true;
                setTimeout(() => {
                    this.$refs['upload-image'].show();
                }, 50);
            },
            closeModal() {
                this.show = false;
                this.$refs['upload-image'].hide();
                setTimeout(() => {
                    this.onModalHide();
                }, 50);
            },
            triggerInput() {
                this.$refs['image-upload-input'].click();
            },
            dropFromArray(data, index) {
                this.imgList.splice(index, 1);
                this.fileContainer.splice(index, 1);
            },
            refreshVisibility(e) {
                this.visibility = e;
                console.log(e);
                /*if (this.visibility === 3) {
                    this.showUsersModal();
                }*/
            },
            dragOverE(e) {},
            handleUserPhoto(e, drop) {
                this.imgList = [];
                let container = !drop ? e.target.files : e.dataTransfer.files;
                container.forEach((o) => {
                    this.fileContainer.push(o)
                });
                this.fileContainer.forEach((o) => {
                    if (this.validTypes.indexOf(o.type) > -1) {
                        this.imgList.push({
                            url: URL.createObjectURL(o),
                            id: this.imgList.length+1,
                            name: o.name,
                            type: o.type,
                            size: (o.size/1024/1024).toFixed(2) + 'MB'
                        })
                    }
                });
            },
            submitUpload() {
                this.uploadLoading = true;
				setTimeout(() => {
					this.uploadLoading = false;
				}, 15000)
                this.fd = new FormData();
                for (let i = 0; i < this.fileContainer.length; i++) {
                    this.fd.append('files[]', this.fileContainer[i])
                }
				console.log(this.fileContainer);
				console.log(this.selected_users);
                if (this.isEditMode) {
                    usersService.editGalleryPostAPI({
                        post_id: this.editPostId,
                        description: this.description,
                        visibility: this.visibility,
                        is_comment_enabled: this.commentOption ? 1 : 0
                    }).then(res => {
                        this.$emit('imageEdited', res);
                        this.closeModal();
                        this.uploadLoading = false;
                    })
                } else {
                    if (this.fileContainer.length) {
	                    usersService.uploadGalleryAPI({
		                    files: this.fd,
		                    description: this.description,
		                    visibility: this.visibility,
		                    is_comment_enabled: this.commentOption ? 1 : 0
	                    }).then(res => {
		                    if (res) {
			                    if (this.selected_users.length) {
				                    /*let count = this.selected_users.length * this.fileContainer.length;
				                    for (let ii = 0; ii < this.selected_users.length; ii++) {
					                    usersService.getChatIdAPI({
						                    id: this.selected_users[ii].id
					                    }).then(response => {
						                    console.log(response);
						                    this.$firebaseChats.doc(response).get().then((querySnapshot) => {
							                    if (querySnapshot.exists) {
								                    console.log(querySnapshot.id);
								                    console.log(querySnapshot.data());
								                    for (let i = 0; i < this.fileContainer.length; i++) {
									                    var ref = this.$storage.ref();
									                    var pathRef = ref.child(`images/${this.moment().format('YYYY_MM_DD_hh_mm_ss')}_${this.fileContainer[i].name}`);
									                    pathRef.put(this.fileContainer[i]).then((snapshot) => {
										                    snapshot.ref.getDownloadURL().then((URL) => {
											                    let ref = this.$firebaseChats.doc(querySnapshot.id).collection('messages').doc();
											                    let refId = ref.id;
											                    let temp = {
												                    attachment: {
													                    type: 2,
													                    type_name: 'image',
													                    data: {
														                    authorId: this.getFireUserData.id,
														                    authorName: this.getFireUserData.name,
														                    text: null,
														                    path: URL
													                    }
												                    },
												                    authorId: this.getFireUserData.id,
												                    authorFull: this.getFireUserData,
												                    chatId: querySnapshot.id,
												                    id: refId,
												                    message: '',
												                    timestamp: this.$firebase.serverTimestamp,
												                    type: 0,
												                    editedAt: null,
												                    status: 0
											                    };
											                    this.$firebaseChats.doc(querySnapshot.id).collection('messages').doc(refId).set(temp).then(() => {
												                    this.$firebaseChats.doc(querySnapshot.id).get().then(c_obj => {
													                    this.$firebaseChats.doc(querySnapshot.id).update({
														                    updatedAt: this.$firebase.serverTimestamp,
														                    unreadCount: c_obj.data().unreadCount + 1,
														                    is_active: 1,
														                    lastMessage: {
															                    messageId: refId,
															                    text: 'фото',
															                    authorId: this.getFireUserData.id,
															                    authorName: this.getFireUserData.name,
															                    date: this.$firebase.serverTimestamp
														                    }
													                    }).then(() => {
														                    count -= 1;
														                    console.log(count);
														                    if (count === 0) {
															                    console.log('REAAAAAAAAAAAAAAADY');
															                    this.$emit('imagesAdded', res.data.id);
															                    this.closeModal();
															                    this.uploadLoading = false;
														                    }
													                    })
												                    })
											                    })
										                    })
									                    })
								                    }
							                    }
						                    })
					                    })
				                    }*/
			                    } else {
				                    this.$emit('imagesAdded', res.data.id);
				                    this.closeModal();
				                    this.uploadLoading = false;
			                    }
		                    } else {
			                    this.uploadLoading = false;
			                    this.$store.dispatch('alert/error', 'Что-то пошло не так :(', { root: true });
								
		                    }
	                    })
                    } else {
                        this.uploadLoading = false;
                    }
                }
            },
            showUsersModal() {
                this.usersList = {
                    users: [],
                    loading: false
                };
                //this.selected_users = [];
                this.getUsersForAskPersonalQuestion();
                this.$refs['add-users'].show();
                console.log(this.usersList);
            },
            resetUsersModal() {
                this.usersList = {
                    users: [],
                    loading: false
                };
            },
            getUsersForAskPersonalQuestion () {
                this.usersList.loading = true;
                usersService.getFriendsAPI({
                    perPage: 1000,
                    page: 1,
                    query: ''
                }).then(res => {
                    console.log(res);
                    this.usersList.users = res['data'];
                    console.log(this.usersList.users);
                    this.usersList.users.forEach(obj => {
                        obj['selected'] = false;
                        this.selected_users.forEach(user => {
                           if (obj.id === user.id) {
                               obj['selected'] = true;
                           }
                        });
                        obj['filtered'] = true;
                        obj['avatar_parsed'] = null;
                        handleAWSImage(obj.avatar_36).then((res) => {
                            obj['avatar_parsed'] = res;
                            this.$forceUpdate();
                        });
                    });
                    console.log(this.usersList.users);
                    console.log(this.selected_users);
                    if (this.selected_users?.length) {
                        this.selected_users.forEach((o) => {
                            this.usersList.users.forEach(obj => {
                                if (o.selected) {
                                    if (obj.id === o.id) {
                                        obj['selected'] = true;
                                    }
                                }
                            });
                        })
                    }
                    this.usersList.loading = false;
                });
            },
            selectUser(data) {
                data.selected = !data.selected;
                this.$forceUpdate();
            },
            filteredUsersList(data) {
                this.searchStr = data.searchStr;
                if (data.searchStr.length) {
                    data.arr.forEach(obj => {
                        this.usersList.users.forEach(c_obj => {
                            if (c_obj.id === obj.id) {
                                c_obj['filtered'] = true;
                            } else {
                                c_obj['filtered'] = false;
                            }
                            this.$forceUpdate()
                        });
                    });
                } else {
                    this.usersList.users.forEach(c_obj => {
                        c_obj['filtered'] = true;
                        this.$forceUpdate()
                    });
                }

            },
            isSelected() {
                return this.arr.filter((obj) => {return obj.selected})
            },
            addUsersToQuestion(select_all) {
                if (select_all) {
                    if (this.isSelected().length === this.arr.length) {
                        this.usersList.users.forEach(obj => {
                            obj['selected'] = false;
                            this.$forceUpdate();
                        });
                    } else {
                        this.usersList.users.forEach(obj => {
                            obj['selected'] = true;
                            this.$forceUpdate();
                        });
                    }
                } else {
                    this.selected_users = [...this.isSelected()];
                    console.log(this.selected_users);
                    this.$refs['add-users'].hide();
                }

            },
        },
        computed: {
            arr() {
                return this.usersList.users
            },
	        getFireUserData() {
		        return this.$store.state.firebase.firebaseUserData
	        },
        },
        beforeCreate() {},
        created() {},
        mounted() {},
        watch: {}

    }
</script>

<style lang="less">
    @import "../../../assets/styles/_vars.less";

    #upload-image {
        .modal-dialog {
            .modal-body {
                position: relative;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-start;
                .uploaded-data {
                    width: 59%;
                    &.edit-mode {
                        width: 100%;
                    }
                    .empty-placeholder {
                        font-size: 14px;
                    }
                    .image-preview-list, .empty-placeholder {
                        width: 100%;
                        min-height: 280px;
                        max-height: 280px;
                        overflow-y: auto;
                        .image-preview-wrapper {
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: flex-start;
                            margin-bottom: 10px;
                            position: relative;
                            .img-wrapper {
                                height: 50px;
                                overflow-y: hidden;
                                margin-right: 10px;
                                min-width: 70px;
                                img {
                                    width: 70px;
                                }
                            }
                            .image-details {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                align-items: center;
                                .img-name {
                                    width: 100%;
                                    font-size: 14px;
                                    max-width: 300px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .img-type {
                                    font-size: 12px;
                                    color: gray;
                                    width: 100%;
                                }
                            }
                            .image-controls {
                                position: absolute;
                                right: 5px;
                                top: 50%;
                                margin-top: -10px;
                                font-size: 20px;
                                padding: 4px;
                                cursor: pointer;
                                font-weight: bold;
                                color: #4AAE9B;
                                background: #b7b7b7;
                                width: 20px;
                                border: 1px solid white;
                                border-radius: 100%;
                            }
                        }
                    }
                    .image-additional-config {
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        .visibility-wrapper, .comment-option, .public-access-option {
                            font-size: 14px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }
                        .comment-option, .public-access-option {
                            label {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-wrap: wrap;
                                cursor: pointer;
                                margin: 0;
                                input {
                                    margin-right: 5px;
                                }
                            }
                        }
                        .selected-users-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            .user {
                                text-align: center;
                                cursor: pointer;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 5px 5px;
                                margin-bottom: 5px;
                                position: relative;
                                &.selected {
                                    background-color: #e0e0e0;
                                }
                                .image-as-bg {
                                    margin-right: 5px;
                                }
                                .name {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #333333; // rgba(51, 51, 51, 0.5);
                                    overflow-wrap: break-word;
                                }
                                img {
                                    width: 20px;
                                    position: absolute;
                                    right: 20px;
                                    top: 50%;
                                    margin-top: -10px;
                                }
                            }
                        }
                    }
                    .image-description {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        margin-bottom: 10px;
                        label {
                            width: 100%;
                            margin: 0;
                            font-size: 14px;
                        }
                        textarea {
                            width: 100%;
                            resize: none;
                            border-radius: 10px;
                            font-size: 14px;
                        }
                    }
                    .upload-controls {

                    }
                }
                .upload-area {
                    width: 39%;
                    min-height: 450px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px dashed #4495CE;
                    border-radius: 10px;
                    cursor: help;
                    flex-wrap: wrap;
                    input {
                        display: none;
                    }
                    .upload-explanation {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        i {
                            color: @theme_color;
                            font-size: 40px;
                            width: 100%;
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        span {
                            color: gray;
                            width: 100%;
                            text-align: center;
                            &:last-of-type {
                                font-size: 12px;
                                margin-bottom: 5px;
                            }
                        }
                        button {
                            border: none;
                            background-color: #4495ce;
                            color: white;
                            font-size: 14px;
                            padding: 5px 10px;
                        }
                    }
                }
            }
        }
    }
    #add-users, #share-photos {
        .modal-dialog {
            .modal-body {
                padding: 0;
                position: relative;
                .question-wrapper {
                    padding: 20px;
                    text-align: center;
                    display: flex;
                    min-height: 100px;
                    background: #5EBD9C;
                    justify-content: center;
                    align-items: center;
                    p {
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 21px;
                        color: #000000;
                        margin-bottom: 0;
                    }
                }
                .users-list-wrapper {
                    padding: 20px;
                    max-height: 350px;
                    min-height: 350px;
                    overflow-y: auto;
                    &.loading {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &::-webkit-scrollbar{
                        height: 4px;
                        width: 4px;
                        background: gray;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb{
                        background: lightgrey;
                        border-radius: 10px;
                    }
                    .users-list {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        .divider {
                            color: #666;
                            font-size: 11px;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                        .user {
                            text-align: center;
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 5px 5px;
                            margin-bottom: 4px;
                            border-radius: 10px;
                            position: relative;
                            &.selected {
                                background-color: #e0e0e0;
                            }
                            .image-as-bg {
                                margin-right: 15px;
                            }
                            .name {
                                font-weight: 500;
                                font-size: 12px;
                                color: #333333; // rgba(51, 51, 51, 0.5);
                                overflow-wrap: break-word;
                            }
                            img {
                                width: 20px;
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                margin-top: -10px;
                            }
                        }
                    }
                }
                .modal-controls {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    background: #fff;
                    bottom: 0;
                    right: 0;
                    padding: 10px 20px;
                    justify-content: flex-end;
                    button {
                        &:last-child {
                            margin-left: 5px;
                        }
                        background-color: @theme_color;
                        color: #ffffff;
                        min-height: 32px;
                        min-width: 60px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -ms-flex-line-pack: center;
                        align-content: center;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        border: none;
                        border-radius: 35px;
                        padding: 5px 15px;
                        -webkit-transition: all 250ms;
                        transition: all 250ms;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        cursor: pointer;
                        outline: none;
                        max-width: 130px;
                        width: 100%;
                        &:focus {
                            box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
                        }
                    }
                }
            }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #upload-image {
            .modal-dialog {
                .modal-body {
                    flex-direction: column-reverse;
                    .uploaded-data {
                        width: 100%;
                        &.edit-mode {}
                        .empty-placeholder {}
                        .image-preview-list, .empty-placeholder {
                            margin-bottom: 15px;
                            min-height: 160px;
                            .image-preview-wrapper {
                                .img-wrapper {
                                    img {}
                                }
                                .image-details {
                                    .img-name {}
                                    .img-type {}
                                }
                                .image-controls {}
                            }
                        }
                        .image-additional-config {
                            .visibility-wrapper, .comment-option, .public-access-option {}
                            .comment-option, .public-access-option {
                                label {
                                    input {}
                                }
                            }
                        }
                        .image-description {
                            label { }
                            textarea {}
                        }
                        .upload-controls {}
                    }
                    .upload-area {
                        width: 100%;
                        min-height: 140px;
                        margin-bottom: 15px;
                        input {}
                        .upload-explanation {
                            i {}
                            span {
                                &:last-of-type {}
                            }
                            button { }
                        }
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {}

    @media (max-width: 350px) {}
</style>
