<template>
    <div :class="'person-progress ' + specificClass" :style="{'width': width + 'px'}" >
        <span :style="{'height': height + 'px', 'width': green + '%'}" class="progress-green"></span>
        <span :style="{'height': height + 'px', 'width': red + '%'}" class="progress-red"></span>
        <span :style="{'height': height + 'px', 'width': rest + '%'}" class="progress-gray"></span>
        <span :style="{'height': height + 'px', 'width': gray + '%'}" class="progress-lightgray"></span>
    </div>
</template>

<script>
    export default {
        name: 'personProgress',
        props: {
            specificClass: {
                type: String,
                required: false
            },
            height: {
                type: String,
                required: false
            },
            width: {
                type: String,
                required: false
            },
            red: {
                required: true
            },
            green: {
                required: true
            },
            gray: {
                required: true
            }
        },
        data() {
            return {
                rest: 100
            }
        },
        created() {
            this.rest = 100 - (this.red + this.green + this.gray);
        }
    }
</script>

<style lang="less" scoped>
    .person-progress {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        background-color: #e7e7e7;
        border-radius: 10px;
        overflow: hidden;
        span {
            height: 3px;
        }
        .progress-green {
            background-color: #b0d4ac;
        }
        .progress-red {
            background-color: #eca2a4;
        }
        .progress-gray {
            background-color: #cccccc;
        }
        .progress-lightgray {
            background-color: #e7e7e7;
        }
    }
</style>
